import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Axios from "axios";
import {
  REFERENCE_TYPE,
  LOADER_RED,
  GET_VISIT_PRESCRIPTION_PDF,
} from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import { Modal } from "antd";
// import "chartjs-plugin-zoom";
class PaediatricChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRxModal: false,
      rxdataLoaded: false,
      rxPdfPath: null,
      options: {},
    };
    this.component = this;
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return true;
  }

  componentDidMount() {
    const __data = this.props.data;
    const cf = this.component;
    this.setState({
      options: {
        onClick: function (evt, item) {
          if (item.length) {
            let activePoint = this.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
            let index = activePoint[0].index;
            if (
              activePoint[0].element.$datalabels[0].$context.dataset.type == "scatter"
            ) {
              let reference_id = item.length && activePoint[0].element.$datalabels[0].$context.dataset.data[index].z;
              cf.handleModalPopup(null, "showRxModal", reference_id);
            }
          }
        },
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        chartArea: {
          backgroundColor: "rgba(0, 0, 0, 1)",
        },
        hover: {
          mode: "index",
          intersect: true,
        },
        plugins: {
          tooltip: {
            //intersect: true,
            displayColors: false,
            callbacks: {
              title: function (tt) {
                return (
                  "Age: " + tt[0].label + " " + cf.props.otheroptions.ageLabel
                );
              },
              label: function (context) {
                if (__data.datasets[context.datasetIndex]?.type == "line") {
                  var label2 =
                    __data.datasets[context.datasetIndex]?.label2 || "";

                  if (label2) {
                    label2 += ": ";
                  }
                  label2 += Math.round(context.parsed.y * 100) / 100;
                  if (
                    __data.datasets[context.datasetIndex].group ==
                      "Height for age" ||
                    __data.datasets[context.datasetIndex].group ==
                      "Head CC for age"
                  ) {
                    label2 += " cms.";
                  } else {
                    label2 += " kgs.";
                  }
                  return label2;
                } else if (
                  __data.datasets[context.datasetIndex].type == "scatter"
                ) {
                  var label = __data.datasets[context.datasetIndex].label || "";
                  let suffix = "";
                  if (label == "Height" || label == "Head Circumference") {
                    suffix = " cms.";
                  } else {
                    suffix = " kgs.";
                  }
                  if (label) {
                    label += ": ";
                  }
                  label += context.parsed.y + suffix;
                  return label;
                }
              },
            },
          },
          datalabels: {
            align: "end",
            anchor: "end",
            color: "#000",
            /* color: function(context) {
                            return context.dataset.borderColor;
                        }, */
            font: function (context) {
              var w = context.chart.width;
              return {
                size: w < 512 ? 12 : 14,
                weight: "normal",
              };
            },
            formatter: function (value, context) {
              if (
                context.dataset.type == "line" &&
                context.dataset.labeltype == "97"
              ) {
                if (
                  cf.props.otheroptions.XRight == 36 &&
                  context.dataIndex == context.dataset.data.length - 5
                ) {
                  return context.dataset.group;
                } else if (
                  cf.props.otheroptions.XRight == 240 &&
                  context.dataIndex == context.dataset.data.length - 15
                ) {
                  return context.dataset.group;
                } else if (
                  cf.props.otheroptions.XRight == 24 &&
                  context.dataIndex == context.dataset.data.length - 3
                ) {
                  return context.dataset.group;
                } else if (
                  cf.props.otheroptions.XRight == 60 &&
                  context.dataIndex == context.dataset.data.length - 5
                ) {
                  return context.dataset.group;
                } else if (
                  cf.props.otheroptions.XRight == 18 &&
                  context.dataIndex == context.dataset.data.length - 3
                ) {
                  return context.dataset.group;
                } else {
                  return null;
                }
              } else {
                return null;
              }
            },
          },
          legend: {
            display: false,
            position: "right",
            //padding: 20,
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 30,
              },
            },
          },
          title: {
            display: true,
            text: cf.props.otheroptions.label + " Growth Charts",
            ///fontSize: 18,
            //fontStyle: "normal",
            //fontFamily: "Arial, sans-serif, Helvetica",
            color: "#706c6c",
            //lineHeight: 1.6,
            font: {
              size: 18,
              family: "Arial, sans-serif, Helvetica",
              style: "normal",
              lineHeight: 1.6,
            },
          },
        },
        scales: {
          bottom: {
            type: "linear",
            position: "bottom",
            display: true,
            ticks: {
              /* beginAtZero: true, */
              stepSize: 0.5,
              /* callback: function(value, index, values) {
                                      return  value + 'month';
                                  } */
            },
            max: cf.props.otheroptions.XRight,
            min: cf.props.otheroptions.XLeft,
            // scaleLabel: {
            //   display: true,
            //   labelString: "Age in " + cf.props.otheroptions.ageLabel,
            //   fontSize: 20,
            // },
            title: {
              display: true,
              text: "Age in " + cf.props.otheroptions.ageLabel,
              color: "#706c6c",
              font: {
                size: 18,
                weight: "bold",
              },
              padding: { bottom: 35 },
            },
          },
          L: {
            type: "linear",
            position: "left",
            display: true,
            ticks: {
              beginAtZero: true,
              stepSize: 5,
            },
            max: cf.props.otheroptions.YTop,
            min: cf.props.otheroptions.YBottom,
          },

          // xAxes: [
          //   {
          //     type: "linear",
          //     display: true,
          //     position: "bottom",
          //     xAxisID: "bottom",
          //     ticks: {
          //       /* beginAtZero: true, */
          //       //stepSize: 0.5,
          //       // max: cf.props.otheroptions.XRight,
          //       // min: cf.props.otheroptions.XLeft,
          //       /* callback: function(value, index, values) {
          //                         return  value + 'month';
          //                     } */
          //     },
          //     scaleLabel: {
          //       display: true,
          //       // labelString: "Age in " + cf.props.otheroptions.ageLabel,
          //       // fontSize: 20,
          //     },
          //   },
          // ],
          // yAxes: [
          //   {
          //     display: true,
          //     position: "left",
          //     id: "L",
          //     ticks: {
          //       beginAtZero: true,
          //       // stepSize: 5,
          //       // max: cf.props.otheroptions.YTop,
          //       // min: cf.props.otheroptions.YBottom,
          //     },
          //   },
          // ],
        },
        layout: {
          // padding: {
          //   top: 8,
          //   left: 8,
          //   right: 8,
          //   bottom: 20,
          // },
        },
      },
    });
  }

  // For Modal popup
  handleModalPopup = async (e, popup, reference_id) => {
    if (e !== null) e.preventDefault();
    this.setState({
      [popup]: !this.state[popup],
    });
    if (popup == "showRxModal") {
      const { patient_id, hospital_id } = this.props.patientUrlData;
      const PARAMS = {
        hospital_id,
        reference_type: REFERENCE_TYPE,
        reference_id: reference_id,
        patient_id,
      };
      const getVisitPromise = await Axios.get(
        GET_VISIT_PRESCRIPTION_PDF(PARAMS)
      );
      if (getVisitPromise.data.status) {
        const { data } = getVisitPromise;
        this.setState({
          rxdataLoaded: true,
        });
        if (Object.keys(data.data).length > 0) {
          this.setState({
            rxPdfPath: data.data.visitPdf,
          });
        }
      }
    }
  };

  render() {
    return (
      <>
        {Object.keys(this.state.options).length ? (
          <Line
            id="myCanvas"
            data={this.props.data}
            options={this.state.options}
            plugins={[ChartDataLabels]}
          />
        ) : null}

        {/* // Chart Modal */}
        <Modal
          title="View Prescription"
          visible={this.state.showRxModal}
          footer={false}
          width={850}
          onCancel={() => {
            this.setState({
              showRxModal: false,
              rxdataLoaded: false,
              rxPdfPath: null,
            });
          }}
          style={{ top: 20 }}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              {this.state.rxdataLoaded ? (
                this.state.rxPdfPath != null ? (
                  <iframe
                    src={this.state.rxPdfPath + "#toolbar=0"}
                    frameBorder="0"
                    scrolling="no"
                    style={{ overflow: "hidden" }}
                    width="100%"
                    height="700"
                  >
                    {LOADER_RED}
                  </iframe>
                ) : (
                  "No prescription is generated for the visit."
                )
              ) : (
                LOADER_RED
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default PaediatricChart;
