import React, { Component } from "react";
import Axios from "axios";
import { Form, Modal, Input, Radio, Select, notification } from "antd";
import { LOADER } from "../../utils/constant";
import {
  jQueryLabels,
  AntSelectLabels,
  AntDisabledLabels,
  onlynumbers,
} from "../../utils/jQueryLabels";
import moment from "moment";
import { CDC, WHO, IAP, WHOIAP } from "../../utils/growthchartdata";
import PaediatricChart from "../../components/chart/paediatrics-chart";
import LS_SERVICE from "../../utils/localStorage";

import { Link } from "react-router-dom";

class PaediatricGrowthChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomClass: false,
      showChart: true,
      type: null,
      growth_chart: null,
      patient: {},
      chartData: {},
      constants: {},
      data: {},
      otheroptions: {},
      gender: props.patient.gender == "Female" ? "girls" : "boys",
      ChartDropDownOptions: {
        boys: [
          {
            value: "0-5",
            type: "WHO",
            label: "WHO Boys Height, Weight and Head Chart 0 - 5 yrs",
          },
          {
            value: "0-18",
            type: "WHOIAP",
            label: "Boys 0 - 18 IAP and WHO combined Charts Height and Weight",
          },
          {
            value: "5-18",
            type: "IAP",
            label: "IAP Boys Height and Weight Charts 5 - 18 years",
          },
          {
            value: "2-20",
            type: "CDC",
            label: "CDC Boys Height and Weight  2 - 20 years",
          },
          {
            value: "0-36",
            type: "CDC",
            label: "CDC Boys Height, Weight and Head Chart Birth to 36 months",
          },
          {
            value: "0-2",
            type: "WHO",
            label: "WHO Boys Height, Weight and Head Chart Birth - 2 yrs",
          },
          {
            value: "2-5",
            type: "WHO",
            label: "WHO Boys Height, Weight and Head Chart 2 - 5 yrs",
          },
        ],
        girls: [
          {
            value: "0-5",
            type: "WHO",
            label: "WHO Girls Height, Weight and Head Chart 0 - 5 yrs",
          },
          {
            value: "0-18",
            type: "WHOIAP",
            label: "Girls 0 - 18 IAP and WHO combined Charts Height and Weight",
          },
          {
            value: "5-18",
            type: "IAP",
            label: "IAP Girls Height and Weight Charts 5 - 18 years",
          },
          {
            value: "2-20",
            type: "CDC",
            label: "CDC Girls Height and Weight  2 - 20 years",
          },
          {
            value: "0-36",
            type: "CDC",
            label: "CDC Girls Height, Weight and Head Chart Birth to 36 months",
          },
          {
            value: "0-2",
            type: "WHO",
            label: "WHO Girls Height, Weight and Head Chart Birth - 2 yrs",
          },
          {
            value: "2-5",
            type: "WHO",
            label: "WHO Girls Height, Weight and Head Chart 2 - 5 yrs",
          },
        ],
      },
    };
    this.parentHandleChange = this.parentHandleChange.bind(this);
    this.printCheckBoxChange = this.printCheckBoxChange.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.initSpeech = this.initSpeech.bind(this);
  }
  async componentDidMount() {
    const { patient, chartData, constants } = this.props;
    await this.setState({
      patient,
      chartData: Object.values(chartData.visitVitals),
      constants,
    });

    let paedVitalsConstant = this.state.constants
      ? this.state.constants.vital_groups["VG002"]
        ? this.state.constants.vital_groups["VG002"].vital_sign
        : this.state.constants.vitals_details
      : [];

    let age = "0-5";
    let type = "WHO";
    let lebel = "";
    if (this.state.gender == "girls") {
      lebel = "WHO Girls Height, Weight and Head Chart 0 - 5 yrs";
    } else {
      lebel = "WHO Boys Height, Weight and Head Chart 0 - 5 yrs";
    }

    if (patient.monthsage >= 60 && patient.country == "India") {
      age = "5-18";
      type = "IAP";
      if (this.state.gender == "girls") {
        lebel = "IAP Girls Height and Weight Charts 5 - 18 years";
      } else {
        lebel = "IAP Boys Height and Weight Charts 5 - 18 years";
      }
    } else if (patient.monthsage >= 60 && patient.country != "India") {
      age = "2-20";
      type = "CDC";
      if (this.state.gender == "girls") {
        lebel = "CDC Girls Height and Weight  2 - 20 years";
      } else {
        lebel = "CDC Boys Height and Weight  2 - 20 years";
      }
    }

    await this.setState({
      paedVitalsConstant,
      growth_chart: age,
    });

    this.setChartData(type, age, lebel);
    jQueryLabels();
    AntSelectLabels();
    onlynumbers();
  }

  async setChartData(type, age, label) {
    await this.setState({
      type,
    });
    this.props.setChartTypeAgeToPrint(type, age);
    let data = {};
    if (type == "CDC") {
      type = CDC;
    } else if (type == "WHO") {
      type = WHO;
    } else if (type == "IAP") {
      type = IAP;
    } else if (type == "WHOIAP") {
      type = WHOIAP;
    } else {
      type = null;
    }
    let gendertype = this.state.gender;
    if (type && type[gendertype]) {
      const findex = type[gendertype].findIndex((x) => x.age == age);
      if (findex !== -1) {
        //data.labels = CDCDATA.boys[findex].monthlabels;
        data.datasets = [];
        if (type[gendertype][findex].datasets.height) {
          type[gendertype][findex].datasets.height.forEach((ele, i) => {
            let obj = {
              type: "line",
              label: ele.label,
              label2: ele.label2,
              data: ele.data,
              labeltype: ele.labeltype,
              group: "Height for age",
              xAxisID: "bottom",
              yAxisID: "L",
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0,
            };
            data.datasets.push(obj);
          });
        }
        if (type[gendertype][findex].datasets.headcc) {
          type[gendertype][findex].datasets.headcc.forEach((ele, i) => {
            let obj = {
              type: "line",
              label: ele.label,
              label2: ele.label2,
              data: ele.data,
              labeltype: ele.labeltype,
              group: "Head CC for age",
              xAxisID: "bottom",
              yAxisID: "L",
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0,
            };
            data.datasets.push(obj);
          });
        }
        if (type[gendertype][findex].datasets.weight) {
          type[gendertype][findex].datasets.weight.forEach((ele, i) => {
            let obj = {
              type: "line",
              label: ele.label,
              label2: ele.label2,
              data: ele.data,
              labeltype: ele.labeltype,
              group: "Weight for age",
              xAxisID: "bottom",
              yAxisID: "L",
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0,
            };
            data.datasets.push(obj);
          });
        }

        let vitals = [];
        this.state.paedVitalsConstant.forEach((data, i) => {
          let obj = {};
          obj.id = data.id;
          obj.display_name = data.display_name;
          obj.values = [];
          this.state.chartData.forEach((d, j) => {
            if (d[obj.id] && d[obj.id] != undefined && d[obj.id] != "") {
              let findind;
              /* let dayofmonth;
              let dayofyear; */
              let findvalue;
              /* if(type[gendertype][findex].ageLabel == 'months'){
                dayofmonth = moment(d.date).date();
                if(dayofmonth > 15){
                  findvalue = moment(d.date).format("YYYY-MM") + '.5';
                } else {
                  findvalue = moment(d.date).format("YYYY-MM");
                }
                findind = obj.values.findIndex(x => x.month == findvalue);
              }
              if(type[gendertype][findex].ageLabel == 'years'){
                dayofyear = moment(d.date).dayOfYear();
                if(dayofyear >= 183){
                  findvalue = moment(d.date).format("YYYY") + '.5';
                } else {
                  findvalue = moment(d.date).format("YYYY");
                }
                findind = obj.values.findIndex(x => x.year == findvalue);
              } */
              if (type[gendertype][findex].ageLabel == "months") {
                //objvalues.month = findvalue;
                if (
                  this.state.patient.cdob &&
                  this.state.patient.monthsage == this.state.patient.cmonthsage
                ) {
                  findvalue = moment(new Date(d.date))
                    .diff(new Date(this.state.patient.cdob), "months", true)
                    .toFixed(1);
                } else {
                  findvalue = moment(new Date(d.date))
                    .diff(new Date(this.state.patient.dob), "months", true)
                    .toFixed(1);
                }
              }
              if (type[gendertype][findex].ageLabel == "years") {
                //objvalues.year = findvalue;
                if (
                  this.state.patient.cdob &&
                  this.state.patient.monthsage == this.state.patient.cmonthsage
                ) {
                  findvalue = moment(new Date(d.date))
                    .diff(new Date(this.state.patient.cdob), "years", true)
                    .toFixed(1);
                } else {
                  findvalue = moment(new Date(d.date))
                    .diff(new Date(this.state.patient.dob), "years", true)
                    .toFixed(1);
                }
              }
              findind = obj.values.findIndex((x) => x.age == findvalue);
              if (findind === -1) {
                let objvalues = {};
                objvalues.val = d[obj.id];
                objvalues.date = d.date;
                objvalues.age = findvalue;
                /* if(type[gendertype][findex].ageLabel == 'months'){
                  //objvalues.month = findvalue;
                  if(this.state.patient.cdob && this.state.patient.monthsage == this.state.patient.cmonthsage){
                    objvalues.age = moment(new Date(d.date)).diff(new Date(this.state.patient.cdob), 'months', true).toFixed(1);
                  } else {
                    objvalues.age = moment(new Date(d.date)).diff(new Date(this.state.patient.dob), 'months', true).toFixed(1);
                  }
                }
                if(type[gendertype][findex].ageLabel == 'years'){
                  //objvalues.year = findvalue;
                  if(this.state.patient.cdob && this.state.patient.monthsage == this.state.patient.cmonthsage){
                    objvalues.age = moment(new Date(d.date)).diff(new Date(this.state.patient.cdob), 'years', true).toFixed(1);
                  } else {
                    objvalues.age = moment(new Date(d.date)).diff(new Date(this.state.patient.dob), 'years', true).toFixed(1);
                  }
                } */
                objvalues.reference_id = d.reference_id;
                obj.values.push(objvalues);
              }
            }
          });
          vitals.push(obj);
        });

        let vitals_max_value = 0;
        vitals.forEach((dat, i) => {
          let include = true;
          if (dat.id == "V016" && !type[gendertype][findex].datasets.headcc) {
            include = false;
          }
          if (include) {
            let scatterdata = [];
            if (
              (dat.id == "V001" &&
                type[gendertype][findex].datasets.height &&
                data.datasets[0]) ||
              (dat.id == "V016" &&
                type[gendertype][findex].datasets.headcc &&
                data.datasets[1]) ||
              (dat.id == "V002" &&
                type[gendertype][findex].datasets.weight &&
                data.datasets[2])
            ) {
              dat.values.forEach((d, j) => {
                if (parseInt(d.val) > vitals_max_value) {
                  vitals_max_value = parseInt(d.val);
                }
                scatterdata.push({ x: d.age, y: d.val, z: d.reference_id });
              });
              let scatterobj = {
                type: "scatter",
                label: dat.display_name,
                data: scatterdata,
                xAxisID: "bottom",
                yAxisID: "L",
                backgroundColor: "rgba(255, 0, 0, 1)",
                borderWidth: 1,
                radius: 5,
              };
              data.datasets.push(scatterobj);
            }
          }
        });
        let ytop = Math.max(type[gendertype][findex].YTop, vitals_max_value);
        let divider = 5;
        /* if (ytop >= 100) {
          divider = 10;
        } */
        let remainder = ytop % divider;
        ytop = ytop + (divider - remainder);
        await this.setState((prevState) => {
          let prevItems = { ...prevState.otheroptions };
          prevItems.XLeft = type[gendertype][findex].XLeft;
          prevItems.XRight = type[gendertype][findex].XRight;
          prevItems.YBottom = type[gendertype][findex].YBottom;
          prevItems.YTop = ytop;
          prevItems.ageLabel = type[gendertype][findex].ageLabel;
          prevItems.label = label;
          return { otheroptions: prevItems };
        });
      }
      await this.setState({
        data,
      });
    }
  }

  /* autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  } */

  zoomChart = (e) => {
    e.preventDefault();
    const { zoomClass } = this.state;
    this.setState({
      zoomClass: !this.state.zoomClass,
    });
  };

  printChart = (e) => {
    let selHospitalData = Object.values(LS_SERVICE.get("slot_hospitals")).find(
      (item) => item.id == this.props.patientUrlData.hospital_id
    );
    let selHospitalDataImage = selHospitalData.hospital_image;
    var dataUrl = document.getElementById("myCanvas").toDataURL();
    var windowContent = "<!DOCTYPE html>";
    windowContent += "<html>";

    windowContent +=
      '<head><title>Print Chart</title><style type="text/css" media="print">@page {size: landscape; margin:0}</style></head>';
    windowContent += "<body>";
    windowContent +=
      '<div style="width: 100%; display: table;">' +
      '<div style="display: table-row; height: 60px;">' +
      '<div style="width: 50%;display: table-cell;vertical-align: top;font-weight: bold; font-family: Roboto, sans-serif, Helvetica; color: #333; padding: 5px 0px; font-size: 22px;">' +
      "<span>Growth Chart</span>" +
      "</div>" +
      '<div style="display: table-cell;">' +
      '<span style="display:block;float: right;">' +
      '<img style="height:auto;width: 120px;margin-right:20px;" src="' +
      selHospitalDataImage +
      '" ' +
      'alt="Hospital Image"></img></span>' +
      "</div>" +
      "</div>" +
      "</div>";
    windowContent +=
      '<div style="width: 100%; display: table;background-color: #f4f4f4" >' +
      '<div style="margin-bottom:10px ;vertical-align: middle; color: #555; padding: 7px 10px 5px 12px; font-size: 18px;' +
      'font-family: Roboto; sans-serif, Helvetica">' +
      '<p style="margin-bottom: 1px;"><strong>Patient Name: </strong>' +
      (this.state.patient.first_name !== undefined &&
      this.state.patient.first_name !== null
        ? this.state.patient.first_name.charAt(0).toUpperCase() +
          this.state.patient.first_name.slice(1) +
          " "
        : "") +
      (this.state.patient.last_name !== undefined &&
      this.state.patient.last_name !== null
        ? this.state.patient.last_name.charAt(0).toUpperCase() +
          this.state.patient.last_name.slice(1) +
          ", "
        : "") +
      (this.state.patient.dob !== undefined && this.state.patient.age !== null
        ? this.getPatientAge(this.state.patient.dob) + ", "
        : "") +
      (this.state.patient.gender !== undefined &&
      this.state.patient.gender !== null
        ? this.state.patient.gender
        : null) +
      "</p>" +
      "<p><strong>Address: </strong>" +
      (this.state.patient.address_1 !== undefined &&
      this.state.patient.address_1 !== null
        ? this.state.patient.address_1 + ", "
        : "") +
      (this.state.patient.address_2 !== undefined &&
      this.state.patient.address_2 !== null
        ? this.state.patient.address_2 + ", "
        : "") +
      (this.state.patient.city !== undefined && this.state.patient.city !== null
        ? this.state.patient.city + ", "
        : "") +
      (this.state.patient.country !== undefined &&
      this.state.patient.country !== null
        ? this.state.patient.country
        : null) +
      "</p>" +
      "</div>" +
      "</div>";
    windowContent +=
      '<div style="text-align: center; width:100%; display:block;"><img style="width:100%; height:auto" src="' +
      dataUrl +
      '"></div>';
    windowContent +=
      '<div style="text-align: center; width:100%; display:block; font-family: sans-serif; font-size:12px; color:#666; margin-top:10px">';
    if (this.state.type == "WHO") {
      windowContent +=
        '<span style="display:inline-block; width:15px; height:10px; margin:0 10px; background:#ec003b"></span> 1st Percentile ';
      windowContent +=
        '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#ffec00"></span> 3rd Percentile ';
      windowContent +=
        '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#5ac9ef"></span> 50th Percentile ';
      windowContent +=
        '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#aaaaaa"></span> 97th Percentile';
    }
    if (this.state.type == "CDC") {
      windowContent +=
        '<span style="display:inline-block; width:15px; height:10px; margin:0 10px; background:#ec003b"></span> 3rd Percentile ';
      windowContent +=
        '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#ffec00"></span> 5th Percentile ';
      windowContent +=
        '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#5ac9ef"></span> 50th Percentile ';
      windowContent +=
        '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#aaaaaa"></span> 97th Percentile';
    }
    if (this.state.type == "IAP" || this.state.type == "WHOIAP") {
      windowContent +=
        '<span style="display:inline-block; width:15px; height:10px; margin:0 10px; background:#ec003b"></span> 3rd Percentile ';
      /* windowContent += '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#ffec00"></span> 3rd Percentile '; */
      windowContent +=
        '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#5ac9ef"></span> 50th Percentile ';
      windowContent +=
        '<span style="display:inline-block; width:15px; margin:0 10px; height:10px; background:#aaaaaa"></span> 97th Percentile';
    }

    windowContent += "</div>";
    windowContent += "</body>";
    windowContent += "</html>";
    let newWin = window.open("");
    let is_chrome = Boolean(newWin.chrome);
    newWin.document.write(windowContent);

    newWin.document.close(); // necessary for IE >= 10

    if (is_chrome) {
      // wait until all resources loaded
      newWin.onload = function () {
        // wait until all resources loaded
        newWin.focus(); // necessary for IE >= 10
        newWin.print(); // change window to mywindow
        newWin.close(); // change window to mywindow
      };
    } else {
      newWin.document.close(); // necessary for IE >= 10
      newWin.focus(); // necessary for IE >= 10
      newWin.print();
      newWin.close();
    }

    // newWin.document.addEventListener('load', function() {
    //   newWin.document.close();
    //   newWin.focus();
    //   newWin.print();
    //   newWin.close();
    // }, true);
  };

  //for edit input value
  async handleChange(event, field) {
    event.preventDefault();
    let value = event.target.value;
    if (field && field == "growth_chart") {
      this.setState({ showChart: false });
      let findex = this.state.ChartDropDownOptions[this.state.gender].findIndex(
        (x) => x.value == value
      );
      let type = "";
      let label = "";
      if (findex !== -1) {
        type = this.state.ChartDropDownOptions[this.state.gender][findex].type;
        label =
          this.state.ChartDropDownOptions[this.state.gender][findex].label;
        await this.setChartData(type, value, label);
        await this.setState({ showChart: true });
      }
    }

    field
      ? this.setState({
          [field]: value,
        })
      : this.setState({
          [event.target.name]: value,
        });
  }

  getPatientAge(dob) {
    var ageD = Math.floor(
      moment().diff(moment(dob, "YYYY-MM-DD"), "D") / (1000 * 3600 * 24)
    );
    var ageM = moment().diff(moment(dob, "YYYY-MM-DD"), "M");
    var ageY = ageM / 12;
    var remainM = ageM % 12;
    ageY = Math.floor(ageY);
    var ageText = "";
    if (ageM == 0) ageText = ageD + " Day" + (ageD == 1 ? "" : "s");
    else if (ageY < 1) ageText = ageM + " Month" + (ageM == 1 ? "" : "s");
    else if (ageY <= 5) {
      if (remainM != 0) {
        ageText =
          ageY +
          " Year" +
          (ageY == 1 ? "" : "s ") +
          remainM +
          " Month" +
          (remainM == 1 ? "" : "s");
      } else {
        ageText = ageY + " Year" + (ageY == 1 ? "" : "s");
      }
    } else ageText = ageY + " Years";
    return ageText;
  }

  async parentHandleChange(e, field) {
    this.props.parentHandleChange(e, field);
  }

  printCheckBoxChange(val) {
    this.props.printCheckBoxChange(val);
  }

  async handleContinue() {
    this.props.handlePaediatricContinue();
  }

  initSpeech(info) {
    this.props.initSpeech(info);
  }

  render() {
    const textareaSizeDefault = { minRows: 3, maxRows: 6 };
    return (
      <>
        <div className="row mb-3">
          <div className="col-5">
            <select
              onChange={(e) => this.handleChange(e, "growth_chart")}
              value={this.state.growth_chart}
              className="form-control"
              name="growth_chart"
              id="growth_chart"
            >
              {this.state.ChartDropDownOptions[this.state.gender].map(
                (d, i) => (
                  <option key={i} value={d.value} type={d.type}>
                    {d.label}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="col">
            {this.state.patient.monthsage >= 60 ? (
              <>
                <div
                  className={
                    this.props.father_height
                      ? "antInputItem hasdata"
                      : "antInputItem"
                  }
                >
                  <Form.Item
                    name="father_height"
                    label="Father's Height"
                    id="father_height"
                    onChange={(e) =>
                      this.parentHandleChange(e, "father_height")
                    }
                    rules={[
                      {
                        pattern: /^\d{1,3}(\.\d{1,2})?$/,
                        message: "Not allowed!",
                      },
                      /* {max: 8, message: 'Max 8 chars.'} */
                    ]}
                  >
                    <Input
                      disabled={!this.props.allowEdit}
                      defaultValue={this.props.father_height}
                      addonAfter="cm"
                    />
                  </Form.Item>
                </div>
                {/* <input type="text" className="form-control" id="fathers_height" placeholder="Father's Height" />
              <label htmlFor="fathers_height">Father's Height</label> */}
              </>
            ) : null}
          </div>
          <div className="col">
            {this.state.patient.monthsage >= 60 ? (
              <>
                <div
                  className={
                    this.props.mother_height
                      ? "antInputItem hasdata"
                      : "antInputItem"
                  }
                >
                  <Form.Item
                    name="mother_height"
                    label="Mother's Height"
                    id="mother_height"
                    onChange={(e) =>
                      this.parentHandleChange(e, "mother_height")
                    }
                    rules={[
                      {
                        pattern: /^\d{1,3}(\.\d{1,2})?$/,
                        message: "Not allowed!",
                      },
                      /* {max: 8, message: 'Max 8 chars.'} */
                    ]}
                  >
                    <Input
                      disabled={!this.props.allowEdit}
                      defaultValue={this.props.mother_height}
                      addonAfter="cm"
                    />
                  </Form.Item>
                </div>
                {/* <input type="text" className="form-control" id="mothers_height" placeholder="Mother's Height" />
              <label htmlFor="mothers_height">Mother's Height</label> */}
              </>
            ) : null}
          </div>
          <div
            className={
              this.props.target_height_min || this.props.target_height_max
                ? "col antInputItem multi-col hasdata"
                : "col antInputItem multi-col"
            }
          >
            {this.state.patient.monthsage >= 60 ? (
              <div className="multi-input-col-inner">
                <div className="ant-input-wrapper ant-input-group">
                  <div className="ant-input target_height">
                    <div className="ant-col ant-form-item-label">
                      <label
                        for="target_height_min"
                        className=""
                        title="Target Height"
                      >
                        Target Height
                      </label>
                    </div>
                    <Form.Item
                      name="target_height_min"
                      id="target_height_min"
                      onChange={(e) =>
                        this.parentHandleChange(e, "target_height_min")
                      }
                      rules={[
                        {
                          pattern: /^\d{1,3}(\.\d{1,2})?$/,
                          message: "Not allowed!",
                        },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input
                        disabled={!this.props.allowEdit}
                        defaultValue={this.props.target_height_min}
                      />
                    </Form.Item>
                    <Form.Item
                      name="target_height_max"
                      id="target_height_max"
                      className="second_col"
                      onChange={(e) =>
                        this.parentHandleChange(e, "target_height_max")
                      }
                      rules={[
                        {
                          pattern: /^\d{1,3}(\.\d{1,2})?$/,
                          message: "Not allowed!",
                        },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input
                        disabled={!this.props.allowEdit}
                        defaultValue={this.props.target_height_max}
                      />
                    </Form.Item>
                  </div>
                  <span className="ant-input-group-addon">cm</span>
                </div>
              </div>
            ) : null}
          </div>
          {this.props.paediatric_chart_print ? (
            <div className="col-12 text-right">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  disabled={!this.props.allowEdit}
                  className="custom-control-input"
                  id="print_with_prescription"
                  checked={this.props.printPrescription}
                  onChange={(e) =>
                    this.printCheckBoxChange(this.props.printPrescription)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="print_with_prescription"
                >
                  Print with prescription
                </label>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className={`paediatrics_chart ${
                this.state.zoomClass ? "zoomChart" : ""
              }`}
              style={{
                height: "95vh",
              }} /* style={{'height': '110vh', 'width': '79vw'}} */
            >
              <div
                className="zoomChartBtn"
                onClick={(e) => this.zoomChart(e)}
              ></div>
              {/* <ul className="legend_label">
                <li style={{ top: '25%' }}>Height</li>
                <li style={{ top: '50%' }}>Head Circumference</li>
                <li style={{ top: '70%' }}>Weight</li>
              </ul> */}
              {Object.keys(this.state.data).length && this.state.showChart ? (
                <PaediatricChart
                  data={this.state.data}
                  patient={this.props.patient}
                  patientUrlData={this.props.patientUrlData}
                  otheroptions={this.state.otheroptions}
                />
              ) : null}

              <ul className="legend_unit_label">
                {this.state.type == "CDC" ? (
                  <>
                    <li>
                      <span className="fst"></span>3rd Percentile
                    </li>
                    <li>
                      <span className="thrd"></span>5th Percentile
                    </li>
                    <li>
                      <span className="fif"></span>50th Percentile
                    </li>
                    <li>
                      <span className="nint"></span>97th Percentile
                    </li>
                  </>
                ) : this.state.type == "WHO" ? (
                  <>
                    <li>
                      <span className="fst"></span>1st Percentile
                    </li>
                    <li>
                      <span className="thrd"></span>3rd Percentile
                    </li>
                    <li>
                      <span className="fif"></span>50th Percentile
                    </li>
                    <li>
                      <span className="nint"></span>97th Percentile
                    </li>
                  </>
                ) : this.state.type == "IAP" || this.state.type == "WHOIAP" ? (
                  <>
                    <li>
                      <span className="fst"></span>3rd Percentile
                    </li>
                    {/* <li><span className="thrd"></span>5th Percentile</li> */}
                    <li>
                      <span className="fif"></span>50th Percentile
                    </li>
                    <li>
                      <span className="nint"></span>97th Percentile
                    </li>
                  </>
                ) : null}
              </ul>
              <div className="mb-4 printChart">
                <button
                  type="button"
                  className="btn btn-outline-primary px-4"
                  onClick={(e) => this.printChart(e)}
                >
                  PRINT CHART
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 my-3">
            <div
              className={`antInputItem form-group micwrap ${
                this.props.remarks !== "" ? "hasdata" : ""
              }`}
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  this.initSpeech({ identifier: "remarks" });
                }}
                className={
                  this.props.speech.identifier === "remarks" &&
                  this.props.speech.state
                    ? "mic subjective-mic on mute"
                    : "mic subjective-mic"
                }
              ></Link>
              <Form.Item
                name="remarks"
                id="remarks"
                label="Remarks"
                onChange={(e) => this.parentHandleChange(e, "remarks")}
              >
                <Input.TextArea
                  disabled={!this.props.allowEdit}
                  autoSize={textareaSizeDefault}
                  defaultValue={this.props.remarks}
                  showCount
                  maxLength={250}
                />
              </Form.Item>
            </div>
            {/* <div className="form-group">
              <textarea onInput={(e) => this.autoHeight(e)} rows={1} id="chart_remarks" className="form-control autoHeightText"
                placeholder="Therapy Required" />
              <label htmlFor="chart_remarks">Remarks</label>
            </div> */}
          </div>
        </div>
        <div className="row fixed-cta">
          <div className="col-12 text-center mb-4">
            {this.props.pathname &&
            this.props.pathname.includes("objective") ? (
              this.props.allowEdit ? (
                <button
                  type="submit"
                  className="btn btn-primary px-4"
                  disabled={!this.props.allowEdit || this.props.spin_loading}
                >
                  CONTINUE{this.props.spin_loading ? LOADER : ""}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  onClick={this.handleContinue}
                >
                  CONTINUE
                </button>
              )
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default PaediatricGrowthChart;
