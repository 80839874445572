var _ = require('lodash');

class QbHelpersClass {

    QbSession = {};

    callees = [];

    QbWebrtcSession = {};

    statesPeerConn = {};

    VcState = {};

    quickbloxDoctorInfo = {};

    recorder = {};

    patientInfo = {};

    isCallActive = false;

    audioMuted = false;

    localMediaStream = {};

    recorderOpts = {
        pointer: this,
        onstart: function onStartRecord() {
            console.log('>>>> Recorder onstart');
        },
        onstop: function (blob) {
            console.log('>>>> Recorder blob', blob);
            const bloburl = URL.createObjectURL(blob);
            console.log('>>>> Recorder bloburl', bloburl);
            // document.getElementById('BlobVideo').src = bloburl;
            // console.log('>>> this.recorder', this.$this.recorder);
            // QbHelpers.recorder.download('QB_WEBrtc_sample_' + Date.now(), blob);
            // console.log('>>> this.recorder download resp', resp);
        },
        onerror: function (error) {
            console.log('>>>> Recorder error', error);
        }
    };

    alterVcStateAgain(pointer) {
        this.VcState = pointer;
    }

    async login(pointer, credentials) {
        this.VcState = pointer;
        return await this.join(credentials);
    }

    async chatConnection(user, constants) {
        return await this.chatConnect(user, constants);
    }

    chatConnect(user, constants) {
        const QB = window.QB;
        var $this = this;
        return new Promise(function (resolve, reject) {

            if (!window.navigator.onLine) {
                const resp = {
                    status: false,
                    error: "Internet connection not available."
                };
                resolve(resp);
                return resp;
            }
            // console.log('>> user chat connect', user)

            QB.chat.connect({
                jid: QB.chat.helpers.getUserJid(user.data.id, window.CONFIG.CREDENTIALS.appId),
                password: constants.quickbloxPwd
            }, function (err, res) {
                if (err) {
                    if (!_.isEmpty($this.QbWebrtcSession)) {
                        $this.QbWebrtcSession.stop({});
                        $this.QbWebrtcSession = {};
                    }

                    const resp1 = {
                        status: false,
                        error: err
                    };
                    resolve(resp1);
                    return resp1;
                } else {
                    const resp1 = {
                        status: true,
                        response: res
                    };
                    resolve(resp1);
                    return resp1;
                }
            });
        });
    }

    qbSessionCreate() {
        var QB = window.QB;
        QB.init(
            window.CONFIG.CREDENTIALS.appId,
            window.CONFIG.CREDENTIALS.authKey,
            window.CONFIG.CREDENTIALS.authSecret,
            window.CONFIG.APP_CONFIG
        );
        this.statesPeerConn = _.invert(QB.webrtc.PeerConnectionState);
        var $this = this;

        return new Promise(function (resolve, reject) {
            if (!window.navigator.onLine) {
                var resp = {
                    status: false,
                    error: "Internet connection not available."
                };
                resolve(resp);
                return resp;
            }

            QB.createSession(function (csErr, csRes) {
                if (csErr) {
                    const resp1 = {
                        status: false,
                        error: csErr
                    };
                    resolve(resp1);
                    return resp1;
                } else {
                    $this.QbSession = csRes;
                    const resp1 = {
                        status: true,
                        session: csRes
                    };
                    resolve(resp1);
                    return resp1;
                }
            });
        });
    }

    join(credentials) {
        var userRequiredParams = {
            'login': credentials.login,
            'password': credentials.password
        };
        var QB = window.QB;
        var $this = this;

        return new Promise(function (resolve, reject) {
            if (!window.navigator.onLine) {
                var resp = {
                    status: false,
                    error: "Internet connection not available."
                };
                resolve(resp);
                return resp;
            }

            /** In first trying to login */
            QB.login(userRequiredParams, function (loginErr, loginUser) {
                if (loginErr) {
                    // console.log('>> loginErr', loginErr);
                    /** Login failed, trying to create account */
                    QB.users.create({
                        'login': userRequiredParams.login,
                        'password': userRequiredParams.password,
                        'full_name': credentials.username,
                    }, function (createErr, createUser) {
                        if (createErr) {
                            // console.log('[create user] Error:', createErr);
                            const resp2 = {
                                status: false,
                                error: createErr
                            };
                            resolve(resp2);
                            return resp2;
                        } else {
                            QB.login(userRequiredParams, function (reloginErr, reloginUser) {
                                if (reloginErr) {
                                    const resp2 = {
                                        status: false,
                                        error: reloginErr
                                    };
                                    resolve(resp2);
                                    return resp2;
                                } else {
                                    $this.quickbloxDoctorInfo = reloginUser;
                                    const resp2 = {
                                        status: true,
                                        data: reloginUser
                                    };
                                    resolve(resp2);
                                    return resp2;
                                }
                            });
                        }
                    });
                } else {
                    $this.quickbloxDoctorInfo = loginUser;
                    const resp2 = {
                        status: true,
                        data: loginUser
                    };
                    resolve(resp2);
                    return resp2;
                }
            });
        });
    }

    endCall() {
        if (!_.isEmpty(this.QbWebrtcSession)) {
            this.callLog({
                source: "web",
                state: 1,
                booking_id: this.VcState.state.booking_id
            });
            document.getElementById('floating_vc_dialog').classList.add("hidden");
            document.getElementById('floating_vc_dialog').removeAttribute('data-pid', null);
            document.getElementById('floating_vc_dialog').removeAttribute('data-booking_id', null);
            // let cpbtn = document.getElementById('completed_consult_btn');
            // if (cpbtn != null) {
            //     cpbtn.removeAttribute("disabled");
            // }
            this.isCallActive = false;
            // this.VcState.checkAppointmentForm();
            var sounds = {
                'call': 'callingSignal',
                'end': 'endCallSignal',
                'rington': 'ringtoneSignal'
            };
            document.getElementById(sounds.call).pause();
            // console.log('Ended paused')
            document.getElementById(sounds.end).play();
            // console.log('Ended played')

            // if (!_.isEmpty(this.recorder)) {
            //     this.recorder.stop();
            // }

            // this.resetVcCallState('Call disconnected with Patient.');

            // $('.j-actions').removeClass('hangup');
            // $('.j-caller__ctrl').removeClass('active');
            // $(ui.bandwidthSelect).attr('disabled', false);

            // $('.j-callees').empty();
            // $('.frames_callee__bitrate').hide();

            document.getElementById('js-call-controls').classList.add("hidden");
            // document.getElementById('recording-icon-1').classList.add("hidden");
            this.QbWebrtcSession.detachMediaStream('main_video');
            this.QbWebrtcSession.detachMediaStream('localVideo');
            this.QbWebrtcSession.stop({});
            this.QbWebrtcSession = {};
            // console.log('stream stopped')
            return true;
        }
        return false;
    }

    muteOrUnmute(muteState) {
        if (muteState) {
            this.audioMuted = false;
            this.QbWebrtcSession.unmute('audio');
            return false;
        } else {
            this.audioMuted = true;
            this.QbWebrtcSession.mute('audio');
            return true;
        }
    }

    changeMuteStatus(muteState) {
        if (muteState) {
            this.audioMuted = true;
            this.QbWebrtcSession.mute('audio');
            return true;
        } else {
            this.audioMuted = false;
            this.QbWebrtcSession.unmute('audio');
            return false;
        }
    }

    getUsers() {
        const QB = window.QB;
        const condition = {
            order: {
                field: "updated_at",
                sort: "desc"
            },
            page: 1,
            per_page: 100,
            headers: {
                'QB-Token': this.QbSession.token
            }
        };
        return new Promise(function (resolve, reject) {
            QB.users.listUsers(condition, function (err, result) {
                if (err) {
                    reject(err);
                } else {
                    resolve({
                        'users': result
                    });
                }
            });
        });
    }

    getUserInfo(number, constants) {
        const QB = window.QB;
        var params = { 'login': number, 'password': constants.quickbloxPwd };
        // console.log(params);
        return new Promise(function (resolve, reject) {
            QB.login(params, function (err, result) {
                if (result) {
                    resolve({
                        status: true,
                        data: result
                    });
                } else {
                    resolve({
                        status: false,
                        error: err
                    });
                }
            });
        });
    }

    async startWebrtcSession(calleesIds) {
        var QB = window.QB;
        // var calleesIds = ["106159845"]; // Quickblox User's ids 
        this.callees = calleesIds;
        var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
        // var callerID = "106159828"; // Your user ID (optional, will be defined from chat connection)
        var callerID = this.quickbloxDoctorInfo.id;
        var additionalOptions = {
            "bandwith": 512
        }; // The video bandwith (number, optional, 0 = unlimited)
        var $this = this;

        return new Promise(function (resolve, reject) {
            const session = QB.webrtc.createNewSession(calleesIds, sessionType, callerID, additionalOptions);
            $this.QbWebrtcSession = session;
            resolve(session);
        });
    }

    deviceMediaStream(webrtcSession) {
        const mediaParams = {
            'audio': true,
            'video': true,
            'options': {
                'muted': true,
                'mirror': true
            },
            'elemId': 'localVideo'
        };
        var isAudio = true;
        var $this = this;
        return new Promise(function (resolve, reject) {
            webrtcSession.getUserMedia(mediaParams, function (err, stream) {
                // console.log('>> err, stream', err, stream)
                if (err || !stream.getAudioTracks().length || (isAudio ? false : !stream.getVideoTracks().length)) {
                    console.log('>> err', err)
                    const resp = {
                        status: false,
                        message: 'Permission required for media(webcam and microphone) for video calling',
                        error: err
                    };
                    resolve(resp);
                } else {
                    $this.localMediaStream = stream;
                    $this.QbWebrtcSession.attachMediaStream('localVideoModal', stream);
                    $this.QbWebrtcSession.attachMediaStream('localVideoFloating', stream);
                    document.getElementById('localVideoModal').muted = true;
                    document.getElementById('localVideoFloating').muted = true;
                    document.getElementById('localVideoModal').setAttribute('muted', true);
                    document.getElementById('localVideoFloating').setAttribute('muted', true);
                    resolve({
                        status: true,
                        mediaStream: stream
                    });
                }
            });
        });
    }

    unbindFloatingMedia() {
        // document.getElementById('localVideoModal').muted = true;
        // document.getElementById('localVideoFloating').muted = true;
        // document.getElementById('localVideoModal').setAttribute('muted', true);
        // document.getElementById('localVideoFloating').setAttribute('muted', true);
        // document.getElementById('floatingLocalElem').innerHTML = '<video className="full-wh-elem" id="localVideoFloating" autoPlay playsInline mute="true"></video>';
        // document.getElementById('localVideoModalElem').innerHTML = '<video className="full-wh-elem" id="localVideoModal" autoPlay playsInline mute="true"></video>';
        // this.QbWebrtcSession.detachMediaStream('localVideo');
        // this.QbWebrtcSession.detachMediaStream('localVideoModal');
        // this.QbWebrtcSession.detachMediaStream('localVideoFloating');
        // this.deviceMediaStream(this.QbWebrtcSession);
    }

    bindLocalMedia() {
        document.getElementById('localVideo').muted = true;
        document.getElementById('localVideoModal').muted = true;
        document.getElementById('localVideoFloating').muted = true;
        document.getElementById('localVideo').setAttribute('muted', true);
        document.getElementById('localVideoModal').setAttribute('muted', true);
        document.getElementById('localVideoFloating').setAttribute('muted', true);
        this.QbWebrtcSession.attachMediaStream('localVideo', this.localMediaStream);
        this.QbWebrtcSession.attachMediaStream('localVideoModal', this.localMediaStream);
        // this.QbWebrtcSession.attachMediaStream('localVideoFloating', this.localMediaStream);
    }

    webrtcCall(webrtcSession, constants) {
        var QB = window.QB;
        var sounds = {
            'call': 'callingSignal',
            'end': 'endCallSignal',
            'rington': 'ringtoneSignal'
        };
        var $this = this;

        return new Promise(function (resolve, reject) {
            webrtcSession.call({}, function () {
                if (!window.navigator.onLine) {
                    webrtcSession.stop({});
                    const resp = {
                        status: false,
                        error: "Internet connection not available."
                    };
                    resolve(resp);
                } else {
                    document.getElementById(sounds.call).play();

                    // and also send push notification about incoming call
                    // (corrently only iOS/Android users will receive it)
                    var payload = JSON.stringify({
                        message: $this.quickbloxDoctorInfo.full_name + " is calling you",
                        ios_voip: "1",
                        VOIPCall: "1",
                        sessionID: webrtcSession.ID,
                        opponentsIDs: $this.callees,
                        contactIdentifier: $this.patientInfo.full_name,
                        conferenceType: "1"
                    });
                    var pushParameters = {
                        notification_type: "push",
                        user: { ids: $this.callees },
                        environment: constants.quickblox_mode, // development production
                        message: QB.pushnotifications.base64Encode(payload)
                    };
                    QB.pushnotifications.events.create(pushParameters, function (error, result) {
                        if (error) {
                            console.log("Push Notification", error);
                            resolve({
                                notification: error
                            });
                        } else {
                            console.log("Push Notification is sent.");
                            resolve({
                                notification: result
                            });
                        }
                    });

                    /*var params = {
                        notification_type: 'push',
                        user: {
                            ids: $this.callees
                        },
                        environment: constants.quickblox_mode, // environment, can be 'production' as well.
                        message: QB.pushnotifications.base64Encode(JSON.stringify({
                            "message": $this.quickbloxDoctorInfo.full_name + " is calling you",
                            "ios_voip": "1",
                            "VOIPCall": "1"
                        }))
                    };


                    QB.pushnotifications.events.create(params, function (error, response) {
                        if (error) {
                            // console.log(">> Push notification error", error);
                            resolve({
                                notification: error
                            });
                        } else {
                            // console.log("Push Notification is sent.");
                            resolve({
                                notification: response
                            });
                        }
                    });*/
                }
            });
        });
    }

    resetVcCallState(data) {
        if (data.tag && (data.tag === 'call_disconnected_by_patient' || data.tag === 'call_rejected_by_patient')) {
            this.VcState.setState({ showVideoFrame: false });
        }
        this.VcState.setState({
            callPatientBtn: data.btnStatus,
            callNotificationMsg: data.message,
            canEndCall: data.canEndCall
        });
        this.VcState.receiveInParent({
            callPatientBtn: data.btnStatus,
            callNotificationMsg: data.message,
            canEndCall: data.canEndCall
        });
    }

    callLog(data) {
        // this.VcState.createCallLog(data);
    }

    getPeerConnections() {
        return this.QbWebrtcSession.peerConnections;
    }

    bindPatientVc() {
        var $this = this;
        return new Promise(function (resolve, reject) {
            const stream = $this.QbWebrtcSession.peerConnections[
                $this.callees[0]
            ].stream;
            $this.QbWebrtcSession.attachMediaStream('main_video', stream);
            $this.QbWebrtcSession.attachMediaStream('main_video_modal', stream);
            resolve({
                status: true,
                message: "Video binded for patient"
            });
        });
    }

    bindCallListeners() {
        var QB = window.QB;

        var sounds = {
            'call': 'callingSignal',
            'end': 'endCallSignal',
            'rington': 'ringtoneSignal'
        };

        var remoteStreamCounter = 0;
        var $this = this;
        var configDebug = window.CONFIG.APP_CONFIG.debug;

        /**
         * QB Event listener.
         *
         * [Recommendation]
         * We recomend use Function Declaration
         * that SDK could identify what function(listener) has error
         *
         * Chat:
         * - onDisconnectedListener
         * WebRTC:
         * - onCallListener
         * - onCallStatsReport
         * - onUpdateCallListener
         *
         * - onAcceptCallListener
         * - onRejectCallListener
         * - onUserNotAnswerListener
         *
         * - onRemoteStreamListener
         *
         * - onStopCallListener
         * - onSessionCloseListener
         * - onSessionConnectionStateChangedListener
         * 
         * - onDevicesChangeListener
         */

        // QB.chat.onDisconnectedListener = function () {
        //     // console.log('onDisconnectedListener.');
        // };

        // QB.webrtc.onCallStatsReport = function onCallStatsReport(session, userId, stats, error) {
        //     if (configDebug) {
        //         console.group('onCallStatsReport');
        //         console.log('userId: ', userId);
        //         console.log('session: ', session);
        //         console.log('stats: ', stats);
        //         console.groupEnd();
        //     }

        //     if (stats.remote.video.bitrate) {
        //         console.log('video bitrate for userid: ' + userId + ' - ' + stats.remote.video.bitrate);
        //     } else if (stats.remote.audio.bitrate) {
        //         console.log('audio bitrate: userid: ' + userId + ' - ' + stats.remote.audio.bitrate);
        //     }
        // };

        QB.webrtc.onSessionCloseListener = function onSessionCloseListener(session) {
            if (configDebug) {
                console.log('onSessionCloseListener: ', session);
            }

            document.getElementById(sounds.call).pause();
            document.getElementById(sounds.end).play();

            // $('.j-actions').removeClass('hangup');
            // $('.j-caller__ctrl').removeClass('active');
            // $(ui.bandwidthSelect).attr('disabled', false);

            // $('.j-callees').empty();
            // $('.frames_callee__bitrate').hide();
            $this.VcState.hideModalCall();

            remoteStreamCounter = 0;

            // if (session.opponentsIDs.length > 1) {
            //     app.helpers.stateBoard.update({
            //         'title': 'tpl_call_stop',
            //         'property': {
            //             'name': app.caller.full_name
            //         }
            //     });
            // } else {
            //     app.helpers.stateBoard.update({
            //         title: 'tpl_default',
            //         property: {
            //             'name': app.caller.full_name,
            //         }
            //     });
            // }

            // if (document.querySelector('.j-actions[hidden]')) {
            //     document.querySelector('.j-actions[hidden]').removeAttribute('hidden');
            // }
            // if (document.querySelector('.j-caller__ctrl')) {
            //     document.querySelector('.j-caller__ctrl').removeAttribute('hidden');
            // }

        };

        QB.webrtc.onUserNotAnswerListener = function onUserNotAnswerListener(session, userId) {
            if (configDebug) {
                console.group('onUserNotAnswerListener.');
                console.log('UserId: ', userId);
                console.log('Session: ', session);
                console.groupEnd();
            }

            $this.resetVcCallState({
                btnStatus: false,
                message: 'Patient patient did not answer call.',
                canEndCall: false
            });

            if (!_.isEmpty($this.QbWebrtcSession)) {
                // 	$this.QbWebrtcSession.detachMediaStream('main_video');
                // 	$this.QbWebrtcSession.detachMediaStream('main_video_modal');
                $this.QbWebrtcSession.detachMediaStream('localVideo');
                $this.QbWebrtcSession.detachMediaStream('localVideoModal');
                $this.QbWebrtcSession.detachMediaStream('localVideoFloating');

                $this.QbWebrtcSession.stop({});
                $this.QbWebrtcSession = {};
            }

            // var opponent = _.findWhere(app.users, {
            //     'id': +userId
            // });

            /** It's for p2p call */
            // if (session.opponentsIDs.length === 1) {
            //     app.helpers.stateBoard.update({
            //         'title': 'p2p_call_stop',
            //         'property': {
            //             'name': opponent.full_name,
            //             'currentName': app.caller.full_name,
            //             'reason': 'not answered'
            //         }
            //     });
            // } else {
            //     $('.j-callee_status_' + userId).text('No Answer');
            // }
        };

        // QB.webrtc.onCallListener = function onCallListener(session, extension) {
        //     if (configDebug) {
        //         console.group('onCallListener.');
        //         console.log('Session: ', session);
        //         console.log('Extension: ', extension);
        //         console.groupEnd();
        //     }

        //     // module.exports.QbWebrtcSession = session;
        //     // QB.users.get(Number(session.initiatorID), function (error, user) {
        //     //     if (error) {
        //     //         console.log('>>> onCallListener', error);
        //     //     } else {
        //     //         // app.users = app.users.filter(function (item) {
        //     //         //     return user.id !== item.id;
        //     //         // });
        //     //         // app.users.push(user);
        //     //         /** close previous modal */
        //     //         // $(ui.income_call).modal('hide');
        //     //         // $('.j-ic_initiator').text(user.full_name);
        //     //         // check the current session state
        //     //         if (module.exports.QbWebrtcSession.state !== QB.webrtc.SessionConnectionState.CLOSED) {
        //     //             // $(ui.income_call).modal('show');
        //     //             document.getElementById(sounds.rington).play();
        //     //         }
        //     //     }
        //     // });
        // };

        QB.webrtc.onRejectCallListener = function onRejectCallListener(session, userId, extension) {
            setTimeout(function () {
                // console.log('>>> session.state=', session.state);
                if (session.state !== 5) {
                    // console.log('>>> MISMATCH onRejectCallListener', session.state, $this.QbWebrtcSession.ID, session);
                    return;
                } else {
                    // console.log('>>> MATCH onRejectCallListener', session.state, session);
                }

                if (configDebug) {
                    console.group('onRejectCallListener.' + session.state);
                    console.log('UserId: ' + userId);
                    console.log('Session: ' + session);
                    console.log('Extension: ' + JSON.stringify(extension));
                    console.groupEnd();
                }

                document.getElementById(sounds.call).pause();
                document.getElementById(sounds.end).play();


                if (!_.isEmpty($this.QbWebrtcSession)) {
                    // 	$this.QbWebrtcSession.detachMediaStream('main_video');
                    // 	$this.QbWebrtcSession.detachMediaStream('main_video_modal');
                    $this.QbWebrtcSession.detachMediaStream('localVideo');
                    $this.QbWebrtcSession.detachMediaStream('localVideoModal');
                    $this.QbWebrtcSession.detachMediaStream('localVideoFloating');
                    // 	$this.QbWebrtcSession.stop({});
                    // $this.QbWebrtcSession = {};

                }

                $this.resetVcCallState({
                    btnStatus: false,
                    message: 'Patient rejected the call request.',
                    canEndCall: false,
                    tag: 'call_rejected_by_patient'
                });
            }, 500);

            // var user = _.findWhere(app.users, {
            //         'id': +userId
            //     }),
            //     userCurrent = _.findWhere(app.users, {
            //         'id': +session.currentUserID
            //     });

            // var user = {
            //         full_name: 'Mushran'
            //     },
            //     userCurrent = {
            //         full_name: 'Salman'
            //     };

            /** It's for p2p call */
            // if (session.opponentsIDs.length === 1) {
            //     // app.helpers.stateBoard.update({
            //     //     'title': 'p2p_call_stop',
            //     //     'property': {
            //     //         'name': user.full_name,
            //     //         'currentName': userCurrent.full_name,
            //     //         'reason': 'rejected the call'
            //     //     }
            //     // });
            // } else {
            //     // var userInfo = _.findWhere(app.users, {
            //     //     'id': +userId
            //     // });
            //     // app.calleesRejected.push(userInfo);
            //     // $('.j-callee_status_' + userId).text('Rejected');
            // }
        };

        QB.webrtc.onStopCallListener = function onStopCallListener(session, userId, extension) {
            if (configDebug) {
                console.group('onStopCallListener.');
                console.log('UserId: ', userId);
                console.log('Session: ', session);
                console.log('Extension: ', extension);
                console.groupEnd();
            }

            $this.callLog({
                source: "web",
                state: 1,
                booking_id: $this.VcState.state.booking_id
            });

            document.getElementById('floating_vc_dialog').classList.add("hidden");
            document.getElementById('floating_vc_dialog').removeAttribute('data-pid', null);
            document.getElementById('floating_vc_dialog').removeAttribute('data-booking_id', null);
            // let cpbtn = document.getElementById('completed_consult_btn');
            // if (cpbtn != null) {
            //     cpbtn.removeAttribute("disabled");
            // }

            $this.QbWebrtcSession.detachMediaStream('main_video');
            $this.QbWebrtcSession.detachMediaStream('main_video_modal');
            $this.QbWebrtcSession.detachMediaStream('main_video_floating');
            $this.QbWebrtcSession.detachMediaStream('localVideo');
            $this.QbWebrtcSession.detachMediaStream('localVideoModal');
            $this.QbWebrtcSession.detachMediaStream('localVideoFloating');

            $this.QbWebrtcSession.stop({});
            $this.QbWebrtcSession = {};

            // if (!_.isEmpty($this.recorder)) {
            //     $this.recorder.stop();
            // }

            $this.resetVcCallState({
                btnStatus: false,
                message: 'Call disconnected by Patient.',
                canEndCall: false,
                tag: 'call_disconnected_by_patient'
            });
            $this.isCallActive = false;
            // $this.VcState.checkAppointmentForm();
            document.getElementById('js-call-controls').classList.add("hidden");
            // document.getElementById('recording-icon-1').classList.add("hidden");
            // console.log('>> Salman, 4 this.state.vcSetupDone=', $this.VcState.state.vcSetupDone)
            $this.VcState.resetCallAudio();

            // app.helpers.notifyIfUserLeaveCall(session, userId, 'hung up the call', 'Hung Up');

            // if (recorder) {
            //     recorder.stop();
            // }
        };

        QB.webrtc.onAcceptCallListener = function onAcceptCallListener(session, userId, extension) {
            if (configDebug) {
                console.group('onAcceptCallListener.');
                console.log('UserId: ', userId);
                console.log('Session: ', session);
                console.log('Extension: ', extension);
                console.groupEnd();
            }

            $this.resetVcCallState({
                btnStatus: true,
                message: 'Patient accepted the call request.',
                canEndCall: true
            });

            // var userInfo = _.findWhere(app.users, {
            //         'id': +userId
            //     }),
            // var userInfo = {
            //         userId: userId
            //     },
            // filterName = $.trim($(ui.filterSelect).val());
            var filterName = "Mushran";

            document.getElementById(sounds.call).pause();
            $this.QbWebrtcSession.update({
                'filter': filterName
            });

            /** update list of callee who take call */
            // app.calleesAnwered.push(userInfo);

            if ($this.QbWebrtcSession.currentUserID === $this.QbWebrtcSession.initiatorID) {
                // app.helpers.stateBoard.update({
                //     'title': 'tpl_call_status',
                //     'property': {
                //         'users': app.helpers.getUsersStatus()
                //     }
                // });
            }
        };

        QB.webrtc.onRemoteStreamListener = function onRemoteStreamListener(session, userId, stream) {
            if (configDebug) {
                console.group('onRemoteStreamListener.');
                console.log('userId: ', userId);
                console.log('Session: ', session);
                console.log('Stream: ', stream);
                console.groupEnd();
            }
            // console.log('>>> onRemoteStreamListener.');

            var state = $this.QbWebrtcSession.connectionStateForUser(userId),
                peerConnList = QB.webrtc.PeerConnectionState;

            if (state === peerConnList.DISCONNECTED || state === peerConnList.FAILED || state === peerConnList.CLOSED) {
                return false;
            }

            $this.QbWebrtcSession.peerConnections[userId].stream = stream;

            // console.info('onRemoteStreamListener add video to the video element', stream);
            $this.QbWebrtcSession.attachMediaStream('main_video', stream);
            $this.QbWebrtcSession.attachMediaStream('main_video_modal', stream);
            $this.QbWebrtcSession.attachMediaStream('main_video_floating', stream);
            $this.resetVcCallState({
                btnStatus: true,
                message: 'In call with Patient.',
                canEndCall: true
            });
            $this.isCallActive = true;
            document.getElementById('floating_vc_dialog').setAttribute("data-booking_id", $this.VcState.state.booking_id);
            document.getElementById('floating_vc_dialog').setAttribute("data-pid", $this.patientInfo.id);
            document.getElementById('js-call-controls').classList.remove("hidden");
            $this.callLog({
                source: "web",
                state: 0,
                booking_id: $this.VcState.state.booking_id
            });
            // document.getElementById('completed_consult_btn').setAttribute("disabled", "disabled");
            // $this.VcState.checkAppointmentForm();

            // Uncomment below code for starting recording
            // var QBMR = window.QBMediaRecorder;
            // if (_.isEmpty($this.recorder) && QBMR.isAvailable()) {
            //     $this.recorder = new QBMR($this.recorderOpts);
            //     var elem = document.getElementById("main_video");
            //     if (typeof elem.srcObject === 'object') {
            //         document.getElementById('recording-icon-1').classList.remove("hidden");
            //         $this.recorder.start(elem.srcObject);
            //     }
            // }

            // this.QbWebrtcSession.attachMediaStream('remote_video_' + userId, stream);

            if (remoteStreamCounter === 0) {
                // $('#remote_video_' + userId).click();

                // app.mainVideo = userId;
                ++remoteStreamCounter;
            }

            // if (!call.callTimer) {
            //     call.callTimer = setInterval(function () {
            //         call.updTimer.call(call);
            //     }, 1000);
            // }

            // $('.frames_callee__bitrate').show();
        };

        // QB.webrtc.onUpdateCallListener = function onUpdateCallListener(session, userId, extension) {
        //     if (configDebug) {
        //         console.group('onUpdateCallListener.');
        //         console.log('UserId: ' + userId);
        //         console.log('Session: ' + session);
        //         console.log('Extension: ' + JSON.stringify(extension));
        //         console.groupEnd();
        //     }

        //     // app.helpers.changeFilter('#remote_video_' + userId, extension.filter);

        //     // if (+(app.mainVideo) === userId) {
        //     //     app.helpers.changeFilter('#main_video', extension.filter);
        //     // }
        // };

        QB.webrtc.onSessionConnectionStateChangedListener = function onSessionConnectionStateChangedListener(session, userId, connectionState) {
            if (configDebug) {
                console.group('onSessionConnectionStateChangedListener.');
                console.log('UserID:', userId);
                console.log('Session:', session);
                console.log('Сonnection state:', connectionState, $this.statesPeerConn[connectionState]);
                console.groupEnd();
            }

            // var connectionStateName = _.invert(QB.webrtc.SessionConnectionState)[connectionState];
            // var $calleeStatus = $('.j-callee_status_' + userId);
            var isCallEnded = false;

            if (connectionState === QB.webrtc.SessionConnectionState.CONNECTING) {
                // $calleeStatus.text(connectionStateName);
            }

            if (connectionState === QB.webrtc.SessionConnectionState.CONNECTED) {
                // app.helpers.toggleRemoteVideoView(userId, 'show');
                // $calleeStatus.text(connectionStateName);
            }

            if (connectionState === QB.webrtc.SessionConnectionState.COMPLETED) {
                // app.helpers.toggleRemoteVideoView(userId, 'show');
                // $calleeStatus.text('connected');
            }

            if (connectionState === QB.webrtc.SessionConnectionState.DISCONNECTED) {
                // app.helpers.toggleRemoteVideoView(userId, 'hide');
                // $calleeStatus.text('disconnected');
            }

            if (connectionState === QB.webrtc.SessionConnectionState.CLOSED) {
                // app.helpers.toggleRemoteVideoView(userId, 'clear');

                // if (app.mainVideo === userId) {
                // $('#remote_video_' + userId).removeClass('active');
                // app.helpers.changeFilter('#main_video', 'no');
                // app.mainVideo = 0;
                for (var key in $this.QbWebrtcSession.peerConnections) {
                    if ( /*key != userId &&*/
                        $this.QbWebrtcSession.peerConnections[key].stream !== undefined &&
                        $this.QbWebrtcSession.peerConnections[key].stream.active) {
                        $this.QbWebrtcSession.attachMediaStream('main_video', $this.QbWebrtcSession.peerConnections[key].stream);
                        $this.QbWebrtcSession.attachMediaStream('main_video_modal', $this.QbWebrtcSession.peerConnections[key].stream);
                        $this.QbWebrtcSession.attachMediaStream('main_video_floating', $this.QbWebrtcSession.peerConnections[key].stream);
                        // app.mainVideo = key;
                        break;
                    }
                }
                // }

                if (!_.isEmpty($this.QbWebrtcSession)) {
                    if (Object.keys($this.QbWebrtcSession.peerConnections).length === 1 || userId === $this.QbWebrtcSession.initiatorID) {
                        // $(ui.income_call).modal('hide');
                        document.getElementById(sounds.rington).pause();
                    }
                }

                isCallEnded = _.every($this.QbWebrtcSession.peerConnections, function (i) {
                    return i.iceConnectionState === 'closed';
                });

                /** remove filters */

                if (isCallEnded) {
                    // app.helpers.changeFilter('#localVideo', 'no');
                    // app.helpers.changeFilter('#main_video', 'no');
                    // $(ui.filterSelect).val('no');

                    // app.calleesAnwered = [];
                    // app.calleesRejected = [];
                    // app.network[userId] = null;
                }

                // if ($this.QbWebrtcSession.currentUserID === $this.QbWebrtcSession.initiatorID && !isCallEnded) {
                //     var userInfo = _.findWhere(app.users, {
                //         'id': +userId
                //     });

                //     /** get array if users without user who ends call */
                //     app.calleesAnwered = _.reject(app.calleesAnwered, function (num) {
                //         return num.id === +userId;
                //     });
                //     app.calleesRejected.push(userInfo);

                //     app.helpers.stateBoard.update({
                //         'title': 'tpl_call_status',
                //         'property': {
                //             'users': app.helpers.getUsersStatus()
                //         }
                //     });
                // }

                // if (_.isEmpty($this.QbWebrtcSession) || isCallEnded) {
                //     if (call.callTimer) {
                //         $('#timer').addClass('invisible');
                //         clearInterval(call.callTimer);
                //         call.callTimer = null;
                //         call.callTime = 0;
                //         app.helpers.network = {};
                //     }
                // }
            }
        };

        // QB.webrtc.onDevicesChangeListener = function onDevicesChangeListeners() {
        //     // fillMediaSelects().then(switchMediaTracks);
        //     // console.log('QbHelpers - Need to enable this method - switchMediaTracks');
        // };
    }

};

const QbHelpers = new QbHelpersClass();
export default QbHelpers;
