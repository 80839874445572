import React, { Component } from "react";
import { Form, Modal, Input, Radio, Select, Checkbox, DatePicker, notification, Popover } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { speechService, speechTextService } from '../../utils/rxjs-sharing';
import LMP from "../../components/gynaecology/LMP";
import MenstrualHxList from "./gynae-menstrual-hx-list";
import { AudioOutlined } from "@ant-design/icons";
import qs from "qs";
import { AntSelectLabels } from "../../utils/jQueryLabels";
import LS_SERVICE from "../../utils/localStorage";
import TrendGraph from "../../components/chart/trend-graph";
import {
    MENSTRUAL_HISTORY_STORE, MENSTRUAL_HISTORY_GRAPH, LOADER, MENSTRUAL_HX_TREND_GRAPH
} from "../../utils/constant";
import Axios from 'axios';
import { number } from "prop-types";
import Lactating from "../../components/gynaecology/Lactating";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const suffix = (
    <AudioOutlined
        style={{
            fontSize: 16,
            color: "#1890ff",
        }}
    />
);

class MenstrualHx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spin_loading: false,
            addMenstrualModal: false,
            chartModal: false,
            chartDataLoaded: false,
            chartData: [],
            chartType: "all",
            chartsTab: "2",
            heading: "",
            chartHeading: "Trend Graph",
            chartGraphSettings: "",
            actionText : 'Add',
            menstrualHistoryListStatus: false,
            menstrualHistoryList: [],
            no_menstrual_hx: props?.patientDetails?.is_checked || 0,
            menopause_age_hx: false,
            menopause_age_input: 0,
            menarch_age: props?.patientDetails?.menarch_age || "",
            menopause_age: props?.patientDetails?.menopause_age || "",
            lmp_date: "",
            duration_of_period: "",
            lmp_not_sure: false,
            lmp_missed_period: false,
            cycle_characteristics_flow: null,
            cycle_characteristics_regularity: null,
            change_description : null,
            since_length: "",
            since_time: "days",
            pain_ful: "",
            pain_score: "",
            requiredPainScore: false,
            cycle_length : "",
            disabled_cycle_length : false,
            changes_in_cycle: "",
            changes_in_cycle_status: true,
            menstrual_remarks: null,
            is_missed_period: false,
            is_pain_ful: true,
            disableSaveButton: true,
            disabled_menarch_age : false,
            disabled_menopause_age_hx : false,
            menopause_age_dis: true,
            disabled_lmp_date : false,
            disabled_ducation : false,
            disabled_lmp_not_sure  : false,
            disabled_lmp_missed_period : false,
            disabled_menstrual_remarks : false,
            disabled_no_menstrual_hx : false,
            requiredMenopauseAge:false,
            requiredChangeDescription:false,
            requiredFlow:true,
            menstrualHistoryCheckedStatus:false,
            all_print_checked_status:false,
            toddaychartData : true,
            speech: {
                identifier: null,
                state: false
            },
            lactating: props.lactating,
            tabs_available: props.tabs_available
        };
        this.FormRef = React.createRef();
        this.MenstrualHxListRef = React.createRef();
        this.TrendGraphRef = React.createRef();
    }

    componentDidMount() {
        this.menstrualGraph();
        
        this.subscription = speechTextService.status().subscribe((data) => {
            if (this.state.speech.state) {
                if (this.state.speech.identifier === "change_description") {
                    this.setState({ change_description: data.text });
                }
                if (this.state.speech.identifier === "menstrual_remarks") {
                    this.setState({ menstrual_remarks: data.text });
                }
                document.querySelector(
                    "#" + this.state.speech.identifier
                ).style.height =
                    document.querySelector("#" + this.state.speech.identifier)
                        .scrollHeight + "px";
            }
        });

        this.setState({ dob : moment().diff(LS_SERVICE.get('dob'), 'years') });
        this.handleGraphClick();
    }

    chartStateUpdate = (data) => {
        this.setState({
            chartGraphSettings: data,
        });
    };

    static getDerivedStateFromProps(props, state) {
        const { patient, allowEdit, lactating, isPracticing } = props;

        var menstrualHistoryCheckedList = {checkedMenstrualCycleLength: props.patientDetails?.checkedMenstrualCycleLength,checkedMenstrualDurationOfPeriod: props.patientDetails?.checkedMenstrualDurationOfPeriod,checkedMenstrualPainScore: props.patientDetails?.checkedMenstrualPainScore};

        return {
            patient: {
                patient_id: patient.patient_id,
                reference_id: patient.reference_id,
                reference_type: patient.reference_type,
                doctor_id:LS_SERVICE.get("staff_id"),
                hospital_id:LS_SERVICE.get("slot_hospital_id")
            },
            patientData: props.patientData,
            patientDetails : props.patientDetails,
            menstrualHistoryListStatus : props.menstrualHistoryListStatus,
            menstrualHistoryList : props.menstrualHistoryList,
            disabled_no_menstrual_hx : props.disabled_no_menstrual_hx,
            allowEdit: allowEdit,
            lactating: lactating,
            menstrualHistoryChecked : menstrualHistoryCheckedList,
            isPracticing: isPracticing,
            all_print_checked : (menstrualHistoryCheckedList?.checkedMenstrualCycleLength === true && menstrualHistoryCheckedList?.checkedMenstrualDurationOfPeriod === true && menstrualHistoryCheckedList?.checkedMenstrualPainScore === true)?true:false
        };
    }

    updateData = async(val) => {
        var menstrualHistoryChecked = this.state.menstrualHistoryChecked;
        if(val.cycle_length && val.cycle_length != undefined){
            menstrualHistoryChecked.checkedMenstrualCycleLength = (val.cycle_length=='1')?true:false;
        }
        if(val.duration_of_period && val.duration_of_period != undefined){
            menstrualHistoryChecked.checkedMenstrualDurationOfPeriod = (val.duration_of_period=='1')?true:false;
        }
        if(val.pain_score && val.pain_score != undefined){
            menstrualHistoryChecked.checkedMenstrualPainScore = (val.pain_score=='1')?true:false;
        }

        await this.setState({ menstrualHistoryCheckedStatus:true, menstrualHistoryCheckedNew : menstrualHistoryChecked, all_print_checked_status : true, update_all_print_checked : (menstrualHistoryChecked?.checkedMenstrualCycleLength === true && menstrualHistoryChecked?.checkedMenstrualDurationOfPeriod === true && menstrualHistoryChecked?.checkedMenstrualPainScore === true)?true:false });
    }

    handleModalPopup = async(e, popup, data = null) => {
        if (e !== null) e.preventDefault();
        await this.setState({
            [popup]: !this.state[popup],
        });
        if(this.state.chartModal){
            this.setState({
                chartDataLoaded: true,
                chartType: (data == null)?this.state.chartType:data.type,
                chartHeading: (data == null)?'Trend Graphs':data.name,
                checkedVal: data.checkedVal
            });

            this.handleGraphClick(e, "2");
        }
        if(this.state.addMenstrualModal){
            this.setState({
                menopause_age_hx : (this.state.patientDetails && this.state.patientDetails.menopause_age && this.state.patientDetails.menopause_age != null)?true:false,
                menopause_age_dis : (this.state.patientDetails && this.state.patientDetails.menopause_age && this.state.patientDetails.menopause_age != null)?false:true,
                menopause_age : (this.state.patientDetails && this.state.patientDetails.menopause_age)?this.state.patientDetails.menopause_age: '',
                requiredMenopauseAge : false
            });
        }
    }

    handleGraphClick = (e, type = "2") => {
        var grphStatus = true;
        if(type == 0 || type == 1){
            if(type == 0 && this.state.chartData.dayLabel.length === 0){
                grphStatus = false;
            }else if(type == 1 && this.state.chartData.weekLabel.length === 0){
                grphStatus = false;
            }
        }
        
        this.setState(
            {
                chartDataLoaded: false,
                chartsTab: type,
                toddaychartData: grphStatus
            },
            () => this.setState({ chartDataLoaded: true })
        );
    };

    //Speech
    initSpeech(info) {
        let initialText = '';
        if (info.identifier === 'change_description') {
            initialText = this.state.change_description != undefined ? this.state.change_description : '';
        }
        if (info.identifier === 'menstrual_remarks') {
            initialText = this.state.menstrual_remarks != undefined ? this.state.menstrual_remarks : '';
        }
        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        } else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                setTimeout(() => {
                    this.setState({
                        speech: {
                            identifier: info.identifier,
                            state: true,
                            initialText: initialText
                        }
                    }, () => {
                        speechService.init(this.state.speech);
                    });

                }, 1000);
            });
        }
    }

    numberValidation(value) {
        const isInteger = /^[0-9]+$/g;
        if (value === '' || isInteger.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    onChangeCyclecharacteristicsSelect = async(value, name) => {
        await this.setState({
            [name]: value
        });
        await this.saveButtonStatus();
    }

    onChangeCyclecharacteristics = async(e) => {
        if(e.target.name === 'change_description'){
            this.setState({ [e.target.name] : e.target.value })
        }else{
            let check = await this.numberValidation(e.target.value);
            if(check == true){
                this.setState({ [e.target.name] : e.target.value })
            }else{
                this.FormRef.current.setFieldsValue({ [e.target.name] : '' })
            }
        }

        await this.saveButtonStatus();
    }

    onChangeLMPDateHandler = async(date) => {
        if(date != null){
            let lmpDate = moment(date._d).format('YYYY-MM-DD');
            await this.setState({
                lmp_date: lmpDate
            });

            await this.saveButtonStatus();
        }
    }

    onChangeMenstrualHxHandler = (e) => {
        const name = e.target?.name;
        const checked = e.target?.checked;
        this.setState({
            disabled_lmp_date : false,
            disabled_ducation: false,
        })
        if (name) {
            this.setState(
                {
                    [name]: checked ? 1 : 0,
                },
                () => {
                    if (name === "no_menstrual_hx") {
                        if (name === "no_menstrual_hx" && this.state[name] == 1) {
                            const target = e.target;
                            const value = target.type === 'checkbox' ? target.checked : target.value;
                            this.setState(prevState => ({
                                [target.name]: value,
                                requiredFlow:false
                            }));
                            this.FormClear();
                            this.disabledAllFields();
                        }else {
                            this.setState({
                                requiredFlow:true
                            });
                            this.disabledAllFields();
                        }
                    }else if (name === "menopause_age_hx") {
                        if (checked) {
                            this.setState({ 
                                menopause_age_dis: false,
                                requiredMenopauseAge : true,
                                menopause_age : ''
                            });

                            const target = e.target;
                            const value = target.type === 'checkbox' ? target.checked : target.value;
                            this.setState(prevState => ({
                                [target.name]: value,
                            }));
                        } else {
                            this.setState({ 
                                menopause_age_dis: true,
                                requiredMenopauseAge : false,
                                menopause_age : ''
                            });
                            this.FormRef.current.setFieldsValue({
                                menopause_age : ''
                            });
                        }

                        this.saveButtonStatus();
                    }else if(name === "lmp_not_sure") {
                        if(name === "lmp_not_sure" && this.state[name] == 1){
                            this.setState({
                                // disabled_lmp_date : true,
                                // disabled_ducation: true,
                                lmp_missed_period:false,
                                is_missed_period : false,
                            })

                            this.FormRef.current.setFieldsValue({
                                // lmp_date: "",
                                // duration_of_period: "",
                            });
                        }else{
                            this.setState({
                                disabled_lmp_date : false,
                                // disabled_ducation: false,
                            })
                            this.setState({lmp_not_sure:false});
                        }
                    }else if(name === "lmp_missed_period") {
                        if(name === "lmp_missed_period" && this.state[name] == 1){
                            this.setState({
                                // disabled_lmp_date : true,
                                // disabled_ducation: true,
                                lmp_not_sure:false,
                                // is_missed_period : true,
                                // is_pain_ful: true,
                                // changes_in_cycle_status : true,
                                // pain_ful: "0",
                                // changes_in_cycle: "0",
                                // pain_score: "",
                                // cycle_length: "",
                                // since_length: "",
                                // change_description: "",
                                // requiredFlow:false
                            })

                            this.FormRef.current.setFieldsValue({
                                // lmp_date: "",
                                // duration_of_period: "",
                                // cycle_characteristics_flow: "",
                                // cycle_characteristics_regularity: "",
                                // pain_score: "",
                                // cycle_length: "",
                                // change_description: "",
                                // since_length: "",
                                // since_time: "days",
                            });
                        }else{
                            this.setState({
                                // disabled_lmp_date : false,
                                // disabled_ducation: false,
                                is_missed_period : false,
                            })
                            this.setState({lmp_missed_period:false});
                        }

                    }
                }
            );
        }
    };

    remarksHandler(e) {
        this.setState({ menstrual_remarks: e.target.value });
    }

    menstrualRadioHxHandler = async(e, state) => {
        if (this.state.speech.identifier != null && this.state.speech.identifier === 'change_description') {
            this.initSpeech({ identifier: null });
        }
        
        const value = e.target?.value;
        await this.setState({ changes_in_cycle: value });
        
        if(value == 1){
            await this.setState({ changes_in_cycle_status : false, requiredChangeDescription : true });
        }else{
            
            await this.FormRef.current.setFieldsValue({
                change_description: "",
                since_length: "",
                since_time: "days",
            });
            await this.setState({ change_description: "", changes_in_cycle_status : true, requiredChangeDescription : false });
        }

        await this.saveButtonStatus();
    }

    menstrualRadioHxHandler2 = async(e, state) => {
        const value = e.target?.value;
        await this.setState({ pain_ful: value, requiredPainScore : false });
        
        if(value == 1){
            await this.setState({ is_pain_ful : false, requiredPainScore : true, });
        }else{
            this.FormRef.current.setFieldsValue({
                pain_score: "",
            });
            await this.setState({ is_pain_ful : true });
        }

        await this.saveButtonStatus();
    }

    menarcheAgeHandler = async(e) => {
        let check = await this.numberValidation(e.target.value);
        if(check == true){
            this.setState({ menarch_age : e.target.value })
        }else{
            this.FormRef.current.setFieldsValue({ menarch_age : '' })
        }

        await this.saveButtonStatus();
    }

    menopauseAgeHandler = async(e) => {
        let check = await this.numberValidation(e.target.value);
        if(check == true){
            this.setState({ menopause_age : e.target.value })
        }else{
            this.FormRef.current.setFieldsValue({ menopause_age : '' })
        }

        await this.setState({ requiredMenopauseAge:true });
        await this.saveButtonStatus();
    }

    handleChangeInput= async(event) =>{
        await this.setState({ 
        [event.target.name] : event.target.value 
        })
    }

    handleFormSubmit = async (values) => {
        const { spin_loading, isPracticing } = this.state;
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;
        if(LS_SERVICE.get('user_type') !== 2){
            if (!isPracticing) {
                this.props.parentHandleModalPopup(null, 'isPracticingModal');
                return;
            }
        }
        
        let no_menstrual_hx = (this.state.no_menstrual_hx)?'1':'0';
        let menstrualObj = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id: hospital_id,
            reference_id:reference_id,
            reference_type:0,
            no_menstrual_hx: no_menstrual_hx,
            menarch_age: this.state.menarch_age,
            menopause_age_hx: (this.state.menopause_age_hx)?'1':'0',
            menopause_age: this.state.menopause_age,
            lmp_date: this.state.lmp_date,
            duration_of_period: this.state.duration_of_period,
            lmp_not_sure: (this.state.lmp_not_sure)?'1':'0',
            lmp_missed_period: (this.state.lmp_missed_period)?'1':'0',
            cycle_characteristics_flow: this.state.cycle_characteristics_flow,
            cycle_characteristics_regularity: this.state.cycle_characteristics_regularity,
            pain_ful: this.state.pain_ful,
            pain_score: this.state.pain_score,
            cycle_length: this.state.cycle_length,
            changes_in_cycle: this.state.changes_in_cycle,
            change_description: (this.state.changes_in_cycle == "1")?this.state.change_description:null,
            since_length: (this.state.changes_in_cycle == "1")?this.state.since_length:null,
            since_time: (this.state.changes_in_cycle == "1")?this.state.since_time:null,
            menstrual_remarks: this.state.menstrual_remarks
        }

        this.setState({
            spin_loading: !spin_loading
        });

        await Axios.post(MENSTRUAL_HISTORY_STORE, menstrualObj)
        .then(async success => {
            const { spin_loading } = this.state;
            this.setState({
            spin_loading: !spin_loading
            });
            notification.success({
                message:  success.data.message,
                placement: 'topRight'
            });
            await this.FormClear();
            
            if(no_menstrual_hx == '0'){
                await this.FormClear2();
            }
            this.setState({
                addMenstrualModal: false
            });
            if (this.state.speech.identifier != null) {
                this.initSpeech({ identifier: this.state.speech.identifier });
            }
            this.props.menstrualList();
            if(this.state.menopause_age){
                this.setState({ menopause_age_dis : false });
            }
            this.menstrualGraph();
        }); 
    }

    FormClear = async () => {
        this.setState({
            menarch_age: "",
            lmp_not_sure: false,
            lmp_missed_period: false,
            pain_ful: "",
            changes_in_cycle: "",
            menstrual_remarks:null,
            duration_of_period: "",
            cycle_characteristics_flow: "",
            cycle_characteristics_regularity:"",
            change_description: "",
            requiredChangeDescription : false,
            requiredPainScore : false,
            since_length: ""
        });
        
        this.FormRef.current.setFieldsValue({
            lmp_date: "",
            duration_of_period: "",
            cycle_characteristics_flow: "",
            cycle_characteristics_regularity: "",
            pain_score: "",
            cycle_length: "",
            change_description: "",
            since_length: "",
            since_time: "days",
        });

        AntSelectLabels();

        this.saveButtonStatus();
    }

    FormClear2 = async () => {
        this.setState({
            no_menstrual_hx: false,
        });
        this.disabledAllFields();
        AntSelectLabels();
    }

    disabledAllFields = async() =>{
        const { no_menstrual_hx }= this.state;
        if(no_menstrual_hx){
            await this.setState({
                disabled_menarch_age : true,
                disabled_menopause_age_hx: true,
                menopause_age_dis : true,
                disabled_lmp_date : true,
                disabled_lmp_not_sure  : true,
                disabled_lmp_missed_period : true,
                is_missed_period : true,
                is_pain_ful: true,
                disabled_cycle_length: true,
                disabled_menstrual_remarks: true,
                disabled_ducation : true,
                changes_in_cycle_status : true,
                requiredChangeDescription:false,
                menopause_age_hx: false,
                change_description: '',
            })
        }else{
            await this.setState({
                disabled_menarch_age : false,
                disabled_menopause_age_hx: false,
                menopause_age_dis : true,
                disabled_lmp_date : false,
                disabled_lmp_not_sure  : false,
                disabled_lmp_missed_period : false,
                is_missed_period : false,
                disabled_menstrual_remarks: false,
                disabled_ducation: false,
                disabled_cycle_length: false,
                // changes_in_cycle_status : false,
              })
        }

        await this.saveButtonStatus();
    }

    saveButtonStatus = async() =>{
        const { no_menstrual_hx, lmp_date, duration_of_period, menarch_age, menopause_age, lmp_missed_period, lmp_not_sure, cycle_characteristics_flow, changes_in_cycle }= this.state;
        var allowSave=true;
        if(no_menstrual_hx){
            allowSave=false;
        }
        if(lmp_date || menarch_age || menopause_age || duration_of_period || lmp_missed_period || lmp_not_sure || cycle_characteristics_flow || changes_in_cycle){
            allowSave=false;
        }
        
        await this.setState({disableSaveButton:allowSave})
    }

    //MENSTRUAL_HISTORY_GRAPH
    menstrualGraph = async () => {
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;
        
        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id: hospital_id,
            reference_id:reference_id,
            reference_type:0,
        }
        
        const result = await Axios.get(MENSTRUAL_HISTORY_GRAPH(PARAMS));
        if (result?.data?.data && result?.data?.data.chartdata) {
            this.setState({
                chartData: result?.data?.data.chartdata,
            });
        }

        if(this.state.patientDetails?.is_checked){
            this.setState({ no_menstrual_hx : true });
            this.disabledAllFields();
        }
    }

    disabledDate(current) {
        return current && (current < moment(LS_SERVICE.get('dob')).startOf('day') || current > moment());
    }

    handleContinue = () => {
        const {tabs_available} = this.state;
        let next_tab = tabs_available.sexual_tab_available ? '2' : (tabs_available.obs_tab_available ? '3' : (tabs_available.physical_tab_available ? '4' : null));
        if(next_tab){
            this.props.handleContinue(next_tab);
        } else {
            const { patient_id, reference_id, reference_type, hospital_id } = this.state.patient;
            const { patientData } = this.state;

            const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
            const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
            let index = pageListArr.indexOf('mensural_hx');
            index++;
            if (index < pageListArr.length && !isTodayVisitArr.includes(reference_id.toString())) {
                let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
                soapurl = soapurl.replace("HOSPITALID", hospital_id);
                soapurl = soapurl.replace("REFID", reference_id);
                soapurl = soapurl.replace("REFTYPE", reference_type);
                soapurl = soapurl.replace("PATID", patient_id);
                this.props.history.push({
                    pathname: soapurl,
                    state: { patientData }
                })
            } else {
                this.props.history.push({
                    pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`,
                    state: { patientData }
                })
            }
        }
    }

    // selectAll = async(e) =>{
    //     const name = e.target?.name;
    //     const checked = e.target?.checked;

    //     this.TrendGraphRef.current.handleGraphPrint(e);
    // }

    render() {
        const { patient, patientDetails, menstrualHistoryListStatus, menstrualHistoryList } = this.state;
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <form className="emrfrm">
                            <div className="row mb-2">
                                <div className="col-3">
                                {((patientDetails && patientDetails.is_checked == true) || menstrualHistoryListStatus)?
                                    <>
                                    <div className="custom-control custom-checkbox float-left pt-2">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="no-menstrual-hostory"
                                            checked={(patientDetails && patientDetails.is_checked == true)? true : false}
                                            disabled={true}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="no-menstrual-hostory"
                                        >
                                            No Menstrual History
                                        </label>
                                    </div>
                                    </>
                                    : null }
                                </div>
                                <div className="col-3">
                                    {(patientDetails && (patientDetails.menopause_age || patientDetails.menarch_age))?
                                        <div className="form-group pt-2">
                                        <span>{(patientDetails && patientDetails.menopause_age)? 'Menopause Age' : 'Menarche Age' }</span>{" "}
                                        <span className="font-weight-bold">
                                            :&nbsp;
                                        </span>
                                        <span className="font-weight-bold">
                                            {(patientDetails && patientDetails.menopause_age)? `${patientDetails.menopause_age} Yrs` : (patientDetails && patientDetails.menarch_age)?`${patientDetails.menarch_age} Yrs`:'-' }
                                        </span>
                                    </div>
                                    :
                                    null
                                    }
                                </div>
                                
                                <div className="col-3">
                                    <div className="form-group pt-2 pl-5">
                                        {(patientDetails && patientDetails.lmp_date)?
                                        <LMP patient={patient} patientDetails={patientDetails} />
                                        :null}
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <button
                                            type="button"
                                            disabled={!this.state.allowEdit}
                                            className="float-md-right btn btn-outline-primary text-uppercase"
                                            onClick={(e) =>
                                                this.handleModalPopup(
                                                    e,
                                                    "addMenstrualModal"
                                                )
                                            }
                                        >
                                            + MENSTRUAL HX
                                        </button>
                                        <div style={{ float: "right", marginRight: "10px", marginTop: "5px" }}>
                                            <Lactating lactating={this.state.lactating} />
                                        </div>
                                        {this.props.menstrualHistoryList && this.props.menstrualHistoryList.length > 0 ? 
                                        <button
                                        style={{
                                            width: "16px",
                                            float: "right",
                                            marginRight: "20px",
                                            marginTop: "5px",
                                        }}
                                        type="button"
                                        className="float-md-right btn top-chart-btn p-0"
                                        onClick={(e) =>
                                            this.handleModalPopup(e, "chartModal", {
                                            type: "all",
                                            name: "Menstrual History Trend Graphs",
                                            })
                                        }
                                        >
                                        <i className="icon-chart"></i>
                                        </button>
                                        :null}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* ######################### tab body ################### */}
                    <MenstrualHxList handleModalPopup={this.handleModalPopup} ref={this.MenstrualHxListRef} menstrualHistoryListStatus={menstrualHistoryListStatus} menstrualHistoryList={menstrualHistoryList} patientDetails={patientDetails} patient={this.state.patient} />
          
                </div>

                {/* Modal For Menstrual Hx */}
                {(this.state.addMenstrualModal)?
                <Modal
                    title={this.state.actionText + " Menstrual History"}
                    visible={this.state.addMenstrualModal}
                    onCancel={(e) =>
                        this.handleModalPopup(e, "addMenstrualModal")
                    }
                    width="1000px"
                    bodyStyle={{ paddingBottom: "15px" }}
                    className="emrfrm"
                    footer={false}
                >
                    <Form
                     ref={this.FormRef}
                     onFinish={this.handleFormSubmit}
                     layout="vertical">
                        <div className="ant-row">
                            <div className="ant-col-6 pt-2">
                                <Checkbox
                                    name="no_menstrual_hx"
                                    onChange={this.onChangeMenstrualHxHandler}
                                    defaultChecked={patientDetails?.is_checked}
                                    disabled={
                                        this.state.disabled_no_menstrual_hx
                                    }
                                >
                                    No Menstrual Hx
                                </Checkbox>
                            </div>

                            <div className="ant-col-6 text-center">
                                <div className={(patientDetails && patientDetails.menarch_age && patientDetails.menarch_age != null)?"antInputItem hasdata":"antInputItem"
                                            }>
                                    <Form.Item
                                        name="menarch_age"
                                        label="Menarch Age"
                                        rules={[{
                                                type: 'number',
                                                max:this.state.dob,
                                                message:`Menarch age cannot be greater than patient's age`,
                                                transform: function (value) {
                                                return value === "" || value === undefined ? value : +value
                                            }
                                        }]}
                                    >
                                        <Input
                                            addonAfter="Years"
                                            name="menarch_age"
                                            id="menarch_age"
                                            maxLength="2"
                                            type="text"
                                            defaultValue={
                                                (patientDetails && patientDetails.menarch_age)?patientDetails.menarch_age:this.state.menarch_age
                                            }
                                            value={(patientDetails && patientDetails.menarch_age)?patientDetails.menarch_age:this.state.menarch_age}
                                            disabled={
                                                this.state.disabled_menarch_age
                                            }
                                            onChange={(e) =>
                                                this.menarcheAgeHandler(e)
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div
                                className="ant-col-6 text-center pt-2"
                                style={{ alignItems: "center" }}
                            >
                                <Checkbox
                                    name="menopause_age_hx"
                                    onChange={this.onChangeMenstrualHxHandler}
                                    checked={this.state.menopause_age_hx}
                                    value={this.state.menopause_age_hx}
                                    disabled={
                                        this.state.disabled_menopause_age_hx
                                    }
                                >
                                    Menopause Age
                                </Checkbox>
                            </div>

                            <div className="ant-col-6 text-center">
                                <div className={(patientDetails && patientDetails.menopause_age && patientDetails.menopause_age != null)?"antInputItem hasdata":"antInputItem"
                                            }>
                                    <Form.Item
                                        label="Menopause Age"
                                        name="menopause_age"
                                        rules={[{
                                                    required: this.state.requiredMenopauseAge,
                                                    message: "`Menopause Age` is required"
                                                },
                                                {
                                                max:this.state.dob,
                                                message:`Menopause age cannot be greater than patient's age`,
                                                type: 'number',
                                                transform: function (value) {
                                                return value === "" || value === undefined ? value : +value
                                            }
                                        }]}
                                    >
                                        <Input
                                            addonAfter="Years"
                                            id="menopause_age"
                                            name="menopause_age"
                                            type="text"
                                            defaultValue={(patientDetails && patientDetails.menopause_age && patientDetails.menopause_age != null)?patientDetails.menopause_age:''}
                                            value={(patientDetails && patientDetails.menopause_age && patientDetails.menopause_age != null)?patientDetails.menopause_age:''}
                                            disabled={
                                                this.state.menopause_age_dis
                                            }
                                            onChange={(e) =>
                                                this.menopauseAgeHandler(e)
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="ant-row">
                            <div className="ant-col-24 pb-2">
                                <span className="font-weight-bold">LMP</span>
                            </div>
                        </div>

                        <div className="ant-row">
                            <div className="ant-col-6 pr-2">
                                <Form.Item
                                 name="lmp_date"
                                >
                                    <DatePicker
                                        name="lmp_date"
                                        format="DD MMM, YYYY"
                                        disabledDate={this.disabledDate}
                                        style={{
                                            width: 100 + "%",
                                            height: 44 + "px",
                                        }}
                                        disabled={
                                            this.state.disabled_lmp_date
                                        }
                                        onChange={this.onChangeLMPDateHandler}
                                    />
                                </Form.Item>
                            </div>

                            <div className="ant-col-6">
                                <div className={(this.state.duration_of_period &&  this.state.duration_of_period != null)?"antInputItem hasdata":"antInputItem"}>
                                    <Form.Item
                                        label="Duration of Period"
                                        name="duration_of_period"
                                        rules={[{
                                                type: 'number',
                                                max: 99,
                                                message:"'Duration of Period' cannot be greater than 99",
                                                transform: function (value) {
                                                return value === "" || value === undefined ? value : +value
                                               }
                                            }]}
                                    >
                                        <Input
                                            type="text"
                                            name="duration_of_period"
                                            maxlength="2"
                                            addonAfter="Days"
                                            disabled={
                                                this.state.disabled_ducation
                                            }
                                            onChange={(e) =>
                                                this.onChangeCyclecharacteristics(
                                                    e,
                                                    "duration_of_period"
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="ant-col-4 text-center pt-2">
                                <Checkbox
                                    name="lmp_not_sure"
                                    onChange={this.onChangeMenstrualHxHandler}
                                    defaultChecked={this.state.lmp_not_sure}
                                    checked={this.state.lmp_not_sure}
                                    defaultValue={this.state.lmp_not_sure}
                                    disabled={
                                        this.state.disabled_lmp_not_sure
                                    }
                                >
                                    Not Sure
                                </Checkbox>
                            </div>
                            <div className="ant-col-4 text-center pt-2">
                                <Checkbox
                                    name="lmp_missed_period"
                                    onChange={this.onChangeMenstrualHxHandler}
                                    defaultChecked={
                                        this.state.lmp_missed_period
                                    }
                                    checked={this.state.lmp_missed_period}
                                    disabled={
                                        this.state.disabled_lmp_missed_period
                                    }
                                >
                                    Missed Period
                                </Checkbox>
                            </div>
                        </div>

                        
                        <div className="ant-row">
                            <div className="ant-col-24 pb-2">
                                <span className="font-weight-bold ">
                                    Cycle Characteristics
                                </span>
                            </div>
                        </div>

                        <div className="ant-row mt-2">
                            <div className="ant-col-4">
                                <div className="antSelectItem">
                                    <Form.Item
                                        label="Flow"
                                        name="cycle_characteristics_flow"
                                        // rules={[
                                        //     {
                                        //         required:this.state.requiredFlow,
                                        //         message: "Select Flow",
                                        //     },
                                        // ]}
                                    >
                                        <Select
                                            style={{ width: 120 }}
                                            name="cycle_characteristics_flow"
                                            disabled={
                                                this.state.is_missed_period
                                            }
                                            defaultValue={
                                                this.state
                                                    .cycle_characteristics_flow
                                            }
                                            onChange={(e) =>
                                                this.onChangeCyclecharacteristicsSelect(
                                                    e,
                                                    "cycle_characteristics_flow"
                                                )
                                            }
                                        >
                                            <Option value="normal">
                                                Normal
                                            </Option>
                                            <Option value="light">Light</Option>
                                            <Option value="heavy">Heavy</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="ant-col-4">
                                <div className="antSelectItem">
                                    <Form.Item
                                        label="Regularity"
                                        name="cycle_characteristics_regularity"
                                        rules={[
                                            {
                                                message: "Select Regularity",
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: 120 }}
                                            name="cycle_characteristics_regularity"
                                            disabled={
                                                this.state.is_missed_period
                                            }
                                            defaultValue={
                                             this.state
                                               .cycle_characteristics_regularity
                                            }
                                            onChange={(e) =>
                                                this.onChangeCyclecharacteristicsSelect(
                                                    e,
                                                    "cycle_characteristics_regularity"
                                                )
                                            }
                                        >
                                            <Option value="regular">
                                                Regular
                                            </Option>
                                            <Option value="irregular">
                                                Irregular
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="ant-col-6 cus-radio pt-2">
                                <label htmlFor="pain_ful">Painful</label>
                                <Radio.Group
                                    name="pain_ful"
                                    defaultValue={this.state.pain_ful}
                                    value={this.state.pain_ful}
                                    label=""
                                    disabled={this.state.is_missed_period}
                                    onChange={(e) =>
                                        this.menstrualRadioHxHandler2(
                                            e,
                                            "pain_ful"
                                        )
                                    }
                                >
                                    <Radio value="1" className="pl-3 pr-3">
                                        Yes
                                    </Radio>
                                    <Radio value="0" className="mr-2">
                                        No
                                    </Radio>
                                </Radio.Group>
                            </div>

                            <div className="ant-col-4">
                                <div className="antSelectItem">
                                    <Form.Item
                                        name="pain_score"
                                        label="Pain Score"
                                        rules={[
                                            {
                                                required:this.state.requiredPainScore,
                                                message:"'Pain Score' is required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: 120 }}
                                            //defaultValue={this.state.pain_score}
                                            name="pain_score"
                                            disabled={
                                                this.state.is_pain_ful
                                            }
                                            onChange={(e) =>
                                                this.onChangeCyclecharacteristicsSelect(
                                                    e,
                                                    "pain_score"
                                                )
                                            }
                                        >
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                            <Option value="6">6</Option>
                                            <Option value="7">7</Option>
                                            <Option value="8">8</Option>
                                            <Option value="9">9</Option>
                                            <Option value="10">10</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="ant-col-6">
                                <div className={(this.state.cycle_length &&  this.state.cycle_length != null)?"antInputItem hasdata":"antInputItem"}>
                                    <Form.Item
                                        label="Cycle Length"
                                        name="cycle_length"
                                        rules={[{
                                                type: 'number',
                                                max: 99,
                                                message:"'Cycle Length' cannot be greater than 99",
                                                transform: function (value) {
                                                   return value === "" || value === undefined ? value : +value
                                                }
                                            }]}
                                    >
                                        <Input
                                            type="text"
                                            name="cycle_length"
                                            maxlength="2"
                                            defaultValue={this.state.cycle_length}
                                            addonAfter="Days"
                                            disabled={
                                                this.state.disabled_cycle_length
                                            }
                                            onChange={(e) =>
                                                this.onChangeCyclecharacteristics(
                                                    e,
                                                    "pain_score"
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="ant-row ">
                            <div className="ant-col-8 pt-2">
                                <label
                                    htmlFor="changes_in_cycle"
                                    className="pr-4"
                                >
                                    Changes in Cycle
                                </label>
                                <Radio.Group
                                    name="changes_in_cycle"
                                    defaultValue={this.state.changes_in_cycle}
                                    value={this.state.changes_in_cycle}
                                    disabled={this.state.is_missed_period}
                                    label=""
                                    onChange={(e) =>
                                        this.menstrualRadioHxHandler(
                                            e,
                                            "changes_in_cycle"
                                        )
                                    }
                                >
                                    <Radio value="1" className="mr-4">
                                        Yes
                                    </Radio>
                                    <Radio value="0" className="mr-4">
                                        No
                                    </Radio>
                                </Radio.Group>
                            </div>

                            <div className="ant-col-10">
                                <div className={(this.state.change_description &&  this.state.change_description != null)?"form-group antInputItem micwrap ant-mic-wrap pr-2 hasdata":"form-group antInputItem micwrap ant-mic-wrap pr-2"
                                            }>
                                  
                                    <Form.Item
                                        label="Change Description"
                                        name="change_description"
                                        // rules={[{
                                        //     required:this.state.requiredChangeDescription,
                                        //     message:"'Change description' is required"
                                        // }]}
                                    >
                                    <a
                                        disabled={
                                            this.state.changes_in_cycle_status
                                        }
                                        onClick={(this.state.changes_in_cycle_status)? (e) => { e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: 'change_description' })} }
                                        className={(this.state.speech.identifier === 'change_description' && this.state.speech.state) ? 'mic r-0 on mute' : 'mic r-0'} 
                                    ></a>
                                        <Input
                                            id="change_description"
                                            style={{ minHeight: "43px" }}
                                            name="change_description"
                                            value={this.state.change_description}
                                            defaultValue={this.state.change_description}
                                            maxlength={255}
                                            autoComplete="off"
                                            disabled={
                                                this.state
                                                    .changes_in_cycle_status ||
                                                this.state.is_missed_period
                                            }
                                            onChange={(e) =>
                                                this.onChangeCyclecharacteristics(
                                                    e,
                                                    "change_description"
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="ant-col-6 ">
                                <div className="ant-input-select antInputItem d-flex">
                                    <Form.Item
                                        name="since_length"
                                        label="Since"
                                        rules={[{
                                                required:this.state.requiredChangeDescription,
                                                message:"'Since' is required"
                                            },
                                            {
                                                type: 'number',
                                                max: 99,
                                                message:"'Since' cannot be greater than 99",
                                                transform: function (value) {
                                                    return value === "" || value === undefined ? value : +value
                                                }
                                        }]}
                                    >
                                    <Input
                                        type="text"
                                        name="since_length"
                                        defaultValue={this.state.since_length}
                                        value={this.state.since_length}
                                        autoComplete="off"
                                        className="since_length"
                                        disabled={this.state.changes_in_cycle_status || this.state.is_missed_period}
                                        onChange={(e) =>
                                            this.onChangeCyclecharacteristics(
                                                e,
                                                "since_length"
                                            )
                                        }
                                    />
                                    </Form.Item>
                                    <Form.Item 
                                        name="since_time"
                                        >
                                            <Select
                                               
                                                defaultValue={
                                                    this.state.since_time
                                                }
                                                disabled={
                                                    this.state
                                                        .changes_in_cycle_status
                                                }
                                                onChange={(e) =>
                                                    this.onChangeCyclecharacteristicsSelect(
                                                        e,
                                                        "since_time"
                                                    )
                                                }
                                            >
                                                <Option value="days">
                                                    Days
                                                </Option>
                                                <Option value="weeks">
                                                    Weeks
                                                </Option>
                                                <Option value="months">
                                                    Months
                                                </Option>
                                                <Option value="years">
                                                    Years
                                                </Option>
                                            </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className="ant-row mt-3">
                            <div className="ant-col-24">
                                <div className={(this.state.menstrual_remarks &&  this.state.menstrual_remarks != null)?"antInputItem micwrap ant-mic-wrap hasdata":"antInputItem micwrap ant-mic-wrap"
                                            }>
                                    <Form.Item
                                        label="Remarks"
                                        name="menstrual_remarks"
                                        rules={[{
                                            len: 10,
                                            message:'Enter valid'
                                        }]}
                                    >
                                        <a
                                            disabled={
                                                this.state.disabled_menstrual_remarks
                                            }
                                            onClick={(this.state.disabled_menstrual_remarks)? (e) => { e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: 'menstrual_remarks' }) } }
                                            className={(this.state.speech.identifier === 'menstrual_remarks' && this.state.speech.state) ? 'mic r-0 on mute' : 'mic r-0'} 
                                        ></a>
                                        <TextArea
                                            id="menstrual_remarks"
                                            name="menstrual_remarks"
                                            style={{ minHeight: "59px" }}
                                            autoComplete="off"
                                            onChange={(e) =>
                                                this.remarksHandler(e)
                                            }
                                            disabled={
                                                this.state.disabled_menstrual_remarks
                                            }
                                            value={
                                                this.state.menstrual_remarks
                                            }
                                            suffix={suffix}
                                        ></TextArea>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="ant-col-24 mt-3 mb-2 text-center">
                            <button
                                type="submit"
                                className="btn btn-primary text-uppercase px-5"
                                id="menstrual_modal_save"
                                data-target=""
                                disabled={this.state.disableSaveButton || this.state.spin_loading}
                            >
                                Save{this.state.spin_loading ? LOADER : ''}
                            </button>
                        </div>
                    </Form>
                </Modal>
                : null }

                {(patientDetails && this.state.chartModal)? 
                <Modal
                    title={this.state.chartHeading}
                    visible={this.state.chartModal}
                    destroyOnClose={true}
                    onCancel={(e) =>
                        this.handleModalPopup(e, "chartModal")
                    }
                    style={{
                        top: "40px",
                        maxWidth: this.state.chartType === "all" ? "90%" : "600px",
                    }}
                    width="100%"
                    className={`${
                        this.state.chartType === "all" ? "all" : "all"
                    }`}
                    bodyStyle={{ maxHeight: "700px", overflowY: "auto" }}
                    footer={
                        this.state.chartType === "all" ? (
                            <div className="text-center justify-content-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary text-uppercase ml-2"
                                    onClick={(e) =>
                                    this.handleModalPopup(e, "chartModal", {
                                    type: "all",
                                    name: "Trend Graphs",
                                    disabled: !this.state.allowEdit
                                    })}
                                >
                                    Confirm
                                </button>
                            </div>
                        ) : null
                    }
                >
                    {/* <h5 className="mt-3">{this.state.chartYaxisName}</h5> */}
                    <div className="col d-flex justify-content-around align-items-center">
                        <ul className="chart_filter">
                            <li>
                                <button
                                    type="button"
                                    className={`btn btn-default chart-day ${
                                        this.state.chartsTab === "0" ? "active" : ""
                                    }`}
                                    onClick={(e) => this.handleGraphClick(e, "0")}
                                >
                                    DAY
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    className={`btn btn-default chart-week ${
                                        this.state.chartsTab === "1" ? "active" : ""
                                    }`}
                                    onClick={(e) => this.handleGraphClick(e, "1")}
                                >
                                    WEEK
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    className={`btn btn-default chart-month ${
                                        this.state.chartsTab === "2" ? "active" : ""
                                    }`}
                                    onClick={(e) => this.handleGraphClick(e, "2")}
                                >
                                    MONTH
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    className={`btn btn-default chart-year ${
                                        this.state.chartsTab === "3" ? "active" : ""
                                    }`}
                                    onClick={(e) => this.handleGraphClick(e, "3")}
                                >
                                    YEAR
                                </button>
                            </li>
                        </ul>
                    </div>
                    {(this.state.toddaychartData && this.state.chartType === 'all' && LS_SERVICE.get('user_type') !== 2)?
                    <div className="col-8 pl-2 check-primary">
                        <div className="custom-control custom-checkbox">
                            <input 
                            type="checkbox"
                            onChange={(e) => this.TrendGraphRef.current.handleGraphPrint(e, 'all_print')} 
                            checked={(this.state.all_print_checked_status)? this.state.update_all_print_checked: this.state.all_print_checked}
                            className="custom-control-input"
                            id="all_print"
                            name="all_print" autocomplete="off"
                            disabled={!this.state.allowEdit}
                            />
                            <label className="custom-control-label text-basic" htmlFor="all_print">Select All to Print with Prescription</label>
                        </div>
                    </div>
                    :null}
                    {this.state.chartDataLoaded ? (
                        <div className="row" style={{ marginTop: "22px" }}>
                        {(this.state.chartType != 'all')?
                        <TrendGraph
                            isPracticing={this.state.isPracticing} 
                            parentHandleModalPopup={this.props.parentHandleModalPopup}
                            allowEdit={this.state.allowEdit}
                            updateData={this.updateData}
                            chartData={this.state.chartData}
                            dataLoaded={this.state.chartDataLoaded}
                            chartType={this.state.chartType}
                            chartsTab={this.state.chartsTab}
                            checkBoxDisable={true}
                            checkBoxVisible={(this.state.checkBoxVisible === false)?false:true}
                            patient={this.state.patient}
                            menstrualHistoryChecked={(this.state.menstrualHistoryCheckedStatus == true)?this.state.menstrualHistoryCheckedNew:this.state.menstrualHistoryChecked}
                            single_print={(this.state.menstrualHistoryCheckedStatus == true)?this.state.menstrualHistoryCheckedNew[this.state.checkedVal]:this.state.menstrualHistoryChecked[this.state.checkedVal]}
                        />
                        : null}
                        {(this.state.chartType == 'all')?
                        <div className="col-12 fix-height-right col-md-12" style={{ marginTop: "22px" }}>
                            {MENSTRUAL_HX_TREND_GRAPH !== undefined && MENSTRUAL_HX_TREND_GRAPH.length > 0 ? MENSTRUAL_HX_TREND_GRAPH.map((d, i) => (
                                <TrendGraph
                                    isPracticing={this.state.isPracticing}
                                    parentHandleModalPopup={this.props.parentHandleModalPopup}
                                    allowEdit={this.state.allowEdit}
                                    updateData={this.updateData}
                                    chartData={this.state.chartData}
                                    dataLoaded={this.state.chartDataLoaded}
                                    chartType={d.val}
                                    chartTypes={'all'}
                                    title={d.des}
                                    chartsTab={this.state.chartsTab}
                                    checkBoxDisable={true}
                                    checkBoxVisible={true}
                                    patient={this.state.patient}
                                    menstrualHistoryChecked={(this.state.menstrualHistoryCheckedStatus == true)?this.state.menstrualHistoryCheckedNew:this.state.menstrualHistoryChecked}
                                    single_print={(this.state.menstrualHistoryCheckedStatus == true)?this.state.menstrualHistoryCheckedNew[d.checkedVal]:this.state.menstrualHistoryChecked[d.checkedVal]}
                                    ref={this.TrendGraphRef}
                                />
                           )) : null}
                        </div>
                        : null}
                        </div>
                    ) : null}
                </Modal> 
                : null}
                <div className="fixed-cta">
                {(this.props.pathname && (this.props.pathname.includes("objective") || LS_SERVICE.get('user_type') === 2)) ? 
                    <a href="javascript:void(0)" className="btn btn-primary px-4 py-1 nostyle-link" onClick={this.handleContinue}>CONTINUE</a> : null }
                </div>
            </>
        );
    }
}

export default MenstrualHx;
