import React, { Component } from 'react';
import AsideLeft from '../../components/aside/asideleft';
import {
    TEST_RESULT_INFO,
    TEST_RESULT_GRAPH,
    LOADER_RED
} from '../../utils/constant';
import API_SERVICE from '../../utils/apiService';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import HcLineGraph from '../../components/chart/line-graph';

class TestResultPathology extends Component {

    state = {
        result: {},
        requestSent: false,
        loading: false,
        code: null,
        patientId: null,
        showGraphModal: false,
        graphTitle: null,
        graphTab: 'y',
        graphKeys: [],
        graphValues: []
    }

    componentDidMount() {
        this.getResult();
    }

    async getResult() {
        const arrRoute = this.props.location.pathname.split('/');
        const code = arrRoute[arrRoute.length - 1];
        const patientId = arrRoute[arrRoute.length - 2];
        this.setState({
            code: code,
            patientId: patientId,
            loading: true,
            requestSent: false
        });

        const url = TEST_RESULT_INFO.replace('{code}', code);
        const result = await API_SERVICE.post(url, {
            bookingCode: code,
            patientId: patientId
        });
        if (result.status) {
            this.setState({
                result: result.data
            });
        }
        this.setState({
            loading: false,
            requestSent: true
        });
    }

    async showGraph() {
        this.setState({
            showGraphModal: true,
            graphTitle: this.state.result.test_name
        });

        const type = this.state.graphTab;
        const testId = this.state.result.test_id;
        let graphTodaysDate = new Date().toJSON().slice(0, 10).split('-');
        let graphEndDate = graphTodaysDate[2] + "/" + graphTodaysDate[1] + "/" + graphTodaysDate[0];

        let todayDate = new Date();
        let start = graphTodaysDate[2] + "/" + graphTodaysDate[1] + "/" + graphTodaysDate[0];

        if (type === 'y') {
            todayDate.setFullYear(todayDate.getFullYear() - 1);
            let graphLastYear = todayDate.toJSON().slice(0, 10).split('-');
            start = graphLastYear[2] + "/" + graphLastYear[1] + "/" + graphLastYear[0];
        } else if (type === 'm') {
            todayDate.setMonth(todayDate.getMonth() - 1);
            let graphLastYear = todayDate.toJSON().slice(0, 10).split('-');
            start = graphLastYear[2] + "/" + graphLastYear[1] + "/" + graphLastYear[0];
        } else if (type === 'w') {
            todayDate.setDate(todayDate.getDate() - 7);
            let graphLastYear = todayDate.toJSON().slice(0, 10).split('-');
            start = graphLastYear[2] + "/" + graphLastYear[1] + "/" + graphLastYear[0];
        }

        const result = await API_SERVICE.post(TEST_RESULT_GRAPH, {
            start_date: start,
            end_date: graphEndDate,
            patient_id: this.state.patientId,
            type: type,
            test_id: testId
        });
        if (result.dates != undefined && result.dates.length > 0) {
            this.setState({
                graphKeys: result.dates,
                graphValues: result.values
            });
        } else {
            this.setState({
                graphKeys: [],
                graphValues: []
            });
        }
    }

    render() {
        return (
            <>

                {this.state.patientId != null ? <AsideLeft patient_id={this.state.patientId} /> : null}

                <div className="content-wrapper">
                    <div className="container-fluid">

                        {this.state.result.test_name != undefined ?
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="float-left">
                                            <h1 className="page-title">Test Results</h1>
                                        </div>
                                        <div className="float-right">
                                            <Link className="btn btn-outline-secondary px-4 small text-uppercase" to={"/patient/test-results/" + this.state.patientId}>
                                                BACK
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <h2 className="page-title">{this.state.result.test_name}</h2>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <table className="table table-borderless table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>Ordering Physician</td>
                                                            <td className="text-dark">: {this.state.result.auth_doctor_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Test Date</td>
                                                            <td className="text-dark">: <Moment date={this.state.result.test_result_date} format="D MMM, YYYY" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-5">
                                                <table className="table table-borderless table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>Location</td>
                                                            <td className="text-dark">: {this.state.result.hospital_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Order Date</td>
                                                            <td className="text-dark">: <Moment date={this.state.result.checkup_date} format="D MMM, YYYY" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>Referral Interval</td>
                                                    <td>Unit</td>
                                                    <td>Trend</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="datatestname">
                                                    <td className="test_name" data-test_name="test_name">
                                                        {this.state.result.test_name}<br />
                                                        <span className={"badge " + ((this.state.result.test_result >= this.state.result.max_value || this.state.result.test_result <= this.state.result.min_value) ? 'badge-danger' : 'badge-success')}>{this.state.result.test_result}</span>
                                                    </td>
                                                    <td className="text-dark font-weight-bold">
                                                        {this.state.result.min_value != "" && this.state.result.max_value != "" ? this.state.result.min_value + '-' + this.state.result.max_value : null}
                                                        {this.state.result.min_value != "" && this.state.result.max_value == "" ? this.state.min_value + '<' : null}
                                                        {this.state.result.min_value == "" && this.state.result.max_value != "" ? '>' + this.state.result.max_value : null}
                                                    </td>
                                                    <td>{this.state.result.units}</td>
                                                    <td style={{ cursor: 'pointer' }} className="testGraphWidget" onClick={() => this.showGraph()}>
                                                        <img src="http://dev.woodlands.opdemr.myhealthcare.co/assets/images/icon-chart.png" alt="Chart" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : <div className="row">
                                <div className="col-md-12 text-center">
                                    {LOADER_RED}
                                </div>
                            </div>}

                        {this.state.requestSent && this.state.result.test_name == undefined ? <div className="row">
                            <div className="col-12">
                                <h4>Information not available</h4>
                            </div>
                        </div> : null}

                        <Modal
                            title={this.state.graphTitle}
                            visible={this.state.showGraphModal}
                            footer={false}
                            width={500}
                            onCancel={() => { this.setState({ showGraphModal: false }) }}
                        >
                            <div className="row">
                                <div className="col-12 my-3 text-center">

                                    <div className="row">
                                        <div className="col-12">

                                            <div className="chartCanvas">
                                                <div className="chartjs-size-monitor">
                                                    <div className="chartjs-size-monitor-expand">
                                                        <div className=""></div>
                                                    </div>
                                                    <div className="chartjs-size-monitor-shrink">
                                                        <div className=""></div>
                                                    </div>
                                                </div>
                                                <h4></h4>
                                                <ul className="chart_filter mt-1">
                                                    <li><button type="button" className={"btn btn-default js-test-chart-navigation chart-testid " + (this.state.graphTab == 'd' ? 'active' : '')} id="width-d2"
                                                        onClick={() => { this.setState({ graphTab: 'd' }, () => { this.showGraph() }) }}>DAY</button></li>
                                                    <li><button type="button" className={"btn btn-default js-test-chart-navigation chart-testid " + (this.state.graphTab == 'w' ? 'active' : '')} id="width-w2"
                                                        onClick={() => { this.setState({ graphTab: 'w' }, () => { this.showGraph() }) }}>WEEK</button></li>
                                                    <li><button type="button" className={"btn btn-default js-test-chart-navigation chart-testid " + (this.state.graphTab == 'm' ? 'active' : '')} id="width-m2"
                                                        onClick={() => { this.setState({ graphTab: 'm' }, () => { this.showGraph() }) }}>MONTH</button></li>
                                                    <li><button type="button" className={"btn btn-default js-test-chart-navigation chart-testid " + (this.state.graphTab == 'y' ? 'active' : '')} id="width-y2"
                                                        onClick={() => { this.setState({ graphTab: 'y' }, () => { this.showGraph() }) }}>YEAR</button></li>
                                                </ul>
                                                <HcLineGraph graphKeys={this.state.graphKeys} graphValues={this.state.graphValues} />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Modal>

                    </div>
                </div>

            </>
        )
    }
}

export default TestResultPathology;