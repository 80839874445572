import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import heart from '../../assets/images/ic-heart.svg'
import Moment from "react-moment";
import { Row, Col, Card, } from "react-bootstrap";
import { KARDIA_ECG, LOADER_RED, UHID, BOOKINGTIME24TO12, PATIENT_DATA, } from '../../utils/constant';
import AsideLeft from '../../components/aside/asideleft';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$ } from '../../utils/rxjs-sharing';

let _scrollEcgEvent = "";


class Ecg extends React.Component {

  state = {
    kardiaRecordings: [],
    kardiaDataLoaded: false,
    kardiaMrn: null,
    pdfData: null,
    activeId: null,
    noMoreData: false,
    isProcessing: false,
    pagination: 1,
    patient: null,
    ecgType: null,
  }

  componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;
    const patient = {
      hospital_id,
      patient_id,
      kardia_mrn: patient_id,
      qms_token_id: reference_id
    }
    this.setState({
      hospital_id,
      patient_id,
      reference_id,
      patient
    }, () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
        this.handleKardiaData();
      }
      else{
        this.handleKardiaData();
      }
    });

    // if (this.props.location.state !== undefined) {
    //     this.setState({
    //         patient: this.props?.location?.state?.patient
    //     }, () => {
    //         this.handleKardiaData();
    //     })
    // } else {
    //     this.getPatientData();
    // }
    
    this.setState({
      ecgType: this.props.location.state.ecgType ? this.props.location.state.ecgType : false
    });
  }

  // getPatientData = _ => {
  //     const { match: { params } } = this.props;
  //     const { patient_id } = params;
  //     Axios.get(PATIENT_DATA({ patient_id }))
  //         .then(success => {
  //             const patient = {
  //                 ...success.data.data.results,
  //                 qms_token_id: success.data.data.results.qmsToken.qms_token_id
  //             }
  //             this.setState({
  //                 patient
  //             }, () => {
  //                 this.handleKardiaData();
  //             })
  //         })
  //         .catch(err => console.log(err))
  // }

  handleActiveData = (e) => {
    this.setState({
      pdfData: e.target.dataset.pdf,
      activeId: e.target.dataset.id
    })
  }

  handleKardiaData = () => {
    const { kardiaMrn, pagination, kardiaRecordings, patient } = this.state;

    this.setState({
      isProcessing: true
    })

    Axios.get(KARDIA_ECG({ kardiaMrn: this.state.patient_id, pagination }))
      .then((res) => {
        const data = res.data.recordings;
        this.setState({
          kardiaRecordings: [...kardiaRecordings, ...data],
          noMoreData: res.data.pagination.links.next === "" ? true : false,
          activeId: data[0]?.id,
          pdfData: data[0]?.kardia_pdf_path,
          isProcessing: false,
          kardiaDataLoaded: true
        }, () => {
          const infitr = document.querySelector(".cp_scroll_loader");
          if (infitr !== null)
            infitr.addEventListener("scroll", this.handleScroll, true);
        })
      })
  }


  handleScroll = () => {
    const lastLi = document.querySelector(
      ".cp_scroll_loader > div > div:last-child"
    );
    const scrollWin = document.querySelector(".cp_scroll_loader");
    if (lastLi === null) return;
    let lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
    let pageOffset = scrollWin.scrollTop + scrollWin.clientHeight + lastLi.clientHeight;
    if (pageOffset > lastLiOffset) {
      this.LoadMore();
    }
  };

  LoadMore = () => {
    const { noMoreData, isProcessing } = this.state;
    if (noMoreData) return;
    if (isProcessing === true) return;
    this.setState(prevState => ({
      isProcessing: true,
      pagination: prevState.pagination + 1
    }), () => this.handleKardiaData())
  }

  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const { kardiaDataLoaded, pdfData, kardiaRecordings, activeId, isProcessing, subHeaderData, patient } = this.state
    // console.log(this.props.location.state.kardia_mrn, this.props.location.state.patient, isProcessing)
    return (
      <div>
        {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} isHomecare={this.state.ecgType} /> : null}

        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row emrfrm">
              <div className="col-12 mb-3">
                <div className="float-left">
                  <h1 className="page-title mb-0">ECG Readings</h1>
                </div>
                <div className="float-right">
                  <a href="#" className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</a>
                </div>
              </div>
            </div>

            {kardiaDataLoaded && kardiaRecordings.length > 0 ? (
              <div className="row ecg__body p-2">
                <div className="col-3 p-0">
                  <aside className="aside-copy  w-100 cp_scroll_loader">
                    <div>
                      {kardiaRecordings.map((record, i) => (
                        <div key={record.id} className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
                          <div className="ecg-card-bpm d-flex my-2 justify-content-between">
                            <div className="ecg-bpm-value d-flex flex-row">
                              <img className="mb-1" src={heart} alt="heart-img" />
                              <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
                            </div>
                            <div className="font-weight-bold text-capitalize">{record.algorithm_determination.toLowerCase()}</div>
                          </div>
                          <p>
                            <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
                              {record.recorded_at}
                            </Moment>
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="col justify-content-center align-self-center"
                      style={{
                        display: `${isProcessing ? "flex" : "none"}`,
                        margin: `${isProcessing ? "30px 0" : ""}`,
                      }}
                    >
                      {LOADER_RED}
                    </div>
                  </aside>
                </div>
                <div className="col-9 pl-2 pr-0">
                  <div className="ecg_right bg-white">
                    <object title="Document" data={pdfData + "#toolbar=0"} type='application/pdf' data-rotate="0"></object>
                  </div>
                </div>
              </div>
            ) : kardiaDataLoaded && kardiaRecordings.length === 0 ? (
              <div className="row">
                <div className="col-12">
                  <div>
                    <Card className="mb-3">
                      <Card.Body className="card-pastVisit">
                        <Row className="d-flex" style={{ flexWrap: "nowrap" }}>
                          <Col className="d-flex justify-content-center">
                            <Card.Title className="font-weight-normal mb-0">
                              No ECG Readings
                                                        </Card.Title>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col justify-content-center align-self-center"
                style={{
                  display: `${!kardiaDataLoaded ? "flex" : "none"}`,
                  margin: `${!kardiaDataLoaded ? "30px 0" : ""}`,
                }}
              >
                {LOADER_RED}
              </div>

            )}
          </div>


        </div>

      </div>
    )
  }

}


export default withRouter(Ecg);

