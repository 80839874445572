import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/pages/app/App';
import { BrowserRouter as Router } from "react-router-dom";
// import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app/assets/scss/index.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import './app/assets/css/vc.css';
import './app/assets/css/pubnub.css';
import './app/assets/css/bootstrap4-toggle.mis.css'
import './app/assets/css/custom.css';
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
