import LS_SERVICE from './localStorage';
import {
    VONAGE_CALL_API,
    USERTYPES
} from "./constant";
import axios from "axios";

const VC_HELPER = {
    getOtSessionToken(data) {
        // var $this = this;
        const is_homecarecall = LS_SERVICE.get('is_homecarecall');
        const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor ? true : false;
        // const doctor = LS_SERVICE.get('doctor');

        return new Promise((resolve, reject) => {
            const PARAMS = {
                source: 'web',
                // booking_id: +this.state.booking_id,
                booking_id: data.booking_id,
                is_homecare: is_homecarecall,
                caller_id: LS_SERVICE.get('staff_id'),
                caller_type: is_doctor ? 1 : 2 // (1 - Doctor and 2 - Nurse)
            }

            axios.post(VONAGE_CALL_API, PARAMS)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    // $this.updateVideoCallMsg({
                    //     btnStatus: true,
                    //     message: 'Session cannot be started, please refresh page to start video call'
                    // });
                    resolve({
                        btnStatus: true,
                        message: 'Session cannot be started, please refresh page to start video call'
                    });
                });
        });
    },

    ring() {
        document.getElementById("callingSignal").play();
    },

    stopring() {
        document.getElementById("callingSignal").pause();
        document.getElementById("endCallSignal").play();
    },

    pauseRing() {
        document.getElementById("callingSignal").pause();
    },

    ic_ring() {
        document.getElementById("ringtoneSignal").play();
    },

    ic_stopring() {
        document.getElementById("ringtoneSignal").pause();
        document.getElementById("endCallSignal").play();
    },

    ic_stopOutring() {
        document.getElementById("callingSignal").pause();
        document.getElementById("endCallSignal").play();
    },

    dragElement() {
        var elmnt = document.getElementById('vmp_vc_dialog');
        if (elmnt === null) return;
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById('vmp_vc_dialog_drag') == null) {
            return;
        }
        if (document.getElementById('vmp_vc_dialog_drag')) {
            // if present, the header is where you move the DIV from:
            document.getElementById('vmp_vc_dialog_drag').onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            /** was brekaing with the following error 'TypeError: Cannot set property 'onmousedown' of null' so added null check at start **/
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;

            let topPos = 0;
            if (elmnt.offsetTop <= 0) {
                topPos = 1;
            } else if ((elmnt.offsetTop + elmnt.offsetHeight) >= window.innerHeight) {
                topPos = window.innerHeight - elmnt.offsetHeight - 1;
            } else {
                topPos = elmnt.offsetTop - pos2;
            }

            let leftPos = 0;
            if (elmnt.offsetLeft <= 0) {
                leftPos = 1;
            } else if ((elmnt.offsetLeft + elmnt.offsetWidth) >= window.innerWidth) {
                leftPos = window.innerWidth - elmnt.offsetWidth - 10;
            } else {
                leftPos = elmnt.offsetLeft - pos1;
            }

            //   elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            //   elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            elmnt.style.top = topPos + "px";
            elmnt.style.left = leftPos + "px";
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    },

    dragElementV2() {
        var elmnt = document.getElementById('vmp_vc_dialog_v2');
        console.log(">> Salman",1)
        if (elmnt === null) return;
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        console.log(">> Salman",2)
        if (document.getElementById('vmp_vc_dialog_drag_v2') == null) {
            return;
        }
        console.log(">> Salman",3)
        if (document.getElementById('vmp_vc_dialog_drag_v2')) {
            // if present, the header is where you move the DIV from:
            console.log(">> Salman",4)
            document.getElementById('vmp_vc_dialog_drag_v2').onmousedown = dragMouseDownV2;
            console.log(">> Salman",5)
        } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            /** was brekaing with the following error 'TypeError: Cannot set property 'onmousedown' of null' so added null check at start **/
            elmnt.onmousedown = dragMouseDownV2;
        }

        function dragMouseDownV2(e) {
            console.log(">> Salman",6)
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElementV2;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDragV2;
        }

        function elementDragV2(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;

            let topPos = 0;
            if (elmnt.offsetTop <= 0) {
                topPos = 1;
            } else if ((elmnt.offsetTop + elmnt.offsetHeight) >= window.innerHeight) {
                topPos = window.innerHeight - elmnt.offsetHeight - 1;
            } else {
                topPos = elmnt.offsetTop - pos2;
            }

            let leftPos = 0;
            if (elmnt.offsetLeft <= 0) {
                leftPos = 1;
            } else if ((elmnt.offsetLeft + elmnt.offsetWidth) >= window.innerWidth) {
                leftPos = window.innerWidth - elmnt.offsetWidth - 10;
            } else {
                leftPos = elmnt.offsetLeft - pos1;
            }

            //   elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            //   elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            elmnt.style.top = topPos + "px";
            elmnt.style.left = leftPos + "px";
        }

        function closeDragElementV2() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }
};

export default VC_HELPER;