import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { CHANGE_PASSWORD } from '../../utils/constant';
import $ from 'jquery';
import { jQueryLabels } from '../../utils/jQueryLabels';
import LogoImg from "../../assets/images/logo.png";
import { headerService$ } from '../../utils/rxjs-sharing';
import { notification } from 'antd';

class PasswordComponent extends Component {

  state = {
    old_password: '',
    password: '',
    confirm_password: '',
    ErrMessage: ''
  }

  constructor(props) {
    super(props);

    this.userOldPassword = React.createRef();
    this.userNewPassword = React.createRef();
    this.userConfirmPassword = React.createRef();
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  componentDidMount() {
    $(document).ready(function () {
      jQueryLabels();
    })
  }



  handleOnSubmit = (event) => {
    event.preventDefault();

    let old_password, password, confirm_password;
    old_password = this.userOldPassword.current.value;
    password = this.userNewPassword.current.value;
    confirm_password = this.userConfirmPassword.current.value;

    const PARAMS = {
      old_password,
      password,
      confirm_password
    }
    Axios.post(CHANGE_PASSWORD, qs.stringify(PARAMS))
      .then(response => {
        //console.log(response);

        notification.success({
          message: response.data.message,
          description: 'Please Login to continoue',
          placement: 'topRight'
        });

        headerService$.showHeader({ showHeader: 'false' });
        LS_SERVICE.clear();
        this.props.history.push('/');

        //debugger;
      }).catch(error => {
        console.log(error.response);
        // if (error && error.response && error.response.status === 401) {
        // 	LS_SERVICE.clear();
        // 	this.props.history.push("/");
        // }
        let errorMsg = error.response.data.message;
        if (errorMsg === '"old_password" is not allowed to be empty') {
          errorMsg = "Old password is not allowed to be empty";
        }
        if (errorMsg === "Bad Request") {
          errorMsg = "Incorrect old password";
        }
        // if (error && error.response && error.response.message) {
        notification.error({
          message: errorMsg,
          placement: 'topRight'
        });
        // }
      })

  }


  render() {
    return (
      <main id="main">
        <div className="container-fluid">
          <div className="row justify-content-center my-4">
            <form className="emrfrm password_wrap p-4 py-5 submitForm" autoComplete="off" onSubmit={this.handleOnSubmit}>
              <div className="row text-center">
                <div className="col-12 pb-3"><img className="img-fluid" src={LogoImg} alt="logo" /></div>
                <h3 className="col-12 text-center font-weight-light">Change Password</h3>
                <p>Please complete the following fields to change your password.</p>
                {/* <!-- <p className="col-12 text-center font-weight-light">Please complete the following fields to change your password.</p> --> */}
                <div className="col-12 mt-3">
                  <div className="alert alert-danger d-none text-left" id="js-flash-msg">
                    {/* {ErrMessage} */}
                  </div>

                  <div className="form-group">
                    <input type="password" id="old-password" name="old_password" className="form-control" placeholder="Old Password" autoComplete="off" ref={this.userOldPassword} />
                    <label htmlFor="old-password">Old Password</label>
                  </div>

                  <div className="form-group">
                    <input type="password" name="password" id="new-password" className="form-control" placeholder="New Password" autoComplete="off" ref={this.userNewPassword} />
                    <label htmlFor="new-password">New Password</label>
                  </div>

                  <div className="form-group">
                    <input type="password" name="confirm_password" id="confirm-new-password" className="form-control" placeholder="Confirm New Password" autoComplete="off" ref={this.userConfirmPassword} />
                    <label htmlFor="confirm-new-password">Confirm New Password</label>
                  </div>
                  <button className="col-12 btn btn-primary px-5 text-uppercase operational-btns" type="submit" id="submit">Change Password</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    )
  }
}

export default PasswordComponent;