import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Moment from "react-moment";
import moment, { duration } from "moment";
import { Modal } from "antd";
import "./LMPCalender.scss";
import {MENSTRUAL_HISTORY_LIST} from "../../utils/constant";
import Axios from 'axios';
import { lmpDataService } from '../../utils/rxjs-sharing';

class LMPCalender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLMPCalender: false,
            addMenstrualCalenderModal: false,
            periodStartDate: [],
            calenderEventsData: [],
            patientLmpDetails: [],
            durationOfPeriod : 5,
            lmpCalenderCurrentDate : ""
        };
        this.calendarRef = React.createRef();
        this.calenderDateChangeHandler =
        this.calenderDateChangeHandler.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { patient, showLMPCalender, patientLmpDetails  } = props;
        return { patient: patient, showLMPCalender: showLMPCalender, patientLmpDetails: patientLmpDetails };
    }

    componentDidMount = async () => {
        this.getPeriodStartDate();
    }

    componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    //Get data from api
    getPeriodStartDate = async() => {
        this.subscription = lmpDataService.status().subscribe(data => {
            let LmpLabelList    = data?.menstrualHistoryLmp?.patientLmpDetails.LmpLabelList;
            let LmpDurationList = data?.menstrualHistoryLmp?.patientLmpDetails.LmpDurationList;
        
            if(LmpLabelList && LmpLabelList.length > 0){
                // let dateLmp = LmpLabelList[0].split('-');
                // let currentdate = new Date();
                // let cur_month = currentdate.getMonth() + 1;
                // let cur_year =currentdate.getFullYear();

                // if(cur_year == dateLmp[0] && cur_month == dateLmp[1]){
                    this.setState({
                        lmpCalenderCurrentDate: LmpLabelList[LmpLabelList.length - 1]
                    });
                // }   
            }
            
            this.setState({
                LmpDurationList : LmpDurationList,
                periodStartDate : LmpLabelList,
                durationOfPeriod : LmpDurationList[moment(LmpLabelList[0]).format('DDMMYYYY')]
            });
        });
    }
    
    //@param string yyyy-mm-dd(2021-06-07)
    getCalenderEventsData(__date) {
        let __startDate = new Date();
        if (__date) {
            __startDate = new Date(__date);
        }
        // console.log("__startDate : ", __startDate);
        let __endDateStamp = moment(__startDate).add(30, "days");
        let __endDate = new Date(__endDateStamp);
        //console.log("__endDate : ", __endDate);

        const calenderEventsData = [];

        let count = 0;
        let flag = false;

        while (__startDate <= __endDate) {
            count++;
            var __month =
                __startDate.getMonth() + 1 >= 10
                    ? __startDate.getMonth() + 1
                    : "0" + (__startDate.getMonth() + 1);
            var __day =
                __startDate.getDate() >= 10
                    ? __startDate.getDate()
                    : "0" + __startDate.getDate();
            var __year = __startDate.getFullYear();
            var date = `${__year}-${__month}-${__day}`; //yyyy-mm-dd

            if (count <= this.state.durationOfPeriod) {
                calenderEventsData.push({
                    start: date,
                    end: date,
                    rendering: "background",
                    backgroundColor: "#efbdd7",
                    title: count,
                    textColor: "#333",
                });
                flag = true;
            } else {
                flag = false;
            }

            if (count >= 10 && count <= 14) {
                calenderEventsData.push({
                    start: date,
                    end: date,
                    backgroundColor: "#a9c370",
                    title: count,
                    textColor: "#fff",
                });
                flag = true;
            } else {
                flag = false;
            }

            if (!flag) {
                calenderEventsData.push({
                    start: date,
                    end: date,
                    title: count,
                    textColor: "#333",
                });
            }
            //console.warn(date);
            __startDate = new Date(
                __startDate.setDate(__startDate.getDate() + 1)
            ); //date increase by 1
        }
        this.setState({ calenderEventsData: calenderEventsData });
    }

    changePeriodDateHandler = async(__date) => {
        //console.log("__date : ", __date);
        const calendarApi = this.calendarRef?.current?.getApi();
        await calendarApi.gotoDate(__date);
        await this.setState({ durationOfPeriod : this.state.LmpDurationList[moment(__date).format('DDMMYYYY')]});
        await this.getCalenderEventsData(__date);
    }

    checkPeriodDate(_date) {
        if (!_date) return;

        let __date = new Date(_date);
        // console.log("__date : ", __date);
        const __calenderMonth = __date.getMonth() + 1;
        const __calenderYear = __date.getFullYear();

        const { periodStartDate } = this.state;

        if (periodStartDate && periodStartDate.length > 0) {
            for (let __perioddate of periodStartDate) {
                const __newPeriodDate = new Date(__perioddate);
                const __periodMonth = __newPeriodDate.getMonth() + 1;
                const __periodYear = __newPeriodDate.getFullYear();

                if (
                    __periodMonth === __calenderMonth &&
                    __periodYear === __calenderYear
                ) {
                    this.setState({ lmpCalenderCurrentDate: __perioddate });
                    this.getCalenderEventsData(__perioddate);
                    break;
                }
            }
        }
    }

    calenderDateChangeHandler(info) {
        if (!info.view.title) return;
        let __dateChanged = info.view.title;
        let __date = new Date(__dateChanged);
        //console.log("__date : ", __date);
        const __calenderMonth = __date.getMonth() + 1;
        const __calenderYear = __date.getFullYear();
        const __newDate = `${__calenderYear}-${__calenderMonth}-01`;
        this.checkPeriodDate(__newDate);
    }

    // For Modal popup
    handleModalPopup = (e, popup) => {
        if (e !== null) e.preventDefault();

        this.setState({
            [popup]: !this.state[popup],
        });
    };

    render() {
        return (
            <Modal
                title="Menstrual Hx"
                visible={this.state.showLMPCalender}
                onCancel={this.props.hideLMPCalender}
                width="1000px"
                bodyStyle={{ paddingBottom: "15px" }}
                className="emrfrm"
                footer={false}
            >
                <div className="menstrual__lmpPeriodStartDate pt-3">
                    <div className="ant-row">
                        <div className="col-2">
                            <div className="period__startDateText">
                                Period Start Date
                            </div>
                        </div>
                        <div className="col-10 ">
                            <div className="period__startDates">
                                {this.state.periodStartDate &&
                                    this.state.periodStartDate.map(
                                        (period_date, index) => (
                                            <a
                                                className={
                                                    this.state
                                                        .lmpCalenderCurrentDate ===
                                                    period_date
                                                        ? "active"
                                                        : ""
                                                }
                                                key={index}
                                                onClick={(e) =>
                                                    this.changePeriodDateHandler(
                                                        period_date
                                                    )
                                                }
                                            >
                                                <Moment
                                                    parse="YYYY-MM-DD"
                                                    date={period_date}
                                                    format="DD MMM, YYYY"
                                                />
                                            </a>
                                        )
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="menstrual__lmpCalender"
                    className="menstrual__lmpCalender"
                    style={{ width: "600px", margin: "20px auto" }}
                >
                    <FullCalendar
                        firstDay={1}
                        defaultDate= {this.state.lmpCalenderCurrentDate}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        selectable={true}
                        datesRender={this.calenderDateChangeHandler}
                        events={this.state.calenderEventsData}
                        ref={this.calendarRef}
                        header={{
                            left: "leftCustom prev",
                            center: "title",
                            right: "rightCustom next",
                        }}
                    />
                    <div className="menstrual__colorLegends">
                        <div className="menstrual__period">
                            <span className="period__color period__colorPink mb-3"></span>
                            <span className="period__text">
                                Menstrual Period
                            </span>
                        </div>
                        <div className="menstrual__period">
                            <span className="period__color period__colorGreen"></span>
                            <span className="fertile__periodText">
                                Most Fertile Period
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default LMPCalender;
