import React, { Component, createRef } from 'react';
import qs from 'qs';
import { withRouter } from "react-router-dom";
import AsideLeft from '../../../components/aside/asideleft';
import Axios from "axios";
import { HC_GOBACK } from '../../../utils/constant';
import iconOkay from "../../../assets/images/ok-icon.svg";
import { HOMECARE_CARE_PLAN, HOMECARE_CARE_PLAN_V2 } from "../../../utils/constant";
import moment from 'moment';


class HomecareCarePlan extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tableHeaders: [],
      dataByTasks: [],
      // distinctTask: []
    }
  }



  /*Initial Function*/
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const { patient_id, reference_id } = params;
    let booking_id = reference_id
    Axios
      .get(HOMECARE_CARE_PLAN({ booking_id, patient_id }))
      .then((res) => {
        this.setState({
          task_data: res.data.task_data,
          tableHeaders: res.data.tableHeaders,
          dataByTasks: res.data.dataByTasks,
          checksByFrequency: res.data.checksByFrequency,
        }, console.log('This state', this.state))
      })
  }

  getTableData() {
    const { tableHeaders, dataByTasks, task_data, checksByFrequency } = this.state;
    return (
      tableHeaders ?
        task_data.map((th) => {
          return (
            <>
              <tr>
                <td style={{backgroundColor: '#e6e6e6'}}>{th.task_name}</td>
                {tableHeaders.map((thDate) => (
                  <td style={{backgroundColor: '#e6e6e6'}}>
          
                    {th.sub_tasks.length == 0 ? <React.Fragment>

                    {(typeof dataByTasks['task_' + th.id] != 'undefined' && dataByTasks['task_' + th.id].includes(thDate)) ? <React.Fragment>
                      
                      {(typeof checksByFrequency['task_' + th.id] != 'undefined' && typeof checksByFrequency['task_' + th.id][thDate] != 'undefined' && checksByFrequency['task_' + th.id][thDate].length > 0) ? checksByFrequency['task_' + th.id][thDate].map(() => <React.Fragment>
                        <img style={{ width: 10, marginRight: '2px' }} className="edit-img" src={iconOkay} alt="^" />
                      </React.Fragment>) : null}

                    </React.Fragment> : null}

                    </React.Fragment> : null}
                  </td>
                ))}
              </tr>
              {
                th.sub_tasks?.map((st) => {
                  return(
                  <tr>
                    <td>{st.subtask_name}</td>
                    {tableHeaders.map((stDate) => (
                      <td>

                        {(typeof dataByTasks['task_' + st.id] != 'undefined' && dataByTasks['task_' + st.id].includes(stDate)) ? <React.Fragment>
                          
                          {(typeof checksByFrequency['task_' + st.id] != 'undefined' && typeof checksByFrequency['task_' + st.id][stDate] != 'undefined' && checksByFrequency['task_' + st.id][stDate].length > 0) ? checksByFrequency['task_' + st.id][stDate].map(() => <React.Fragment>
                            <img style={{ width: 10, marginRight: '2px' }} className="edit-img" src={iconOkay} alt="^" />
                          </React.Fragment>) : null}

                        </React.Fragment> : null}
                      </td>
                    ))}
                  </tr>
                  )
                })
              }

            </>
          )
        })
        :
        <tr><td>"Home Care Plan Not Found!!"</td></tr>
    )
  }

  render() {
    const { tableHeaders, distinctTask, dataByTasks } = this.state;
    return (
      <>
        <AsideLeft isHomecare={true} />
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="col-12 mb-3">
              <div className="float-left">
                <h1 className="page-title mb-0">Care Plan</h1>
              </div>
              {/* <div className="float-right">
                <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => HC_GOBACK(e, this.props, this.state)}>BACK TO PATIENT</button>
              </div> */}
            </div>
            <br />
            {this.state.tableHeaders && this.state.tableHeaders.length > 0 ?
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>DATE</th>
                    {
                      tableHeaders ?
                        tableHeaders.map((th) => (
                          <th>{th}</th>
                        ))
                        :
                        null
                    }
                  </tr>
                </thead>
                <tbody>
                  {/* {
                    tableHeaders ?
                      distinctTask.map((th) => (
                        <tr>
                          <td>{th.task_name}</td>
                          {tableHeaders.map((thDate) => (
                            <td>{(dataByTasks[th.task_name].includes(thDate)) ? <img style={{ width: 10 }} className="edit-img" src={iconOkay} alt="edit-icon" /> : '-'}</td>
                          ))}
                        </tr>
                      ))
                      :
                      <tr><td>"Home Care Plan Not Found!!"</td></tr>
                  } */}
                  {this.getTableData()}
                </tbody>
              </table> :
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered">
                      <tr>
                        <td>Home Care Plan Not Found!!</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>

      </>
    )
  }

}


export default withRouter(HomecareCarePlan);

