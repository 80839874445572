import React, { Component, Fragment } from 'react';
import { SEARCH_DIAGNOSIS, FAVOURITE_DIAGNOSIS, LOADER_BLACK, IMAGE_LIBRARY } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Select, Modal, Button, Tabs, Radio } from 'antd';
import 'antd/dist/antd.css';
import Sketchpad from '../../utils/sketchpad';
import axios from 'axios';
import qs from 'qs';
import icImagereference from '../../assets/images/ic-imagereference.png';
import icDrawing from '../../assets/images/ic-drawing.png';
import icEraser from '../../assets/images/ic-eraser.png';
import icundo from '../../assets/images/ic-undo.png';
import icredo from '../../assets/images/ic-redo.png';
import icbrush from '../../assets/images/ic-brush.png';
import { jQueryLabels, ClearAntList } from '../../utils/jQueryLabels';
import './create-template.scss'
const { Option } = Select;
const { TabPane } = Tabs;

class TemplateAssesment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchDiagnosis: [],
      isModalAssesmentVisible: false,
      isModalImageAnnotation: false,
      lineWidth: 1,
      lineColor: 'black',
      undo: 15,
      imageUploadedval: '',
      imageFreehandComment: '',
      selectedImg: '',
      btndisabled: true,
      DiagnositicTable: false,
      selectValue: '',
      diagnosis_notes: '',
      diagnosisAddArrayList: [{
        diagnosis_id: '',
        description: '',
        icd_code: '',
        image_comment: '',
        image_uploaded: ''
      }],
      diagnosisAddArrayListed: [],
      isAlertModalVisible: false,
      deletetemplateId: '',
      PropFlag: 1,
      PropEditFlag: 1,
      PropAddFlag: 1,
      sketch2background: '',
      formIsFetching: false,
      isCopyVist: props.isCopyVist ? props.isCopyVist : false,
      assessmenteditId: props.assessmenteditId,
      isAssesmentEdit: props.isEdit,
      isAssesmentAdd: props.isAssesmentAdd,
      stage: 0,
      type: 0,
      status: 0,
      resetForm: false,
      id: '',
      assessmentImages: []
    };
    this.sketchpad1 = null;
    this.sketchpad2 = null;
    this._sketch1 = React.createRef();
    this._sketch2 = React.createRef();
    this.LibraryImages = this.LibraryImages.bind(this);
    this.urlSketch1 = '';
    this.urlSketch2 = '';
    this.diagSelectRef = React.createRef();
  }
  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    jQueryLabels();
    if (this.state.isCopyVist) {
      this.TemplateCopyVisit()
    } else {
      this.TemplateDiagnoseApi();
    }
    this.fetchImages();
  }

  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }

  fetchImages = _ => {
    axios.get(IMAGE_LIBRARY)
      .then(success => {
        const { data } = success.data;
        this.setState({
          assessmentImages: data.assessmentImages
        })
      })
      .catch(err => console.log(err))

  }

  onRadioStageChange = (e, props) => {
    this.setState({
      stage: e.target.value,
      diagnosisAddArrayList: [{ diagnosis_id: this.state.id, description: this.state.description, icd_code: this.state.icd_code, image_uploaded: this.state.imageUploadedval, image_comment: this.state.imageFreehandComment, stage: e.target.value, type: this.state.type, status: this.state.status, id: this.state.id, diagnosis_notes: this.state.diagnosis_notes }]
    }
    )
  };
  onRadioTypeChange = (e, props) => {
    this.setState({
      type: e.target.value,
      diagnosisAddArrayList: [{ diagnosis_id: this.state.id, description: this.state.description, icd_code: this.state.icd_code, image_uploaded: this.state.imageUploadedval, image_comment: this.state.imageFreehandComment, stage: this.state.stage, type: e.target.value, status: this.state.status, id: this.state.id, diagnosis_notes: this.state.diagnosis_notes }]
    })
  };
  onRadioStatusChange = (e, props) => {
    this.setState({
      status: e.target.value,
      diagnosisAddArrayList: [{ diagnosis_id: this.state.id, description: this.state.description, icd_code: this.state.icd_code, image_uploaded: this.state.imageUploadedval, image_comment: this.state.imageFreehandComment, stage: this.state.stage, type: this.state.type, status: e.target.value, id: this.state.id, diagnosis_notes: this.state.diagnosis_notes }]
    })
  };
  showAlertModal = () => {
    this.setState({ isAlertModalVisible: true })
  };
  handleAlertOk = () => { this.setState({ isAlertModalVisible: false }); this.TemplateOndelete(this.state.deletetemplateId) };
  handleAlertCancel = () => { this.setState({ isAlertModalVisible: false }) };
  TemplateOndelete = (val) => {
    if (this.state.diagnosisAddArrayListed.length === 1) {
      this.setState({ diagnosisAddArrayListed: [], DiagnositicTable: false }, () => {
        if (this.props.diagnosis_data !== undefined) {

          this.props.diagnosis_data(
            this.state.diagnosisAddArrayListed
          )
        }
      })
    }
    else if (this.state.diagnosisAddArrayListed.length > 1) {
      let array = this.state.diagnosisAddArrayListed;
      array.splice(val, 1);
      this.setState({ diagnosisAddArrayListed: array }, () => {
        if (this.props.diagnosis_data !== undefined) {
          this.props.diagnosis_data(
            this.state.diagnosisAddArrayListed
          )
        }
      })
    }
  }
  showModal = () => {
    this.setState({
      isModalAssesmentVisible: true
    }, () => {
      // this.sketchpad1 = new Sketchpad({
      //   element: '#sketch1',
      //   width: 960,
      //   height: 320,
      // });
    })
  };

  handleTabSwitch = key => {
    if (key === "1") {
      setTimeout(() => {
        this.sketchpad1 = new Sketchpad({
          element: '#' + this._sketch1.current.id,
          width: 960,
          height: 320,
        });
      }, 0);
    }
  }

  handleOk = () => {
    this.setState({ isModalAssesmentVisible: false })
  };

  handleCancel = () => {
    this.setState({ isModalAssesmentVisible: false, PropEditFlag: 1, isAssesmentEdit: false, PropAddFlag: 1 })
  };
  assessmentFavourite = (id) => {
    const elem = document.querySelector('#diagnosis').parentElement?.nextSibling?.querySelector('.desc');
    let searchKeys = elem !== undefined ? elem.innerText : document.querySelector('#diagnosis').value;
    searchKeys = searchKeys.replace(/[^\w\s]/gi, ' ');

    axios.post(FAVOURITE_DIAGNOSIS, qs.stringify({
      id: id,
    })).then(success => {
      if (success.data.status) {
        this.TemplateDiagnoseApi(searchKeys);
      }
    }).catch(err => {
      console.log(err);
    })

  }
  ImageshowModal = () => {
    this.setState({ isModalImageAnnotation: true }, () => {
      this.sketchpad2 = new Sketchpad({
        element: '#sketch2',
        width: 960,
        height: 500,
      });
      this.sketchpad2.penSize = 2;
      this.drawBackgroundCanvas(this._sketch2, this.state.imageUrl)
    })
  };
  ImagehandleOk = () => {
    this.setState({ isModalImageAnnotation: false })
  };

  ImagehandleCancel = () => {
    this.setState({ isModalImageAnnotation: false, PropEditFlag: 1, PropAddFlag: 1 })
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.diagnosisAddArrayListed !== this.state.diagnosisAddArrayListed) {
      if (this.props.diagnosis_data !== undefined) {
        this.props.diagnosis_data(
          this.state.diagnosisAddArrayListed
        )
      }
    }

    if (prevState.resetForm !== this.state.resetForm) {
      if (this.state.resetForm) {
        this.handleFormReset();
      }
    }
    if (prevState.isAssesmentEdit !== this.state.isAssesmentEdit) {
      if (this.state.isAssesmentEdit) {
        this.handleFormDataAdd();
      }
    }
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {

    if (prevState.diagnosisAddArrayListed !== undefined && prevState.diagnosisAddArrayListed !== null && prevState.diagnosisAddArrayListed.length === 0 && prevState.PropFlag) {
      if (nextProps.Input_data !== prevState.diagnosisAddArrayListed && nextProps.Input_data !== undefined && !nextProps.idisNaN) {
        return { diagnosisAddArrayListed: nextProps.Input_data, DiagnositicTable: true, PropFlag: 0 };
      }
    }

    if (nextProps.Input_data !== undefined) {
      if (nextProps.isEdit !== prevState.isAssesmentEdit) {
        return {
          assessmenteditId: nextProps.assessmenteditId,
          isAssesmentEdit: nextProps.isEdit,
          diagnosisAddArrayListed: nextProps.Input_data,
          resetForm: nextProps.resetForm
        }
      }
    }

    return null;
  }
  handleFormReset = _ => {
    this.setState({
      resetForm: false
    }, () => this.Formclear())
  }
  Formclear = () => {
    this.setState({
      selectValue: '',
      description: '',
      icd_code: '',
      stage: 0,
      status: 0,
      type: 0,
      id: '',
      imageUploadedval: '',
      imageFreehandComment: '',
      PropAddFlag: 0,
      searchDiagnosis: [],
      diagnosis_notes: ''
    })

  }
  handleFormDataAdd = () => {
    const { isAssesmentEdit, diagnosisAddArrayListed, assessmenteditId } = this.state;
    if (isAssesmentEdit && diagnosisAddArrayListed.length !== 0) {
      let filterarr = diagnosisAddArrayListed.filter((data, i) =>
        data.id === assessmenteditId
      )
      let editIndex = diagnosisAddArrayListed.findIndex((data, i) =>
        data.id === assessmenteditId
      )
      this.setState({
        selectValue: filterarr[0].description,
        description: filterarr[0].description,
        icd_code: filterarr[0].icd_code,
        stage: filterarr[0].stage,
        status: filterarr[0].status,
        type: filterarr[0].type,
        id: filterarr[0].id,
        imageUploadedval: filterarr[0].image_uploaded,
        imageFreehandComment: filterarr[0].image_comment,
        btndisabled: false,
        PropEditFlag: 0,
        isAssesmentEdit: false,
        editIndex: editIndex,
        diagnosis_notes: filterarr[0]?.diagnosis_notes || filterarr[0]?.diagnosisNotes
      })
    }
  }
  onselectChange1 = (value, props) => {
    this.setState({ btndisabled: false, id: value, description: props.title, icd_code: props.icdcode, selectValue: value, diagnosisAddArrayList: [{ diagnosis_id: value, description: props.title, icd_code: props.icdcode, image_uploaded: this.state.imageUploadedval, image_comment: this.state.imageFreehandComment, stage: this.state.stage, type: this.state.type, status: this.state.status, id: props.value, diagnosis_notes: this.state.diagnosis_notes }] });
  };
  TemplateCopyVisit = (templateId) => {
    axios.post(SEARCH_DIAGNOSIS, qs.stringify({
      search: templateId
    })).then(success => {
      let favfilter = success.data.filter(data => data.fav === 1)
      this.setState({ searchDiagnosis: favfilter });
      this.diagSelectRef.current.focus()
    }).catch(err => console.log(err))
  }
  TemplateDiagnoseApi = (templateId) => {
    // if (templateId !== '' && templateId !== undefined) {
    this.setState({
      formIsFetching: true
    })
    axios.post(SEARCH_DIAGNOSIS, qs.stringify({
      search: templateId
    })).then(success => {
      this.setState({
        searchDiagnosis: success.data,
        formIsFetching: false
      });
    }).catch(err => console.log(err))
    // }
    // else {
    //   ClearAntList();
    //   this.setState({ searchDiagnosis: [] })
    // }
  };
  optionLoad() {
    if (!this.state.searchDiagnosis || this.state.searchDiagnosis.length === 0) {
      return (null)
    }
    return this.state.searchDiagnosis.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description} icdcode={obj.icd_code}>
        <span className="desc">
          {obj.description}
        </span>
        <span className="float-right text-primary fav_diagnosis">
          <i onClick={e => { e.preventDefault(); e.stopPropagation(); this.assessmentFavourite(obj.id) }} className={obj.fav ? 'icon_unique icon_star favourite mr-2' : 'icon_unique icon_star mr-2'}></i>
          {/* <i className="icon_info"></i> */}
        </span>
        <span className="float-right mr-2">{obj.icd_code}</span></Option>
      )
    })
  }
  drawBackgroundCanvas = (sketchRef, imgSrc) => {
    var canvasWidth = sketchRef.current.width;
    var canvasHeight = sketchRef.current.height;

    // Draw Image on the Canvas
    var canvas = sketchRef.current;
    var ctx = canvas.getContext('2d');
    var img = new Image();
    img.src = imgSrc;
    var imageWidth;
    var imageHeight;
    img.onload = function () {
      if (this.width > canvasWidth && this.height > canvasHeight) {
        imageHeight = canvasHeight;
        imageWidth = this.width * (imageHeight / this.height);
      }
      else if (this.width > canvasWidth && canvasHeight >= this.height) {
        imageWidth = canvasWidth;
        imageHeight = this.height * (canvasWidth / this.width);
      }
      else if (canvasWidth >= this.width && this.height > canvasHeight) {
        imageHeight = canvasHeight;
        imageWidth = this.width * (imageHeight / this.height);
      }
      else if (canvasWidth >= this.width && canvasHeight >= this.height) {
        imageWidth = this.width;
        imageHeight = this.height;
      }
      var posX = (canvasWidth - imageWidth) / 2;
      img.setAttribute('crossorigin', 'anonymous');
      ctx.drawImage(this, posX, 0, imageWidth, imageHeight);
    };
  }

  onPen = () => {
    this.sketchpad1.penSize = 2;
    this.sketchpad1.color = '#000'
  }

  onEraser = () => {
    this.sketchpad1.penSize = 10;
    this.sketchpad1.color = '#fff';
  }

  onUndo = () => {
    this.sketchpad1.undo();
  }

  onRedo = () => {
    this.sketchpad1.redo();
  }

  onBrush = () => {
    this.sketchpad1.penSize = 8;
    this.sketchpad1.color = '#000';
  }
  imageFreehandComment = (e) => {
    this.setState({ imageFreehandComment: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })
  }
  onsaveFreehandImage() {
    this.setState({
      imageUploadedval: this._sketch1.current.toDataURL(), isModalAssesmentVisible: false,
      diagnosisAddArrayList: [{ diagnosis_id: this.state.id, description: this.state.description, icd_code: this.state.icd_code, image_uploaded: this._sketch1.current.toDataURL(), image_comment: this.state.imageFreehandComment, stage: this.state.stage, type: this.state.type, status: this.state.status, id: this.state.id, diagnosis_notes: this.state.diagnosis_notes }]
    })
  }
  LibraryImages = (e) => {
    this.setState({
      isModalAssesmentVisible: false,
      imageUrl: e.target.attributes.src.nodeValue,
      selectedImg: e.target.id,
      sketch2background: e.target.src
    },
      () => {
        this.ImageshowModal()
      }
    );
  }
  AddLibraryImage = () => {
    this.setState({ imageUploadedval: this._sketch2.current.toDataURL(), isModalImageAnnotation: false, diagnosisAddArrayList: [{ diagnosis_id: this.state.id, description: this.state.description, icd_code: this.state.icd_code, image_uploaded: this._sketch2.current.toDataURL(), image_comment: this.state.imageFreehandComment, stage: this.state.stage, type: this.state.type, status: this.state.status, id: this.state.id, diagnosis_notes: this.state.diagnosis_notes }] })
  }
  diagnosisSave = () => {
    let index = this.state.diagnosisAddArrayListed.findIndex(x => {
      return x.id === this.state.id
    });
    if (this.state.isAssesmentEdit) {
      let arr = this.state.diagnosisAddArrayListed;
      if (this.state.diagnosisAddArrayList[0].diagnosis_id !== "") {
        if (this.state.diagnosisAddArrayList.length === 1) {
          arr.splice(this.state.editIndex, 1, this.state.diagnosisAddArrayList[0]);
        } else {
          arr.splice(this.state.editIndex, 1, this.state.diagnosisAddArrayList[index]);
        }
      }
      this.setState({ diagnosisAddArrayList: arr }, () => {
        if (this.props.diagnosis_data !== undefined) {
          this.props.diagnosis_data(
            this.state.diagnosisAddArrayListed
          )
        }
      })
    } else if (index === -1) {
      this.setState({
        DiagnositicTable: this.state.isCopyVist ? false : true,
        btndisabled: true,
        selectValue: '',
        diagnosis_notes: '',
        imageUploadedval: '',
        imageFreehandComment: '',
        selectedImg: '',
        searchDiagnosis: [],
        diagnosisAddArrayListed: [...this.state.diagnosisAddArrayListed, this.state.diagnosisAddArrayList[0]]
      }, () => {
        if (this.props.diagnosis_data !== undefined) {
          this.props.diagnosis_data(
            this.state.diagnosisAddArrayListed
          )
        }
      })
    }
    if (this.sketchpad1 !== null && this.sketchpad1 !== undefined) { this.sketchpad1.clear() }
  }
  onssearch = (value) => {
    this.TemplateDiagnoseApi(value)
  }
  onselectDiagnosisNotesChange = (e) => {
    this.setState({
      diagnosis_notes: e.target.value,
      diagnosisAddArrayList: [{ diagnosis_id: this.state.id, description: this.state.description, icd_code: this.state.icd_code, image_uploaded: this.state.imageUploadedval, image_comment: this.state.imageFreehandComment, stage: this.state.stage, type: this.state.type, status: this.state.status, id: this.state.id, diagnosis_notes: e.target.value }]
    })
  }
  diagnosisAddArrayListedLoad = () => {
    if (!this.state.diagnosisAddArrayListed || this.state.diagnosisAddArrayListed.length === 0) {
      return (null)
    }
    return (
      <Fragment>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Diagnosis</th>
              <th width="100">ICD 10</th>
              <td>NOTES</td>
              <td>IMAGE REFERENCE</td>
              <th width="100">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.diagnosisAddArrayListed.map((data, i) => {
              return (
                <tr key={i} id={'assessmentrec' + data.diagnosis_id}>
                  <td>{data.description}</td>
                  <td><span className='text-primary'>{data.icd_code}</span></td>
                  <td>{data.diagnosis_notes}</td>
                  <td className='image-reference-column'>
                    <img src={data.image_uploaded} alt={data.description} title={data.image_comment}
                      className={`img-fluid ${data.image_uploaded === "" ? 'd-none' : ''}`} />
                  </td>
                  <td>
                    <Button className='nostyle-link delete-assessment'
                      onClick={() => { this.setState({ deletetemplateId: i }); this.showAlertModal() }}>
                      <i className='icon_delete'></i>
                    </Button>
                  </td>
                </tr>
              )
            })
            }
          </tbody>
        </table>
      </Fragment>
    )
  }
  render() {
    const { assessmentImages } = this.state;
    return (
      <Fragment>
        <h2 className="col-12 text-heading" style={{ display: this.state.isCopyVist ? 'none' : 'flex' }}>Assessment</h2>
        <div className="col-12 emrfrm">
          <div className="form-group hasdata">
            <Select
              id="diagnosis"
              name="diagnosis"
              style={{ width: 100 + '%' }}
              placeholder="Diagnosis"
              showSearch
              notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
              loading={this.state.formIsFetching}
              onChange={this.onselectChange1}
              onSearch={this.onssearch}
              className="select-diagnosis"
              value={this.state.selectValue}
              autoFocus={true}
              defaultOpen={this.state.isCopyVist ? true : false}
              ref={this.diagSelectRef}
              filterOption={false}
            // optionFilterProp="children"
            // filterOption={(input, option) => {
            //   return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
            // }}
            >
              {this.optionLoad()}
            </Select>
            <label htmlFor="diagnosis">Diagnosis<span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="col-12 mb-3" style={{ display: this.state.isCopyVist ? 'block' : 'none' }}>
          <div className="row">
            <div className="col-md-4">
              <label className="text-grey mb-0">Stage<span className="text-danger">*</span></label>
              <Radio.Group onChange={this.onRadioStageChange} value={this.state.stage}>
                <Radio value={0} name="stage" title={'Provisional'}>Provisional</Radio>
                <Radio value={1} name="stage" title={'Final'}>Final</Radio>
              </Radio.Group>
            </div>
            <div className="col-md-4">
              <label className="text-grey mb-0">Type<span className="text-danger">*</span></label>
              <Radio.Group onChange={this.onRadioTypeChange} value={this.state.type}>
                <Radio value={0} name="stage" title={'Primary'}>Primary</Radio>
                <Radio value={1} name="stage" title={'Secondary'}>Secondary</Radio>
              </Radio.Group>
            </div>
            <div className="col-md-4">
              <label className="text-grey mb-0">Status<span className="text-danger">*</span></label>
              <Radio.Group onChange={this.onRadioStatusChange} value={this.state.status}>
                <Radio value={0} name="stage" title={'Active'}>Active</Radio>
                <Radio value={1} name="stage" title={'Cured'}>Cured</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <textarea className="form-control" id="diagnosis_notes" name="diagnosis_notes" placeholder="Diagnosis Notes"
              rows="1"
              onInput={(e) => this.autoHeight(e)}
              onChange={this.onselectDiagnosisNotesChange}
              value={this.state.diagnosis_notes}
            ></textarea>
            <label htmlFor="diagnosis_notes">Diagnosis Notes</label>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col align-self-center">
              <label>Image Reference</label>
              <div className="row">
                <div className="col-12 text-muted image-ref-comment">{this.state.imageFreehandComment}</div>
              </div>
            </div>
            <div className="image-references">
              <img src={this.state.imageUploadedval} alt="" className="img-fluid" id="selected-image" />
            </div>
            <Button className="image-reference-btn nostyle-link h-auto p-0" onClick={() => this.showModal()}>
              <img src={icImagereference} alt="Image Reference" className="img-fluid" />
            </Button>
          </div>
        </div>
        <div className="col-12 mb-2 d-inline-flex justify-content-end">
          <button type="button" className="float-right btn btn-primary px-4 small diagnosis-save text-uppercase mandatory-save"
            onClick={() => this.diagnosisSave()} disabled={this.state.btndisabled}>Add</button>
        </div>
        <div className="col-md-12">
          <div className="table-responsive" id="diagnosis-list" style={{ display: this.state.DiagnositicTable && !this.state.isCopyVist ? 'block' : 'none' }}>
            {this.diagnosisAddArrayListedLoad()}
          </div>
        </div>
        <input type="hidden" name="diagnosis-list-data" id="diagnosis-list-data" />
        <Modal id="alert_confirm_message"
          className="alert_confirm_message"
          title='Are you sure you want to delete assessment?'
          visible={this.state.isAlertModalVisible}
          onOk={() => this.handleAlertOk()}
          bodyStyle={{ padding: 0 + 'px' }}
          onCancel={() => this.handleAlertCancel()}
          closable={false}
          footer={[
            <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.handleAlertCancel()}>
              CANCEL
          </Button>,
            <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.handleAlertOk()}>
              DELETE
          </Button>,
          ]}
        >
        </Modal>
        <Modal id="image_reference_dialog"
          className="image_reference_dialog"
          title="Image Reference"
          visible={this.state.isModalAssesmentVisible}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          footer={false}
        >
          <Tabs defaultActiveKey="2" onChange={this.handleTabSwitch}>
            <TabPane tab="Free Hand" key="1" className="pills-freehand">
              <div className="position-relative">
                <canvas id="sketch1" ref={this._sketch1}></canvas>
                <div className="freehand-toolbar">
                  <ul>
                    <li>
                      <Button className="nostyle-link" id="pen" onClick={() => this.onPen()}>
                        <img src={icDrawing} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                    <li>
                      <Button className="nostyle-link" id="eraser" onClick={() => this.onEraser()}>
                        <img src={icEraser} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                    <li>
                      <Button className="nostyle-link" id="undo" onClick={() => this.onUndo()}>
                        <img src={icundo} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                    <li>
                      <Button className="nostyle-link" id="redo" onClick={() => this.onRedo()}>
                        <img src={icredo} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                    <li>
                      <Button className="nostyle-link" id="brush" onClick={() => this.onBrush()}>
                        <img src={icbrush} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="library-comments" className="sr-only">Comments</label>
                    <input type="text" className="form-control image-freehand-comment" maxLength="50" value={this.state.imageFreehandComment} placeholder="Comments" onChange={this.imageFreehandComment} />
                  </div>
                  <div className="form-group text-center">
                    <button className="btn btn-primary px-5 save-freehand-image text-uppercase" onClick={() => this.onsaveFreehandImage()}>
                      Add
                  </button>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Library" key="2" className="pills-library">
              <div className="row no-gutters library-images mx-0 cursor">
                {assessmentImages?.length > 0 ? (
                  assessmentImages.map((aI, i) => {
                    return (
                      <div className="col-md-4 border text-center" key={i}>
                        <Button onClick={this.LibraryImages}
                          className={this.state.selectedImg === 'img' + i ? 'nostyle-link selected' : 'nostyle-link'}>
                          <img src={aI} className="img-fluid" alt="Assessment Images" crossorigin="anonymous" />
                        </Button>
                      </div>
                    )
                  })
                ) : null}
                {/* <div className="col-md-4 border text-center">
                  <Button onClick={this.LibraryImages} className={this.state.selectedImg === 'ear01' ? 'nostyle-link selected' : 'nostyle-link'}>
                    <img src={ear01} id="ear01" className="img-fluid" alt="Ear 01" />
                  </Button>
                </div> */}
              </div>
            </TabPane>
          </Tabs>

        </Modal>
        <Modal id="image_annotation_dialog"
          className="image_annotation_dialog"
          title={false}
          visible={this.state.isModalImageAnnotation}
          onOk={() => this.ImagehandleOk()}
          onCancel={() => this.ImagehandleCancel()}
          footer={false}
        >
          <form>
            <div className="form-group">
              <canvas id="sketch2" ref={this._sketch2}></canvas>
            </div>
            <div className="form-group">
              <label htmlFor="library-comments" className="sr-only">Comments</label>
              <input type="text" className="form-control image-library-comment" maxLength="50" value={this.state.imageFreehandComment} placeholder="Comments" onChange={this.imageFreehandComment} />
            </div>
            <div className="form-group text-center">
              <Button typle="primary" className="btn btn-primary px-5 save-library-image text-uppercase" onClick={() => this.AddLibraryImage()}>Add</Button>
            </div>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

export default TemplateAssesment;