import React, { Component } from "react";
import Axios from "axios";
import { OBSTETRICS_GYNAE_HISTORY_CONFIDENTIAL } from "../../utils/constant";
import { lmpDataService } from '../../utils/rxjs-sharing';
import LS_SERVICE from "../../utils/localStorage";

class GynaeTabs extends Component {
    constructor(props){
        super(props);
        this.state = {
            tabType : props.activeTab,
            tabs_available: props.tabs_available
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { patient, activeTab, allowEdit, isPracticing } = props;

        return {
            patient: patient,
            tabType: activeTab,
            allowEdit: allowEdit,
            isPracticing: isPracticing
        };
    }

    componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    componentDidMount = async() => {
        this.subscription = lmpDataService.status().subscribe(data => {
            let confidentialStatusList = {
                is_confidential_menstrual_hx: data?.menstrualHistoryLmp?.patientDetails?.is_confidential_menstrual_hx,
                is_confidential_sexual_hx: data?.menstrualHistoryLmp?.patientDetails?.is_confidential_sexual_hx,
                is_confidential_obstetric_hx: data?.menstrualHistoryLmp?.patientDetails?.is_confidential_obstetric_hx,
                is_confidential_physical_examination: data?.menstrualHistoryLmp?.patientDetails.is_confidential_physical_examination,
            };

            this.setState({ isconfidentialStatusList : confidentialStatusList });

            this.ChangeTabHandler(this.state.tabType);
        });
    }

    ChangeTabHandler = async(tab, is_confidential_new=null) => {
        await this.setState({ tabType : tab });
        this.props.changeParentTab(tab);
        var confidentialStatusList = this.state.isconfidentialStatusList;

        var isConfidentialNew = (is_confidential_new == '1')?true:false;
        if(tab == '1' && is_confidential_new != null){
            confidentialStatusList['is_confidential_menstrual_hx'] = isConfidentialNew;
        }
        if(tab == '2' && is_confidential_new != null){
            confidentialStatusList['is_confidential_sexual_hx'] = isConfidentialNew;
        }
        if(tab == '3' && is_confidential_new != null){
            confidentialStatusList['is_confidential_obstetric_hx'] = isConfidentialNew;
        }
        if(tab == '4' && is_confidential_new != null){
            confidentialStatusList['is_confidential_physical_examination'] = isConfidentialNew;
        }

        if(tab == '1'){
            await this.setState({ isConfidential : confidentialStatusList?.is_confidential_menstrual_hx });
        }else if(tab == '2'){
            await this.setState({ isConfidential : confidentialStatusList?.is_confidential_sexual_hx });
        }else if(tab == '3'){
            await this.setState({ isConfidential : confidentialStatusList?.is_confidential_obstetric_hx });
        }else if(tab == '4'){
            await this.setState({ isConfidential : confidentialStatusList?.is_confidential_physical_examination });
        }
        
    }

    confidentialHandler = (e) => {
        const { patient, isPracticing } = this.state;
        const { patient_id, doctor_id } = patient;
        if (!isPracticing) {
            this.props.parentHandleModalPopup(null, 'isPracticingModal');
            return;
        }

        const checked = e.target?.checked;
        if(checked === true){
            var is_confidential = '1';
        }else{
            var is_confidential = '0';
        }
        
        let confidentialObj = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            type: this.state.tabType,
            is_confidential : is_confidential
        }

        Axios.post(OBSTETRICS_GYNAE_HISTORY_CONFIDENTIAL, confidentialObj).then(success => {
            this.ChangeTabHandler(this.state.tabType,is_confidential);
        }); 
    }

    render() {
        return (
            <ul
                className={`nav nav-pills myhealthcare-tabs auto-width ${this.props.pathname && this.props.pathname.includes("objective") ? 'mt-5' : ''}`}
                role="tablist"
            >
                {this.state.tabs_available.menstrual_tab_available ? 
                <li className="nav-item">
                    <a
                        href="#menstrual-history"
                        className={`nav-link gynae ${this.state.tabType == '1' ? 'active' : ''}`}
                        data-toggle="pill"
                        role="tab"
                        aria-controls="#menstrual-history"
                        aria-selected="true"
                        value="1"
                        onClick={() => this.ChangeTabHandler('1')}
                    >
                        Menstrual History
                    </a>
                </li> : null}
                {this.state.tabs_available.sexual_tab_available ? 
                <li className="nav-item">
                    <a
                        href="#sexual-history"
                        className={`nav-link gynae ${this.state.tabType == '2' ? 'active' : ''}`}
                        data-toggle="pill"
                        role="tab"
                        aria-controls="#sexual-history"
                        aria-selected="false"
                        value="2"
                        onClick={() => this.ChangeTabHandler('2')}
                    >
                        Sexual History
                    </a>
                </li> : null}
                {this.state.tabs_available.obs_tab_available ? 
                <li className="nav-item">
                    <a
                        href="#obstetric-history"
                        className={`nav-link gynae ${this.state.tabType == '3' ? 'active' : ''}`}
                        data-toggle="pill"
                        role="tab"
                        aria-controls="#obstetric-history"
                        aria-selected="false"
                        value="3"
                        onClick={() => this.ChangeTabHandler('3')}
                    >
                        Obstetric History
                    </a>
                </li> : null}
                {this.state.tabs_available.physical_tab_available ? 
                <li className="nav-item">
                    <a
                        href="#physical-examination"
                        className={`nav-link gynae ${this.state.tabType == '4' ? 'active' : ''}`}
                        data-toggle="pill"
                        role="tab"
                        aria-controls="#physical-examination"
                        aria-selected="false"
                        value="4"
                        onClick={() => this.ChangeTabHandler('4')}
                    >
                        Physical Examination
                </a>
                </li> :  null}
                <li className={(LS_SERVICE.get('user_type') === 2) ? "custom-control custom-checkbox col hidden" : "custom-control custom-checkbox col"}>
                    <div className="float-right pt-1 ml-3">
                        <input 
                            disabled={!this.state.allowEdit}
                            type="checkbox"
                            className="custom-control-input"
                            id="conf"
                            name="confidential"
                            checked={this.state.isConfidential}
                            /* defaultChecked={this.state.isConfidential} */
                            onClick={(e) => this.confidentialHandler(e)}
                        />
                        <label className="custom-control-label" htmlFor="conf">
                            Confidential
                    </label>
                    </div>
                </li>
            </ul>
        )
    };
}

export default GynaeTabs;
