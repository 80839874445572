import React from 'react';
import {
    Route,
    Redirect
} from 'react-router';
import LS_SERVICE from '../utils/localStorage';

const PrivateRoute = ({ component: Component, isPaired, ...rest }) => {
    return (
        <Route
            {...rest}
            render={
                (props) => (LS_SERVICE.get("is_authenticated") ? <Component {...props} /> : <Redirect to="/" />)
            }
        />
    );
};

export default PrivateRoute;