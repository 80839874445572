import React, { Component } from 'react';
import AsideLeft from '../../components/aside/asideleft';
import copyImg from '../../assets/images/copy.png';
import searchIcon from '../../assets/images/search-ic.png';
import {
    TEST_RESULT_INFO,
    TEST_RESULT_GRAPH,
    LOADER_RED
} from '../../utils/constant';
import API_SERVICE from '../../utils/apiService';
import Moment from 'react-moment';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';

class TestResultRadiology extends Component {
    state = {
        result: {},
        requestSent: false,
        loading: false,
        code: null,
        patientId: null,
        showGraphModal: false,
        graphTitle: null,
        graphTab: 'y',
        graphKeys: [],
        graphValues: [],
        openImgModal: false
    }

    componentDidMount() {
        this.getResult();
    }

    async getResult() {
        const arrRoute = this.props.location.pathname.split('/');
        const code = arrRoute[arrRoute.length - 1];
        const patientId = arrRoute[arrRoute.length - 2];
        this.setState({
            code: code,
            patientId: patientId,
            loading: true,
            requestSent: false
        });

        const url = TEST_RESULT_INFO.replace('{code}', code);
        const result = await API_SERVICE.post(url, {
            bookingCode: code,
            patientId: patientId
        });
        if (result.status) {
            this.setState({
                result: result.data
            });
        }
        this.setState({
            loading: false,
            requestSent: true
        });
    }

    async showGraph() {
        this.setState({
            showGraphModal: true,
            graphTitle: this.state.result.test_name
        });

        const type = this.state.graphTab;
        const testId = this.state.result.test_id;
        let graphTodaysDate = new Date().toJSON().slice(0, 10).split('-');
        let graphEndDate = graphTodaysDate[2] + "/" + graphTodaysDate[1] + "/" + graphTodaysDate[0];

        let todayDate = new Date();
        let start = graphTodaysDate[2] + "/" + graphTodaysDate[1] + "/" + graphTodaysDate[0];

        if (type === 'y') {
            todayDate.setFullYear(todayDate.getFullYear() - 1);
            let graphLastYear = todayDate.toJSON().slice(0, 10).split('-');
            start = graphLastYear[2] + "/" + graphLastYear[1] + "/" + graphLastYear[0];
        } else if (type === 'm') {
            todayDate.setMonth(todayDate.getMonth() - 1);
            let graphLastYear = todayDate.toJSON().slice(0, 10).split('-');
            start = graphLastYear[2] + "/" + graphLastYear[1] + "/" + graphLastYear[0];
        } else if (type === 'w') {
            todayDate.setDate(todayDate.getDate() - 7);
            let graphLastYear = todayDate.toJSON().slice(0, 10).split('-');
            start = graphLastYear[2] + "/" + graphLastYear[1] + "/" + graphLastYear[0];
        }

        const result = await API_SERVICE.post(TEST_RESULT_GRAPH, {
            start_date: start,
            end_date: graphEndDate,
            patient_id: this.state.patientId,
            type: type,
            test_id: testId
        });
        if (result.dates != undefined && result.dates.length > 0) {
            this.setState({
                graphKeys: result.dates,
                graphValues: result.values
            });
        } else {
            this.setState({
                graphKeys: [],
                graphValues: []
            });
        }
    }

    render() {
        return (
            <>

                {this.state.patientId != null ? <AsideLeft patient_id={this.state.patientId} /> : null}

                <div className="content-wrapper">
                    <div className="container-fluid">

                        {this.state.result.test_name != undefined ?
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="float-left">
                                            <h1 className="page-title">Test Results</h1>
                                        </div>
                                        <div className="float-right">
                                            <Link className="btn btn-outline-secondary px-4 small text-uppercase" to={"/patient/test-results/" + this.state.patientId}>
                                                BACK
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <h2 className="page-title">{this.state.result.test_name}</h2>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <table className="table table-borderless table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>Ordering Physician</td>
                                                            <td className="text-dark">: {this.state.result.ref_phy_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Test Date</td>
                                                            <td className="text-dark">: <Moment date={this.state.result.checkup_date} format="D MMM, YYYY" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-5">
                                                <table className="table table-borderless table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>Location</td>
                                                            <td className="text-dark">: {this.state.result.hospital_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Order Date</td>
                                                            <td className="text-dark">: <Moment date={this.state.result.order_date_time} format="D MMM, YYYY" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="my-4" />

                                    <div className="col-12 mb-3">

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <h5 className="text-dark text-uppercase">Remarks:</h5>
                                                        <p className="mr-5">{this.state.result.phy_remarks}</p>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <img src={this.state.result.link} className="img-fluid" onClick={() => this.setState({ openImgModal: true })} style={{ cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Modal
                                            title=""
                                            visible={this.state.openImgModal}
                                            footer={false}
                                            width={700}
                                            onCancel={() => { this.setState({ openImgModal: false }) }}
                                        >
                                            <div className="row">
                                                <div className="col-12 my-3 text-center">
                                                    <img src={this.state.result.link} className="img-fluid" />
                                                </div>
                                            </div>
                                        </Modal>

                                    </div>
                                </div>
                            </div> : <div className="row">
                                <div className="col-md-12 text-center">
                                    {LOADER_RED}
                                </div>
                            </div>}

                        {this.state.requestSent && this.state.result.test_name == undefined ? <div className="row">
                            <div className="col-12">
                                <h4>Information not available</h4>
                            </div>
                        </div> : null}

                    </div>
                </div>

            </>
        )
    }
}

export default TestResultRadiology;