import React, { Component } from 'react';
import PubNub from 'pubnub';
// import './pubnub.css';
import { PubNubProvider, PubNubConsumer } from 'pubnub-react';
import { LOADER_BLACK, LOADER_RED, PUBNUBPUBLISHKEY, PUBNUBSUBSCRIBEKEY, SAVE_CHAT_TIME, USERTYPES } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import Moment from 'react-moment';
import $ from 'jquery';
import qs from 'qs';
// import rotateLeft from "../../assets/images/rotate-left.png";
// import rotateRight from "../../assets/images/rotate-right.png";
// import { Modal, ModalHeader, ModalBody } from "reactstrap";
// import { HANDLEIMAGEROTATE } from "../../utils/constant";
// import ImageMaginify from '../../utils/imageMaginify';
import { pubnubService$ } from '../../utils/pubnub-sharing';
// import prevImgIcon from "../../assets/images/previous-icon.svg";
// import nextImgIcon from "../../assets/images/next-icon.svg";
import axios from 'axios';
import mic from "../../assets/images/ic-mic.svg";
import { Modal, notification } from 'antd';

// let context = "";
// let source = "";
// let processor = "";
// let audioData = "";
let escListener = "";
let chatScroll = "";
let textAreaListener = "";
let recorderEvent = "";
let recorderTimeOut = "";
let recorderTimeoutTime = 60000;

class PubNubComponent extends Component {

    state = {
        is_doctor: false,
        channels: [],
        pubNubChannels: [],
        activeChannel: null,
        pushChannels: [],
        doctor_uuid: null,
        messages: [],
        notification_message_text: "",
        isFileBefore: false,
        messages_loaded: false,
        patient_details: undefined,
        message: "",
        timetoken: "",
        fetchendTime: new Date().getTime() * 1000,
        isTyping: null,
        isPubNubFetchRequestProcessing: false,
        pubNubPopupOpen: false,
        noMorePunNubData: false,
        historyMessageLength: 25,
        modal: false,
        dataFile: "",
        modalClass: "modal-imgdocs",
        documentIndex: 0,
        documentCount: 0,
        documentType: "",
        allDocuments: [],
        modalFile: false,
        fileDataPrior: [],
        fileAudio: false,
        fileLoading: false
    }

    constructor(props) {
        super(props);

        // this.messageTextareaRef = React.createRef();

        if (LS_SERVICE.has('staff_id')) {
            this.pubnub = new PubNub({
                publishKey: PUBNUBPUBLISHKEY,
                subscribeKey: PUBNUBSUBSCRIBEKEY,
                uuid: LS_SERVICE.get('staff_id')
            });
            console.log(this.pubnub.getUUID())
        }
    }


    // UNSAFE_componentWillReceiveProps(props) {
    //     let { channels, pubNubChannels, patient_details } = this.state;

    //     if (props.pubnubActiveChatData !== undefined) {

    //         if (JSON.stringify(pubNubChannels) !== JSON.stringify(props.pubnubActiveChatData)) {
    //             pubNubChannels = props.pubnubActiveChatData.map(e => e.booking_id);

    //             this.setState({
    //                 pubNubChannels
    //             })
    //         }
    //     }

    //     if (props.data !== undefined) {
    //         if (channels.filter(e => e === props.data.id).length === 0) {
    //             channels.push(props.data.id);
    //             patient_details = props.data.patient;
    //             this.setState({
    //                 channels,
    //                 patient_details,
    //                 pubNubPopupOpen: props.show,
    //                 activeChannel: props.data.id,
    //                 doctor_uuid: LS_SERVICE.get('doctor').id
    //             }, () => {

    //                 const { activeChannel } = this.state;

    //                 if (activeChannel !== null) {
    //                     this.handleEventListeners();
    //                     this.handleFetchMessages();
    //                 }
    //             })
    //         }
    //     }
    // }

    handleEventListeners = () => {

        if (chatScroll === "") {
            chatScroll = document.querySelector(".pubnub_scroll_loader");
            if (chatScroll !== null)
                chatScroll.addEventListener("scroll", this.handlePubNubScroll, true);
        }

        if (escListener === "") {
            escListener = document.addEventListener('keydown', this.handleEscapeListener, true);
        }

        if (textAreaListener === "") {
            textAreaListener = document.querySelector("#inputText");
            if (textAreaListener !== null)
                textAreaListener.addEventListener("keydown", this.handleTextAreaEnter, true);
        }

        setTimeout(() => {
            document.getElementById("inputText").focus();
        }, 0);
    }

    handleEscapeListener = (event) => {
        const { modal } = this.state;
        const key = event.keyCode; // const {key} = event; in ES6+

        if (key === 27 && !modal) {
            this.handlePubNubShowCloseSL(null);
        }
    }

    handlePushPubnubMessages = () => {

        const { pushChannels } = this.state;

        this.pubnub.subscribe({
            channels: [...pushChannels]
        });

        // debugger;

        // pubnub.getMessage(...pushChannels, (msg) => {
        //     this.notifyMe(msg.message.text);
        // })

    }

    componentDidMount() {

        this.pubnubServiceVar = pubnubService$.pubnub_observe().subscribe(success => {

            const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor ? true : false;

            this.setState({
                doctor_uuid: LS_SERVICE.get('staff_id'),
                is_doctor
            })

            let { channels, pubNubChannels, patient_details } = this.state;

            if (success.pubnubActiveChatData !== undefined) {

                if (JSON.stringify(pubNubChannels) !== JSON.stringify(success.pubnubActiveChatData)) {
                    pubNubChannels = success.pubnubActiveChatData.map(e => e.booking_id);

                    this.setState({
                        pubNubChannels
                    })
                }
            }

            if (success.data !== undefined) {
                if (channels.filter(e => e === success.data.id).length === 0) {
                    channels.push(success.data.id);
                    patient_details = success.data.patient;
                    this.setState({
                        channels,
                        patient_details,
                        pubNubPopupOpen: success.show,
                        activeChannel: success.data.id,
                        doctor_uuid: LS_SERVICE.get('staff_id')
                    }, () => {

                        const { activeChannel } = this.state;

                        if (activeChannel !== null) {
                            this.handleEventListeners();
                            this.handleFetchMessages();
                        }
                    })
                }
            }

            if (success.pubnubActiveChatData) {
                this.handlePubnubMessageCount(success.pubnubActiveChatData);
            }

            if (success.alert) {
                this.handleChatTimeUpdate(success.data);
            }
        })
    }

    componentWillUnmount() {
        this.pubnubServiceVar?.unsubscribe();
        this.pubnub?.unsubscribeAll();
    }

    handleTextAreaEnter = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            document.querySelector('#submitButton').click();
        }
    }

    handlePubnubMessageCount = (data) => {

        const dataArr = { booking_ids: [], timestamps: [] };

        if (data !== undefined && data.length > 0) {
            data.forEach((e, i) => {
                dataArr.booking_ids.push(e.booking_id);
                dataArr.timestamps.push(e.timestamp !== null && e.timestamp !== "0" ? e.timestamp : new Date().getTime() * 1000);
            })
        } else return;

        this.pubnub.messageCounts({
            channels: [...dataArr.booking_ids],
            channelTimetokens: [...dataArr.timestamps]
        }).then((response) => {
            pubnubService$.pubnub_message_count({ pubnub_message_counts: response.channels });
        }).catch((error) => {
            console.log(error)
        }
        );
    }

    handleFetchMessages = () => {

        let { fetchendTime, isPubNubFetchRequestProcessing, noMorePunNubData, historyMessageLength, messages, channels } = this.state;

        if (isPubNubFetchRequestProcessing) return;

        this.setState({
            isPubNubFetchRequestProcessing: true
        })

        if (noMorePunNubData) return;

        if (channels.length !== 0) {

            let fetchParams = {
                channels: [...channels],
                count: historyMessageLength
            }

            if (messages.length > 0) {
                fetchParams.start = fetchendTime;
            } else {
                fetchParams.end = fetchendTime;
            }

            this.pubnub.fetchMessages(
                fetchParams,
                (status, response) => {

                    if (status.error) {
                        notification.error({
                            message: status.errorData.error_message,
                            placement: 'topRight'
                        })
                    }

                    if (response !== undefined && response.channels[channels[0]] !== undefined) {

                        const data = response.channels[channels[0]];

                        if (JSON.stringify(fetchendTime) !== data[0].timetoken) {

                            let newMessages = [];

                            data.forEach(e => {
                                if (e.messageType !== 4) {
                                    newMessages.push({
                                        message: e.message.message,
                                        uuid: e.message.uuid,
                                        name: e.message.name,
                                        timetoken: e.timetoken
                                    })
                                } else {
                                    newMessages.push({
                                        message: e.message.file.name,
                                        file_id: e.message.file.id,
                                        audio: e.message.message.audio !== undefined ? e.message.message.audio : this.handleAudioCheck(e.message.file.name),
                                        uuid: e.message.message.uuid,
                                        name: e.message.message.name,
                                        timetoken: e.timetoken
                                    })
                                }
                            })

                            this.setState({
                                messages: [...newMessages, ...messages],
                                fetchendTime: +data[0].timetoken,
                                isPubNubFetchRequestProcessing: false,
                                messages_loaded: true,
                                noMorePunNubData: data.length < historyMessageLength
                            })
                        }
                    } else {
                        this.setState({
                            isPubNubFetchRequestProcessing: false,
                            noMorePunNubData: true,
                            messages_loaded: true,
                        })
                    }
                }
            );
        }

    }

    handleImageUploadPrior = (input, audio = false) => {

        this.setState({
            fileDataPrior: [],
            modalFile: true
        })

        const { files } = input.currentTarget;
        const { type } = files[0];
        // const filesLength = files.length;

        // for (var i = 0; i < filesLength; i++) {
        //     var f = files[i]
        var fileReader = new FileReader();
        fileReader.onload = ((e) => {
            if (!audio) {
                $(`<span class="pip ${type === 'application/pdf' ? 'pdf' : ''}">
                    <object title="Document" data=${e.target.result} type=${type}></object>
                    </span>`).appendTo("#file-modalpopup");
            } else {
                $(`<span class="pip">
                    <audio controls>
                        <source src="${e.target.result}" type="audio/wav">
                        Your browser does not support the audio tag.
                    </audio>
                    </span>`).appendTo("#file-modalpopup");
                // $(".remove").click(() => {
                //     $(this).parent(".pip").remove();
                // });
            }
        });

        fileReader.readAsDataURL(files[0]);
        // }

        this.setState({
            fileDataPrior: [...files],
            fileAudio: audio
        })
    }

    handleImageUpload = async (input, audio = false) => {

        this.setState({
            modalFile: !this.state.modalFile,
            fileLoading: true
        })

        let { channels, doctor_uuid, fileAudio } = this.state;

        let { files } = input.currentTarget;

        if (files == undefined) {
            files = [...this.state.fileDataPrior];
            audio = fileAudio;
        }

        Object.values(files).forEach((file) => {
            const PAYLOAD = {
                notification: {
                    body: 'Sent you a file',
                    title: `Chat with ${LS_SERVICE.get('doctor').name}`
                },
                data: {
                    message: '',
                    channelId: channels[0],
                    name: LS_SERVICE.get('doctor').name,
                    uuid: doctor_uuid
                }
            }

            this.pubnub.sendFile({
                channel: channels[0],
                file: file,
                message: {
                    pc_gcm: PAYLOAD,
                    audio,
                    name: LS_SERVICE.get('doctor').name,
                    uuid: doctor_uuid
                }
            }, () => this.pubnub.fire(
                {
                    channel: channels[0],
                    message: {
                        pn_gcm: PAYLOAD,
                        message: '',
                        channelId: channels[0],
                        name: LS_SERVICE.get('doctor').name,
                        uuid: doctor_uuid
                    },
                }, () => this.setState({ fileLoading: false })
            )
            );
        })
    }

    addMessage = newmessage => {

        let { messages, timetoken } = this.state;

        if (timetoken !== newmessage.timetoken) {
            messages = [...messages, {
                message: newmessage.message.message,
                timetoken: newmessage.timetoken,
                name: newmessage.message.name,
                uuid: newmessage.message.uuid,
                file_id: newmessage.file_id,
                audio: newmessage.message.audio
            }];
            this.setState({
                messages,
                timetoken: newmessage.timetoken
            }, () => {
                this.handleChatTimeUpdate();
            })
        }

        // pubnub.addMessageAction(
        //     {
        //         channel: channels[0],
        //         messageTimetoken: this.state.timetoken,
        //         action: {
        //             type: 'receipt',
        //             value: 'message_read',
        //         },
        //     },
        //     function (status, response) {
        //         debugger
        //     }
        // );
    }

    handleChatTimeUpdate = (data = null) => {

        let { is_doctor, timetoken, channels } = this.state;

        if (data !== null) {
            timetoken = new Date().getTime() * 10000;
            channels.push(data.id);
        }

        const PARAMS = {
            booking_id: channels[0],
            timestamp: timetoken
        }

        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

        axios.post(SAVE_CHAT_TIME, qs.stringify(PARAMS))
            .then(success => {
                pubnubService$.pubnub_notification_update({ pubnub_notification_update: true })
            })
    }

    // calcHeight = (value) => {
    //     let numberOfLineBreaks = (value.match(/\n/g) || []).length;
    //     let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
    //     return newHeight;
    // }

    setMessage = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
        // const el = this.messageTextareaRef.current;
        // el.style.height = this.calcHeight(el.value) + "px";

        // if (value.length >= 1) {
        //     pubnub.signal({
        //         channel: channels[1],
        //         message: {
        //             isTyping: true,
        //             uuid: LS_SERVICE.get('doctor').id
        //         }
        //     })
        // } else if (value.length === 0) {
        //     pubnub.signal({
        //         channel: channels[1],
        //         message: {
        //             isTyping: false,
        //             uuid: LS_SERVICE.get('doctor').id
        //         }
        //     })
        // }
    }

    sendMessage = (e, message) => {

        if (e !== null)
            e.preventDefault();

        if (message == "" || message.trim() == "") return;

        const { channels, doctor_uuid } = this.state;

        const PAYLOAD = {
            notification: {
                body: message,
                title: `Chat with ${LS_SERVICE.get('doctor').name}`
            },
            data: {
                message,
                channelId: channels[0],
                name: LS_SERVICE.get('doctor').name,
                uuid: doctor_uuid
            }
        }

        this.pubnub.publish(
            {
                channel: channels[0],
                message: {
                    pn_gcm: PAYLOAD,
                    message,
                    channelId: channels[0],
                    name: LS_SERVICE.get('doctor').name,
                    uuid: doctor_uuid
                },
            },
            () => this.setState({ message: "" })
        );

        // this.pubnub.signal({
        //     channel: channels[1],
        //     message: {
        //         isTyping: false,
        //         uuid: LS_SERVICE.get('doctor').id
        //     }
        // })
    };

    getFileUrl = (data) => {

        const { channels } = this.state;

        return this.pubnub.getFileUrl({
            channel: channels[0],
            id: data.file_id,
            name: data.message
        });

        // const file = await pubnub.downloadFile({
        //     channel: channels[0],
        //     id: data.file_id,
        //     name: data.message
        //   });


        // debugger
        // const myImageTag = document.createElement('img');
        // myImageTag.src = URL.createObjectURL(await file.toFile());

        // document.body.appendChild(myImageTag);
    }

    showTyping = (data) => {

        let { isTyping } = this.state;

        isTyping = {
            status: data.message.isTyping,
            uuid: data.message.uuid
        }

        this.setState({
            isTyping
        })
    }

    recordAudio = () => {
        return new Promise(resolve => {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;

                    let audioContext = new AudioContext();

                    let gumStream = stream;

                    let input = audioContext.createMediaStreamSource(stream);

                    let rec = "";

                    rec = new window.Recorder(input, { numChannels: 1 });

                    const start = () => {
                        rec.record();
                    };

                    const stop = () => {
                        return new Promise(_ => {

                            rec.stop();

                            //stop microphone access
                            gumStream.getAudioTracks()[0].stop();

                            //create the wav blob and pass it on to createDownloadLink

                            rec.exportWAV((blob) => {
                                // debugger

                                const inputFile = {
                                    currentTarget: {
                                        files: [
                                            new File([blob], "my_audio.wav", { type: blob.type, lastModified: new Date() })
                                        ]
                                    }
                                }

                                this.handleImageUploadPrior(inputFile, true);
                            });
                        });
                    };

                    resolve({ start, stop });
                });
        });
    };

    handleAudioUpload = async () => {

        const { audio } = this.state;

        this.setState({
            audio: !audio,
        }, async () => {
            const { audio } = this.state;

            if (recorderEvent === "")
                recorderEvent = await this.recordAudio();

            if (audio) {
                recorderEvent.start();
                recorderTimeOut = setTimeout(() => {
                    this.setState({
                        audio: !audio
                    }, () => {
                        recorderEvent.stop();
                        recorderEvent = "";
                    })
                }, recorderTimeoutTime)
            } else {
                if (recorderTimeOut !== "") {
                    clearTimeout(recorderTimeOut);
                    recorderTimeOut = "";
                }
                recorderEvent.stop();
                recorderEvent = "";
                // audiox.play();
            }
        })
    }

    handlePubNubScroll = () => {
        const firstLi = document.querySelector(
            ".pubnub_scroll_loader  > div"
        );
        const scrollWin = document.querySelector(".pubnub_scroll_loader");
        if (firstLi === null) return;
        var firstLiOffset = (firstLi.offsetTop + firstLi.clientHeight) + 250;
        let pageOffset = scrollWin.scrollTop + scrollWin.scrollHeight;
        if (firstLiOffset > pageOffset) {
            this.handleFetchMessages();
        }
    };

    documentToggle = (e, files, isDoc = false) => {

        if (files !== undefined) {
            const fileExt = files[0].split(".").slice(-1)[0];
            if (fileExt === "pdf") {
                isDoc = true;
            }

            if (isDoc) {
                this.setState({
                    modalClass: "modal-imgdocs modal-docs",
                    documentType: 'application/pdf',
                });
            } else {
                this.setState({
                    modalClass: "modal-imgdocs",
                    documentType: '',
                });
            }
            if (e !== null) {
                e.preventDefault();
                this.setState({
                    documentIndex: $(e.currentTarget).index(),
                    documentCount: e.currentTarget.parentElement.childElementCount
                }, () => {
                    this.setState({
                        showArrow: this.state.documentCount > 1 ? true : false,
                    })
                })

                this.setState({
                    allDocuments: files,
                    dataFile: files[$(e.currentTarget).index()],
                });
            } else {
                this.setState({
                    dataFile: files,
                })
            }
        }

        this.setState({
            modal: !this.state.modal,
        })
    };

    fileModalToggle = (e) => {
        this.setState({
            modalFile: !this.state.modalFile,
        }, () => {
            const { modalFile } = this.state;
            if (!modalFile) {
                this.setState({
                    fileDataPrior: [],
                    fileAudio: false
                })
            }
        })
    }


    handlePubNubShowCloseSL = (e) => {
        if (e !== null) {
            e.preventDefault();
        }

        if (chatScroll !== "") {
            chatScroll.removeEventListener("scroll", this.handlePubNubScroll, true);
            chatScroll = "";
        }

        if (escListener !== "") {
            escListener = document.removeEventListener('keydown', this.handleEscapeListener, true);
            escListener = "";
        }

        // document.removeEventListener('keydown', this.handleEscapeListener, true);
        // pubnub.unsubscribe({ "channels": [...channels] });
        this.setState({
            // pushChannels: channels,
            channels: [],
            activeChannel: null,
            messages: [],
            patient_details: undefined,
            message: "",
            timetoken: "",
            pubNubPopupOpen: false,
            fetchendTime: new Date().getTime() * 1000,
            noMorePunNubData: false,
            modalFile: false
        }, () => {
            // this.handlePushPubnubMessages();
            // this.props.onPubNubChatClose();
            pubnubService$.pubNubBooking_data({ data: undefined, show: false, alert: false });
        })
    }

    // handlePubNubStateUpdateSL = () => {
    //     this.props.onPubNubNotificationClick();
    // }

    notifyMe = messageData => {

        const { notification_message_text, isFileBefore } = this.state;

        if (notification_message_text === messageData.message && !isFileBefore) return;

        this.setState({
            notification_message_text: messageData.message,
            isFileBefore: false
        })

        if (!("Notification" in window)) {
            alert("This browser does not support system notifications");
        } else if (Notification.permission === "granted") {
            if (typeof messageData.message === 'string' || messageData.message instanceof String) {
                let notification = new Notification(`Chat with ${messageData.name}`, {
                    body: messageData.message,
                    data: messageData
                });
                notification.onclick = (e) => {
                    window.focus();
                    this.handleNotificationClick(e.currentTarget.data);
                };
            }
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    if (typeof messageData.message === 'string' || messageData.message instanceof String) {
                        let notification = new Notification(`Chat with ${messageData.name}`, {
                            body: messageData.message,
                            data: messageData
                        });
                        notification.onclick = (e) => {
                            window.focus();
                            this.handleNotificationClick(e.currentTarget.data);
                        };
                    }
                }
            });
        }

        pubnubService$.pubnub_notification_update({ pubnub_notification_update: true });
    }

    handleNotificationClick = (data) => {
        let { channels, patient_details } = this.state;

        patient_details = {
            first_name: data.name.split(" ")[0],
            last_name: data.name.split(" ")[1],
            dob: data.dob,
            uhid: undefined
        }

        channels.push(data.channelId);

        this.setState({
            channels,
            patient_details,
            activeChannel: data.channelId,
            pubNubPopupOpen: !this.state.pubNubPopupOpen
        }, () => {
            // this.handlePubNubStateUpdateSL();
            this.handleEventListeners();
            this.handleFetchMessages()
        })

        pubnubService$.pubNubBooking_data({ data, show: true, alert: true });
    }

    handleIsFileBefore = () => {
        this.setState({
            isFileBefore: true
        })
    }

    handleAudioCheck = (filename) => {
        return (filename.split(".")[1] === '3gp' || filename.split(".")[1] === 'm4a' || filename.split(".")[1] === 'wav')
    }

    getAge(dob) {
        if (dob) {
            let dateParts = dob.split('-');
            let dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
            let newdob = new Date(dateObject.toString());
            let today = new Date();
            let age = Math.floor(
                (today - newdob) / (365.25 * 24 * 60 * 60 * 1000)
            );
            return age;
        }
        return '';
    }


    render() {

        const { message, messages, channels, pubNubChannels, patient_details, pubNubPopupOpen, isPubNubFetchRequestProcessing,
            audio, messages_loaded, activeChannel, doctor_uuid, fileLoading } = this.state;

        // console.log(messages, doctor_uuid, patient_details)

        const age = this.getAge(patient_details?.dob)

        const { dataFile } = this.state

        const PubNubRender = (
            <PubNubProvider client={this.pubnub}>
                <div className="pubnub-app">
                    <div className="pubnub-appwrapper">

                        <PubNubConsumer>
                            {client => {
                                client.addListener({
                                    message: messageEvent => {
                                        const { activeChannel, pubNubChannels } = this.state;

                                        if (activeChannel !== null) {
                                            if (+activeChannel === +messageEvent.subscribedChannel) {
                                                this.addMessage(messageEvent);
                                            } else {
                                                this.notifyMe(messageEvent.message);
                                            }
                                        } else {
                                            if (pubNubChannels.length > 0) {
                                                this.notifyMe(messageEvent.message);
                                            }
                                        }
                                    },
                                    presence: p => {
                                        // handle presence
                                        // var action = p.action; // Can be join, leave, state-change, or timeout
                                        // var channelName = p.channel; // The channel to which the message was published
                                        // var occupancy = p.occupancy; // No. of users subscribed to the channel
                                        // var state = p.state; // User State
                                        // var channelGroup = p.subscription; //  The channel group or wildcard subscription match (if exists)
                                        // var publishTime = p.timestamp; // Publish timetoken
                                        // var timetoken = p.timetoken;  // Current timetoken
                                        // var uuid = p.uuid; // UUIDs of users who are subscribed to the channel

                                        console.log('Presence')
                                        console.log(p);
                                    },
                                    signal: s => {
                                        // handle signal
                                        // var channelName = s.channel; // The channel to which the signal was published
                                        // var channelGroup = s.subscription; // The channel group or wildcard subscription match (if exists)
                                        // var pubTT = s.timetoken; // Publish timetoken
                                        // var msg = s.message; // The Payload
                                        // var publisher = s.publisher; //The Publisher

                                        // console.log('Signal');
                                        // console.log(s);
                                        // this.showTyping(s);
                                    },
                                    objects: objectEvent => {
                                        // var channel = objectEvent.channel; // The channel
                                        // var channelGroup = objectEvent.subscription; // The channel group
                                        // var timetoken = objectEvent.timetoken; // The event timetoken
                                        // var publisher = objectEvent.publisher; // The UUID that triggered this event
                                        // var event = objectEvent.event; // The event name that occurred
                                        // var type = objectEvent.type; // The event type that occurred
                                        // var data = objectEvent.data; // The event data that occurred

                                        console.log('Objects')
                                        console.log(objectEvent);
                                    },
                                    messageAction: ma => {
                                        // handle message action
                                        // var channelName = ma.channel; // The channel to which the message was published
                                        // var publisher = ma.publisher; //The Publisher
                                        // var event = ma.message.event; // message action added or removed
                                        // var type = ma.message.data.type; // message action type
                                        // var value = ma.message.data.value; // message action value
                                        // var messageTimetoken = ma.message.data.messageTimetoken; // The timetoken of the original message
                                        // var actionTimetoken = ma.message.data.actionTimetoken; // The timetoken of the message action

                                        console.log('Message Action')
                                        console.log(ma);
                                    },
                                    file: event => {
                                        // const channelName = event.channel; // Channel to which the file belongs
                                        // const channelGroup = event.subscription; // Channel group or wildcard subscription match (if exists)
                                        // const publisher = event.publisher; // File publisher
                                        // const timetoken = event.timetoken; // Event timetoken
                                        // const message = event.message; // Optional message attached to the file
                                        // const fileId = event.file.id; // File unique id
                                        // const fileName = event.file.name;// File name
                                        // const fileUrl = event.file.url; // File direct URL

                                        // console.log('File')
                                        // console.log(event);

                                        const { activeChannel } = this.state;

                                        this.handleIsFileBefore();

                                        if (activeChannel !== null) {
                                            const message = {
                                                message: {
                                                    message: event.file.name,
                                                    name: event.message.name,
                                                    uuid: event.message.uuid,
                                                    audio: event.message.audio !== undefined ? event.message.audio : this.handleAudioCheck(event.file.name)
                                                },
                                                file_id: event.file.id,
                                                timetoken: event.timetoken,
                                                publisher: event.message.uuid
                                            }

                                            this.addMessage(message)
                                        }
                                    },
                                    status: s => {
                                        // var affectedChannelGroups = s.affectedChannelGroups; // The channel groups affected in the operation, of type array.
                                        // var affectedChannels = s.affectedChannels; // The channels affected in the operation, of type array.
                                        // var category = s.category; //Returns PNConnectedCategory
                                        // var operation = s.operation; //Returns PNSubscribeOperation
                                        // var lastTimetoken = s.lastTimetoken; //The last timetoken used in the subscribe request, of type long.
                                        // var currentTimetoken = s.currentTimetoken; //The current timetoken fetched in the subscribe response, which is going to be used in the next request, of type long.
                                        // var subscribedChannels = s.subscribedChannels; //All the current subscribed channels, of type array.

                                        // console.log('Status')
                                        // console.log(s);
                                    },
                                });

                                if (activeChannel !== null) {
                                    client.subscribe({ channels });
                                } else {
                                    if (pubNubChannels.length > 0) {
                                        client.subscribe({ channels: pubNubChannels });
                                    }
                                }
                            }}
                        </PubNubConsumer>


                        <main id="main" style={{ position: 'absolute' }}>
                            <div id="pubnub_data_modal" tabIndex={-1} role="dialog" className="modal fade show" style={{ display: 'block', paddingRight: '9px', background: '#232020d6' }} aria-modal="true">
                                <div className="modal-dialog pubnub-popup modal-xl">
                                    <div className="modal-content">
                                        <div className="modal-body p-0 pubnub-container">
                                            <div className="pubnub-appwrapper">
                                                <div className="pubnub-wrapper">
                                                    <div className="pubnub-common pubnub-header">
                                                        <div className="pubnub-title">Message Patient</div>

                                                        {channels.length > 0 && patient_details !== undefined ? (<div className="pubnubheader-patientdetail">
                                                            Patient :{" "}
                                                            <strong>
                                                                {patient_details.first_name !== undefined ? patient_details.first_name + ' ' + patient_details.last_name : patient_details.fullname}
                                                            </strong>{" "}
                                                            {age} Yrs,{" "}
                                                            {patient_details?.gender?.substr(0, 1)}{" "}
                                                            {/* {(patient_details.uhid !== '' && patient_details.uhid !== undefined && patient_details.uhid !== null) ? '| ' + UHID(patient_details.uhid) : ""} */}
                                                        </div>) : ""}

                                                        <a href="#" onClick={this.handlePubNubShowCloseSL}>&times;</a>
                                                    </div>
                                                    <div className="pubnub-common pubnub-content pubnub_scroll_loader">
                                                        <div>
                                                            {activeChannel !== null ? (messages.map((mess, messageIndex) => {
                                                                if (mess.message !== undefined && mess.message.length > 0) {
                                                                    return (
                                                                        <div
                                                                            className="pubnub-content-innerwrapper"
                                                                            key={`message-${messageIndex}`}
                                                                            style={{
                                                                                textAlign: +mess.uuid === doctor_uuid ? 'right' : 'left'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="pubnub-contentinner-content"
                                                                                style={{
                                                                                    backgroundColor: +mess.uuid !== doctor_uuid ? '#ffb9b9' : '#ffffff',
                                                                                }}
                                                                            >
                                                                                <p className="mb-2">
                                                                                    <span>
                                                                                        {mess.name}
                                                                                    </span>
                                                                                    <span>
                                                                                        <Moment format="h:mm A, D MMM, Y" unix>
                                                                                            {new Date(Math.floor(+mess.timetoken / 10000000))}
                                                                                        </Moment>
                                                                                    </span>
                                                                                </p>
                                                                                {mess.file_id ? (!mess.audio ? (
                                                                                    <span style={{
                                                                                        display: 'inline-block',
                                                                                        cursor: 'pointer'
                                                                                    }} onClick={() => this.documentToggle(null, [this.getFileUrl(mess)])}>
                                                                                        <object title="Document" data={this.getFileUrl(mess)} style={{
                                                                                            pointerEvents: 'none',
                                                                                            maxWidth: '100px'
                                                                                        }}></object>
                                                                                    </span>
                                                                                ) : (
                                                                                    <audio controls>
                                                                                        <source src={this.getFileUrl(mess)} />
                                                                                            Your browser does not support the audio element.
                                                                                    </audio>
                                                                                )) : <pre style={{
                                                                                    margin: '0',
                                                                                    whiteSpace: 'break-spaces',
                                                                                    textAlign: 'left'
                                                                                }}>{mess.message}</pre>}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            })) : ""}

                                                            {messages.length === 0 && isPubNubFetchRequestProcessing || fileLoading ? (
                                                                <div className={!fileLoading ? 'text-center' : 'text-right'}>
                                                                    {!fileLoading ? LOADER_RED : LOADER_BLACK}
                                                                </div>
                                                            ) : messages.length === 0 && messages_loaded ? (
                                                                <>
                                                                    <div className="text-center">
                                                                        No Messages
                                                                    </div>
                                                                </>
                                                            ) : ""}

                                                            {/* {isTyping !== null && isTyping.status && +isTyping.uuid !== doctor_uuid ? LOADER_BLACK : ""} */}
                                                        </div>
                                                    </div>
                                                    {this.state.modalFile ? (
                                                        <>  <div className="file-modalpopup-wrapper">
                                                            <h4>Please confirm before submitting...</h4>
                                                            <div id="file-modalpopup"></div>
                                                            <div className="fmp-button-wrapper">
                                                                <button type="button" className="btn btn-secondary mr-2" onClick={this.fileModalToggle}>Cancel</button>
                                                                <button type="button" className="btn btn-primary" onClick={this.handleImageUpload}>Submit</button>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ) : ""}
                                                    <form className={`pubnub-common pubnub-footer ${this.state.modalFile ? 'd-none' : ""}`}>
                                                        <textarea
                                                            type="text"
                                                            style={{
                                                                borderRadius: '5px',
                                                                flexGrow: 1,
                                                                fontSize: '18px',
                                                                border: 'none',
                                                                resize: 'none',
                                                            }}
                                                            id="inputText"
                                                            placeholder="Type here"
                                                            autoComplete="off"
                                                            value={message}
                                                            name="message"
                                                            // ref={this.messageTextareaRef}
                                                            onChange={this.setMessage}
                                                        />
                                                        <div className="pubnub_footer_buttonwrapper">
                                                            <button type="button" className={`icon-mute-btn ${audio ? 'active' : ""}`} title="Microphone" onClick={this.handleAudioUpload}>
                                                                <img src={mic} alt="mic-icon" />
                                                                <span></span>
                                                            </button>
                                                            <button className="btn btn-sm btn-primary" type="button">
                                                                <label className="m-0" htmlFor="inputFile">Upload</label>
                                                                <input id="inputFile" className="hidden" type="file" name="files"
                                                                    accept="image/png, image/jpeg, image/pdf"
                                                                    onChange={this.handleImageUploadPrior} />
                                                            </button>
                                                            <button className="btn btn-sm btn-primary" id="submitButton" type="button"
                                                                onClick={e => { this.sendMessage(e, message); }}>
                                                                Send
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </PubNubProvider>
        )

        return (

            <>
                <div className={`pubnub-container ${pubNubPopupOpen ? 'visible' : 'hidden'}`}>
                    {LS_SERVICE.has('staff_id') && PubNubRender}
                </div>

                {/* <Modal
                    isOpen={this.state.modal}
                    toggle={this.documentToggle}
                    className={this.state.modalClass}
                >
                    <ModalHeader toggle={this.documentToggle}></ModalHeader>
                    <ModalBody style={{background:"rgba(0,0,0,0.8)"}}>
                        <div className="img-rotate">
                            <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
                            <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
                        </div>
                        <div className="img-arrows" style={{ 'display': this.state.showArrow ? 'flex' : 'none' }}>
                            <i className="ir-left" onClick={this.handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
                            <i className="ir-right" onClick={this.handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
                        </div>
                        <div className="object-wrapper ow-fullheight">
                            {this.state.documentType === 'application/pdf' ?
                                <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object> : (
                                    <figure className="zoom" style={{ backgroundImage: `url('${this.state.dataFile}')`, backgroundSize: '150%', backgroundRepeat: 'no-repeat' }} id='objdoc1' data-rotate="0">
                                        <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object>
                                     </figure> 
                                )}
                        </div>
                    </ModalBody>
                </Modal> */}

                {/* <Modal
                    title={"Test"}
                    visible={this.state.modal}
                    onCancel={this.documentToggle}
                    footer={false}
                    bodyStyle={{ padding: "10px 20px" }}
                    width="600px"
                >
                    <div className="img-rotate">
                            <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
                            <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
                        </div>
                        <div className="img-arrows" style={{ 'display': this.state.showArrow ? 'flex' : 'none' }}>
                            <i className="ir-left" onClick={this.handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
                            <i className="ir-right" onClick={this.handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
                        </div>
                        <div className="object-wrapper ow-fullheight">
                            {this.state.documentType === 'application/pdf' ?
                                <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object> : (
                                    <figure className="zoom" style={{ backgroundImage: `url('${this.state.dataFile}')`, backgroundSize: '150%', backgroundRepeat: 'no-repeat' }} id='objdoc1' data-rotate="0">
                                        <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object>
                                     </figure> 
                                )}
                        </div>
                </Modal> */}

                <Modal
                    title="Documents"
                    visible={this.state.modal}
                    footer={false}
                    width={850}
                    onCancel={this.documentToggle}
                    style={{ top: 20 }}
                    wrapClassName={'pubnub_modal'}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center" style={{ width: 'auto', height: '70vh' }}>
                            {this.state.dataFile != null ? <span class={` pip  ${this.state.documentType == 'application/pdf' ? 'pdf' : ''}`}>
                                <object title="Document" className={`${dataFile.toString().search('pdf') > 0 ? "w-100" : 'w-auto'} h-100`} data={this.state.dataFile} type={this.state.documentType}></object>
                            </span> : null}
                        </div>
                    </div>
                </Modal>



            </>
        )
    }
}

export default PubNubComponent;