import * as React from 'react';
import './incoming-call.css';
import { withRouter } from "react-router-dom";
import LS_SERVICE from "../../utils/localStorage";
// import axios from "axios";
import {
    USERTYPES,
    // API_BASE_URL3,
    VONAGE_INCOMING_CALL_SOCKET
} from "../../utils/constant";

import { messageService, callService } from '../../utils/rxjs-sharing';

class IncomingCall extends React.Component {

    state = {
        show: false,
        secondsRemaining: 0,
        info: {
            patient_name: null,
            appointment_time: null,
            booking_id: null,
            session_id: null,
            token: null
        },
        socket: {}
    };
    setTimeRemaining;

    constructor(props) {
        super(props);
        this.goToConsultation = this.goToConsultation.bind(this);
        this.close = this.close.bind(this);
        this.startTimer = this.startTimer.bind(this);
    }

    ring() {
        document.getElementById("ringtoneSignal").play();
    }

    stopring() {
        document.getElementById("ringtoneSignal").pause();
        document.getElementById("endCallSignal").play();
    }

    stopOutring() {
        document.getElementById("callingSignal").pause();
        document.getElementById("endCallSignal").play();
    }

    componentDidMount() {
        let $this = this;

        console.log('VC - Sock Url', VONAGE_INCOMING_CALL_SOCKET);
        const socket = window.io.connect(VONAGE_INCOMING_CALL_SOCKET, { reconnect: true });

        socket.on("connect", function (data) {
            console.log('VC - Connected for IC');
        });

        socket.on("vonage_call", function (data) {
            console.log('>>> incoming call data', data);
            const doctorInfo = LS_SERVICE.get('doctor');
            if (doctorInfo === null && !data.is_homecare) {
                return;
            }
            // const doctor = doctorInfo;
            const doctor_id = LS_SERVICE.get('staff_id');
            // const booking_id = LS_SERVICE.has('homecare_booking_id') ? LS_SERVICE.get('homecare_booking_id') : '';
            // const is_bookingidsame = data.booking_id == booking_id;

            // if (data.doctor_id == doctor_id && data.type == "join") {
            //     if ($this.setTimeRemaining) {
            //         clearInterval($this.setTimeRemaining);
            //     }

            //     $this.ring();

            //     $this.setState({
            //         info: {
            //             patient_name: data.patient_name,
            //             appointment_time: data.appointment_time,
            //             booking_id: data.booking_id,
            //             session_id: data.session_id,
            //             token: data.token
            //         },
            //         secondsRemaining: 60,
            //         show: true
            //     }, () => {
            //         $this.startTimer();
            //     });
            // }

            const booking_floating_widget = document.getElementById('floating_vc_dialog');

            let booking_id = null;

            if (booking_floating_widget !== null) {
                booking_id = booking_floating_widget.getAttribute('data-booking_id');
            }

            // if ((data.doctor_id == doctor_id || is_bookingidsame) && data.type == "declined" && window.InncVonage.data.call_started) {
            // console.log('>> DD', data.doctor_id, doctor_id);
            if (((data.is_homecare && data.booking_id == booking_id) || data.doctor_id == doctor_id) && data.type == "declined" && window.InncVonage.data.call_started) {
                $this.stopOutring();
                callService.init({ end: true, notify: false, message: "Patient (" + data.patient_name + ") disconnected the call" });
            }
            // if ((data.doctor_id == doctor_id || is_bookingidsame) && data.type == "declined" && !window.InncVonage.data.call_started && $this.state.show) {
            //     $this.stopOutring();
            //     $this.close();
            // }
        });

    }

    startTimer() {
        var $this = this;
        this.setTimeRemaining = setInterval(function () {
            $this.setState({
                secondsRemaining: $this.state.secondsRemaining - 1
            }, () => {
                if ($this.state.secondsRemaining == 0) {
                    clearInterval($this.setTimeRemaining);
                    $this.stopOutring();
                    $this.busy();
                }
            });
        }, 1000);
    }

    goToConsultation = (e) => {
        if (window.InncVonage.data.call_started) {
            callService.init({ end: true });
            console.log('>> location.pathname', this.props.location);
            if (this.props.location != '/doctor-consult') {
                window.InncVonage.disconnect();
            }
        }
        if (this.setTimeRemaining) {
            clearInterval(this.setTimeRemaining);
        }
        this.props.history.push({
            pathname: "/doctor-consult",
            search: '?session_id=' + this.state.info.session_id + '&token=' + this.state.info.token,
            state: {
                booking_id: this.state.info.booking_id
            },
        });
        messageService.sendMessage({ update: true });
        this.close();
    };

    close() {
        this.stopring();
        this.setState({
            show: false
        });
    }

    busy() {
        this.close();

        // axios.get(API_BASE_URL3 + 'vonage/booking/' + this.state.info.booking_id + '/status/3')
        //     .then(function (response) {
        //     })
        //     .catch(function (error) {
        //     });
    }

    render() {
        const showClass = this.state.show ? '' : 'd-none';
        return (
            <div className={"incoming-call-widget " + showClass}>
                <div className="call-header">
                    Incoming Call
                    {/* <a onClick={() => this.busy()} className="float-right close-inccall">&times;</a> */}
                    <span className="closingin float-right">
                        Closing in {this.state.secondsRemaining} second(s)
                    </span>
                </div>
                <div className="call-body" title="Go to consultation page">

                    <div className="media">
                        <img src="/assets/images/incoming-call.png" className="mr-3" alt="..." />
                        <div className="media-body">
                            <h5 className="mt-0">{this.state.info.patient_name} at {this.state.info.appointment_time}</h5>
                            Patient has joined the video chat room, please join!
                        </div>
                    </div>

                </div>

                <div className="call-footer">
                    <button type="button" className="btn btn-outline-success btn-sm" onClick={() => this.goToConsultation()}>
                        &crarr; Consult
                    </button>
                    <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => this.busy()}>
                        &times; Busy
                    </button>
                </div>
            </div>
        )
    }

}

export default withRouter(IncomingCall);
