import React, { Component, Fragment } from 'react';
import { DIAGNOSIS_DO_NOT_PRINT, SEARCH_DIAGNOSIS, FAVOURITE_DIAGNOSIS, LOADER_BLACK, IMAGE_LIBRARY } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Select, Modal, Button, Tabs, Radio } from 'antd';
import 'antd/dist/antd.css';
import Sketchpad from '../../utils/sketchpad';
import axios from 'axios';
import qs from 'qs';
import icImagereference from '../../assets/images/ic-imagereference.png';
import icDrawing from '../../assets/images/ic-drawing.png';
import icEraser from '../../assets/images/ic-eraser.png';
import icundo from '../../assets/images/ic-undo.png';
import icredo from '../../assets/images/ic-redo.png';
import icbrush from '../../assets/images/ic-brush.png';
import { jQueryLabels, ClearAntList } from '../../utils/jQueryLabels';
// import './forms.scss';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
const { Option } = Select;
const { TabPane } = Tabs;

class AssessmentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessmentData: [],
      viewType: 'full', //template, full
      constants: [],
      allowEdit:this.props.allowEdit??true
    };
  }

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    jQueryLabels();
  }

  componentDidUpdate(prevProps, prevState) {

  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      assessmentData: nextProps.assessmentData,
      viewType: nextProps.viewType,
      constants: nextProps.constants,
    }
  }

  handleModalPopup = (e, popupName, data, type) => {
    e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    }, () => {

      const { viewDocumentModal } = this.state;

      if (viewDocumentModal) {
        if (data !== null) {
          this.setState({
            docComment: data.image_comment,
            docSrc: (data.image_uploaded && data.image_uploaded != undefined) ? data.image_uploaded : data.image,
            docType: type === 'img' ? 'image/jpeg' : 'application/pdf'
          })
        }
      } else {
        this.setState({
          docSrc: null,
          docComment: '',
          docType: ''
        })
      }

    })
  }

  handleEditDelete = (e, modal, data, action, idx) => {
    if (this.props.isPracticing === false) {
      this.props.practiceModal('isPracticingModal');
      return;
    }

    this.props.handlePopUp(e, modal, action == 'editAssessment' ? data : { data }, action, idx);
    // this.props.handlePopUp(e, 'deleteConfirmModal', { data }, action, idx);
  }

  render() {
    const { constants, viewType } = this.state;

    return (
      <Fragment>
        {this.state.assessmentData.length > 0 ?
          <>
            <div className="table-responsive" id="diagnosis-list">
              <table className="table table-bordered mb-0">
                <thead>
                  {this.state.viewType == 'template' ?
                    <tr>
                      <th>Diagnosis</th>
                      <th width="100">ICD 10</th>
                      <td>NOTES</td>
                      <td>IMAGE REFERENCE</td>
                      {this.props.showAction ? <th width="100">Action</th> : null}
                    </tr> :
                    <tr>
                      <td>DIAGNOSIS</td>
                      <td>ICD 10</td>
                      <td>STAGE</td>
                      <td>TYPE</td>
                      <td>STATUS</td>
                      <td>NOTES</td>
                      <td>IMAGE REFERENCE</td>
                      {this.props.showAction ? <td width="120">Action</td> : null}
                    </tr>
                  }
                </thead>
                <tbody>
                  {this.state.assessmentData.map((data, i) => {
                    var deleteIndex = this.state.viewType == 'visit-full' ? data.id : i;
                    return (
                      <tr key={i} id={data.diagnosis_id}>
                      <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{(DIAGNOSIS_DO_NOT_PRINT.includes(data.diagnosis_id) == true || !data.icd_code || data.icd_code == "" || data.icd_code == null) && data.diagnosis_notes ? data.diagnosis_notes : data.description}</td>
                        <td><span className='text-primary'>{data.icd_code?? '-'}</span></td>
                        {this.state.viewType != 'template' ?
                          (
                            <>
                              <td>{constants.assessmentStage[data.stage] ? constants.assessmentStage[data.stage] : '-'} </td>
                              <td>{constants.assessmentType[data.type] ? constants.assessmentType[data.type] : '-'} </td>
                              <td className="ant_switch_button_sm">
                                <BootstrapSwitchButton
                                  width={90} height={24}
                                  size="xs"
                                  // disabled={!disabled}
                                  checked={data.status === 0 ? true : false}
                                  onlabel='Active'
                                  offlabel='Cured'
                                  onstyle="switch" offstyle="success"
                                  onChange={e => this.props.statusSwitch(e, i, data)}
                                  disabled={!this.state.allowEdit}
                                />
                              </td>
                            </>
                          )
                          : (null)
                        }
                        <td>{(DIAGNOSIS_DO_NOT_PRINT.includes(data.diagnosis_id) == true || !data.icd_code || data.icd_code == "" || data.icd_code == null) && data.diagnosis_notes ? '-' : (data.diagnosis_notes ? data.diagnosis_notes : '-')}</td>
                        <td className='image-reference-column'>
                          {this.props.viewType == 'copy-to-visit' && data.image && data.image != null && data.image != '' ?
                            <img src={data.image} alt={data.description} title={data.image_comment}
                              className={`img-fluid ${data.image === "" ? 'd-none' : ''}`} style={{ cursor: 'pointer' }}
                              onClick={e => this.handleModalPopup(e, 'viewDocumentModal', data, 'img')} />
                            : null}
                          {this.props.viewType != 'copy-to-visit' && data.image_uploaded && data.image_uploaded != null && data.image_uploaded != '' ?
                            <img src={data.image_uploaded} alt={data.description} title={data.image_comment}
                              className={`img-fluid ${data.image_uploaded === "" ? 'd-none' : ''}`} style={{ cursor: 'pointer' }}
                              onClick={e => this.handleModalPopup(e, 'viewDocumentModal', data, 'img')} />
                            : null}
                        </td>
                        {this.props.showAction ?
                          <td>
                            {
                              viewType == 'template' ? null :
                                viewType == "soap-full" ?
                                  <a className="nostyle-link mr-2" onClick={e => this.props.handleEdit(data, i)}><i className="icon-edit"></i></a>
                                  :
                                  <a className="nostyle-link mr-2" onClick={e => this.handleEditDelete(e, 'showAssessmentModal', data, 'editAssessment', i)}><i className="icon-edit"></i></a>
                            }
                            {/*{this.state.viewType != 'template' ?<a className="nostyle-link mr-2" onClick={e => this.props.handlePopUp(e, 'showAssessmentModal', data, 'editAssessment', i)}><i className="icon-edit"></i></a>:null}*/}
                            <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', data, 'deleteAssessment', deleteIndex)}><i className="icon_delete"></i></a>
                            {this.props.showSelectCheck ?
                              <span className="select-diagnosis select-check">
                                <i data-checkSelect={i} data-type={'assessment'} onClick={(e) => this.props.updateSelection(i, 'assessmentData')}
                                  className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                              </span>
                              : null}
                            {/*<Button className='nostyle-link delete-assessment'
                                      onClick={() => { this.setState({ deletetemplateId: i }); this.showAlertModal() }}>
                                <i className='icon_delete'></i>
                              </Button>*/}
                          </td>
                          : null
                        }

                      </tr>
                    )
                  }, this)
                  }
                </tbody>
              </table>
            </div>
          </>
          : (null)
        }

        <input type="hidden" name="diagnosis-list-data" id="diagnosis-list-data" />


        <Modal
          title={"Image Reference"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={850}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className="pdf_slider">
            <figure><img className="mr-3 w-100" src={this.state.docSrc} alt='uploaded Doc' /></figure>
            <div className="text-center">
              <strong style={{ fontSize: '16px' }}>
                {this.state.docComment}
              </strong>
            </div>
          </div>
        </Modal>

      </Fragment>
    );
  }
}

export default AssessmentTable;