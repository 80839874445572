import React, { Component, Fragment } from "react";
import { Select, Modal, Button, Tabs, Radio, Form, Input, notification, Pagination } from 'antd';
import {
  SEARCH_DIAGNOSIS,
  FAVOURITE_DIAGNOSIS,
  LOADER_BLACK,
  LOADER_RED,
  SPECIALITY_LIST, STORE_DIAGNOSIS_KEYWORD, GET_ALL_DIAGNOSIS_KEYWORD, DELETE_DIAGNOSIS_KEYWORD
} from '../../utils/constant';
import axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { async } from "rxjs";
import Axios from "axios";
import _ from "lodash";

const { Option } = Select;

class DiagnosisKeyword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResultLoaded: false,
      searchResultLoadedDiffrential: false,
      seachSpecialityLoaded: false,
      searchDiagnosis: [],
      searchDiffrentialDiagnosis: [],
      specialityList: [],
      saveButton: false,
      diagnosId: '',
      diffrentialDiagnosisId: [],
      specialityId: [],
      classicKeyword: [],
      optionalkeyword: [],
      diagnosisKeywordList: [],
      editDiagnoseId: null,
      listingLoader: true,
      updateButton: false,
      deleteId: null,
      diagnoseDeleteModel: false,
      page: '',
      pagenatedDiaognosisData: '',
      currentPage: 1,
    }
    this.diagnosisKeywordRef = React.createRef();
    this.onSpecialtySearch = this.onSpecialtySearch.bind(this);
    this.FormClear = this.FormClear.bind(this);
  }

  onAssessmentSearch = (value) => {
    this.SearchDiagnoseApi(value)
  }

  SearchDiagnoseApi = (query) => {
    this.setState({
      searchResultLoaded: true,
    })
    axios.post(SEARCH_DIAGNOSIS, qs.stringify({
      search: query
    })).then(success => {

      this.setState({
        searchDiagnosis: success.data,
        searchResultLoaded: false,
      });
    }).catch(err => console.log(err))

  };

  onAssessmentDiffrentialSearch = (value) => {
    this.SearchDiffrentialDiagnoseApi(value)
  }

  SearchDiffrentialDiagnoseApi = (value) => {
    this.setState({
      searchResultLoadedDiffrential: true
    })
    axios.post(SEARCH_DIAGNOSIS, qs.stringify({
      search: value
    })).then(success => {

      this.setState({
        searchDiffrentialDiagnosis: success.data,
        searchResultLoadedDiffrential: false
      });
    }).catch(err => console.log(err))
  };

  optionLoad() {
    if (!this.state.searchDiagnosis || this.state.searchDiagnosis.length === 0) {
      return (null)
    }
    return this.state.searchDiagnosis.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description} icdcode={obj.icd_code} >
        <span className="desc">
          {obj.description}
        </span>
        {/* {
          (LS_SERVICE.get('user_type') == USERTYPES.doctor)
            ?
            <span className="float-right text-primary fav_diagnosis">
              <i onClick={e => { e.preventDefault(); e.stopPropagation(); this.assessmentFavourite(obj.id) }} className={obj.fav ? 'icon_unique icon_star favourite mr-2' : obj.id ? 'icon_unique icon_star mr-2' : 'icon_unique mr-2'}></i>
              <i className="icon_info"></i>
            </span>
            :
            null
        } */}
        {/* <span className="float-right mr-2">{obj.icd_code}</span> */}
      </Option>
      )
    })
  }

  autoHeight = (e) => {
    if (e)
      e.target.style.height = e.target.scrollHeight + "px";
  }
  optionDiffrentialLoad() {
    if (!this.state.searchDiffrentialDiagnosis || this.state.searchDiffrentialDiagnosis.length === 0) {
      return (null)
    }
    return this.state.searchDiffrentialDiagnosis.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description} icdcode={obj.icd_code} >
        <span className="desc">
          {obj.description}
        </span>
        {/* {
          (LS_SERVICE.get('user_type') == USERTYPES.doctor)
            ?
            <span className="float-right text-primary fav_diagnosis">
              <i onClick={e => { e.preventDefault(); e.stopPropagation(); this.assessmentFavourite(obj.id) }} className={obj.fav ? 'icon_unique icon_star favourite mr-2' : obj.id ? 'icon_unique icon_star mr-2' : 'icon_unique mr-2'}></i>
              <i className="icon_info"></i>
            </span>
            :
            null
        } */}
        {/* <span className="float-right mr-2">{obj.icd_code}</span> */}
      </Option>
      )
    })
  }

  async componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      LS_SERVICE.get("token");
    await this.diaognosisWordData();
    // this.pagination();
  }

  diaognosisWordData = async () => {
    this.setState({
      listingLoader: true,
    })
    let params = {
      page: 1,
    };
    await axios.get(GET_ALL_DIAGNOSIS_KEYWORD, { params }
    ).then((success) => {
      this.setState({
        diagnosisKeywordList: success.data.data,
        page: success.data.totalCount,
        listingLoader: false,
        currentPage: 1
      })
    }).catch((err) => {
      console.log(err);
      this.setState({
        listingLoader: false,
      });
    });
  }

  onAssessmentSelect = async (value, props) => {
    const { diagnosisKeywordList } = this.state;
    let diagDuplicateID = diagnosisKeywordList.filter((list) => { if (list.diagnose_id == value) return list.diagnose_id })
    if (diagDuplicateID.length > 0) {
      let id = diagDuplicateID[0].id;
      let diagnose_id = diagDuplicateID[0].diagnose_id;
      let diagnose_name = diagDuplicateID[0].diagnose_name;
      let differential_diagnosis_ids = diagDuplicateID[0].differential_diagnosis_ids;
      let differential_diagnosis_name = diagDuplicateID[0].differential_diagnosis_name;
      let speciality_id = diagDuplicateID[0].speciality_id;
      let speciality_name = diagDuplicateID[0].speciality_name;
      let classicKeyword = diagDuplicateID[0].classicKeyword;
      let optionalKeyword = diagDuplicateID[0].optionalKeyword;
      this.editDiaonosisKeyword(
        id, diagnose_id, diagnose_name, differential_diagnosis_ids, differential_diagnosis_name, speciality_id, speciality_name, classicKeyword, optionalKeyword
      )
      return
    }

    await this.setState({
      diagnosId: value,
      saveButton: true,

    })
  };

  selectDiffrentialDiagnosis = async (value, props) => {
    await this.setState({
      diffrentialDiagnosisId: value
    })
  }

  onSpecialitySelect = async (value, props) => {
    await this.setState({
      specialityId: value,
    })
  }

  selectClassicKeyword = async (value, props) => {
    await this.setState({
      classicKeyword: value
    })
  }

  selectOptionalkeyword = async (value, props, e) => {
    await this.setState({
      optionalkeyword: value
    })
  }

  templaterowload() {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{
              textAlign:
                "center",
            }}>DIAGNOSIS</th>
            <th style={{
              textAlign:
                "center",
            }}>DIFFRENTIAL DIAGNOSIS</th>
            <th style={{
              textAlign:
                "center",
            }}>SPECIALITY</th>
            <th style={{
              textAlign:
                "center",
            }}>CLASSIC KEYWORD</th>
            <th style={{
              textAlign:
                "center",
            }}>OPTIONAL KEYWORD</th>
            <th style={{
              textAlign:
                "center",
            }}>ACTION</th>
          </tr>
        </thead>
        <tbody id="template-list"></tbody>
        <>
          {
            this.state.diagnosisKeywordList.length == 0 ?
              (<><td></td><td></td><td></td><td className="text-center">No Records Found.</td><td></td><td></td></>)
              :
              this.state.diagnosisKeywordList.map((template, i) => {
                return (
                  <tr>

                    {template.diagnose_name && template.diagnose_name != "" && template.diagnose_name != null ?
                      <td>{template.diagnose_name}</td> : <td style={{ textAlign: "center" }}>-</td>
                    }
                    {
                      template.differential_diagnosis_name?.length > 0 ? (
                        <td>
                          <ul>
                            {template.differential_diagnosis_name.map((name, i) => {
                              return <li>{name}</li>
                            })
                            }
                          </ul>
                        </td>
                      )
                        : <td style={{ textAlign: "center" }}>-</td>
                    }

                    {template.speciality_name?.length > 0 ? (
                      <td>
                        <ul>{template.speciality_name.map((sp, i) => {
                          return <li>{sp}</li>
                        })
                        }
                        </ul>
                      </td>
                    )
                      : <td style={{ textAlign: "center" }}>-</td>}

                    {template.classicKeyword?.length > 0 ? (
                      <td>
                        <ul>
                          {template.classicKeyword.map((classicKeyword, i) => {
                            return <li>{classicKeyword}</li>
                          })
                          }
                        </ul>
                      </td>
                    )
                      : <td style={{ textAlign: "center" }}>-</td>}


                    {template.optionalKeyword?.length > 0 ? (
                      <td>
                        <ul>
                          {template.optionalKeyword.map((optionalKeyword, i) => {
                            return <li>{optionalKeyword}</li>
                          })
                          }  </ul>
                      </td>
                    ) : <td style={{ textAlign: "center" }}>-</td>}

                    <td style={{
                      textAlign:
                        "center",
                    }}><Button
                      type="link p-0"
                      className="nostyle-link mr-2"
                      onClick={() =>
                        this.editDiaonosisKeyword(
                          template.id, template.diagnose_id, template.diagnose_name, template.differential_diagnosis_ids, template.differential_diagnosis_name, template.speciality_id, template.speciality_name, template.classicKeyword, template.optionalKeyword
                        )
                      }
                    >
                        <i className="icon-edit"></i>
                      </Button>
                      &nbsp;
                      <Button
                        type="link p-0"
                        className="nostyle-link data-delete"
                        onClick={() =>
                          this.deleteWord(
                            template.id
                          )
                        }
                      >
                        <i className="icon_delete"></i>
                      </Button></td>
                  </tr>
                )
              })
          }
        </>
      </table >
    );
  }

  editDiaonosisKeyword = async (id, diagnoseId, diagnoseValue, differentialDiagnosis_Id, differentialDiagnosisName, speciality_id, specialityName, classicKeyword, optionalKeyword) => {
    await this.setState({
      updateButton: true,
      saveButton: true,
      editDiagnoseId: id,
      diagnosId: diagnoseId,
      diffrentialDiagnosisId: differentialDiagnosis_Id != null ? differentialDiagnosis_Id.split(',').map(Number) : [],
      specialityId: speciality_id != null ? speciality_id.split(',').map(Number) : [],
      classicKeyword: classicKeyword != null ? classicKeyword : [],
      optionalkeyword: optionalKeyword != null ? optionalKeyword : []
    })
    this.diagnosisKeywordRef.current.setFieldsValue({
      diagnosis: diagnoseValue,
      differential_diagnosis: differentialDiagnosis_Id != null ? differentialDiagnosis_Id.split(',').map(Number) : [],
      speciality: speciality_id != null ? speciality_id.split(',').map(Number) : [],
      classicKeyword: classicKeyword != null ? classicKeyword : '',
      optionalKeyword: optionalKeyword != null ? optionalKeyword : ''
    });
    await this.SearchDiagnoseApi();
    differentialDiagnosisName.map((value) => {
      this.SearchDiffrentialDiagnoseApi(value);
    });
    await this.allSpecialitySearch();
  }

  onSpecialtySearch(value) {
    this.allSpecialitySearch(value);
  }

  allSpecialitySearch(value) {
    this.setState({
      seachSpecialityLoaded: true
    })
    const PARAMS = { search: value }
    axios.post(SPECIALITY_LIST, qs.stringify(PARAMS))
      .then(res => {
        this.setState({
          specialityList: res.data.status ? res.data.data.results : [],
          seachSpecialityLoaded: false
        })
      })
  }

  specialityLoad() {
    if (!this.state.specialityList || this.state.specialityList.length === 0) {
      return (null)
    }
    return this.state.specialityList.map((obj, i) => {
      return (<Option key={i} value={obj.speciality_id} title={obj.name} speciality_id={obj.speciality_id} >
        <span className="desc">
          {obj.name}
        </span>
        {/* {
          (LS_SERVICE.get('user_type') == USERTYPES.doctor)
            ?
            <span className="float-right text-primary fav_diagnosis">
              <i onClick={e => { e.preventDefault(); e.stopPropagation(); this.assessmentFavourite(obj.id) }} className={obj.fav ? 'icon_unique icon_star favourite mr-2' : obj.id ? 'icon_unique icon_star mr-2' : 'icon_unique mr-2'}></i>
            </span>
            :
            null
        } */}
        {/* <span className="float-right mr-2">{obj.speciality_id}</span> */}
      </Option>
      )
    })
  }

  deleteWord(id) {

    this.setState({
      deleteId: id,
      diagnoseDeleteModel: true,
    })
  }

  deleteDiagnoseKeyword() {
    const { deleteId } = this.state;
    let param = {
      id: deleteId
    }
    axios.post(DELETE_DIAGNOSIS_KEYWORD, param).then(success => {
      this.FormClear();
      this.diaognosisWordData();
      this.setState({ deleteId: '', diagnoseDeleteModel: false, })

      notification.success({
        message: 'diaognois keyword deleted succesfully',
        placement: 'topRight'
      });
    }).catch((err) => {
      notification.error({
        message: 'diaognois keyword not delete',
        placement: 'topRight'
      });
      console.log(err)
    })

  }

  handleDiagnoseCancel() {
    this.setState({
      deleteId: '',
      diagnoseDeleteModel: false,
    })
  }

  saveDiagnosisKeyword = async () => {
    const { editDiagnoseId, diagnosId, specialityId, diffrentialDiagnosisId, classicKeyword, optionalkeyword, diagnosisKeywordList } = this.state;

    let param = {
      id: editDiagnoseId,
      diagnosId: diagnosId,
      specialityId: specialityId,
      diffrentialDiagnosisId: diffrentialDiagnosisId,
      classicKeyword: classicKeyword,
      optionalkeyword: optionalkeyword,
    }
    axios.post(STORE_DIAGNOSIS_KEYWORD, param).then(success => {
      this.FormClear();
      this.diaognosisWordData();
      notification.success({
        message: editDiagnoseId ? 'diaognois keyword update succesfully' : 'diaognois keyword save succesfully',
        placement: 'topRight'
      });
      this.setState({ saveButton: false, updateButton: false })
    }).catch((err) => {
      notification.error({
        message: 'diaognois keyword not saved',
        placement: 'topRight'
      });
      console.log(err)
    })
  }

  paginationData = async (pageNo) => {
    let params = {
      page: pageNo,
    };
    await axios.get(GET_ALL_DIAGNOSIS_KEYWORD, { params }
    ).then((success) => {
      this.setState({
        diagnosisKeywordList: success.data.data,
        currentPage: pageNo,
        listingLoader: false,
      })
    }).catch((err) => {
      console.log(err);
      this.setState({
        listingLoader: false,
      });
    });
  }

  FormClear() {
    this.diagnosisKeywordRef.current.resetFields();
    this.setState({
      editDiagnoseId: '',
      updateButton: false,
      searchDiagnosis: [],
      specialityList: [],
      saveButton: false,
      diagnosId: '',
      diffrentialDiagnosisId: [],
      specialityId: [],
      classicKeyword: [],
      optionalkeyword: [],
      deleteId: null,
    })
  }

  showPagination() {
    const { currentPage } = this.state;
    const pages = _.range(1, this.state.page + 1);
    return (
      pages && pages.length > 0 ?
        <Pagination
          total={this.state.page}
          pageSize={10}
          onChange={this.paginationData}
          className="my-pagination-diagnosis"
        />
        : null
    )
  }

  render() {
    const { page, currentPage } = this.state
    return (

      <Fragment>

        {
          this.state.listingLoader == true ? (
            <div className="col-12 text-center">{LOADER_RED}</div>
          )
            :
            <>
              <div className="container-fluid add_diagnosis emrfrm" id="create-assessment-details">
                <Form
                  id="diaognisKeyword"
                  ref={this.diagnosisKeywordRef}
                  className="emrfrm"
                //  layout="vertical"
                >
                  <div className='row mt-2'>
                    <h2 className="col-12 text-heading">Diagnosis Keyword</h2>
                    <div className="col-12 mt-1">
                      <div className="form-group hasdata">
                        <Form.Item name="diagnosis">
                          <Select
                            id="diagnosis"
                            name="diagnosis"
                            style={{ width: 100 + '%' }}
                            placeholder=" "
                            showSearch
                            notFoundContent={this.state.searchResultLoaded ? LOADER_BLACK : null}
                            loading={this.state.searchResultLoaded}
                            onSelect={this.onAssessmentSelect}
                            onSearch={this.onAssessmentSearch}
                            className="select-diagnosis"
                            value={this.state.diagnosId}
                            filterOption={false}
                            // dataSource={this.state.diagnosId}
                            autoFocus={this.state.diagnosId != null && this.state.diagnosId != '' ? false : true}
                            showAction={this.state.diagnosId != null && this.state.diagnosId != '' ? [] : ['focus', 'click']}
                          >
                            {this.optionLoad()}
                          </Select>
                        </Form.Item>
                        <label htmlFor="diagnosis">
                          Diagnosis
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-1">
                      <div className="form-group hasdata">
                        <Form.Item name="differential_diagnosis"
                          onInput={(e) => this.autoHeight(e)}
                        >
                          <Select
                            mode="multiple"
                            id="differential_diagnosis"
                            name="differential_diagnosis"
                            style={{ width: 100 + '%' }}
                            placeholder=" "
                            showSearch
                            notFoundContent={this.state.searchResultLoadedDiffrential ? LOADER_BLACK : null}
                            loading={this.state.searchResultLoadedDiffrential}
                            onChange={(value, props) => this.selectDiffrentialDiagnosis(value, props)}
                            onSearch={this.onAssessmentDiffrentialSearch}
                            className=""
                            value={this.state.diffrentialDiagnosisId}
                            filterOption={false}

                          >
                            {this.optionDiffrentialLoad()}
                          </Select>
                        </Form.Item>
                        <label htmlFor="differential_diagnosis">
                          Diffrential Diagnosis
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-1">
                      <div className="form-group hasdata">
                        <Form.Item name="speciality"
                          onInput={(e) => this.autoHeight(e)}
                        >
                          <Select
                            mode="multiple"
                            id="speciality"
                            name="speciality"
                            style={{ width: 100 + '%' }}
                            placeholder=" "
                            showSearch
                            notFoundContent={this.state.seachSpecialityLoaded ? LOADER_BLACK : null}
                            loading={this.state.seachSpecialityLoaded}
                            onChange={(value, props) => this.onSpecialitySelect(value, props)}
                            onSearch={this.onSpecialtySearch}
                            className=""
                            value={this.state.specialityId}
                            filterOption={false}
                          >
                            {this.specialityLoad()}
                          </Select>
                        </Form.Item>
                        <label htmlFor="speciality">
                          Speciality
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-1">
                      <label htmlFor="Classic keyword ml-2" style={{ color: '#d24350', marginLeft: '10px', top: '-18px', fontSize: '0.875rem' }}>Classic keyword </label>
                      <Form.Item name="classicKeyword"
                        onInput={(e) => this.autoHeight(e)}
                      >
                        <Select
                          id="Classic keyword"
                          showSearch={false}
                          className="form-control subjective-field custom-cheif break-word dia-key-height"
                          mode="tags"
                          style={{ border: 'none' }}
                          value={this.state.classicKeyword}
                          onChange={(value, props) => this.selectClassicKeyword(value, props)}
                        // value={this.state.classicKeyword==''? null : this.state.classicKeyword}
                        // autoFocus={this.state.classicKeyword != null && this.state.classicKeyword != '' ? false : true}
                        // showAction={this.state.classicKeyword != null && this.state.classicKeyword != '' ? [] : ['focus', 'click']}

                        >
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12 mt-1">
                      <label htmlFor="Optional keyword ml-2" style={{ color: '#d24350', marginLeft: '10px', fontSize: '0.875rem' }}>Optional keyword </label>
                      <Form.Item name="optionalKeyword"
                        onInput={(e) => this.autoHeight(e)}>
                        <Select
                          id="Optional keyword"
                          showSearch={false}
                          className="form-control subjective-field custom-cheif break-word dia-key-height mt-0"
                          mode="tags"
                          style={{ border: 'none' }}
                          onChange={(value, props, e) => this.selectOptionalkeyword(value, props, e)}
                          value={this.state.optionalkeyword}
                        //  defaultValue={null}
                        >
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12 text-center mt-4 ">
                      <button type="button" className="btn btn-primary text-uppercase confirm-copy float-right " disabled={!this.state.saveButton} onClick={() => this.saveDiagnosisKeyword()}>{this.state.updateButton ? 'UPDATE' : 'SAVE'}</button>
                      <button className="btn btn-outline-secondary  small mr-1 float-right" id="assessment-clear" onClick={() => this.FormClear()} >CLEAR</button>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="row mt-4"></div>
              <div classname="row mt-4"
                className=" table-responsive"
                id="order-set-tab">
                {this.templaterowload()}
              </div>
              <div classname="row mt-4">
                <div className="col-12 pl-4">
                  {
                    this.showPagination()
                  }
                </div>
              </div>
            </>
        }
        <Modal
          title={false}
          visible={this.state.diagnoseDeleteModel}
          onCancel={(e) => this.handleDiagnoseCancel()}
          footer={false}
          closable={false}
        >
          <div className="row mb-3">
            <div className="col-12 text-primary">Are you sure you want to delete diagnose?  </div>
          </div>
          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleDiagnoseCancel(e)}>No</button>
            <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.deleteDiagnoseKeyword(e)}>YES</button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
export default DiagnosisKeyword;
