import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
import { Link } from 'react-router-dom';
import Asideleft from '../../components/aside/asideleft';
import {
    APPLY_ORDERSET,
    ASSESSMENT_DELETE, DELETE_MEDICINE, DELETE_TEST, DRUGS_CONSTANT,
    FAVORITE_ORDERSET, ISALLOWEDITSOAP, LAB_TEST_CONSTANTS,
    LOADER_RED,
    ORDERSET_SEARCH,
    PATIENT_DATA,
    PLAN_TEST_ADVICED_LIST,
    PLAN_TEST_ADVICED_STORE,
    REFERENCE_TYPE,
    SOAP_CONFIGURATION_LIST,
    VIEW_ORDERSET,
    TOGGLE_PRACTICE
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Button, Modal, notification } from 'antd';
import { Fragment } from 'react';
import Templatetests from '../create-template/template-test';
import SubHeader from '../../components/subheader/subheader';
import API_SERVICE from '../../utils/apiService';
import TestForm from "../../components/tests/test-form";
import axios from "axios";
import TestTable from "../../components/tests/test-table";
import { AntSelectLabels } from "../../utils/jQueryLabels";
import { headerService$, practiceService } from '../../utils/rxjs-sharing';

class PlanTestAdviced extends Component {

    constructor(props) {
        super(props);

        this.state = {
            patient: null,
            templateList: [],
            templateDataLoaded: false,
            listOrderCurrentTab: 'list',
            $isTestFavCheckedVal: [],
            templateView: [{
                constants: [],
                diagnosisList: [],
                drugList: [],
                templateInstruction: [],
                testList: []
            }
            ],
            testData: [],
            addedTestList: [],
            testAdvisedDuplicateOrders: [],
            constants: [],
            editData: null,
            editIndex: -1,
            testDataLoaded: false,
            templateId: '',
            idisNaN: true,
            isPracticing: false,
            isPracticingModal: false,
            allowEdit:true
        }

        this.is_favoriteArr = [];
        this.tests_data = [];
    }

    static getDerivedStateFromProps() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        return true;
    }

    componentDidMount() {

        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true
            })
        }

        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData')??[];
        hospitalConfigData = hospitalConfigData.find(item=>item.hospital_id==hospital_id);

        let allowEdit = hospitalConfigData?ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button'):true;

        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient,
            allowEdit
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                this.loadData();
                this.handleSoapFlow();
            }
        });

        // if (this.props.location.state !== undefined) {
        //     this.setState({
        //         patient: this.props?.location?.state?.patient
        //     }, () => {
        //         this.loadData();
        //     })
        // } else {
        //     this.getPatientData();
        // }

        // this.handleSoapFlow();
    }

    loadData = () => {
        let { doctor_id, constants } = this.state;
        const { patient_id, qms_token_id } = this.state.patient;
        const drugsConstantsPromise = Axios.get(DRUGS_CONSTANT);
        const testConstantsPromise = Axios.get(LAB_TEST_CONSTANTS);
        const visitTestPromise = axios.get(PLAN_TEST_ADVICED_LIST({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id, patient_id }))
        this.setState({ testDataLoaded: false })

        Axios.all([drugsConstantsPromise, testConstantsPromise, visitTestPromise])
            .then(Axios.spread((...responses) => {
                const responseOne = responses[0];
                const responseTwo = responses[1];
                const responseThree = responses[2];

                if (responseOne.data.hasOwnProperty("data") === true) {
                    constants = responseOne.data.data;

                }

                if (responseTwo.data.hasOwnProperty("data") === true) {
                    constants['radiologyViews'] = responseTwo.data.data;
                }
                if (responseThree.data.hasOwnProperty("data") === true) {
                    const { constants, details, duplicateOrderTests } = responseThree.data.data;
                    let addedTestList = [];
                    if (details && details.length > 0) {
                        details.map((v, k) => {
                            addedTestList.push(v.test_id);
                        });
                    }
                    this.setState({
                        testData: details,
                        testAdvisedDuplicateOrders: duplicateOrderTests,
                        addedTestList: addedTestList,
                    })
                    // this.setState({
                    //     TestAddListed: details,
                    //     TestAddTable: true
                    // })

                }
                this.setState({
                    testDataLoaded: true,
                    constants
                })

            }))

    }

    getVisitTestList = async _ => {
        const { patient_id, qms_token_id } = this.state.patient;
        // this.setState({testDataLoaded:false})

        const planTestAdvPromise = await axios.get(PLAN_TEST_ADVICED_LIST({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id, patient_id }))
        if (planTestAdvPromise.data.status === 'success') {
            const { constants, details, duplicateOrderTests } = planTestAdvPromise.data.data;
            let addedTestList = [];
            if (details && details.length > 0) {
                details.map((v, k) => {
                    addedTestList.push(v.test_id);
                });
            }
            this.setState({
                testData: details,
                testAdvisedDuplicateOrders: duplicateOrderTests,
                addedTestList: addedTestList,
                // testDataLoaded: true
            })
            // this.setState({
            //     TestAddListed: details,
            //     TestAddTable: true
            // })

        }
    }

    // getPatientData = _ => {
    //     const { match: { params } } = this.props;
    //     const { reference_id } = params;
    //     Axios.get(PATIENT_DATA({ reference_id }))
    //         .then(success => {
    //             const patient = {
    //                 ...success.data.data.results,
    //                 qms_token_id: success.data.data.results.qmsToken.qms_token_id
    //             }
    //             this.setState({
    //                 patient
    //             })
    //         })
    //         .catch(err => console.log(err))
    // }

    handleSoapFlow = _ => {
        if (!LS_SERVICE.has('goToSoapPatientPageList') || LS_SERVICE.get('goToSoapPatientPageList').length === 0) {
            this.handleSoapInit()
        }
    }

    handleSoapInit = async _ => {
        const { hospital_id } = this.state.patient;

        const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
            hospital_id,
            staff_id: LS_SERVICE.get('staff_id'),
            speciality_id: LS_SERVICE.get('speciality_id') || null
        });

        const pageList = [];
        const newList = [];

        const newSoapList = Object.values(soapList).map((data) => {
            return data.sort((a, b) => a.display_ordering - b.display_ordering)
        });

        Object.values(newSoapList).flat().forEach((en) => {
            if (en.display === 'Y') {
                if (!pageList.includes((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name)) {
                    pageList.push((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name);
                    newList.push({
                        'url': en.alternate_url_format,
                        'parent_module': en.parent_module,
                        'module': (en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name
                    })
                }
            }
        });

        LS_SERVICE.set('goToSoapPatientUrl', newList);
        LS_SERVICE.set('goToSoapPatientPageList', pageList);
    }

    templateViewDrugs() {
        if (!this.state.templateView.orderSetDrugData || this.state.templateView.orderSetDrugData.length === 0) {
            return (null)
        }
        return (
            <>
                <h4 className="page-title">Medicines</h4>
                <table id="medicine-lists" className="table table-bordered">
                    <thead>
                    <tr>
                        <th>MEDICINE NAME</th>
                        <th>DOSAGE</th>
                        <th>FREQUENCY</th>
                        <th>ROUTE</th>
                        <th width={120 + 'px'}>NO. OF DAYS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.templateView.orderSetDrugData.map((medicine, index) => {
                        return (
                            <tr key={index}>
                                <td>{medicine.drug_name}</td>
                                <td>
                                    {(medicine.dosage !== 'undefined' && medicine.dosage !== null) ?
                                        this.state.templateView.constants.drugDosage.filter(x => x.id === medicine.dosage)[0].description + ' ' + medicine.dosage_form_text
                                        : '-'
                                    }
                                </td>
                                <td>
                                    {(medicine.drug_frequency !== 'undefined' && medicine.drug_frequency !== null) ?
                                        this.state.templateView.constants.drugFrequency.filter(x => x.id === medicine.drug_frequency)[0].description
                                        : '-'
                                    }
                                </td>
                                <td>
                                    {(medicine.route !== 'undefined' && medicine.route !== null) ?
                                        this.state.templateView.constants.route.filter(x => x.id === medicine.route)[0].description : '-'
                                    }
                                </td>
                                <td>{medicine.number_of_days !== null ? medicine.number_of_days : '-'}</td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
            </>
        )
    }

    templateViewtests() {
        if (!this.state.templateView.orderSetTestData || this.state.templateView.orderSetTestData.length === 0) {
            return (null)
        }
        return (
            <>
                <h4 className="page-title mt-4">Tests</h4>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th width={50 + '%'}>TEST NAME</th>
                        <th>INSTRUCTIONS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.templateView.orderSetTestData.length > 0 ? (
                        this.state.templateView.orderSetTestData.map((test, i) => {
                            return (
                                <tr key={i}>
                                    <td>{test.test_name}</td>
                                    <td>{test.instructions ? test.instructions : ''}</td>
                                </tr>
                            )
                        })) : (<tr><td>No Entries</td></tr>)}
                    </tbody>
                </table>
            </>
        )
    }

    templateViewInstruction() {
        if (!this.state.templateView.orderSetInstruction || this.state.templateView.orderSetInstruction.length === 0) {
            return (null)
        }
        return (
            <>
                <h4 className="page-title mt-4">Instructions</h4>
                <table className="table table-bordered">
                    <thead><tr><th>INSTRUCTIONS</th></tr></thead>
                    <tbody>
                    {this.state.templateView.length > 0 ? (
                        this.state.templateView.orderSetInstruction.map((val1, i) => {
                            return (
                                <tr key={i}>
                                    <td>{val1.instruction}</td>
                                </tr>
                            )
                        })) : (<tr><td>No Entries</td></tr>)}
                    </tbody>
                </table>
            </>
        )
    }

    openView(no) {
        if (this.state.viewNo === no) {
            this.setState({ viewNo: 0, templateView: [] });
        }
        else {
            this.setState({ viewNo: no, templateView: [] });
            this.templateOnView(no)
        }
    }

    templateOnView = (templateId) => {
        Axios.post(VIEW_ORDERSET, qs.stringify({ orderSetId: templateId }))
            .then(success => {
                this.setState({ templateView: success.data });
            })
            .catch(err => console.log(err))
    }

    applyOrderSet = (orderset_id, removeallergic = '') => {
        const { patient_id, qms_token_id } = this.state.patient;

        orderset_id = orderset_id === undefined ? this.state.selectedOrderSetId : orderset_id;

        Axios.get(APPLY_ORDERSET({ orderset_id, patient_id, reference_type: REFERENCE_TYPE, reference_id: qms_token_id }))
            .then(success => {
                if (success.data.status === "success") {
                    let { listOrderCurrentTab, templateList } = this.state;
                    listOrderCurrentTab = listOrderCurrentTab === 'list' ? 'order' : 'list';

                    const clickedIndex = LS_SERVICE.get('order_set_clicked');

                    templateList[clickedIndex].checked = true;

                    this.setState({
                        templateList,
                        listOrderCurrentTab
                    }, ()=>{this.getVisitTestList()})
                }
            })
            .catch(error => console.log(error.response))
    }

    templateOnFavourite = (val, index) => {
        if (this.is_favoriteArr[index]) {
            this.is_favoriteArr[index] = 0
        }
        else {
            this.is_favoriteArr[index] = 1
        }

        this.setState({ $isTestFavCheckedVal: this.is_favoriteArr });
        Axios.post(FAVORITE_ORDERSET, qs.stringify({ orderSetId: parseInt(val) }))
            .then(success => {
                this.setState({
                    $isTestFavCheckedVal: this.is_favoriteArr
                });
                notification.success({
                    message: 'Record Updated Successfully',
                    placement: 'topRight'
                });
            })
            .catch(err => {
                console.log(err)
                if (!this.is_favoriteArr[index]) {
                    this.is_favoriteArr[index] = 1
                }
                else {
                    this.is_favoriteArr[index] = 0
                }
                this.setState({
                    $isTestFavCheckedVal: this.is_favoriteArr
                });
            })
    }

    handleOrderSetClick = (e, data, checkIndex) => {
        e.preventDefault();

        const { isPracticing } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        LS_SERVICE.set('order_set_clicked', checkIndex);
        this.setState({
            selectedOrderSetId: data.orderSetId,
        }, this.applyOrderSet(data.orderSetId));
    }

    templateOnListLoad = () => {
        const PARAMS = {
            searchTerm: '',
            doctor_Id: LS_SERVICE.get('staff_id'),
            list: 'all',
            filter_hospital_id: LS_SERVICE.get("slot_hospital_id"),
            filter_speciality_id: LS_SERVICE.get("speciality_id"),
            hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N'
        }

        Axios.post(ORDERSET_SEARCH, qs.stringify(PARAMS))
            .then(success => {
                success.data.data.orderset.forEach((data, i) => {
                    this.is_favoriteArr.push(data.is_favorite);
                })
                this.setState({
                    templateList: success.data.data.orderset,
                    $isTestFavCheckedVal: this.is_favoriteArr,
                    templateDataLoaded: true
                })
            }).catch(err => console.log(err))
    }

    handleListorderTab = e => {
        e.preventDefault();

        let { listOrderCurrentTab } = this.state;

        listOrderCurrentTab = listOrderCurrentTab === 'list' ? 'order' : 'list';

        this.setState({
            listOrderCurrentTab
        }, () => {
            const { listOrderCurrentTab, templateDataLoaded } = this.state;

            if (listOrderCurrentTab === 'order' && !templateDataLoaded) {
                this.templateOnListLoad()
            }
        })
    }

    handlePlanAdvicedContinue = _ => {
        const { patient, isPracticing } = this.state;
        const { qms_token_id, hospital_id, patient_id } = patient;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
        const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
        let index = pageListArr.indexOf('prescribed_tests');
        index++;
        if (index < pageListArr.length && !isTodayVisitArr.includes(qms_token_id)) {
            let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
            soapurl = soapurl.replace("HOSPITALID", hospital_id);
            soapurl = soapurl.replace("REFID", qms_token_id);
            soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
            soapurl = soapurl.replace("PATID", patient_id);
            this.props.history.push({
                pathname: soapurl,
                state: { patient }
            })
        } else {
            this.props.history.push({
                pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                state: { patient }
            })
        }
    }

    handleModalPopup = (e, popupName, data = null, actionType = '', objId = '', patientDocs) => {
        if (e !== null)
            e.preventDefault();
        this.setState({
            [popupName]: !this.state[popupName]
        }, () => {
            const { viewDocumentModal } = this.state;

            this.setState({
                actionText: "Add", editData: null, editIndex: -1
            }, () => {
                // const { addAssessmentModal, addMedicinesModal, addInstructionModal, deleteConfirmModal } = this.state;
                // if (addAssessmentModal) {

                // }
                const { deleteConfirmModal, deleteAssessmentModal, showAssessmentModal, showMedicineModal, showInstructionModal, showTestModal } = this.state;

                if (showMedicineModal) {
                    if (actionType === 'editMedicine') {
                        this.setState({
                            actionText: "Edit",
                            editData: this.state.drugsData[objId],
                            editIndex: objId
                        })
                    }
                }

                if (deleteConfirmModal) {

                    // For Tests
                    if (actionType === 'deleteTest') {
                        this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete test?' });
                    }

                }

            })


        })

    }

    updateTestData = async (data) => {
        let testData = this.state.testData;
        let addedTestList = this.state.addedTestList;
        /* data.selected=true;
         let addedAssessmentList=[];*/
        var index = data.i;
        if (index == -1)
            index = testData == null ? 0 : testData.length;
        testData[index] = data;
        addedTestList = testData.map((v, k) => { return v.test_id });
        /*if (data.i != -1) {
            testData[data.i] = data;
        }
        else {
            testData[testData.length] = data
            addedTestList.push(data.test_id);
        }*/

        const PARAMS = {
            tests_data: [data],
            reference_type: REFERENCE_TYPE,
            reference_id: this.state.patient.qms_token_id,
            patient_id: this.state.patient.patient_id
        }


        const testFormPromise = await Axios.post(PLAN_TEST_ADVICED_STORE, qs.stringify(PARAMS))
        this.setState({ editData: null, editIndex: -1, showTestModal: false, testData, addedTestList })
        if (testFormPromise.data) {

            this.getVisitTestList();
            notification.success({
                message: testFormPromise.data.message,
                placement: 'topRight'
            })
            /*this.setState({
                formModeEdit: false,
                isProcessing: false
            })*/
        }
    }

    handleOnItemDelete = (e, actionType, objId) => {
        e.preventDefault();

        var stateVariable = null;
        var stateAddedVariable = null;
        var propKey = null;
        var deleteUrl = null;
        // For Test
        if (actionType === 'deleteTest') {
            stateVariable = 'testData';
            stateAddedVariable = 'addedTestList';
            propKey = 'test_id';
            deleteUrl = DELETE_TEST;
        }

        let dataArray = this.state[stateVariable];
        var deleteObj = dataArray[objId];
        var deleteId = deleteObj.id;
        if (actionType === 'deleteMedicine')
            deleteId = deleteObj.prescription_drug_id;

        this.setState({
            spin_loading: true
        })
        dataArray.splice(objId, 1);
        let addedArray = [];
        dataArray.map((v, k) => {
            addedArray.push(v[propKey])
        })
        this.setState({
            deleteConfirmModal: false,
            [stateVariable]: dataArray,
            [stateAddedVariable]: addedArray
        });

        Axios.get(deleteUrl({ id: deleteId }))
            .then(success => {
                this.getVisitTestList();
                this.setState({
                    spin_loading: false
                })
            })
            .catch(err => console.log(err))
    }

    clearEdit = () => {
        this.setState({
            editData: null,
            editIndex: -1
        })
    }

    enablePractice = async e => {
        e.preventDefault();
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
          hospital_id: this.state.hospital_id,
          practice_session: 'start'
        });
    
        let is_practicing;
        if (practice.data && practice.data.is_practicing === 'Y') {
          is_practicing = true;
          LS_SERVICE.set('practicing', {
            status: true,
            hospital_id: this.state.hospital_id
          });
        } else {
          is_practicing = false;
        }
        this.setState({
            isPracticing: is_practicing,
        }, () => {
            this.handleModalPopup(null, 'isPracticingModal');
        });
    }

    handlePracticeModal = data => {
        this.handleModalPopup(null, data);
    }

    render() {

        const { patient, isPracticing } = this.state;

        return (
            <>
                {/* Page sidebar */}
                {(patient != undefined && patient.patient_id != undefined) ? <Asideleft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                {/* Page content */}
                <div className="content-wrapper" data-select2-id={11}>
                    <div className="row" id="data-preloader" style={this.state.testDataLoaded ? { display: 'none' } : null}>
                        <div className="col-12">
                            <div className="text-center">
                                {LOADER_RED}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid" data-select2-id={10} style={!this.state.testDataLoaded ? { display: 'none' } : null}>
                        {/*<div className="row" id="data-preloader" style={{ display: 'none' }}>
                            <div className="col-12">
                                <div className="text-center">
                                    <div className="spinner red"><div className="bounce1" /><div className="bounce2" /><div className="bounce3" /></div>
                                </div>
                            </div>
                        </div>*/}
                        <div className=" emrfrm row" id="main-page-container">
                            {/* SOAP Bar */}

                            {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                                <SubHeader patient={patient} />
                                : null}

                            {/* <div className="col-12 mb-3">
                                <ul className="nav nav-pills keyfind_nav">
                                    <li className="nav-item"><Link to={{ pathname: `/patient/todays-visit/subjective${REFERENCE_TYPE}/${qms_token_id}`, state: { patient } }} className="nav-link   " role="tab" aria-selected="true">Subjective</Link></li>
                                    <li className="nav-item"><Link to={{ pathname: `/patient/todays-visit/objective/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`, state: { patient } }} className="nav-link " role="tab" aria-selected="false">Objective</Link></li>
                                    <li className="nav-item"><Link to={{ pathname: `/patient/todays-visit/assessment/key-findings/list/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`, state: { patient } }} className="nav-link  " role="tab" aria-selected="false">Assessment</Link></li>
                                    <li className="nav-item"><Link to={{ pathname: `/patient/todays-visit/plan/prescription/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`, state: { patient } }} className="nav-link active " role="tab" aria-selected="false">Plan</Link></li>
                                    <li className="nav-item"><Link to={{ pathname: `/patient/todays-visit/${REFERENCE_TYPE}/${qms_token_id}`, state: { patient } }} className="nav-link gohome"><i className="icon-home"></i></Link></li>
                                    <li className="nav-item goback"><a href="#" className="btn btn-outline-secondary px-4 small text-uppercase">BACK</a></li>
                                </ul>
                            </div> */}
                            <div className="col-12">
                                <h1 className="page-title">Plan - Test Advised</h1>
                                <div>
                                    <ul className="nav nav-pills justify-content-center myhealthcare-tabs">
                                        <li className="nav-item">
                                            <a href="!#" className={`nav-link ${this.state.listOrderCurrentTab === 'list' ? 'active' : ''}`}
                                               onClick={this.handleListorderTab}>List</a>
                                        </li>
                                        <li className="nav-item position-relative">
                                            <a href="!#" className={`nav-link ${this.state.listOrderCurrentTab === 'order' ? 'active' : ''}`}
                                               onClick={this.handleListorderTab}>Order Set</a>
                                            {/*<a href="#" className="copy-btn btn btn-default">
                                                <img src="/images/ic-copypriscription.png" alt="Copy" className="img-fluid" />
                                            </a>*/}
                                        </li>
                                    </ul>
                                    <div className="tab-content clearfix mb-5 pb-5">
                                        <div className="tab-pane fade show active">
                                            {this.state.listOrderCurrentTab === 'list' ? (
                                                <>
                                                    {patient != undefined && patient.patient_id != undefined && patient.patient_id != null && this.state.testDataLoaded ?

                                                        // <Templatetests isPlanAdviced={true} patient={patient} />
                                                        <>
                                                            <TestForm formType={"soap-full"} actionText={this.state.actionText} updateData={this.updateTestData}
                                                                      autofocus={true} editData={this.state.editData} editIndex={this.state.editIndex}
                                                                      addedTestList={this.state.addedTestList} constants={this.state.constants}
                                                                      testAdvisedDuplicateOrders={this.state.testAdvisedDuplicateOrders} clearEdit={this.clearEdit}
                                                                      isPracticing={isPracticing} practiceModal={this.handlePracticeModal} allowEdit={this.state.allowEdit}
                                                                      hospital_id={this.state.hospital_id}
                                                            />
                                                            <br />
                                                            <TestTable testData={this.state.testData} viewType={'soap-view'} showAction={this.state.allowEdit?true:false} showSelectCheck={false}
                                                                       constants={this.state.constants} updateSelection={this.handleSelectCheck} handlePopUp={this.handleModalPopup}
                                                                       isPracticing={isPracticing} practiceModal={this.handlePracticeModal}
                                                            />
                                                        </>
                                                        : null}

                                                    <div className="fixed-cta">
                                                        <button type="button" className="btn btn-primary px-4 medicine-continue-button"
                                                                onClick={this.handlePlanAdvicedContinue}>CONTINUE</button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={`w-100 align-items-center justify-content-center mt-4 ${!this.state.templateDataLoaded ? 'd-flex' : 'd-none'}`}>
                                                        {LOADER_RED}
                                                    </div>
                                                    <div className="table-responsive">
                                                        {this.state.templateDataLoaded ? (
                                                            <table className="table table-default">
                                                                <thead>
                                                                <tr>
                                                                    {this.state.allowEdit?<th width={50} />:null}
                                                                    <th>Order set name</th>
                                                                    <th>Medicines</th>
                                                                    <th>Tests</th>
                                                                    <th width={80}>View</th>
                                                                    <th width={80}>Favourite</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody id="template-list">
                                                                {this.state.templateList.map((template, i) => {
                                                                    return (
                                                                        <Fragment key={i}>
                                                                            <tr>
                                                                                {this.state.allowEdit?
                                                                                    <td>
                                                                                        <div className="custom-control custom-checkbox orderset-select">
                                                                                            <input type="checkbox" className="custom-control-input" onChange={e => this.handleOrderSetClick(e, template, i)}
                                                                                                   name="plan_orderset" id={`orderset_${template.orderSetId}`} checked={template.checked} />
                                                                                            <label className="custom-control-label" htmlFor={`orderset_${template.orderSetId}`}></label>
                                                                                        </div>
                                                                                    </td>
                                                                                    :null}
                                                                                <td>{template.orderSetName}</td>
                                                                                <td>{template.drug_count} Medicines</td>
                                                                                <td>{template.test_count} Test</td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <Button type="link p-0" className="nostyle-link view_order_set" onClick={() => this.openView(template.orderSetId)}><i className="icon_view"></i></Button>
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <Button type="link p-0" className="nostyle-link" onClick={(e) => this.templateOnFavourite(template.orderSetId, i)}>
                                                                                        <i className={this.state.$isTestFavCheckedVal[i] === 1 ? 'icon_star favourite' : 'icon_star star'} ></i>
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="view_details" style={this.state.viewNo === template.orderSetId ? { display: 'table-row' } : { display: 'none' }}>
                                                                                <td colSpan="6" className="inner_table">
                                                                                    <div className="text-center">{this.state.templateView.length === 0 ? LOADER_RED : null}</div>
                                                                                    {this.templateViewDrugs()}
                                                                                    {this.templateViewtests()}
                                                                                    {this.templateViewInstruction()}
                                                                                </td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        ) : null}
                                                    </div>

                                                </>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* For Modal Delete Confirm  */}
                <Modal
                    title={false}
                    visible={this.state.deleteConfirmModal}
                    onCancel={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}>CANCEL</button>
                        <button type="button" className="btn btn-primary px-5" onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}>DELETE</button>
                    </div>
                </Modal>

                {/** || isPracticing Modal */}
                <Modal
                    title={false}
                    closable={false}
                    visible={this.state.isPracticingModal}
                    footer={false}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                            <h6>Do you want to Start Practice?</h6>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
                        <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default PlanTestAdviced;
