class MyHcNavigation {
    navigation = [];
    relative_navigation = [];
    
    save(pathname) {
        if (window.location.href !== this.navigation[this.navigation.length - 1]) {
            this.navigation[this.navigation.length] = window.location.href;
        }
        if (pathname !== this.relative_navigation[this.relative_navigation.length - 1]) {
            this.relative_navigation[this.relative_navigation.length] = pathname;
        }
        return;
    }
    
    referer(type='absolute') {
        if (type === 'relative') {
            return this.relative_navigation[this.relative_navigation.length - 2];
        }
        return this.navigation[this.navigation.length - 2];
    }
}

const NavHistory = new MyHcNavigation();

export default NavHistory;