import React, { Component } from 'react';
import { Select, Modal, Button, notification } from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { Fragment } from 'react';
import { jQueryLabels, AntSelectLabels, ClearAntList, ClearSelectSelectionItem } from '../../utils/jQueryLabels';
import { REFERENCE_TYPE, TEMPLATE_SEARCH, MEDICINES_GETALL_CURRENT_MEDICINES, VIEW_TEMPLATE, CURRENT_MEDICINES_STOP } from '../../utils/constant';
import AsideLeft from '../../components/aside/asideleft';
const { Option } = Select;
class MedicineAlertPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentMedsLists: [],
            presMedicine: [],
            duplicateMedModel: false,
            isSelect: true,
            templateListmedicine: [],
            patient: [],
            showDupMed: []
        }

    }

    componentDidMount = async () => {
        await this.setState({
            patient: this.props.patient,
            templateListmedicine: this.props.templateListmedicine,
        })
        this.checkPrescribedMed();
    }


    componentWillReceiveProps = async (nextProps) => {
        await this.setState({
            patient: nextProps.patient,
            templateListmedicine: nextProps.templateListmedicine,
        });
        this.checkPrescribedMed();
    }

    checkPrescribedMed = async () => {
        var patient = this.state.patient
        const { patient_id, qms_token_id } = patient;
        let templateMeds = this.state.templateListmedicine;
        if (typeof (patient_id) == 'undefined') {
            return
        }
        await axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id, reference_type: 0, reference_id: qms_token_id })).then(success => {
            let currentMedsList = success.data.data.details.current_medicines;
            this.setState({
                currentMedsLists: currentMedsList,
            })
        });
        const { currentMedsLists } = this.state
        let dupMedicine = [];
        currentMedsLists.map((med, i) => {

            templateMeds.map((element) => {
                if (element.drug_id == med.drug_id && element.selected && med.prescriptionDrugActive == 'Y')
                    dupMedicine.push(med)
            })
        })
        const ids = dupMedicine.map(o => o.drug_id)
        const filteredMed = dupMedicine.filter(({ drug_id }, index) => !ids.includes(drug_id, index + 1))
        if (dupMedicine.length > 0) {
            this.setState({ presMedicine: dupMedicine, showDupMed: filteredMed, duplicateMedModel: true })
        }
    };

    async handleMedPrecribedCancel() {
        await this.setState({
            duplicateMedModel: false,
            isSelect: false
        })
        this.props.updateSelectState(this.state.isSelect);
    }
    stopCurentMeds() {
        const { presMedicine } = this.state;
        let stopDrugIds = presMedicine.map((drugDetailsId) => { return drugDetailsId.prescriptionDrugId })
        let doctor_id = LS_SERVICE.get('staff_id');
        let params = {
            stopDrugIds: stopDrugIds,
        }
        let stopPrescriptionDrugId = null;
        axios.put(CURRENT_MEDICINES_STOP({ stopPrescriptionDrugId, doctor_id }), params).then(res => {
            this.setState({ duplicateMedModel: false })
            notification.success({
                message: 'drug stop succesfully',
                placement: 'topRight'
            });
        }).catch(
            err => {
                notification.error({
                    message: 'failed to stop medicine',
                    placement: 'topRight'
                });
                console.log(err);
            }
        );
    }


    render() {
        return (
            <Fragment>
                <Modal
                    title="Medicine Prescribed Alert"
                    visible={this.state.duplicateMedModel}
                    onCancel={(e) => this.handleMedPrecribedCancel()}
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        {
                            this.state.showDupMed && this.state.showDupMed.length > 0 ?
                                <div className="col-12 text-primary"><span className="font-weight-bold">
                                    {this.state.showDupMed.map((drug, i) => {
                                        if (this.state.showDupMed.length == i + 1) {
                                            return drug.drug_name + "  "
                                        } else {
                                            return drug.drug_name + "  , "
                                        }
                                    }
                                    )}
                                </span>  medicines is already prescribed to the patient. Are you sure you want to stop the medication and prescribe again? </div>
                                : null
                        }

                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleMedPrecribedCancel(e)}>No</button>
                        <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.stopCurentMeds(e)}>YES</button>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}
export default MedicineAlertPopup;