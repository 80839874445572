import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { PROFILE, SOAP_CONFIGURATION_LIST, DOSE_RULE, SOAP_CONFIGURATION_STORE, LOADER_RED, LOADER, USERTYPES } from '../../utils/constant';
import { Modal, notification, Radio } from 'antd';

class SoapListComponent extends Component {
  state = {
    profileData: [],
    soapListData: [],
    soapStoreData: [],
    soapDataLoaded: false,
    configuredData: [],
    addScreenSubj: false,
    addScreenObj: false,
    isScreenMsgModalReq: false,
    doseRule: 3,
    isPaediatrics: 0,
    isObsGynaeDoctor: 0
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  componentDidMount() {
    // this.Speciality();
    // this.SoapList();
    // this.SoapStore();
    const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor;
    Axios.post(PROFILE({ is_doctor }), qs.stringify())
      .then(response => {
        LS_SERVICE.set('isPaediatrician', response.data.data.paediatric)
      }).catch(error => console.log(error))
    this.loadData();
  }

  loadData = () => {
    // const specialityUrl = PROFILE;
    // const soapListUrl = SOAP_CONFIGURATION_LIST;
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    const speciality_id = LS_SERVICE.get('speciality_id');
    const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor;

    const specialityPromise = Axios.post(PROFILE({ is_doctor }));
    const soapListPromise = Axios.post(SOAP_CONFIGURATION_LIST, qs.stringify({ staff_id, hospital_id, speciality_id }));
    const soapDoseRule = Axios.post(DOSE_RULE, qs.stringify({ staff_id, hospital_id, speciality_id }));

    Axios.all([specialityPromise, soapListPromise, soapDoseRule])
      .then(Axios.spread((...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responseThree = responses[2];


        const confInfo = Object.values(responseTwo.data);
        const ruleInfo = Object.values(responseThree.data);
        const paediaInfo = Object.values(responseOne.data);



        if (confInfo[0] != undefined && confInfo[0][0] != undefined) {
          if (confInfo[0][0]['display'] === 'Y') {
            this.setState({
              addScreenSubj: true
            });
          }
        }
        if (confInfo[1] != undefined && confInfo[1][0] != undefined) {
          if (confInfo[1][0]['display'] === 'Y') {
            this.setState({
              addScreenObj: true
            });
          }
        }

        this.setState({
          doseRule: ruleInfo[0] ? ruleInfo[0].rule : 3
        });



        this.setState({
          profileData: responseOne?.data?.data,
          soapListData: confInfo,
          soapDataLoaded: true,
          // configuredData :[...soapListData[0],...soapListData[1],...soapListData[2],...soapListData[3]]
        })
        // }, () => console.log(Object.values(this.state.soapListData)) ) 
        const is_paediatrician = LS_SERVICE.get('isPaediatrician');
        if (is_paediatrician == 1) {
          this.setState({ isPaediatrics: 1 });
        }
        if (LS_SERVICE.get('isObsGynaeDoctor') == 1) {
          this.setState({ isObsGynaeDoctor: 1 });
        }

      }))
  }


  handleScreenCheck = (e) => {
    const { soapListData } = this.state;

    const target = e.target.dataset.id;

    const data = soapListData

    data.forEach(iter => iter.forEach((item) => {
      if (item.id == target) {
        // console.log(item)
        // item.print = item.print == 'Y' ? 'N' : "Y"
        item.display = item.display == 'Y' ? 'N' : "Y"
        if (item.display === 'N') {
          item.print = 'N';
        }
        if (item.parent_module == "paediatric" || item.parent_module == "obsgynae") {
          if (item.display == 'Y') {
            data[1].forEach(item => {
              //item.print = 'N';
              if (LS_SERVICE.get('isObsGynaeDoctor') == 1 && item.parent_module == "obsgynae") {
                /* item.display = isChecked; */
              }
              else if (LS_SERVICE.get('paediatric_doctor') == 1 && item.parent_module == "paediatric") {
                /* item.display = isChecked; */
              }
              else if (item.parent_module == "objective") {
                item.display = 'Y';
              }
              else {
                item.display = 'N'
              }
            })
            this.setState(prev => ({
              addScreenObj: true
            }))
          }
        }
      }
    }))

    this.setState({
      soapListData: data
    })


  }

  handlePrintCheck = (e) => {

    const { soapListData, addScreenObj, addScreenSubj } = this.state;

    const target = e.target.dataset.id;

    const data = soapListData

    if (e.target.dataset.parent == 'subjective' && !addScreenSubj || e.target.dataset.parent == 'objective' && !addScreenObj) {
      this.setState({
        isScreenMsgModalReq: true
      })
      return;
    }

    data.forEach(iter => iter.forEach((item) => {
      if (item.id == target) {
        item.print = item.print == 'Y' ? 'N' : "Y"
        if (item.display === 'N') {
          item.display = "Y";
        }
        if (item.parent_module == "paediatric" || item.parent_module == "obsgynae") {
          if (item.print == 'Y') {
            data[1].forEach(item => {
              //item.print = 'N';
              if (LS_SERVICE.get('isObsGynaeDoctor') == 1 && item.parent_module == "obsgynae") {
                /* item.display = isChecked; */
              }
              else if (LS_SERVICE.get('paediatric_doctor') == 1 && item.parent_module == "paediatric") {
                /* item.display = isChecked; */
              }
              else if (item.parent_module == "objective") {
                item.display = 'Y';
              }
              else {
                item.display = 'N'
              }
            })
            this.setState(prev => ({
              addScreenObj: true
            }))
          }
        }
      }
    }))
    this.setState({
      soapListData: data
    })

  }

  handleSave = () => {
    const data = [...this.state.soapListData[0], ...this.state.soapListData[1], ...this.state.soapListData[2], ...this.state.soapListData[3]];
    this.setState({
      isFormSaving: true
    })

    const configurationData = data.map((item) => (
      {
        print: item.print,
        display: item.display,
        emr_module_id: item.id,
        status: "active",
        print_ordering: item.print_ordering,
        display_ordering: item.display_ordering

      }
    ))

    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    const speciality_id = LS_SERVICE.get('speciality_id');

    const PARAMS = {
      configurationData,
      staff_id,
      hospital_id,
      speciality_id,
      configuration_level: "doctor",
      doseRule: this.state.doseRule,
    };

    Axios.post(SOAP_CONFIGURATION_STORE, qs.stringify(PARAMS))
      .then(res => {
        notification.success({
          message: res.data.message,
          placement: 'topRight'
        })
        this.setState({
          isFormSaving: false
        })
      })
      .catch(error => {
        // if (error && error.response && error.response.status === 401) {
        //     LS_SERVICE.clear();
        //     this.props.history.push("/");
        // }
      })
  }

  handleScreenFullModule = (e) => {

    const { soapListData } = this.state
    const data = soapListData;
    const isChecked = e.target.checked ? 'Y' : 'N';

    if (e.target.name == 'subjective') {
      data[0].forEach(item => {
        item.print = 'N';
        item.display = isChecked;
      })
      this.setState(prev => ({
        addScreenSubj: !prev.addScreenSubj
      }))
    } else {
      data[1].forEach(item => {
        item.print = 'N';
        if (LS_SERVICE.get('isObsGynaeDoctor') == 1 && item.parent_module == "obsgynae") {
          item.display = isChecked;
        }
        else if (LS_SERVICE.get('paediatric_doctor') == 1 && item.parent_module == "paediatric") {
          item.display = isChecked;
        }
        else if (item.parent_module == "objective") {
          item.display = isChecked;
        }
        else {
          item.display = 'N'
        }
      })
      this.setState(prev => ({
        addScreenObj: !prev.addScreenObj
      }))
    }

    this.setState({
      soapListData: data
    });
  }

  isItemChecked(label) {
    if (label === 'subjective') {
      return this.state.addScreenSubj;
    }
    if (label === 'objective') {
      return this.state.addScreenObj;
    }
    return false;
  }

  handleDoseChange = (e) => {

    if (e.target.value != '' || e.target.value != null) {
      this.setState({ doseRule: e.target.value });
    }
  }

  render() {

    const { soapListData, soapDataLoaded, doseRule, isPaediatrics, isObsGynaeDoctor } = this.state;

    const soapDataFirstRow = [soapListData[0], soapListData[1]] // 0 - subjective, 1 - objective 
    const soapDataSecondRow = [soapListData[2], soapListData[3]] // 2 - assessment, 3 - plan

    return (
      <>
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mb-2 px-3">
              <h1 className="page-title">Speciality Level Configuration</h1>
            </div>
            <form className="col-12 emrfrm px-3" id="dashboard" autoComplete="off">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group hasdata">
                    <select className="form-control" name="speciality" id="speciality" disabled="disabled">
                      {this.state.soapDataLoaded ?
                        (this.state.profileData.specialities.map((label, idx) => <option value={label} key={idx}>{label}</option>))
                        : null}
                    </select>
                    <label htmlFor="speciality" className="required">Speciality</label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group hasdata">
                    <select className="form-control" name="doctors" id="doctors" disabled="disabled">
                      <option label value={this.state.profileData.id} selected>{this.state.profileData.name}</option>
                    </select>
                    <label htmlFor="location" className="required">Doctor</label>
                  </div>
                </div>
              </div>

              {soapDataLoaded ? (<div id="module_list">
                <div className="row soap_sortable" id="level1">
                  {soapDataFirstRow?.map((iterable, i) => {
                    return (
                      <Fragment key={i}>
                        <div className={`col-auto pr-0 ${i === 1 ? "pl-0" : ""}`}>
                          <div className="soap_card_no p-3">{iterable[0].order}</div>
                        </div>
                        <div className={`col pl-0 ${i === 0 ? "pr-0" : ""}`}>
                          <div className="p-4 widget-panel" id="widget-1">
                            <div className="card rounded-0 widget-card" id="widget-card-1">
                              <div className="pl-3 py-2 mr-3">
                                <strong className="text-capitalize">{iterable[0].parent_module}</strong>
                                <div className="float-right">
                                  <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                    <input type="checkbox"
                                      disabled={iterable[0].print_configuration_level == 'hospital'}
                                      className="custom-control-input"
                                      checked={this.isItemChecked(iterable[0].parent_module)}
                                      id={`${iterable[0].parent_module}_ats`}
                                      name={iterable[0].parent_module}
                                      onClick={this.handleScreenFullModule}
                                    />
                                    <label className="custom-control-label" htmlFor={`${iterable[0].parent_module}_ats`}>Add to screen</label></div>
                                </div>
                              </div>
                              <div className="card-body p-0">
                                <ul className="list-group list-group-flush  subjective" id="accordion">
                                  {iterable.sort((a, b) => a.id > b.id ? 1 : -1).map((item, i) => {
                                    if (item.parent_module != "paediatric" && item.parent_module != "obsgynae") {
                                      return (<>
                                        <li className="list-group-item ui-state-default" key={item.id} data-printorder={1} data-displayorder={1} data-print="Y" data-display="Y" data-moduleid={1}>
                                          <span className="ordno">{i + 1}.</span><strong>{item.display_name}</strong>
                                          <div className="float-right">
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                              <input type="checkbox"
                                                disabled={item.print_configuration_level == 'hospital'}
                                                onClick={this.handlePrintCheck} checked={item.print === 'Y'}
                                                className="custom-control-input subjectivecheckBoxClass"
                                                data-id={item.id} id={`print_${item.id}`}
                                                data-childid={1} data-parent={`${iterable[0].parent_module}`}
                                              />
                                              <label className="custom-control-label" htmlFor={`print_${item.id}`}>Add to Print</label></div>
                                          </div>
                                        </li>
                                      </>)
                                    }
                                  })}
                                  {i === 1 && isPaediatrics === 1 ?
                                    <>
                                      <li className="list-group-item ui-state-default">
                                        <span className="ordno">5.</span><strong>
                                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                            Paediatrics
                                                                                    </a></strong>

                                        <div id="collapse_1" className="collapse" role="tabpanel" aria-labelledby="collapse_1">
                                          <ul className="paediatrics_list">
                                            {soapListData[1]?.map((val, j) => {
                                              if (val.name == "child_development" || val.name == "vaccination_chart") {
                                                return (<>
                                                  <li className="list-group-item ui-state-default" key={val.id} data-printorder={1} data-displayorder={1} data-print="Y" data-display="Y" data-moduleid={1}>
                                                    <strong>{val.display_name}</strong>
                                                    <div className="float-right">
                                                      <div className="custom-control custom-checkbox custom-control-inline">

                                                        <input disabled={val.print_configuration_level == 'hospital'}
                                                          type="checkbox"
                                                          onChange={this.handleScreenCheck}
                                                          checked={val.display == 'Y'}
                                                          data-check={val.display + val.print}
                                                          className="custom-control-input assessmentscreen"
                                                          id={`screen_${val.id}`}
                                                          data-id={val.id} name="assessment_add_to_screen"
                                                          data-parent={`${val.parent_module}`}
                                                        />

                                                        <label className="custom-control-label" htmlFor={`screen_${val.id}`}>Add to Screen</label>
                                                      </div>
                                                      <div className="custom-control custom-checkbox custom-control-inline mr-0">

                                                        <input
                                                          disabled={val.print_configuration_level == 'hospital'}
                                                          type="checkbox"
                                                          onChange={this.handlePrintCheck}
                                                          checked={val.print == 'Y'}
                                                          className="custom-control-input assessmentcheckBoxClass"
                                                          id={`print_${val.id}`}
                                                          data-id={val.id} name="assessment_add_to_print"
                                                          data-parent={`${val.parent_module}`}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`print_${val.id}`}>Add to Print</label>

                                                      </div>
                                                    </div>
                                                  </li>
                                                </>)
                                              }
                                            })}



                                            <li className="list-group-item ui-state-default">
                                              <strong>Pediatric Dose Calculator</strong>
                                              <div className="pl-4">
                                                <div className="row">
                                                  <div className="col-6">
                                                    <div className="custom-control custom-radio mt-2">
                                                      <input type="radio" id="frieds_rule" name="dose" value="1" defaultChecked={doseRule == 1} className="custom-control-input" onChange={this.handleDoseChange} />
                                                      <label className="custom-control-label font-weight-semibold" htmlFor="frieds_rule">Fried’s Rule</label>
                                                    </div>
                                                    <div className="pl-4"><small>Dose = [Age (month)/150] * Adult Dose</small></div>
                                                  </div>
                                                  <div className="col-6">
                                                    <div className="custom-control custom-radio mt-2">
                                                      <input type="radio" id="youngs_rule" name="dose" value="2" defaultChecked={doseRule == 2} className="custom-control-input" onChange={this.handleDoseChange} />
                                                      <label className="custom-control-label font-weight-semibold" htmlFor="youngs_rule">Young’s Rule</label>
                                                    </div>
                                                    <div className="pl-4"><small>Dose =  (Age in years/ Age in years +12) * Adult Dose</small></div>
                                                  </div>
                                                  <div className="col-6">
                                                    <div className="custom-control custom-radio mt-2">
                                                      <input type="radio" id="clarkes_rule" name="dose" value="3" defaultChecked={doseRule == 3} className="custom-control-input" onChange={this.handleDoseChange} />
                                                      <label className="custom-control-label font-weight-semibold" htmlFor="clarkes_rule">Clarke's Rule</label>
                                                    </div>
                                                    <div className="pl-4"><small>Dose = (Body Weight in Kgs/ 70) * Adult Dose</small></div>
                                                  </div>
                                                  <div className="col-6">
                                                    <div className="custom-control custom-radio mt-2">
                                                      <input type="radio" id="dose_bsa" name="dose" value="4" defaultChecked={doseRule == 4} className="custom-control-input" onChange={this.handleDoseChange} />
                                                      <label className="custom-control-label font-weight-semibold" htmlFor="dose_bsa">Dose based on BSA</label>
                                                    </div>
                                                    <div className="pl-4"><small>(Child's BSA/ Average BSA for adult) * Adult Dose</small></div>
                                                  </div>
                                                </div>
                                              </div>

                                            </li>

                                          </ul>
                                        </div>
                                      </li>
                                    </>
                                    :
                                    ''
                                  }
                                  {i === 1 && isObsGynaeDoctor === 1 ? <>
                                    <li className="list-group-item ui-state-default">
                                      <span className="ordno">5.</span><strong>
                                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                          Obstetrics & Gynaecology</a></strong>

                                      <div id="collapse_2" className="collapse" role="tabpanel" aria-labelledby="collapse_2">
                                        <ul className="paediatrics_list">
                                          {
                                            soapListData[1]?.map((val, j) => {
                                              if (val.name == "mensural_hx" || val.name == "sexual_hx" || val.name == "obstetrics_hx" || val.name == "physical_examination") {
                                                return (
                                                  <>
                                                    <li className="list-group-item ui-state-default" key={val.id} data-printorder={1} data-displayorder={1} data-print="Y" data-display="Y" data-moduleid={1}>
                                                      <strong>{val.display_name}</strong>
                                                      <div className="float-right">
                                                        <div className="custom-control custom-checkbox custom-control-inline">
                                                          <input disabled={val.print_configuration_level == 'hospital'}
                                                            type="checkbox"
                                                            onChange={this.handleScreenCheck}
                                                            checked={val.display == 'Y'}
                                                            data-check={val.display + val.print}
                                                            className="custom-control-input assessmentscreen"
                                                            id={`screen_${val.id}`}
                                                            data-id={val.id} name="assessment_add_to_screen"
                                                            data-parent={`${val.parent_module}`}
                                                          />
                                                          <label className="custom-control-label" htmlFor={`screen_${val.id}`}>Add to Screen</label>
                                                        </div>
                                                        <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                          <input
                                                            disabled={val.print_configuration_level == 'hospital'}
                                                            type="checkbox"
                                                            onChange={this.handlePrintCheck}
                                                            checked={val.print == 'Y'}
                                                            className="custom-control-input assessmentcheckBoxClass"
                                                            id={`print_${val.id}`}
                                                            data-id={val.id} name="assessment_add_to_print"
                                                            data-parent={`${val.parent_module}`}
                                                          />
                                                          <label className="custom-control-label" htmlFor={`print_${val.id}`}>Add to Print</label>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  </>
                                                )
                                              }
                                            })
                                          }
                                        </ul>
                                      </div>
                                    </li>
                                  </>
                                    : ''
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
                <div className="row soap_sortable" id="level2">
                  {soapDataSecondRow?.map((iterable, i) => (
                    <Fragment key={i}>
                      <div className={`col-auto pr-0 ${i === 1 ? "pl-0" : ""} `}>
                        <div className="soap_card_no p-3">{iterable[0].order}</div>
                      </div>
                      <div className={`col pl-0 ${i === 0 ? "pr-0" : ""} `}>
                        <div className="p-4 widget-panel" id="widget-3">
                          <div className="card rounded-0 widget-card" id="widget-card-3">
                            <div className="pl-3 py-2 mr-3">
                              <strong className="text-capitalize">{iterable[0].parent_module}</strong>
                            </div>
                            <div className="card-body p-0">
                              <ul className="list-group list-group-flush sortitem assessment">
                                {iterable.sort((a, b) => a.id > b.id ? 1 : -1).map((item, i) => (
                                  <li className="list-group-item ui-state-default" key={item.id} data-printorder={2} data-displayorder={2} data-print="Y" data-display="Y" data-moduleid={9}>
                                    <span className="ordno">{i + 1}.</span><strong>{item.display_name}</strong>
                                    <div className="float-right">
                                      <div className="custom-control custom-checkbox custom-control-inline">
                                        <input
                                          disabled={item.print_configuration_level == 'hospital'}
                                          type="checkbox"
                                          onChange={this.handleScreenCheck}
                                          checked={item.display == 'Y'}
                                          data-check={item.display + item.print}
                                          className="custom-control-input assessmentscreen"
                                          id={`screen_${item.id}`}
                                          data-id={item.id} name="assessment_add_to_screen"
                                        />
                                        <label className="custom-control-label" htmlFor={`screen_${item.id}`}>Add to Screen</label>
                                      </div>
                                      <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                        <input
                                          disabled={item.print_configuration_level == 'hospital'}
                                          type="checkbox"
                                          onChange={this.handlePrintCheck}
                                          checked={item.print == 'Y'}
                                          className="custom-control-input assessmentcheckBoxClass"
                                          id={`print_${item.id}`}
                                          data-id={item.id} name="assessment_add_to_print"
                                        />
                                        <label className="custom-control-label" htmlFor={`print_${item.id}`}>Add to Print</label>

                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
              ) : (
                <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>
                  {LOADER_RED}
                </div>
              )}


              <div className="mt-5">&nbsp;</div>
              <div className="fixed-cta full">
                <button type="button" className="btn btn-primary px-4 mr-3 configuration-save" disabled={this.state.isFormSaving}
                  onClick={this.handleSave}>
                  SAVE{this.state.isFormSaving ? LOADER : null}
                </button>
              </div>


            </form>
          </div>
        </div>

        <Modal
          visible={this.state.isScreenMsgModalReq}
          onCancel={() => this.setState({ isScreenMsgModalReq: false })}
          footer={[
            <div className="text-center">
              <button className="btn btn-outline-secondary px-5 cancel-delete-history"
                onClick={() => this.setState({ isScreenMsgModalReq: false })}>OK</button>

            </div>
          ]}
        >
          <div className="row">
            <div className="col-12 my-3 text-center confirm-message">
              <h6>Module must be added on screen before adding to print.</h6>
            </div>
          </div>
        </Modal> */}


        {/* ###################### New EMR Configuration design ######################## */}

        <div className="container-fluid mb-5 pb-5">
          <div className="row">
            <div className="col-lg-12 mb-2 px-3">
              <h1 className="page-title">EMR Configuration</h1>
            </div>
          </div>
          <table className="table emr_config_table mt-2 mb-5" id="diagnosis-list">
            <thead>
              <tr>
                <td width="30%">FIELDS</td>
                <td className='text-centers' width="30%">OPD</td>
                <td className='text-centers' width="30%">VIRTUAL CONSULT</td>
                <td width="10%" className='text-center'>SEQUENCE</td>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td colSpan={3} className="highlight_col">Vitals & Key Findings</td>
                <td className='text-center highlight_col'>
                  {/* <button className='btn_sequence'></button> */}
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Today's Vitals</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"
                        
                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  {/* <button className='btn_sequence'></button> */}
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Key Findings</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  {/* <button className='btn_sequence'></button> */}
                </td>
              </tr>

              <tr>
                <td colSpan={3} className="highlight_col">Patient History</td>
                <td className='text-center highlight_col'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Blood Group</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Allergies</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Existing Conditions</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Surgical</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Social Hx</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Family Hx</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td colSpan={3} className="highlight_col">Subjective</td>
                <td className='text-center highlight_col'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Chief Complaint & HPI</span></td>
                <td >
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  {/* <button className='btn_sequence'></button> */}
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Physical Examination</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <span className='sub_column w-75'>Custom Field 1</span>
                    <input type="text" id="speciality-name" name="field_name2" className="form-control" placeholder="Field Name " maxLength="30" />
                  </div>

                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <span className='sub_column w-75'>Custom Field 2</span>
                    <input type="text" id="speciality-name" name="field_name" className="form-control" placeholder="Field Name " maxLength="30" />
                  </div>

                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Clinical Notes</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Internal Notes (Not to be printed)</span>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr className='highlighted_row'>
                <td className="highlight font">
                  <span className='mr-4'>Diagnosis</span>
                  <span className='ml-4'>
                    <Radio.Group onChange={this.onRadioDiagnosisSuggestionChange} value={this.state.diagnosisSuggestion}>
                      <Radio value={"related"} name="related" title={'Related'}>Related</Radio>
                      <Radio value={"favorites"} name="favorites" title={'Favorites'}>Favorites</Radio>
                    </Radio.Group>
                  </span>
                </td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='highlight text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>
              <tr><td></td></tr>

              <tr className='highlighted_row'>
                <td className="highlight font">Medicines</td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='highlight text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>
              <tr><td></td></tr>

              <tr className='highlighted_row'>
                <td className="highlight font">Tests</td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='highlight text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>
              <tr><td></td></tr>

              <tr className='highlighted_row'>
                <td className="highlight font">Instructions</td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='highlight text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>
              <tr><td></td></tr>

              <tr className='highlighted_row'>
                <td className="highlight font">Supporting Material</td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='highlight text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>
              <tr><td></td></tr>

              <tr className='highlighted_row'>
                <td className="highlight font">Referral</td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className="highlight">
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='highlight text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>
              <tr><td></td></tr>

              <tr>
                <td colSpan={3} className="highlight_col">Others</td>
                <td className='text-center highlight_col'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Physiotherapy Advised</span></td>
                <td >
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Admission Advised</span></td>
                <td >
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>

              <tr>
                <td><span className='sub_column'>Follow Up</span></td>
                <td >
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_screen"

                      />
                      <label className="custom-control-label" htmlFor="add_screen">Add to Screen</label>
                    </div>
                  </div>
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input assessmentscreen"
                        id="add_print"

                      />
                      <label className="custom-control-label" htmlFor="add_print">Add to Print</label>
                    </div>
                  </div>
                </td>
                <td className='text-center'>
                  <button className='btn_sequence'></button>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <div className="fixed-cta mt-3 full">
                <button type="button" className="btn btn-primary px-4 mr-3 configuration-save"
                >
                  SAVE
                </button>
              </div>


      </>
    )
  }
}

export default SoapListComponent;