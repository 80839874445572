import React, { Component } from 'react'
import qs from 'qs';
import Axios from 'axios';
import AsideLeft from '../../components/aside/asideleft';
import { NavLink } from 'react-router-dom';
import {
    SOAP_DETAILS,
    REFERENCE_TYPE,
    DRUG_FREQUENCY,
    DRUG_INSTRUCTION,
    DRUG_URGENCY,
    PHYSIO_UPADTE,
    SOCIAL_ROUTE,
    ADMIS_UPADTE,
    ADMISSION_UPDATE,
    LOADER_RED,
    TESTS_DELETE,
    PATIENT_DATA,
    MYHC_APPTYPE,
    WOODLANDS,
    ASSESSMENT_STATUS_UPDATE,
    FOLLOWUP_UPDATE,
    DURATION_TYPE_OBJECT,
    DURATION_TYPE_OBJECT_REVERSE,
    SEPARATEUPLOADEDDOCSNEW,
    APPS_FEATURES_LIST,
    ISALLOWEDITSOAP,
    TOGGLE_PRACTICE,
    REFERRAL_DETAILS,
    DIAGNOSIS_DO_NOT_PRINT
} from '../../utils/constant'
import Moment from 'react-moment';
import moment from 'moment';
import icJpg from '../../assets/images/ic-jpg.png';
import icPdf from '../../assets/images/ic-pdf.png';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Modal, Button, Radio, Carousel, Image } from 'antd';
import LS_SERVICE from '../../utils/localStorage';
import API_SERVICE from '../../utils/apiService';
import { notification } from 'antd';
import SoapFlow from '../../utils/soap';
import { FixedCta, onlynumbers } from '../../utils/jQueryLabels';
import { headerService$, practiceService } from '../../utils/rxjs-sharing';
import CurrentMedsPopup from '../../components/drugs/current-meds-popup';

export class TodaysvisitSoap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patient: null,
            reference_id: null,
            details: [],
            field_status: [],
            dataLoading: true,
            activeCuredClass: true,  //means active,
            physiotherapy_advised: 2,
            physioText: '',
            admissionChecked: false,
            admissionComment: '',
            followUpRadio: null,
            follow_up_days: null,
            isAdmissionAdvisedPriority: false,
            isDeleteModl: false,
            isDelRefId: '',
            follow_up_Others: 'Days',
            otherVal: null,
            viewDocumentModal: false,
            viewAssessmentImage: false,
            docSrc: null,
            docType: '',
            bookNowAllow: true,
            sourceOfRerral: 'dashboard',
            soap_subjective: false,
            soap_assessment: false,
            soap_plan: false,
            soap_vital: false,
            soap_allergies: false,
            soap_existing_conditions: false,
            soap_surgical_hx: false,
            soap_key_findings: false,
            soap_diagnosis: false,
            soap_medicines: false,
            soap_tests: false,
            soap_instructions: false,
            blockname: '',
            hospital_id: null,
            isPracticing: false,
            isPracticingModal: false,
            disableConfirmButton: false,
            allowEdit: true,
            isSoapVaccine: {},
            isSoapPaediat: {},
            isPaediatrics: 0,
            isObsGynaeDoctor: 0,
            referralDetailsArr: [],
            isReferralModal: false,
            showPdfModal: false,
            pdfPath: "",
            fileType: ""
        }

        this.scrollTop$ = '';
        this.carouselRef = React.createRef();
    }

    componentDidMount() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        //From local storage get hospitalConfigData and match hospitalid with url hospital id and get the view.
        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;
        const speciality_id = LS_SERVICE.get('speciality_id');
        const is_paediatrician = LS_SERVICE.get('isPaediatrician');
        // if(IS_PAEDIATRICS.includes(speciality_id))
        // {
        //     this.setState({isPaediatrics: 1}); 
        // }
        if (is_paediatrician == 1) {
            this.setState({ isPaediatrics: 1 });
        }
        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true
            })
        }

        const patientUrlData = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id,
        }
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

        if (LS_SERVICE.has('appointmentCompletedTime')) {
            var allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;
        } else {
            let allowEdit = this.state.allowEdit;
        }

        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patientUrlData,
            allowEdit
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                this.getPatientData();
            }
        });

        /*if (LS_SERVICE.has('soapConfigData')) { // comment out
            const soapConfigData = LS_SERVICE.get('soapConfigData');
            let isSoapOrBasic = soapConfigData.find(scd => scd.reference_id === +reference_id);
            if (isSoapOrBasic !== undefined) {
                this.setState({
                    hospital_id: isSoapOrBasic.hospital_id,
                    reference_id: isSoapOrBasic.reference_id
                })
                headerService$.soapConfig({ soapConfig: isSoapOrBasic.soapConfig }); // IMPORTANT TO HIT AS PER THE VIEW
            }
        } else {
            this.setState({
                reference_id: LS_SERVICE.get('call_patient_qms_token_id')
            })
        }*/

        FixedCta();
        onlynumbers();


        if (LS_SERVICE.has('isTodayVisit')) {
            let isTodayVisitArr = LS_SERVICE.get('isTodayVisit');
            const isAvailable = isTodayVisitArr.includes(reference_id);
            if (isAvailable === false) {
                isTodayVisitArr.push(reference_id);
                LS_SERVICE.set('isTodayVisit', isTodayVisitArr)
            }
        } else {
            const isTodayVisit = [reference_id];
            LS_SERVICE.set('isTodayVisit', isTodayVisit);
        }

        this.getReferralDetails();
    }

    componentDidUpdate() {
        const blockname = LS_SERVICE.get('blockname');
        if (blockname !== 'undefined' && blockname !== '') {
            const blockId = document.getElementById(blockname);
            if (blockId !== null) {
                this.scrollTop$ = setTimeout(() => {
                    window.scrollTo({ top: (blockId.offsetTop + 150), behavior: 'smooth' });
                    LS_SERVICE.set('blockname', '');
                }, 100)

            }
        }

        let Elem = document.querySelectorAll('.autoHeightText');
        Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
    }

    componentWillUnmount() {
        const blockname = LS_SERVICE.get('blockname');
        if (this.scrollTop$ !== '' && blockname !== '') {
            clearTimeout(this.scrollTop$)
        }
    }

    async setSoapFlow() {
        const { hospital_id } = this.state;
        // const staff_id = LS_SERVICE.get('staff_id');
        // const hospital_id = LS_SERVICE.get('slot_hospital_id');
        // const speciality_id = LS_SERVICE.get('speciality_id');
        // const soapList = await Axios.post(SOAP_CONFIGURATION_LIST, qs.stringify({ staff_id, hospital_id, speciality_id }));

        // SoapFlow.setSoapList(soapList.data);
        // SoapFlow.setSoapList();
        // console.log('>> JJJ', this.props.location.state.patient.hospital_id);
        // if (
        //     this.props.location != undefined &&
        //     this.props.location.state != undefined &&
        //     this.props.location.state.patient != undefined &&
        //     this.props.location.state.patient.hospital_id != undefined) {
        //     SoapFlow.hospital(this.props.location.state.patient.hospital_id);
        // } else {
        SoapFlow.hospital(hospital_id)
        // }

        const subjective = SoapFlow.show('subjective');
        const assessment = SoapFlow.show('assessment');
        const plan = SoapFlow.show('plan');

        const vital = SoapFlow.showElement('objective', 'vital');
        const allergies = SoapFlow.showElement('objective', 'allergies');
        const existing_conditions = SoapFlow.showElement('objective', 'existing_conditions');
        const surgical_hx = SoapFlow.showElement('objective', 'surgical_hx');

        const diagnosis = SoapFlow.showElement('assessment', 'diagnosis');
        const key_findings = SoapFlow.showElement('assessment', 'key_findings');

        const medicines = SoapFlow.showElement('plan', 'prescribed_medicines');
        const tests = SoapFlow.showElement('plan', 'prescribed_tests');
        const instructions = SoapFlow.showElement('plan', 'instructions');

        const paediatric = SoapFlow.showElement('paediatric', 'child_development');
        const vaccination_chart = SoapFlow.showElement('paediatric', 'vaccination_chart');

        const mensural_hx = SoapFlow.showElement('obsgynae', 'mensural_hx');
        const sexual_hx = SoapFlow.showElement('obsgynae', 'sexual_hx');
        const obstetrics_hx = SoapFlow.showElement('obsgynae', 'obstetrics_hx');
        const physical_examination = SoapFlow.showElement('obsgynae', 'physical_examination');

        this.setState({
            soap_subjective: subjective,
            soap_assessment: assessment,
            soap_plan: plan,
            soap_vital: vital,
            soap_allergies: allergies,
            soap_existing_conditions: existing_conditions,
            soap_surgical_hx: surgical_hx,
            soap_key_findings: key_findings,
            soap_diagnosis: diagnosis,
            soap_medicines: medicines,
            soap_tests: tests,
            soap_instructions: instructions,
            soap_paediatric: paediatric,
            soap_vaccination_chart: vaccination_chart,
            soap_mensural_hx: mensural_hx,
            soap_sexual_hx: sexual_hx,
            soap_obstetrics_hx: obstetrics_hx,
            soap_physical_examination: physical_examination
        });
    }

    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }

    getPatientData = _ => {
        const { match: { params } } = this.props;
        const { reference_id } = params;
        Axios.get(PATIENT_DATA({ reference_id }))
            .then(success => {
                const patient = {
                    ...success.data.data.results,
                    qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
                }
                this.setState({
                    patient
                }, () => {
                    this.initialSetup();
                    this.setSoapFlow();
                })
            })
            .catch(err => console.log(err))
    }

    initialSetup = () => {
        const { patient, hospital_id } = this.state;
        // const hospitalId = hospital_id !== null ? hospital_id : LS_SERVICE.get("slot_hospital_id");
        // const hospitalId = LS_SERVICE.get("slot_hospital_id");
        const PARAMS = {
            reference_type: REFERENCE_TYPE,
            patient_id: patient.patient_id,
            reference_id: patient.qms_token_id,
            hospital_id
        }
        Axios.post(SOAP_DETAILS, qs.stringify(PARAMS))
            .then((res) => {

                const { details, field_status } = res.data;
                let { followUpRadio, follow_up_days, follow_up_Others, otherVal } = this.state;

                const { patient_note_doctor, patient_docments } = details.appointment;
                const docs = SEPARATEUPLOADEDDOCSNEW(patient_docments);

                let followUpType = null;
                let followUpDays = null;
                if (details.followUp !== null) {
                    followUpType = details.followUp?.followUpType;
                    followUpDays = details.followUp?.followUpDays;

                    if (followUpType !== 0 && followUpType !== undefined) {
                        followUpRadio = -1;
                        otherVal = followUpDays;
                        follow_up_Others = DURATION_TYPE_OBJECT_REVERSE[followUpType]?.toLowerCase();
                    } else if (followUpDays !== undefined) {
                        follow_up_days = followUpDays;
                        followUpRadio = followUpDays
                    }
                }

                const bookNowAllowFn = _ => {
                    if (otherVal !== null || follow_up_days !== null) {
                        if (otherVal > 0) return false;
                        if (follow_up_days > 0) return false;
                    }
                    return true;
                }

                this.setState({
                    details,
                    constants: details.constants,
                    field_status,
                    dataLoading: false,
                    physiotherapy_advised: details?.physiotherapyAdvised === null || details?.physiotherapyAdvised === 0 ? 2 : 1,
                    admissionChecked: details?.admissionAdvised !== null ? (details?.admissionAdvised) : false,
                    physioText: details?.physiotherapyComment ?? '',
                    admissionComment: details?.admissionComment ?? '',
                    isAdmissionAdvisedPriority: details.admissionAdvisedPriority == null || details.admissionAdvisedPriority == 0 ? false : true,
                    followUpRadio,
                    otherVal,
                    follow_up_Others,
                    follow_up_days,
                    bookNowAllow: bookNowAllowFn(),
                    patient_note_doctor: patient_note_doctor || '',
                    patient_docments: docs,
                })
            })
            .catch((err) => {
                console.log(err.data)
            })
    }



    handleSubjectiveRoute = (e) => {
        e.preventDefault()
        const { hospital_id, patient_id, reference_id, patient } = this.state;
        this.props.history.push({
            pathname: `/patient/todays-visit/subjective/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`,
            state: { patient }
        })
    }

    handleObjectiveRoute = (e) => {
        e.preventDefault();

        const { soap_vital, soap_allergies, soap_existing_conditions, soap_surgical_hx } = this.state;

        if (!soap_vital && !soap_allergies && !soap_existing_conditions && !soap_surgical_hx) {
            return false;
        }

        const { hospital_id, patient_id, reference_id, patient } = this.state;
        this.props.history.push({
            pathname: `/patient/todays-visit/objective/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`,
            state: { patient }
        })
    }

    handleAssessmentRoute = (e, type = null) => {
        e.preventDefault();
        const { hospital_id, patient_id, reference_id, patient } = this.state;

        if (type !== null || MYHC_APPTYPE !== WOODLANDS) {
            this.props.history.push({
                pathname: `/patient/todays-visit/assessment/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`,
                state: { patient }
            })
        } else {
            this.props.history.push({
                pathname: `/patient/todays-visit/assessment/key-findings/list/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`,
                state: { patient }
            })
        }
    }

    handlePlanRoute = (e, endPoint) => {
        e.preventDefault();

        const { soap_medicines, soap_tests, soap_instructions, soap_vaccination_chart } = this.state;
        if (!soap_medicines && !soap_tests && !soap_instructions && !soap_vaccination_chart) {
            return false;
        }

        const { hospital_id, patient_id, reference_id, patient } = this.state;
        // const pathEnd = endPoint == "instructions" ? `${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}` : `${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`
        this.props.history.push({
            pathname: `/patient/todays-visit/plan/${endPoint}/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`,
            state: { patient }
        })
    }


    handleReferralRoute = (e) => {
        e.preventDefault()
        const { hospital_id, patient_id, patient, sourceOfRerral } = this.state;
        this.props.history.push({
            pathname: `/patient/todays-visit/referral/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient.qms_token_id}`,
            state: { patient, sourceOfRerral }
        })
    }

    handleRadioChange = (e, callBackFunName = "") => {
        e.preventDefault()
        const { isPracticing } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => {
            if (callBackFunName !== "") {
                this[callBackFunName]();
            }
        })
    }

    handleAdmissionAdvicedCheckbox = e => {
        const { isPracticing, isAdmissionAdvisedPriority } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        let { name, checked } = e.target;
        this.setState({
            [name]: checked,
            isAdmissionAdvisedPriority: !checked ? false : isAdmissionAdvisedPriority
        }, this.admissionSave)
    }
    handleAdmissionAdvicedComment = e => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        }, this.admissionSave)
    }

    physiotherapySave = _ => {
        this.setState({ disableConfirmButton: true });
        const { patient, physioText, physiotherapy_advised } = this.state;
        const PARAMS = {
            reference_id: patient.qms_token_id,
            reference_type: REFERENCE_TYPE,
            physiotherapy_advised: physiotherapy_advised === 2 ? 0 : 1,
            physiotherapy_comment: physioText
        }
        if (physiotherapy_advised === 2) {
            this.setState({
                physioText: ""
            })
            PARAMS.physiotherapy_comment = "";
        }
        Axios.post(PHYSIO_UPADTE, qs.stringify(PARAMS))
            .then(res => {
                this.setState({ disableConfirmButton: false });
            })
    }

    admissionSave = () => {
        //this.setState({ disableConfirmButton: true });
        const { patient, admissionChecked, isAdmissionAdvisedPriority, admissionComment } = this.state;
        const PARAMS = {
            reference_id: patient.qms_token_id,
            reference_type: REFERENCE_TYPE,
            admission_advised: admissionChecked ? 1 : 0,
            admission_comment: admissionComment,
            priority: isAdmissionAdvisedPriority ? 1 : 0
        }
        if (!admissionChecked) {
            this.setState({
                admissionComment: ""
            })
            PARAMS.admission_comment = "";
        }
        Axios.post(MYHC_APPTYPE === WOODLANDS ? ADMIS_UPADTE : ADMISSION_UPDATE, qs.stringify(PARAMS))
            .then(res => {
                //this.setState({ disableConfirmButton: false });
            })
    }

    handleFollowUpRadio = (e) => {

        const { isPracticing } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        const { value, title } = e.target;
        this.setState({
            followUpRadio: value,
            follow_up_days: title !== '-1' ? +title : title,
            bookNowAllow: false,
        }, () => {
            const { follow_up_days } = this.state;
            if (follow_up_days === '-1') return;
            this.handleFollowUpApi();
        })
    }

    handleFollowUpApi = _ => {
        const { follow_up_days, followUpRadio, patient, follow_up_Others, otherVal } = this.state;

        const PARAMS = {
            reference_id: patient.qms_token_id,
            reference_type: REFERENCE_TYPE,
            patient_id: patient.patient_id,
            follow_up_days: followUpRadio != '-1' ? followUpRadio : +otherVal,
            follow_up_type: followUpRadio != '-1' ? follow_up_days : DURATION_TYPE_OBJECT[follow_up_Others.toLowerCase()]
        }

        Axios.post(FOLLOWUP_UPDATE, qs.stringify(PARAMS))
            .then(res => console.log(res.data))
    }

    handleTestDelete = (e) => {
        const param = this.state.isDelRefId;
        const { details } = this.state;
        const soapData = details;
        const filterdArray = details.tests.filter(item => item.id != param);

        soapData.tests = filterdArray;

        Axios.get(TESTS_DELETE(param))
            .then(res => {
                this.setState({
                    details: soapData,
                    isDeleteModl: false
                })
            })
    }

    handleToggleAdmission = (e) => {
        this.setState({
            isAdmissionAdvisedPriority: e
        }, this.admissionSave)

    }

    handleViewPresc = (e) => {
        e.preventDefault()
        const { patient, hospital_id } = this.state;
        this.props.history.push({
            pathname: `/patient/todays-visit/view-prescription/${hospital_id}/${patient.patient_id}/${REFERENCE_TYPE}/${patient.qms_token_id}`,
            state: { patient }
        })
    }

    onbooknow = (submit = false) => {
        const { followUpRadio, patient, follow_up_Others, otherVal, patientUrlData } = this.state;
        let date = new Date();
        let todayDate = date.getDate();
        let foramttedDate = '';
        if (followUpRadio !== -1) {
            let newDate = date.setDate(todayDate + parseInt(followUpRadio));
            foramttedDate = new Date(newDate).toLocaleDateString("zh-Hans-CN", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }).replace(/\//gm, '-');
        } else {
            date = moment().add(+otherVal, follow_up_Others);
            foramttedDate = new Date(date._d).toLocaleDateString("zh-Hans-CN", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }).replace(/\//gm, '-');
            if (!submit) this.handleFollowUpApi();
        }
        patient.id = patient.patient_id;

        if (submit) {
            LS_SERVICE.set('bookNowPatient', patient);
            this.props.history.push({
                pathname: `/patient/todays-visit/follow-up/${patientUrlData.hospital_id}/${patientUrlData.patient_id}/${REFERENCE_TYPE}/${patient.qms_token_id}/${foramttedDate}`,
                state: { patient }
            })
        }
    }

    handleOnChange = (e, callBack = null) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
            bookNowAllow: false
        }, () => {
            if (callBack !== null) {
                callBack();
            }
        })
    }

    handleStatusSwitch = async (e, data) => {
        if (!this.state.allowEdit)
            return;

        const { id, status } = data;

        const switchPromise = await Axios.get(ASSESSMENT_STATUS_UPDATE({ id, status: !status ? 1 : 0 }))

        if (switchPromise) {
            this.initialSetup();

            const { data } = switchPromise;

            notification.success({
                message: data.message,
                placement: 'topRight'
            })
        }
    }

    handleModalPopup = (e, popupName, data, type) => {
        if (e !== null) e.preventDefault();
        this.setState({
            [popupName]: !this.state[popupName]
        }, () => {

            const { viewDocumentModal, viewAssessmentImage } = this.state;

            if (viewDocumentModal) {
                if (data !== null) {
                    /*this.setState({
                        docSrc: data,
                        docComment: data?.comment,
                        docType: type === 'img' ? 'image/jpeg' : 'application/pdf'
                    })*/
                    this.carouselRef.current.goTo(data);
                }
            }
            else if (viewAssessmentImage) {
                if (data !== null) {
                    this.setState({
                        docSrc: data.imagePath,
                        docComment: data?.comment,
                        docType: type === 'img' ? 'image/jpeg' : 'application/pdf'
                    })
                }
            } else {
                this.setState({
                    docSrc: null,
                    docComment: '',
                    docType: ''
                })
            }

        })
    }

    showObjectiveElem(item) {
        if (item.type === 'vitals') {
            return this.state.soap_vital;
        } else if (item.type === 'allergies') {
            return this.state.soap_allergies;
        } else if (item.type === 'existing_conditions') {
            return this.state.soap_existing_conditions;
        } else if (item.type === 'surgical_hx') {
            return this.state.soap_surgical_hx;
        } else {
            return false;
        }
    }

    handleFrequencyDataShow = data => {
        const { constants } = this.state;

        let drugFreqText = data.drug_frequency ? Object.keys(constants.drugFrequency).find(key => constants.drugFrequency[key].id === data.drug_frequency) : null;
        drugFreqText = drugFreqText != null ? constants.drugFrequency[drugFreqText].description : '';

        let drugDailyFrequency = data.daily_frequency !== null && data.daily_frequency !== "" ? Object.keys(constants.dailyFrequency).find(key => constants.dailyFrequency[key].id === data.daily_frequency) : null;
        drugDailyFrequency = drugDailyFrequency != null ? constants.dailyFrequency[drugDailyFrequency].description : '';

        return data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? data.days_in_week_text : (data.drug_frequency == 1 ? drugDailyFrequency : drugFreqText);
    }

    handleDrugRouteDataShow = data => {
        const { constants } = this.state;

        let drugRouteText = data.route ? Object.keys(constants.route).find(key => constants.route[key].id === data.route) : null;
        drugRouteText = drugRouteText != null ? constants.route[drugRouteText].description : '';

        return drugRouteText;
    }

    handleDrugInstructionDataShow = data => {
        const { constants } = this.state;

        let drugRouteText = data.instruction ? Object.keys(constants.drugInstructions).find(key => constants.drugInstructions[key].id === data.instruction) : null;
        drugRouteText = drugRouteText != null ? constants.drugInstructions[drugRouteText].description : '';

        return drugRouteText;
    }

    handleDrugDosageDataShow = data => {
        if (data.dosage) {
            const { constants } = this.state;

            let drugDosageText = data.dosage ? Object.keys(constants.drugDosage).find(key => constants.drugDosage[key].id === data.dosage) : null;
            drugDosageText = drugDosageText != null ? constants.drugDosage[drugDosageText].description : '';
            if (data.dosage_value != null) {
                return drugDosageText + ' ' + data.dosage_form_text;
            } else {
                return '';
            }
        }
        else
            if (data.dosage_value != null) {
                return data.dosage_value + ' ' + data.dosage_form_text;
            } else {
                return '';
            }
    }

    enablePractice = async e => {
        e.preventDefault();
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
            hospital_id: this.state.hospital_id,
            practice_session: 'start'
        });

        let is_practicing;
        if (practice.data && practice.data.is_practicing === 'Y') {
            is_practicing = true;
            LS_SERVICE.set('practicing', {
              status: true,
              hospital_id: this.state.hospital_id
            });
        } else {
            is_practicing = false;
        }
        this.setState({
            isPracticing: is_practicing,
        }, () => {
            this.handleModalPopup(null, 'isPracticingModal');
        });
    }

    getReferralDetails = async () => {
        const { match: { params } } = this.props;
        const { patient_id, reference_id } = params;

        const PARAMS = {
            patient_id: patient_id,
            speciality_id: LS_SERVICE.get('speciality_id') || null,
            reference_id: reference_id
        }

        Axios.post(REFERRAL_DETAILS, qs.stringify(PARAMS))
            .then(res => {
                if (res.data.status && res.data.data.length > 0) {
                    this.setState({
                        referralDetailsArr: res.data.data
                    })
                }
            })
    }

    handleReferralDetails = () => {
        this.setState({
            isReferralModal: true
        });
    }

    subjective = (subjective_id) => {
        var {patient} = this.state;

        var data = null;
        if(subjective_id == 0){
            data = LS_SERVICE.get("chief_complaint_" + patient?.patient_id + "_" + patient?.qms_token_id);
            if(data !==null){
                data = data?.join(", ");
            }
        }
        // if(subjective_id == 1){
        //     data = LS_SERVICE.get("hpi_" + patient?.patient_id + "_" + patient?.qms_token_id);
        // }
        // if(subjective_id == 2){
        //     data = LS_SERVICE.get("review_of_systems_" + patient?.patient_id + "_" + patient?.qms_token_id);
        // }

        return data;
    }

    openSupportingFile(e, file_url, file_name) {
        const splitArray = file_name.split('.');
        const extension = splitArray[splitArray.length - 1];
        this.setState({
            pdfPath: file_url,
            fileType: extension,
            showPdfModal: true
        });
    }

    render() {
        
        const { patient, details, field_status, dataLoading, physiotherapy_advised, physioText, admissionChecked, admissionComment,
            followUpRadio, isAdmissionAdvisedPriority, patient_note_doctor, patient_docments, soap_subjective, soap_assessment, soap_plan,
            soap_key_findings, soap_diagnosis, soap_medicines, soap_tests, soap_instructions, soap_vaccination_chart,
            patientInfo, patient_reference_id, dataLoaded, patient_id, reference_id, hospital_id, soap_paediatric, soap_mensural_hx,
            soap_sexual_hx, soap_obstetrics_hx, soap_physical_examination,
            referralDetailsArr
        } = this.state;
        
        const currentMedicineData = Array.from(new Set(details.currentMedicines?.map(item => item.drug_id)))
            .map(drug_id => details.currentMedicines?.find(a => a.drug_id === drug_id)) //for objective -> current meds feild

        const medicineData = Array.from(new Set(details.medicines?.map(item => item.drug_id)))
            .map(drug_id => details.medicines?.find(a => a.drug_id === drug_id)) //for objective -> medicine feild

        const isPaediatrics = LS_SERVICE.get('isPaediatrics') ? LS_SERVICE.get('isPaediatrics') : false;
        const isObsGynaePatient = LS_SERVICE.get('isObsGynaePatient') ? LS_SERVICE.get('isObsGynaePatient') : false;
        const isObsGynaeDoctor = LS_SERVICE.get('isObsGynaeDoctor') ? LS_SERVICE.get('isObsGynaeDoctor') : 0;
        const { activeAssessments, medicines, tests, instructions, referrals, supportingMaterials } = details;
        return (
            <>
                {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                {!dataLoading ? (
                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <div className="row">

                                {referralDetailsArr?.length > 0 || (patient_note_doctor.length > 0 ||
                                    patient_docments?.length > 0) ? (

                                    <div className="col-12 mb-3" id="patient_notes_docs">
                                        <div className="card bg-grey rounded-0">
                                            <div className="card-body">
                                                <div className='row'>
                                                    {patient_note_doctor?.length > 0 || patient_docments?.length > 0 ?
                                                        <div className={(patient_note_doctor?.length > 0 || patient_docments?.length > 0) && referralDetailsArr?.length == 0 ? "col-12" : "col-6"}>
                                                            {patient_note_doctor.length > 0 ? (
                                                                <>
                                                                    <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Notes For Doctor</h6>
                                                                    <p className="text-dark" id="notes_for_doctor_note">{patient_note_doctor}</p>
                                                                </>) : null}

                                                            {
                                                                patient_docments?.length > 0 ? (
                                                                    <>
                                                                        <h6 className="text-uppercase text-dark" id="docs_for_doctor_head">Documents uploaded by the patient</h6>
                                                                        <figure className="mb-0" id="docs_for_doctor_docs">
                                                                            {
                                                                                patient_docments?.map((file, index) => (
                                                                                    <a href="#" className="show-rx-modal" key={index} onClick={e => this.handleModalPopup(e, 'viewDocumentModal', index, 'img')}>
                                                                                        {/* <img className="mr-3" alt='Doc IImg' src={file.type === 'img' ? icJpg : icPdf} /> */}
                                                                                        <i className={`mr-2 icon-${file.type === 'img' ? 'jpg' : 'pdf'}`}></i>
                                                                                    </a>
                                                                                ))
                                                                            }
                                                                        </figure>
                                                                    </>
                                                                ) : null
                                                            }
                                                        </div>
                                                        : null}
                                                    {referralDetailsArr?.length > 0 ?
                                                        <div className={patient_note_doctor?.length == 0 && patient_docments?.length == 0 ? "col-12" : "col-6"}>
                                                            <div className={patient_note_doctor?.length == 0 && patient_docments?.length == 0 ? "" : "float-right"}>
                                                                <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Referral <a onClick={this.handleReferralDetails} style={{ color: '#d24350', left: '1px', top: '-18px', fontSize: '0.875rem' }}> {referralDetailsArr?.length > 1 ? referralDetailsArr?.length : null}</a></h6>
                                                                <p className='mb-1'>Referral Date & Time : <span className="text-dark">{referralDetailsArr[0].referral_date_time}</span></p>
                                                                <p className='mb-1'>Referral By : <span className="text-dark">Dr {referralDetailsArr[0].referral_by}</span></p>
                                                                <p className='mb-1'>Referral Note : <span className="text-dark">{referralDetailsArr[0].referral_note}</span></p>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}


                                {soap_subjective ? <div className="col-md-6 mb-3" id="subjective">
                                    <div className="card todays-visit-card card-subjective">
                                        <div className="card-header bg-white p-0">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <label className="card-title border-right mb-0 px-3">S</label>
                                                </div>
                                                <div className="col pl-0 align-self-center">
                                                    <a className="d-block nostyle-link" onClick={this.handleSubjectiveRoute}>
                                                        <span className="font-weight-semibold">SUBJECTIVE</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="list-group list-group-flush">
                                                        {field_status.subjective?.map((item, i) => {
                                                            let field = this.subjective(i);
                                                            return (
                                                                <li className="list-group-item" key={i}>
                                                                    <a href="#" className="d-block nostyle-link" onClick={this.handleSubjectiveRoute} >
                                                                        {item.name}
                                                                    </a>
                                                                    {field !== null || details.subjectives !== null && details?.subjectives?.length > 0 ? (
                                                                        <div>
                                                                            <a className="text-muted nostyle-link" href="#" onClick={this.handleSubjectiveRoute}>
                                                                                <pre className="text-muted m-0">{(this.subjective(i) !== null)? this.subjective(i) : details.subjectives[i]?.description}</pre>
                                                                            </a>
                                                                        </div>
                                                                    ) : null}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}

                                <div className="col-md-6 mb-3" id="objective">
                                    <div className="card todays-visit-card card-objective">
                                        <div className="card-header bg-white p-0">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <label className="card-title border-right mb-0 px-3">O</label>
                                                </div>
                                                <div className="col pl-0 align-self-center">
                                                    <a href="#" className="d-block nostyle-link" onClick={this.handleObjectiveRoute}>
                                                        <span className="font-weight-semibold">OBJECTIVE</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="list-group list-group-flush">
                                                        {field_status.objective?.slice(0, -1).map((item, i) =>
                                                            <>{this.showObjectiveElem(item) ? <li className="list-group-item" key={i}>
                                                                <a href="#" className="d-block nostyle-link" onClick={this.handleObjectiveRoute}>{item.name} {item.status ? <span className="float-right check-mark" /> : ""}
                                                                </a>
                                                            </li> : null}</>
                                                        )}

                                                        <li className={`list-group-item accordion_list ${currentMedicineData.length > 0 ? 'haslist' : ''}`}>
                                                            {/* <li className="list-group-item  accordion_list"> */}
                                                            <CurrentMedsPopup patient={this.state.patient} pageName ='today-visit'/>
                                                            {/* <a data-toggle="collapse" data-target="#current_medicine" aria-expanded="false" aria-controls="current_medicine" href="!#" style={{ color: '#5C5C5C' }} className="d-block nostyle-link collapsed">
                                                                Current Meds
                                                            </a> */}
                                                            {/* <div id="current_medicine" className="text-muted collapse"> */}
                                                                {/* {details.currentMedicines?.map(mainDrug => (
                                                                <div> */}
                                                                {/* {currentMedicineData?.map((item, i) => (
                                                                    <div className="accordion_item_row" key={i}>
                                                                        <div className="accordion_item_head collapsed" data-toggle="collapse" data-target={`#current_collapse_${item.prescriptionDrugId}`} aria-expanded="true" aria-controls={`current_collapse_${item.prescriptionDrugId}`}>
                                                                            {item.drug_name}
                                                                        </div>
                                                                        <div className="accordion_item_body collapse" id={`current_collapse_${item.prescriptionDrugId}`} data-parent="#current_medicine" >
                                                                            <table className="table table-borderless table-sm mb-2 text-muted">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td width="25%" className="font-weight-semibold">Urgency</td>
                                                                                        <td width="25%">: {DRUG_URGENCY[item.urgency]}</td>
                                                                                        <td width="25%" className="font-weight-semibold">Instruction</td>
                                                                                        <td width="25%">: {this.handleDrugInstructionDataShow(item)}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="font-weight-semibold">Route</td>
                                                                                        <td>: {this.handleDrugRouteDataShow(item)}</td>
                                                                                        <td className="font-weight-semibold">Start Date</td>
                                                                                        <td>: {item.start_date ? <Moment parse="YYYY-MM-DD" date={item.start_date} format=" D MMM, YYYY" /> : '-'}</td>

                                                                                    </tr>
                                                                                    {details.currentMedicines?.filter(subItem => subItem.drug_id == item.drug_id).length == 1 ? (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td className="font-weight-semibold">Frequency</td>
                                                                                                <td>: {this.handleFrequencyDataShow(item)}</td>
                                                                                                <td className="font-weight-semibold">Duration</td>
                                                                                                <td>: {item.number_of_days && item.number_of_days != null && item.drug_duration_time && item.drug_duration_time != null ? item.number_of_days +' '+item.drug_duration_time : '-'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="font-weight-semibold">Dosage</td>
                                                                                                <td>: {this.handleDrugDosageDataShow(item)}</td>
                                                                                                <td className="font-weight-semibold">Schedule</td>
                                                                                                <td>: {item.drug_frequency != 3 && item.schedule_name ? item.schedule_name : '-'}</td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ) : <tr></tr>}

                                                                                </tbody>
                                                                            </table>
                                                                            {details.currentMedicines?.filter(subItem => subItem.drug_id == item.drug_id).length > 1 ? (
                                                                                <div className="taper_list">
                                                                                    <ul>
                                                                                        {details.currentMedicines?.filter(subItem => subItem.drug_id == item.drug_id).map((drug, i) => drug.active === 'Y' ? (
                                                                                            <li key={i}>
                                                                                                <h5>Taper{" "}{i + 1}</h5>
                                                                                                <table className="table table-borderless table-sm mb-2 text-muted">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td width="22%" className="font-weight-semibold">Frequency</td>
                                                                                                            <td width="25%">: {this.handleFrequencyDataShow(drug)}</td>
                                                                                                            <td width="25%" className="font-weight-semibold">Duration</td>
                                                                                                            <td width="28%">: {drug.number_of_days && drug.number_of_days != null && drug.drug_duration_time && drug.drug_duration_time != null ? drug.number_of_days +' '+drug.drug_duration_time : '-'}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td className="font-weight-semibold">Dosage</td>
                                                                                                            <td>: {this.handleDrugDosageDataShow(drug)}</td>
                                                                                                            <td className="font-weight-semibold">Schedule</td>
                                                                                                            <td>: {drug.drug_frequency != 3 && drug.schedule_name ? drug.schedule_name : '-'}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </li>
                                                                                        )
                                                                                            : null
                                                                                        )}

                                                                                    </ul>
                                                                                </div>
                                                                            ) : ""}

                                                                        </div>
                                                                    </div>
                                                                ))} */}
                                                           {/* // </div> */}
                                                        </li>

                                                        {isPaediatrics && soap_paediatric && this.state.isPaediatrics == 1 ?
                                                            <li className="list-group-item">
                                                                <NavLink exact={true} className="d-block nostyle-link" to={{
                                                                    pathname: `/patient/todays-visit/objective/paediatrics/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                                                                    state: { patient: patientInfo }
                                                                }}>Paediatrics</NavLink>
                                                            </li> : null}

                                                        {(isObsGynaePatient == true && isObsGynaeDoctor == 1 && (soap_mensural_hx || soap_sexual_hx || soap_obstetrics_hx || soap_physical_examination)) ?
                                                            <li className="list-group-item">
                                                                <NavLink exact={true} className="d-block nostyle-link" to={{
                                                                    pathname: `/patient/todays-visit/objective/obs-gynae/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                                                                    state: { patient: patientInfo }
                                                                }}>Obstetrics &amp; Gynaecology</NavLink>
                                                            </li> : null}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {soap_assessment ? <div className="col-md-6 mb-3" id="assessment">
                                    <div className="card todays-visit-card card-assessment">
                                        <div className="card-header bg-white p-0">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <label className="card-title border-right mb-0 px-3">A</label>
                                                </div>
                                                <div className="col pl-0 align-self-center">
                                                    <a href="#" className="d-block nostyle-link" onClick={this.handleAssessmentRoute}>
                                                        <span className="font-weight-semibold">ASSESSMENT</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="list-group list-group-flush">
                                                        {soap_key_findings && APPS_FEATURES_LIST.todayVisitSoap.keyfindings ? (
                                                            <li className="list-group-item"><a href="#" className="d-block nostyle-link" onClick={this.handleAssessmentRoute}>Key Findings</a></li>
                                                        ) : null}
                                                        {/* <li className="list-group-item"><a href="#" className="d-block nostyle-link" onClick={e => this.handleAssessmentRoute(e, 'diagnosis')}>Diagnosis</a></li> */}
                                                        {soap_diagnosis ? <li className={`list-group-item accordion_list ${activeAssessments?.length > 0 ? 'haslist' : ''}`}>
                                                            <a data-toggle="collapse" data-target="#assessments-details" aria-expanded="false" aria-controls="assessments-details" href="#" className="d-block nostyle-link">
                                                                <span onClick={e => this.handleAssessmentRoute(e, 'diagnosis')}>Diagnosis</span>
                                                            </a>

                                                            <div id="assessments-details" className="text-muted collapse show haslist_item">
                                                                <ul className="list-group list-group-flush bg-grey mt-3">
                                                                    {activeAssessments?.map((item, i) => {
                                                                        item.diagnosisInfo = item.diagnosisInfo || {}
                                                                        item.diagnosisInfo.diagnose_description = ((DIAGNOSIS_DO_NOT_PRINT.includes(item.diagnosis_id) == true || !item?.diagnosisInfo?.icd_code || item?.diagnosisInfo?.icd_code == "" || item?.diagnosisInfo?.icd_code == null) && item?.diagnosis_notes != '')? item?.diagnosis_notes : item.diagnosisInfo?.diagnose_description;
                                                                        return (
                                                                            <li className="list-group-item py-1" key={i}>
                                                                                <div className="row">
                                                                                    <small className="col-12">
                                                                                        <strong>
                                                                                            <Moment date={item.created_at} format="D MMM, YYYY" />
                                                                                        </strong>
                                                                                    </small>
                                                                                    <div className="col-8 col-md-6 align-self-center">
                                                                                        <span className="text-medium">{(item.diagnosisInfo?.diagnose_description) ? item?.diagnosisInfo?.diagnose_description : item?.diagnosis_notes}</span>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-3 align-self-center">
                                                                                        <span className="text-dark mr-1">{item?.diagnosisInfo?.icd_code}</span>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-3 align-self-center ant_switch_button_sm">
                                                                                        <BootstrapSwitchButton
                                                                                            width={90} height={24}
                                                                                            size="xs"
                                                                                            checked={!item.status}
                                                                                            onChange={e => this.handleStatusSwitch(e, item)}
                                                                                            onlabel='Active'
                                                                                            offlabel='Cured'
                                                                                            onstyle="switch" offstyle="success"
                                                                                            disabled={!this.state.allowEdit}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })}

                                                                </ul>

                                                                <li className="list-group-item"><a href="#" className="d-block nostyle-link" onClick={this.handleAssessmentRoute}>Image Reference</a></li>
                                                                <li className="list-group-item">
                                                                    <div className="row">
                                                                        {activeAssessments?.map((item, i) => (
                                                                            <div key={i} className={`content_img col-12 col-md-4 ${item.VisitAssessmentImage[0]?.imagePath != null || undefined ? "" : "d-none"}`} >
                                                                                <img src={item.VisitAssessmentImage[0]?.imagePath} title={item.VisitAssessmentImage[0]?.comment} className="img-fluid show-image-modal" style={{ cursor: 'pointer' }}
                                                                                    onClick={e => this.handleModalPopup(e, 'viewAssessmentImage', { imagePath: item.VisitAssessmentImage[0].imagePath, comment: item.VisitAssessmentImage[0].comment }, 'img')} />
                                                                            </div>

                                                                        ))}
                                                                    </div>
                                                                </li>
                                                            </div>
                                                        </li> : null}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}

                                {soap_plan ? <div className="col-md-6 mb-3" id="plan">
                                    <div className="card todays-visit-card card-plan">
                                        <div className="card-header bg-white p-0">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <label className="card-title border-right mb-0 px-3">P</label>
                                                </div>
                                                <div className="col pl-0 align-self-center">
                                                    <a href="#" className="d-block nostyle-link" onClick={(e) => {
                                                        let slug = '';
                                                        if (soap_vaccination_chart)
                                                            slug = 'vaccination-record';
                                                        else if (soap_medicines)
                                                            slug = 'prescription';
                                                        else if (soap_tests)
                                                            slug = 'tests-advised';
                                                        else if (soap_instructions)
                                                            slug = 'instructions';

                                                        if (slug != '') {
                                                            this.handlePlanRoute(e, slug);
                                                        }
                                                        return false;
                                                    }}>
                                                        <span className="font-weight-semibold">PLAN</span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="list-group list-group-flush ">
                                                        {/* <li className="list-group-item">
                                                                <NavLink exact={true} className="d-block nostyle-link" to={{
                                                                pathname: `/patient/vaccination-record/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                                                                state: { patient: patientInfo }
                                                                }}>Vaccination Chart</NavLink>
                                                        </li> */}

                                                        {soap_vaccination_chart && this.state.isPaediatrics == 1 ? <li className="list-group-item"><a href="#" className="d-block nostyle-link" onClick={(e) => this.handlePlanRoute(e, "vaccination-record")}>Vaccination Chart</a>

                                                        </li> : null}

                                                        {soap_medicines ?
                                                            <>
                                                                {medicineData !== null ? <li className={`list-group-item accordion_list ${medicineData.length > 0 ? 'haslist' : ''}`}>
                                                                    <a data-toggle="collapse" data-target="#medicine-details" aria-expanded="false" aria-controls="medicine-details" href="#" className="d-block nostyle-link collapsed">
                                                                        <span onClick={(e) => this.handlePlanRoute(e, "prescription")}>Medicines</span>
                                                                    </a>
                                                                    <div id="medicine-details" className="text-muted collapse haslist_item">
                                                                        {medicineData?.map((item, i) => {
                                                                            return (
                                                                                <div className="accordion_item_row" key={i}>
                                                                                    <div className="accordion_item_head collapsed" data-toggle="collapse" data-target={`#plan_med_collapse_${item.prescriptionDrugId}`} aria-expanded="true" aria-controls={`plan_med_collapse_${item.prescriptionDrugId}`}>
                                                                                        {item.drug_name}
                                                                                    </div>
                                                                                    <div className="accordion_item_body collapse" id={`plan_med_collapse_${item.prescriptionDrugId}`} data-parent="#medicine-details">
                                                                                        <table className="table table-borderless table-sm mb-2 text-muted">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td width="25%" className="font-weight-semibold">Urgency</td>
                                                                                                    <td width="25%">: {item.urgency ? DRUG_URGENCY[item.urgency] : '-'}</td>
                                                                                                    <td width="25%" className="font-weight-semibold">Instruction</td>
                                                                                                    <td width="25%">: {this.handleDrugInstructionDataShow(item)}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="font-weight-semibold">Route</td>
                                                                                                    <td>: {this.handleDrugRouteDataShow(item)}</td>
                                                                                                    <td className="font-weight-semibold">Start Date</td>
                                                                                                    <td>: {item.start_date && item.start_date != "0000-00-00" ? <Moment parse="YYYY-MM-DD" date={item.start_date} format=" D MMM, YYYY" /> : '-'}</td>
                                                                                                </tr>

                                                                                                {details.medicines?.filter(subItem => subItem.drug_id == item.drug_id).length == 1 ? (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td className="font-weight-semibold">Frequency</td>
                                                                                                            <td>: {this.handleFrequencyDataShow(item)}</td>
                                                                                                            <td className="font-weight-semibold">Duration</td>
                                                                                                            <td>: {item.number_of_days && item.number_of_days != null && item.number_of_days_type && item.number_of_days_type != null ? item.number_of_days +' '+item.number_of_days_type : '-'}</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td className="font-weight-semibold">Dosage</td>
                                                                                                            <td>: {this.handleDrugDosageDataShow(item)}</td>
                                                                                                            <td className="font-weight-semibold">Schedule</td>
                                                                                                            <td>: {this.handleFrequencyDataShow(item) && item.drug_frequency != 3 && item.schedule_name ? item.schedule_name : '-'}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ) : <tr></tr>}

                                                                                            </tbody>
                                                                                        </table>
                                                                                        {details.medicines?.filter(subItem => subItem.drug_id == item.drug_id).length > 1 ? (
                                                                                            <div className="taper_list">
                                                                                                <ul>
                                                                                                    {details.medicines?.filter(subItem => subItem.drug_id == item.drug_id).map((drug, i) => (
                                                                                                        <li key={i}>
                                                                                                            <h5>Taper{" "}{i + 1}</h5>
                                                                                                            <table className="table table-borderless table-sm mb-2 text-muted">
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td width="22%" className="font-weight-semibold">Frequency</td>
                                                                                                                        <td width="25%">: {this.handleFrequencyDataShow(drug)}</td>
                                                                                                                        <td width="25%" className="font-weight-semibold">Duration</td>
                                                                                                                        <td width="28%">: {drug.number_of_days && drug.number_of_days != null && drug.number_of_days_type && drug.number_of_days_type != null ? drug.number_of_days_type +' '+drug.number_of_days_type : '-'}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td className="font-weight-semibold">Dosage</td>
                                                                                                                        <td>: {this.handleDrugDosageDataShow(drug)}</td>
                                                                                                                        <td className="font-weight-semibold">Schedule</td>
                                                                                                                        <td>: {drug.drug_frequency != 3 && drug.schedule_name ? drug.schedule_name : '-'}</td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </li>
                                                                                                    ))}

                                                                                                </ul>
                                                                                            </div>
                                                                                        ) : ""}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </li> : <li className="list-group-item accordion_list"><a href="#" className="nostyle-link" onClick={(e) => this.handlePlanRoute(e, "prescription")}>Medicines</a></li>}
                                                            </>
                                                            :
                                                            null
                                                        }

                                                        {soap_tests ?
                                                            <>
                                                                {tests !== null ? <li className={`list-group-item accordion_list ${tests.length > 0 ? 'haslist' : ''}`}>
                                                                    <a data-toggle="collapse" data-target="#tests-details" aria-expanded="false" aria-controls="tests-details" href="!#" className="d-block nostyle-link collapsed">
                                                                        <span onClick={(e) => this.handlePlanRoute(e, "tests-advised")}>Tests</span>
                                                                    </a>

                                                                    <div id="tests-details" className="text-muted collapse haslist_item">
                                                                        <ul className="list-group list-group-flush ">
                                                                            {tests?.map(item => (
                                                                                <li key={item.id} className="list-group-item text-muted">{item.test_name}
                                                                                    <a href className="nostyle-link remove-test float-right" data-id={item.id} test-index={0} onClick={() => this.setState({ isDeleteModl: true, isDelRefId: item.id })}><i className="icon_delete" /></a>
                                                                                </li>
                                                                            ))}
                                                                        </ul>

                                                                    </div>
                                                                </li> : <li className="list-group-item accordion_list"><a href="#" className="nostyle-link" onClick={(e) => this.handlePlanRoute(e, "tests-advised")}>Tests</a></li>}
                                                            </>
                                                            :
                                                            null
                                                        }

                                                        {soap_instructions ? <li className="list-group-item"><a href="#" className="d-block nostyle-link" onClick={(e) => this.handlePlanRoute(e, "instructions")}>Instructions</a>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <ul className="custom-list list-success text-muted">
                                                                        {instructions?.map(item => (
                                                                            <li key={item.instruction_id}>{item.instruction}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li> : null}

                                                        {supportingMaterials ?
                                                            <>
                                                                {supportingMaterials !== null ? <li className={`list-group-item accordion_list ${supportingMaterials.length > 0 ? 'haslist' : ''}`}>
                                                                    <a data-toggle="collapse" data-target="#material-list" aria-expanded="false" aria-controls="tests-details" href="!#" className="d-block nostyle-link collapsed">
                                                                        <span onClick={(e) => this.handlePlanRoute(e, "instructions")}>Supporting Materials</span>
                                                                    </a>
                                                                    <div id="material-list" className="text-muted collapse haslist_item">
                                                                        <ul className="">
                                                                            {supportingMaterials?.map(item => (
                                                                                <li key={item.id} style={{ color: "black" }} className="">
                                                                                    <a style={{ color: "#6699cc" }} onClick={e => this.openSupportingFile(e, item.file_url, item.file_name)}>{item.file_name}</a>
                                                                                </li>

                                                                            ))}
                                                                        </ul>

                                                                    </div>
                                                                </li> : <li className="list-group-item accordion_list"><a href="#" className="nostyle-link" onClick={(e) => this.handlePlanRoute(e, "instructions")}>Supporting Materials</a></li>}
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}

                            </div>
                            {referrals?.map((item, i) => (
                                <div className="row" id="referral" key={item.id}>
                                    <div className="col-12 mb-3">
                                        <div className="card rounded-0">
                                            <div className="card-body py-3">
                                                <a href="#" className="nostyle-link text-dark">
                                                    <div className="row" onClick={(e) => { this.state.allowEdit && this.handleReferralRoute(e) }}>
                                                        <div className="col-10 col-md-11">

                                                            <span className="font-weight-semibold">REFERRAL {i + 1}</span>

                                                            <span className="d-block d-lg-inline pl-0 pl-lg-5">Speciality : <strong>{item.speciality.name != '' ? item.speciality.name : "-"}</strong></span>

                                                            <span className="d-block d-lg-inline pl-0 pl-lg-5">Doctor's Name : <strong>{item.doctor.doctor_name != '' ? item.doctor.doctor_name : '-'}</strong> </span>

                                                            <span className="d-block d-lg-inline pl-0 pl-lg-5">Priority : <span className="text-danger">{item.priorityname}</span></span>

                                                            <span className="d-block d-lg-inline pl-0 pl-lg-5">Doctor's Note : <strong>{item.instructions}</strong></span>
                                                        </div>
                                                        <div className="col-2 col-md-1 align-self-center">
                                                            <span className="float-right chevron chevron-right" />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className={`row ${referrals?.length > 0 ? "d-none" : ""}`} id="referral">
                                <div className="col-12 mb-3">
                                    <div className="card rounded-0">
                                        <div className="card-body py-3">
                                            <a href="#" className="nostyle-link text-dark">
                                                <div className="row" onClick={(e) => { this.state.allowEdit && this.handleReferralRoute(e) }}>
                                                    <div className="col-10 col-md-11">

                                                        <span className="font-weight-semibold">REFERRAL</span>
                                                    </div>
                                                    <div className="col-2 col-md-1 align-self-center">
                                                        <span className="float-right chevron chevron-right" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row emrfrm mb-3">
                                <div className="col-6">
                                    <div className="card rounded-0">
                                        <div className="card-body py-3">
                                            <div className="row">
                                                <div className="col-12 col-md-6 font-weight-semibold text-dark float-left">PHYSIOTHERAPY ADVISED</div>
                                                <div className="col-12 col-md-6 text-md-right">
                                                    <Radio.Group disabled={!this.state.allowEdit} onChange={e => this.handleRadioChange(e, 'physiotherapySave')} name='physiotherapy_advised' value={physiotherapy_advised}>
                                                        <Radio value={1} name="physiotherepy_radio">Yes</Radio>
                                                        <Radio value={2} name="physiotherepy_radio">No</Radio>
                                                    </Radio.Group>
                                                </div>

                                                <div className={`col-12 mt-3 ${physiotherapy_advised == 2 ? "d-none" : ""}`} id="therapy_text">
                                                    <div className={`form-group ${physioText != '' ? 'hasdata' : ''}`}>
                                                        <textarea disabled={!this.state.allowEdit} onInput={(e) => this.autoHeight(e)} rows={1} id="comment" className="form-control autoHeightText"
                                                            placeholder="Therapy Required" value={physioText} onBlur={this.physiotherapySave}
                                                            onChange={(e) => this.setState({ physioText: e.target.value })} />
                                                        <label htmlFor="comment">Therapy Required</label>
                                                    </div>
                                                    {/* <button type="button" className="float-right btn btn-outline-secondary" onClick={this.physiotherapySave}>SAVE</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card rounded-0">
                                        <div className="card-body py-3">
                                            <div className="row">
                                                <div className="col-8 font-weight-semibold mb-1">
                                                    <label className="d-inline font-weight-semibold text-dark mr-3">ADMISSION ADVISED </label>
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <input disabled={!this.state.allowEdit} type="checkbox" className="custom-control-input" id="admission_advised" name="admissionChecked"
                                                            checked={admissionChecked} onChange={this.handleAdmissionAdvicedCheckbox} />
                                                        <label className="custom-control-label text-basic" htmlFor="admission_advised">&nbsp;</label>
                                                    </div>
                                                </div>
                                                {admissionChecked ? (
                                                    <>
                                                        <div className="col-4 font-weight-semibold mb-1">
                                                            <div className="col-12 ant_switch_button_sm" id="emer_elect" >
                                                                <BootstrapSwitchButton
                                                                    width={150} height={24}
                                                                    size="xs"
                                                                    checked={isAdmissionAdvisedPriority}
                                                                    offlabel='Elective'
                                                                    onlabel='Emergency'
                                                                    offstyle="success"
                                                                    onstyle="alert"
                                                                    onChange={this.handleToggleAdmission}
                                                                    disabled={!this.state.allowEdit}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*<div className={`col-12 mt-3 ${admissionChecked? '':'none'}`}>
                                                            <textarea onInput={(e) => this.autoHeight(e)} cols={50} rows={1} className="form-control pull-left" id="admission_comment"
                                                                value={admissionComment} onChange={(e) => this.setState({ admissionComment: e.target.value })} maxLength={255}  />
                                                            <button type="button" className="float-right btn btn-outline-secondary mt-3" onClick={this.admissionSave}>SAVE</button>
                                                        </div>*/}

                                                        <div className={`col-12 mt-3 ${admissionChecked == 2 ? "d-none" : ""}`} id="therapy_text">
                                                            <div className={`form-group ${admissionComment != '' ? 'hasdata' : ''}`}>
                                                                <textarea onInput={(e) => this.autoHeight(e)} rows={1} id="admission_comment" className="form-control autoHeightText"
                                                                    placeholder="Therapy Required" value={admissionComment} onBlur={this.handleAdmissionAdvicedComment} disabled={!this.state.allowEdit}
                                                                    onChange={(e) => this.setState({ admissionComment: e.target.value })} />
                                                                <label htmlFor="admission_comment">Admission Advised Remark</label>
                                                            </div>
                                                            {/* <button type="button" className="float-right btn btn-outline-secondary" onClick={this.physiotherapySave}>SAVE</button> */}
                                                        </div>
                                                    </>
                                                ) : ""}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row emrfrm pb-5" id="followup">
                                <div className="col-12 mb-4" style={{ display: this.state.admissionChecked ? "none" : '' }}>
                                    <div className="card rounded-0">
                                        <div className="card-body py-3">
                                            <div className="row" >
                                                <div className="col-12 col-xl">
                                                    <label className="d-md-inline font-weight-semibold text-dark mr-3">FOLLOW UP (NUMBER OF DAYS)</label>
                                                    <div className="mr-2 d-md-inline">
                                                        <Radio.Group onChange={this.handleFollowUpRadio} checked={followUpRadio} value={followUpRadio} disabled={!this.state.allowEdit}>
                                                            <Radio value={3} name="followUpRadio" title={'0'}>3</Radio>
                                                            <Radio value={5} name="followUpRadio" title={'0'}>5</Radio>
                                                            <Radio value={7} name="followUpRadio" title={'0'}>7</Radio>
                                                            <Radio value={15} name="followUpRadio" title={'0'}>15</Radio>
                                                            <Radio value={30} name="followUpRadio" title={'0'}>30</Radio>
                                                            <Radio value={60} name="followUpRadio" title={'0'}>60</Radio>
                                                            <Radio value={-1} name="followUpRadio" title={'-1'}>Other</Radio>
                                                        </Radio.Group>

                                                    </div>
                                                    <div className="d-md-inline inline-input-other">
                                                        <div className="form-label-group input-group" id="followup_other_block" style={{ display: this.state.followUpRadio === -1 ? "flex" : "none" }}>
                                                            <input type="text" id="followup_other_value" className="form-control " name="otherVal" maxLength="3"
                                                                value={this.state.otherVal} onChange={this.handleOnChange} onBlur={() => this.onbooknow()} disabled={!this.state.allowEdit} />
                                                            <span className="input-group-addon bg-white">
                                                                <select className="custom-select" value={this.state.follow_up_Others} select={this.state.follow_up_Others}
                                                                    name="follow_up_Others" onChange={e => this.handleOnChange(e, () => this.onbooknow())} disabled={!this.state.allowEdit}>
                                                                    <option value={'days'}>Days</option>
                                                                    <option value={'weeks'}>Weeks</option>
                                                                    <option value={'months'}>Months</option>
                                                                    <option value={'years'}>Years</option>
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-auto">
                                                    <button type="button" className="float-right btn btn-outline-secondary text-uppercase"
                                                        disabled={!this.state.allowEdit ? true : this.state.bookNowAllow}
                                                        onClick={() => this.onbooknow(true)} >BOOK NOW</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fixed-cta">
                                <button disabled={this.state.disableConfirmButton} className="btn btn-primary text-uppercase px-5" onClick={this.handleViewPresc}>View Prescription</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="col d-flex justify-content-center align-self-center mt-2">
                        {LOADER_RED}
                    </div>
                )}

                <Modal id="alert_confirm_message"
                    className="alert_confirm_message"
                    title='Are you sure you want to delete?'
                    visible={this.state.isDeleteModl}
                    onOk={() => this.handleTestDelete()}
                    bodyStyle={{ padding: 0 + 'px' }}
                    onCancel={() => this.setState({ isDeleteModl: false })}
                    closable={false}
                    footer={[
                        <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.setState({ isDeleteModl: false })}>
                            CANCEL
                        </Button>,
                        <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={this.handleTestDelete}>
                            DELETE
                        </Button>,
                    ]}
                >
                </Modal>

                {/** Document Viewer */}
                <Modal
                    title={this.state.docComment === '' ? (patient_docments?.length === 1 ? "Document" : "Documents") : 'Image Reference'}
                    visible={this.state.viewDocumentModal}
                    footer={false}
                    width={850}
                    onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
                    style={{ top: 20 }}
                >
                    <div className="pdf_slider">
                        {
                            patient_docments?.length > 0 ? (
                                <Carousel arrows dots={false} ref={this.carouselRef}>
                                    {
                                        patient_docments?.map((file, index) => (
                                            file.type === 'img' ?
                                                <figure key={index}><Image className="mr-3" src={file.src} alt='uploaded Doc' /></figure>
                                                :
                                                <iframe key={index} className="mr-3" src={file.src + "#toolbar=0"} ></iframe>
                                        ))
                                    }

                                </Carousel>
                            ) : (
                                <>
                                    <figure><img className="mr-3 w-100" src={this.state.docSrc} alt='uploaded Doc' /></figure>
                                    <div className="text-center">
                                        <strong style={{ fontSize: '16px' }}>
                                            {this.state.docComment}
                                        </strong>
                                    </div>
                                </>)
                        }
                    </div>
                </Modal>

                <Modal
                    title={'Image Reference'}
                    visible={this.state.viewAssessmentImage}
                    footer={false}
                    width={850}
                    onCancel={e => this.handleModalPopup(e, 'viewAssessmentImage')}
                    style={{ top: 20 }}
                >
                    <div className="pdf_slider">
                        {
                            <>
                                <figure><Image className="mr-3 w-100" src={this.state.docSrc} alt='uploaded Doc' /></figure>
                                <div className="text-center">
                                    <strong style={{ fontSize: '16px' }}>
                                        {this.state.docComment}
                                    </strong>
                                </div>
                            </>
                        }
                    </div>
                </Modal>

                {/** || isPracticing Modal */}
                <Modal
                    title={false}
                    closable={false}
                    visible={this.state.isPracticingModal}
                    footer={false}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                            <h6>Do you want to Start Practice?</h6>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
                        <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
                    </div>
                </Modal>

                <Modal
                    title={false}
                    closable={true}
                    visible={this.state.isReferralModal}
                    footer={false}
                    onCancel={e => this.handleModalPopup(e, 'isReferralModal')}
                >
                    <div className="row">
                        <div className="col-12 mb-3 text-left"><h6>REFERRAL DETAILS</h6></div>
                        {referralDetailsArr?.map((element, index) => (
                            (index != 0) ?
                                <div className="col-12 mb-3" id="patient_notes_docs">
                                    <div className="card bg-grey rounded-0">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className="col-12">
                                                    <div className="">
                                                        <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Referral {index + 1}</h6>
                                                        <p className='mb-1'>Referral Date & Time : <span className="text-dark">{element.referral_date_time}</span></p>
                                                        <p className='mb-1'>Referral By : <span className="text-dark">Dr {element.referral_by}</span></p>
                                                        <p className='mb-1'>Referral Note : <span className="text-dark">{element.referral_note}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        ))}
                        <div className="col-12 text-center mt-3">
                            <button type="button" className="btn btn-outline-secondary px-4 small  mr-2" onClick={e => this.handleModalPopup(e, 'isReferralModal')}>CANCEL</button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="View Supporting Material"
                    visible={this.state.showPdfModal}
                    footer={false}
                    width={850}
                    onCancel={() => { this.setState({ showPdfModal: false, pdfPath: null }) }}
                    style={{ top: 20 }}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                        {this.state.pdfPath != null && this.state.fileType == 'pdf' ? <iframe src={this.state.pdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                                {LOADER_RED}
                            </iframe> : null}
                            {this.state.pdfPath != null && this.state.fileType != 'pdf' ?
                                <img src={this.state.pdfPath} alt={this.state.pdfPath} style={{ width: '100%' }} />
                                : null}
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default TodaysvisitSoap
