import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { CHECK_LATE_ALERT_STATUS, LATE_ALERT, LOADER_RED, USERTYPES } from '../../utils/constant';
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import $ from 'jquery';
import { jQueryLabels, onlynumbers } from '../../utils/jQueryLabels';

class LateComponent extends Component {
	state = {
		isModalVisible: false,
		modalResponseMsg: '',
		AlertStatus: false,
		late_time: '15',
		comment: '',
		loadData: false,
		docDropDownList: []
	}
	constructor(props) {
		super(props);
	}

	static getDerivedStateFromProps() {
		Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
		return null;
	}

	setAlert = (event) => {
		this.setState({ late_time: event.target.value });
		if (event.target.value === "0") {
			ReactDOM.findDOMNode(this.refs.other_mins).classList.remove('d-none');
		} else {
			ReactDOM.findDOMNode(this.refs.other_mins).classList.add('d-none');
		}

	}

	componentDidMount() {
		$(document).ready(function () {
			jQueryLabels();
			onlynumbers();
		})

		const is_doc = LS_SERVICE.get('user_type')

		if (is_doc === 1) {
			this.checkLateAlertStatus()
		}

		// const docsList = LS_SERVICE.get('nurse_doctors')
		if (LS_SERVICE.get('user_type') != USERTYPES.doctor) { //2: nurse, 1: doctor
			this.setState({
				docDropDownList: LS_SERVICE.get('nurse_doctors')
			})
		}

	}


	hideModal = () => {
		this.setState({ isModalVisible: false });
		this.props.history.push("/dashboard");
	}
	hideModalEpt = () => {
		this.setState({ isModalVisible: false });
	}
	checkLateAlertStatus = (doc_id) => {
		const hospital_id = LS_SERVICE.get('slot_hospital_id');
		const doctor_id = doc_id ?? LS_SERVICE.get('staff_id');
		Axios.get(CHECK_LATE_ALERT_STATUS + "?" + qs.stringify({ hospital_id: hospital_id, doctor_id: doctor_id }))
			.then(response => {
				//console.log(response);
				this.setState({
					AlertStatus: response.data.data.details.status,
					modalResponseMsg: response.data.data.details.message,
				});
				if (response.data.data.details.message !== "") {
					this.setState({ isModalVisible: true });
				}
				//debugger;
			})
	}


	handleConfirm = (event) => {
		event.preventDefault();
		let late_time = this.state.late_time;
		const comment = ReactDOM.findDOMNode(this.refs.comment).value;
		const hospital_id = LS_SERVICE.get('slot_hospital_id');
		const doctor_id = LS_SERVICE.get('staff_id');
		let othersValue = ReactDOM.findDOMNode(this.refs["othersValue"]).value;
		if (late_time === "0") {
			late_time = othersValue;
		}
		if (comment !== '' && late_time !== '') {

			const PARAMS = {
				late_time,
				comment,
				hospital_id,
				doctor_id
			}
			this.setState({
				loadData: true
			})
			Axios.post(LATE_ALERT, qs.stringify(PARAMS))
				.then(response => {
					//console.log(response);
					this.setState({
						AlertStatus: response.data.data.details.status,
						modalResponseMsg: response.data.data.details.message,
						loadData: false,
						isModalVisible: true
					});
					//debugger;
				}).catch(error => {
					// if (error && error.response && error.response.status === 401) {
					// 	LS_SERVICE.clear();
					// 	this.props.history.push("/");
					// }
				})
		} else {
			if (late_time === "") {
				let emptyValue = 'Please enter the time (in minutes).';
				this.setState({
					modalResponseMsg: emptyValue,
					loadData: false,
					isModalVisible: true
				})
			}
			if (comment === "") {
				let emptyComment = 'Please enter reason for being late.'
				this.setState({
					modalResponseMsg: emptyComment,
					loadData: false,
					isModalVisible: true
				});
			}
		}
	}

	handleLateLalert = (e) => {
		const value = JSON.parse(e.target.value)
		this.checkLateAlertStatus(value.staff_id)
	}

	render() {

		let isEmptyComment = this.state.comment;
		let late_time = this.state.late_time;
		let button;
		if (this.state.AlertStatus === false && (isEmptyComment === '' || late_time === '')) {
			button = <div className="btn btn-outline-primary px-5" onClick={this.hideModalEpt}>OK</div>;
		} else {
			button = <div className="btn btn-outline-primary px-5" onClick={this.hideModal}>OK</div>;
		}

		return (
			<>
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<form className="emrfrm">
								{LS_SERVICE.get('user_type') != USERTYPES.doctor ? ( //2: nurse, 1: doctor
									<div className="row">
										<div className="col-12 col-md-3">
											<div className="form-group hasdata">
												<select
													className="form-control drug-input mandatory-field"
													data-name="doctor"
													id="doctor_id"
													onChange={this.handleLateLalert}
												>
													<option selected disabled>Select Doctor</option>
													{this.state.docDropDownList?.map(doc => (
														<option value={JSON.stringify(doc)} key={doc.id}>
															{`Dr.${doc.first_name} ${doc.last_name} | ${doc.speciality[0].names}`}
														</option>
													))}

												</select>
												<label className="required" htmlFor="">Select Doctor<span>*</span></label>
											</div>
										</div>
									</div>
								) : ""}

								<h5 className="text-dark">Select Delay in Time</h5>
								<div className="row">
									<div className="col-12">
										<div className="custom-control custom-radio custom-control-inline mt-3 mr-5">
											<input type="radio" id="alert_15" name="alert" className="custom-control-input pr-5" value="15" defaultChecked onChange={this.setAlert} />
											<label className="custom-control-label pl-3" htmlFor="alert_15">Late by 15 mins</label>
										</div>
										<div className="custom-control custom-radio custom-control-inline mt-3">
											<input type="radio" id="alert_30" name="alert" className="custom-control-input pr-5" value="30" onChange={this.setAlert} />
											<label className="custom-control-label pl-3" htmlFor="alert_30">Late by 30 mins</label>
										</div>
										<div className="custom-control custom-radio custom-control-inline mt-3">
											<input type="radio" id="alert_45" name="alert" className="custom-control-input pr-5" value="45" onChange={this.setAlert} />
											<label className="custom-control-label pl-3" htmlFor="alert_45">Late by 45 mins</label>
										</div>
										<div className="custom-control custom-radio custom-control-inline mt-3">
											<input type="radio" id="alert_60" name="alert" className="custom-control-input pr-5" value="60" onChange={this.setAlert} />
											<label className="custom-control-label pl-3" htmlFor="alert_60">Late by 60 mins</label>
										</div>
										<div className="custom-control custom-radio custom-control-inline mt-3">
											<input type="radio" id="alert_other" name="alert" className="custom-control-input pr-5" value="0" onChange={this.setAlert} />
											<label className="custom-control-label pl-3" htmlFor="alert_other">Others</label>
										</div>
										<div className="custom-control custom-radio custom-control-inline mt-3 d-none" id="other_mins" ref='other_mins'>
											<input type="text" className="ml-3 form-control onlynumbers" id="late-others-input" style={{ maxWidth: "30%", textAlign: "center" }} maxLength="3" autoComplete="off" ref="othersValue" />
									  &nbsp;&nbsp;&nbsp;<label className="mt-2">Mins</label>
										</div>
									</div>
									<div className="col-12 mt-5">
										<div className="form-group">
											<textarea id="reason" className="form-control focus" maxLength="200" placeholder="Max 200 char." ref='comment' disabled={this.state.AlertStatus === true ? 'disabled' : ''}></textarea>
											<label htmlFor="reason">Reason<span className="text-danger">*</span>(MAX 200 Chracters)</label>
										</div>
									</div>
								</div>
								<div className="row">
									<div className={`col-md-12 align-items-center justify-content-center mt-4
                                                        ${this.state.AlertStatus === false && this.state.loadData === true ? 'd-flex' : 'd-none'}
                                                    `}>
										{LOADER_RED}
									</div>

									<div className="col-md-12 text-center mt-5">
										<button className="btn btn-primary px-5 text-uppercase" disabled={this.state.AlertStatus === true ? 'disabled' : ''} onClick={this.handleConfirm}>Confirm</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<Modal
					title={false}
					closable={false}
					visible={this.state.isModalVisible}
					footer={false}
					width="400px"
				>
					<div className="row">
						<div className="col-12 my-3 text-center">
							<h6 id="pending-patients-count" ref="modalMsg">{this.state.modalResponseMsg}</h6>
						</div>
					</div>
					<div className="modal-footer justify-content-center pb-0">
						{button}
						{/* <div className="btn btn-outline-primary px-5"  onClick={this.hideModal}>OK</div> */}
					</div>
				</Modal>

			</>
		);
	}
}

export default LateComponent;