import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import { GET_VITALS_RANGE_CLASS } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Popover } from 'antd';

class KeyFindings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: props.dataLoaded,
      constants: props.constants,
      KeyFindingsConstants: Object.values(props.constants.findingGroupData),
      visitKeyFindingsFormatted: props.visitKeyFindingsFormatted ?? [],
      keyFindingsOrder: props.keyFindingsOrder,
      newKeyFindingsOrder: []
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.visitKeyFindingsFormatted !== prevState.visitKeyFindingsFormatted)
      return { visitKeyFindingsFormatted: nextProps.visitKeyFindingsFormatted }
    if (nextProps.keyFindingsOrder !== prevState.keyFindingsOrder)
      return { keyFindingsOrder: nextProps.keyFindingsOrder }
    return null;
  }
  componentDidMount (){
    this.updateKeyFindingsOrder();
  }
  componentDidUpdate(prevProps, prevState) {
    if ((this.state.keyFindingsOrder != null && this.state.keyFindingsOrder != prevState.keyFindingsOrder) || (this.state.visitKeyFindingsFormatted != null && this.state.visitKeyFindingsFormatted != prevState.visitKeyFindingsFormatted)) {
      this.updateKeyFindingsOrder();
    }
  }

  updateKeyFindingsOrder = async () => {
    let new_key_findings_order = [];
    this.state.keyFindingsOrder.map((fo, k2) => {
      let obj = {display_order: fo.display_order, finding_group_id: fo.finding_group_id, vital_sign: []};
      let at_least_one_av = false;
      let findex = this.state.KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
      if(findex !== -1){
        fo.vital_sign.map((v, k) => {
          let obj_vital_sign = {...v};
          let findex2 = this.state.KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
          if(findex2 !== -1){
            let vital = this.state.KeyFindingsConstants[findex].vital_sign[findex2];
            let keyfindingsavailable = false;
            this.state.visitKeyFindingsFormatted[vital.id]?.map((val, i) => {
              if (val && val != "") {
                keyfindingsavailable = true;
                at_least_one_av = true;
              }
            })
            obj_vital_sign.value_available = keyfindingsavailable;
            obj.vital_sign.push(obj_vital_sign);
          }
        });
      }
      obj.one_value_available = at_least_one_av;
      new_key_findings_order.push(obj);
    });
    await this.setState({
      newKeyFindingsOrder: new_key_findings_order
    })
  }

  getSourceDisplayName(source, date, update_date) {
    let sourceName = source[0].toUpperCase() + source.substring(1);
    let time = moment(update_date).format('DD MMM, h:mm A');
    return source == 'patient' && date && update_date && date != update_date ? sourceName + ' | ' + time : sourceName;
  }

  render() {
    let one_group_avail = false;
    this.state.newKeyFindingsOrder.forEach((data) => {
      if(!one_group_avail){
        one_group_avail = data.one_value_available;
      }
    });
    return (
      <>
      {this.state.dataLoaded ? (
        <div className="card-body vitals">
          <div className="row">
            <div className="col-12 table-responsive" style={{ padding: "0px" }}>
              <table className="table">
                <tbody>
                  {this.state.newKeyFindingsOrder.length && one_group_avail ? 
                    <tr>
                      <td className="text-bold"></td>
                        {this.state?.visitKeyFindingsFormatted?.date.map((v, i) => (
                          <td key={i} className={`pl-0 pr-0 ${this.state.visitKeyFindingsFormatted.source[i] === 'patient' && 'bgis-pink'}`} style={{ maxWidth: "250px" }}>
                            <div className="row">
                              <div className="col-12 text-center vital-source">
                                {this.state.visitKeyFindingsFormatted?.source_name[i] !== "" ? (
                                  <Popover
                                    className="ml-1"
                                    content={
                                      this.state.visitKeyFindingsFormatted?.source_name[i]
                                    }
                                  >
                                    {this.getSourceDisplayName(this.state.visitKeyFindingsFormatted.source[i], v, this.state.visitKeyFindingsFormatted.update_date[i])}
                                  </Popover>
                                ) : (
                                  this.getSourceDisplayName(this.state.visitKeyFindingsFormatted.source[i], v, this.state.visitKeyFindingsFormatted.update_date[i])
                                )}
                              </div>
                              <div className="col-12 text-center vital-time">
                                <Moment parse="YYYY-MM-DDTHH:mm:ss.SSSSZ" format="DD MMM, yyyy | h:mm A" date={v} />
                              </div>
                            </div>
                          </td>
                        ))}
                      <td className="text-center text-bold" style={{width: '132px'}}>
                        Reference Range
                      </td>
                      <td className="text-center text-bold">
                        Trend Graph
                      </td>
                    </tr>
                  : <tr><td className="pt-2">No Record Found</td></tr>}
                  
                    {this.state.newKeyFindingsOrder.map((fo, k2) => {
                      let findex = this.state.KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
                      if(findex !== -1){
                        if(fo.one_value_available){
                          return (
                            <React.Fragment key={k2}>
                              <tr>
                                <td className="text-bold">{this.state.KeyFindingsConstants[findex].name}</td>
                                {this.state?.visitKeyFindingsFormatted?.date.map((v, i) => (
                                  <td key={i}></td>
                                ))}
                                <td></td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn chart-btn button-chart-individual"
                                    onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModal', { type: 'finding_group', id: fo.finding_group_id, name: 'Key Findings Trend Graphs' })}
                                  >
                                    <i className="icon-chart"></i>
                                  </button>
                                </td>
                              </tr>
                              {fo.vital_sign.map((v, k) => {
                                let findex2 = this.state.KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
                                if(findex2 !== -1){
                                  let vital = this.state.KeyFindingsConstants[findex].vital_sign[findex2];
                                  if(v.value_available){
                                    return (
                                      <tr key={v.vital_id}>
                                        <td>{vital.display_name} {vital.display_value ? '(' + vital.display_value + ')' : ''}</td>

                                        {this.state.visitKeyFindingsFormatted[vital.id]?.map((val, i) => {
                                          if (val) return (<td key={i} className={`text-center ${GET_VITALS_RANGE_CLASS(val, this.state.constants.vitals_range[vital.id])} ${this.state.visitKeyFindingsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>{val}</td>)
                                          else return (<td key={i} className={`text-center ${this.state.visitKeyFindingsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>-</td>);
                                        })}
                                        <td>{this.state.constants.vitals_range[vital.id].normal.min + '-' + this.state.constants.vitals_range[vital.id].normal.max}</td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn chart-btn button-chart-individual"
                                            onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModal', { type: 'individual', id: vital.id, name: 'Key Findings Trend Graph' })}
                                          >
                                            <i className="icon-chart"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  }
                                }
                              })}
                            </React.Fragment>
                          );
                        }
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>) : ""}
      </>
    );
  }
}

export default KeyFindings;
