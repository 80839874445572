import React, { Component } from "react";
import LMPCalender from "./LMPCalender";

class LMP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLMPCalender: false
    }
    this.toggleLMPCalender = this.toggleLMPCalender.bind(this);
  }

  static getDerivedStateFromProps(props) {
    const { patient, patientDetails } = props;
    return {
      patient: patient,
      patientDetails: patientDetails
    };
}

  toggleLMPCalender() {
    this.setState({ showLMPCalender: !this.state.showLMPCalender });
  }

  render() {
    const {patient, patientDetails} = this.state;
    return (
      <>
        <a
          href="javascript:(void)"
          className="text-decoration-"
          onClick={this.toggleLMPCalender}
        >
          LMP
        </a>
        <span>:</span>{" "}
        <span className="font-weight-bold">
        {(patientDetails && patientDetails.lmp_date)? patientDetails.lmp_date : '-' }
        </span>
        {/* Modal For Menstrual Hx */}
        <LMPCalender
          hideLMPCalender={this.toggleLMPCalender}
          showLMPCalender={this.state.showLMPCalender}
          patient = {patient}
        />
      </>
    );
  }
}

export default LMP;
