import React, { Component } from 'react';
import axios from "axios";
import qs from 'qs';
import AsideLeft from "../../../components/aside/asideleft";
import { Pagination, Modal, Carousel } from 'antd';
import icJpg from '../../../assets/images/ic-jpg.png';
import icPdf from '../../../assets/images/ic-pdf.png';
import "./homecarepastvisit.scss";
import moment from 'moment';
import Moment from 'react-moment';
import {
  HC_GOBACK,
  HOMECAREPASTVISIT,
  LOADER_RED,
  SEPARATEUPLOADEDDOCS,
  PASTPRESCRIPTION_TAB_NAMES,
  ALL_PRESCRIPTIONS, USERTYPES, GETDOCURL
} from "../../../utils/constant";
import LS_SERVICE from "../../../utils/localStorage";

class HomecarePastVisit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_homecare: 1,
      results: [],
      patient: null,
      pagination: null,
      currentPage: 1,
      visits: [],
      isLoading: false,
      showRxModal: false,
      rxPdfPath: null,
      records_per_page: 10,
      total_records: 0,
      DataExist: false,
      uploads: [],
      documents: [],
      hospital_id: null,
      patient_id: null,
      booking_id: null,
      activeTab: PASTPRESCRIPTION_TAB_NAMES[0],
    }

    this.paginate = this.paginate.bind(this);
    this.navigateToPage = this.navigateToPage.bind(this);
    this.carouselRef = React.createRef();
  }

  handleTabChange = (e, tab) => {
    e !== null && e.preventDefault();
    this.setState({
      currentPage: 1,
      results: [],
      isLoading: true,
      total_records: 0,
    });

    this.setState({
      activeTab: tab
    }, this.tabContentShow)

    if (tab == 'vcopd') {
      this.primaryLoadData(this.state.currentPage);
    }
    else {
      this.getPrescriptions(this.state.currentPage);
    }

  }

  /*Initial Function*/
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    // console.warn("aaaa", params);
    const { hospital_id, patient_id, reference_id } = params;
    this.setState(
      {
        hospital_id: hospital_id,
        patient_id: patient_id,
        booking_id: reference_id,
        reference_id
      },
      () => {
        this.getPrescriptions(this.state.currentPage);
      }
    );
  }


  /* Go back button functionality start*/
  // goBack = (event) => {
  //     event.preventDefault();
  //     this.props.history.goBack();
  // }
  /* Go back button functionality end*/


  /* Get all past prescriptions start*/
  getPrescriptions(page) {
    const { patient_id, hospital_id, is_homecare } = this.state;

    this.setState({ isLoading: true });
    axios
      .get(HOMECAREPASTVISIT({ patient_id, hospital_id, is_homecare, page }))
      .then((res) => {
        //console.log('sachin',res.data.pagination);
        this.setState({
          currentPage: res.data.pagination.current_page,
          results: res.data.reports,
          isLoading: false,
          // visits: [],
          total_records: res.data.pagination.total,
          DataExist: true,
          pagination: res.data.pagination,
          total_pages: res.data.pagination.total_pages
        });
      })
  }

  primaryLoadData(page) {
    const { patient_id } = this.state;

    this.setState({ isLoading: true });

    const PARAMS = {
      patient_id: patient_id,
      page: page
    }
    axios.post(ALL_PRESCRIPTIONS, qs.stringify(PARAMS))
      .then(response => {
        this.setState({
          currentPage: response.data.pagination.current_page,
          results: response.data.past_visit ? response.data.past_visit : [],
          isLoading: false,
          total_records: response.data.pagination.total_pages,
          // visits: response.data.past_visit,
          pagination: response.data.pagination
        });
      });
  }
  /* Get all past prescriptions end*/


  /* Pagination start*/
  paginate(page) {
    this.getPrescriptions(page);
  }

  navigateToPage(page) {
    this.primaryLoadData(page);
  }
  /* Pagination end*/

  /* Trim string function to given maxlength start*/
  trimString(notesString) {
    var maxLength = 165;
    var trimmedString = "";
    // notesString = notesString.replaceAll('\\n', ' ');
    if (notesString && notesString.length < maxLength) {
      trimmedString = notesString
    }
    else if (notesString && notesString.length > trimmedString) {
      trimmedString = notesString.substr(0, maxLength);
      trimmedString = trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      );
      trimmedString = trimmedString + '...'
    }
    return trimmedString;
  }
  /* Trim string function to given maxlength end*/


  /* Open/close function start*/
  handleModalPopup = (e, popupName, data) => {

    if (e !== null) e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    }, () => {
      this.carouselRef.current.goTo(data);
    })
  }
  /* Open, close function start*/

  getType(file) {
    var type = file.slice((file.lastIndexOf(".") - 1 >>> 0) + 2);
    return type;
  }

  /* Show popup on click of image,pdf start*/
  showModal(fileData, index) {
    // console.log('======',index);

    let docs = [];
    for (let element of fileData) {
      docs.push({
        file: element,
        type: (element.substr(element.length - 3) === 'pdf') ? 'pdf' : 'img'
      });
    }


    this.setState({
      documents: docs,
      viewDocumentModal: true
    },
      () => {
        this.carouselRef.current.goTo(index);
      });

  }

  getMediaUrl = (filePath) => {
    this.setState({ isLoading: true });
      //console.log(" You clicked me", filePath);
      var filename = {
        f: filePath,
    };
    axios
    .post(GETDOCURL, qs.stringify(filename), { responseType: 'arraybuffer' })
    .then((response) => {
      //console.log('sachin',res.data.pagination);
      const blob = new Blob([response.data], { type: 'application/pdf' }); 
      const url = URL.createObjectURL(blob);
      this.setState({
        rxPdfPath: url, showRxModal: true,
        isLoading:false
      });
    })
  }
  /*Show popup on click of image,pdf end*/


  /* Past visit  for returning html start*/
  homecarepastvisits() {
    return this.state.results.map((result, index) => {

      const uploads = SEPARATEUPLOADEDDOCS(result.patient_docments);

      let htmlData = (
        <div className="mb-3" key={index}>
          <div className="card-bodycustom card-body p-3">
            <div className="row" style={{ flexWrap: 'nowrap' }}>
              <div className="col-md-2">
                <div>
                  <div className="text-dark font-weight-bold mb-0 card-title">{result.doctor_name}</div>
                  <h6 className="healtvc-apptime pv textis-primary mb-0 d-inline-flex justify-content-center align-items-center">
                    {this.state.activeTab == 'homecare' && result.prescription_date != null && (
                      moment(result.prescription_date).format("h:mm A, D MMM, Y")
                    )}
                    {this.state.activeTab == 'vcopd' && result.date != null && (
                      moment(result.date).format("h:mm A, D MMM, Y")
                    )}
                  </h6>
                </div>
              </div>
              <div className="col-md-5">
                <div className="pl-2">
                  <div className="text-dark font-weight-bold mb-1 card-title">
                    {result.patient_note_doctor && result.patient_note_doctor != '' ? (
                      <span className="tickRight"></span>
                    ) : null}
                                                Notes for Doctor</div>
                  <p className="card-light-gray pc-doctor-notes pcd-notes card-text">{result.patient_note_doctor && result.patient_note_doctor !== ''
                    ? this.trimString(result.patient_note_doctor)
                    : "Nil"}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pl-2">

                  <div className="text-dark font-weight-bold mb-1 card-title">
                    {!uploads.fileImg.length > 0 &&
                      !uploads.fileDoc.length > 0 ? null : (
                      <span className="tickRight"></span>
                    )}
                                                Documents Uploaded
                                            </div>
                  <p className="pc-doctor-notes card-text">{!uploads.fileImg.length > 0 &&
                    !uploads.fileDoc.length > 0
                    ? "Nil"
                    : null}
                    {uploads.fileImg.length > 0
                      ? uploads.fileImg.map((file, i) => {
                        return (
                          <a
                            disabled = {result.confidential_prescription}
                            className="btn btn-outline-light p-0 ml-1"
                            key={i} fieldf={i}
                            onClick={e => this.showModal([...uploads.fileImg, ...uploads.fileDoc], i)}
                          >
                            {/* <img alt='Image or PDF' src={icJpg} /> */}
                            <i className="icon-jpg"></i>
                          </a>
                        );
                      })
                      : null}
                    {uploads.fileDoc.length > 0
                      ? uploads.fileDoc.map((file, i) => {
                        return (
                          <a
                            disabled = {result.confidential_prescription}
                            className="btn btn-outline-light p-0 ml-1"
                            key={i} fieldf={uploads.fileImg.length + i}
                            onClick={e => this.showModal([...uploads.fileImg, ...uploads.fileDoc], uploads.fileImg.length + i)}
                          >
                            {/* <img alt='Image or PDF' src={icPdf} /> */}
                            <i className="icon-pdf"></i>
                          </a>
                        );
                      })
                      : null}</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="d-flex flex-column justify-content-around">
                  { result.confidential_prescription == false ?
                      <button onClick={() => this.getMediaUrl(result.upload_prescription)}
                      className="btn btn-sm float-right btn-primary text-uppercase show-rx-modal"
                      style={{ whiteSpace: 'nowrap' }}>VIEW RX</button>
                    :
                    <p class="text-secondary text-uppercase" >Confidential</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      return htmlData;
    });
  }
  /* Past visit  for returning html end*/

  allvisits() {
    return (
      <>
        {this.state.visits.map((visit, index) => (
          <div className={"card card1 border-0 mb-4 " + (index > 0 ? 'mt-4' : '')} key={index}>
            <div className="row">
              <div className="col-md-6">
                <button className="btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed" data-referencetype="0" data-referenceid="undefined" data-toggle="collapse" data-target="#collapseBA517H2L49" role="button" aria-expanded="false" aria-controls="collapseBA517H2L49">
                  <div className="float-left">
                    <span className="d-block font-weight-normal">{visit.doctor_name}</span>
                    <small className="mt-1">
                      {visit.date != null ? <Moment parse="YYYY-MM-DD HH:mm" date={visit.date} format="D MMM, YYYY h:mm A" /> : null}
                    </small>
                  </div>
                  <div className="float-right mt-2">
                    <button onClick={() => this.setState({ rxPdfPath: visit.upload_prescription, showRxModal: true })} className="btn btn-sm float-right btn-primary text-uppercase show-rx-modal">VIEW RX</button>
                  </div>
                </button>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }

  render() {
    const { results, total_pages, visits, patient, isLoading, documents, activeTab } = this.state;
    //console.log('>>> documents', documents);

    return (
      <>
        <AsideLeft isHomecare={true} />

        <div className="content-wrapper">
          <div className="container-fluid">

            <div className="row">
              <div className="col-12 mb-3 d-flex align-items-center justify-content-between">
                <div className="float-left">
                  <h1 className="page-title mb-0">Past Prescriptions</h1>
                </div>
                <div className="col mb-0">
                  <ul className="nav nav-pills justify-content-center myhealthcare-tabs mb-0" role="tablist">
                    <li className="nav-item">
                      <a id="homecare-tab" href="#homecare-list" className={`nav-link ${activeTab === PASTPRESCRIPTION_TAB_NAMES[0] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="list-tab" aria-selected="true" ref='HomecareTab'
                        onClick={e => this.handleTabChange(e, PASTPRESCRIPTION_TAB_NAMES[0])}>
                        HomeCare
                                        </a>
                    </li>
                    <li className="nav-item">
                      {/* <a id="vc-opd-tab" href="#vc-opd-list" className={`nav-link ${activeTab === PASTPRESCRIPTION_TAB_NAMES[1] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="list-tab" aria-selected="true" ref='VcOpdTab'
                        onClick={e => this.handleTabChange(e, PASTPRESCRIPTION_TAB_NAMES[1])}>
                        VC/OPD
                                        </a> */}
                    </li>
                  </ul>
                </div>
                <div className="float-right">
                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => HC_GOBACK(e, this.props, this.state)}>BACK TO PATIENT</button>
                </div>
              </div>
              {/* <div className="col-12 mb-0">
                            <ul className="nav nav-pills justify-content-center myhealthcare-tabs " role="tablist">
                                <li className="nav-item">
                                    <a id="homecare-tab" href="#homecare-list" className={`nav-link ${activeTab === PASTPRESCRIPTION_TAB_NAMES[0] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="list-tab" aria-selected="true" ref='HomecareTab'
                                        onClick={e => this.handleTabChange(e, PASTPRESCRIPTION_TAB_NAMES[0])}>
                                        HomeCare
                              </a>
                                </li>
                                <li className="nav-item">
                                    <a id="vc-opd-tab" href="#vc-opd-list" className={`nav-link ${activeTab === PASTPRESCRIPTION_TAB_NAMES[1] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="list-tab" aria-selected="true" ref='VcOpdTab'
                                        onClick={e => this.handleTabChange(e, PASTPRESCRIPTION_TAB_NAMES[1])}>
                                        VC/OPD
                              </a>
                                </li>
                            </ul>
                        </div> */}
              {(results?.length > 0 && activeTab == 'homecare') ? <div className="col-12" id="PastVisitContainerFluid">
                <div className="accordion" id="accordionPastVisit">
                  {this.homecarepastvisits()}
                </div>
              </div> : null}

              {(!isLoading && total_pages > 1 && activeTab == 'homecare') && this.state.pagination != null && this.state.pagination.total_pages > 0 ? <div className="col-12 mb-5">
                <Pagination onChange={this.paginate} defaultCurrent={this.state.currentPage} total={this.state.total_records} />
              </div> : null}

              {!isLoading && results?.length === 0 && activeTab == 'homecare' && (
                <div className="col-12">
                  <div className="card bg-light5">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 text-center">No Data available</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {results?.length > 0 && activeTab == 'vcopd' ? <div className="col-lg-12" id="PastVisitContainerFluid">
                <div className="accordion" id="accordionPastVisit">
                  {this.homecarepastvisits()}
                </div>
              </div> : null}

              {!isLoading && results?.length === 0 && activeTab == 'vcopd' && (
                <div className="col-12">
                  <div className="card bg-light5">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 text-center">No Data available</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {(!isLoading && total_pages > 1 && activeTab == 'vcopd') && this.state.pagination != null && this.state.pagination.total_pages > 0 ? <div className="col-12 mb-5">
                <Pagination onChange={this.navigateToPage} defaultCurrent={this.state.currentPage} total={this.state.total_records} />
              </div> : null}
            </div>

            {isLoading ? <div className="row">
              <div className="col-md-12 text-center">
                {LOADER_RED}
              </div>
            </div> : null}

          </div>
        </div>

        <Modal
          title="View Prescription"
          visible={this.state.showRxModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
          style={{ top: 20 }}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath + "#toolbar=0"} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                {LOADER_RED}
              </iframe> : null}
            </div>
          </div>
        </Modal>

        <Modal
          title={documents?.length === 1 ? "Document" : "Documents"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={850}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className="pdf_slider">
            {
              documents?.length > 0 ? (
                <Carousel arrows dots={false} ref={this.carouselRef} >
                  {
                    documents.map((document, index) => (
                      document.type === 'img' ?
                        <figure key={index}><img className="mr-3" src={document.file} alt='uploaded Doc' /></figure>
                        :
                        <embed key={index} className="mr-3" src={document.file + "#toolbar=0&scrollbar=0&pagemode=none"} />
                    ))
                  }

                </Carousel>
              ) : null
            }
          </div>
        </Modal>

      </>
    )
  }
}

export default HomecarePastVisit;