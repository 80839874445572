import React, { Component } from 'react';
import { Form, Modal } from 'antd';
import LS_SERVICE from '../../utils/localStorage';
import { hospitalModalService } from '../../utils/rxjs-sharing';

class Hospitalsmodal extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            hospitalModal : props.hospitalModal,
            start_consult_id: '',
            enableConfirmButton : true,
        }
    }

    componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    componentDidMount(){
        this.subscription = hospitalModalService.status().subscribe(data => {
            this.setState({ 
                hospitalModal : data.hospitalModal,
                patient_id    : data.patient_id,
                start_consult_id : data.start_consult_id
            });
        });
    }

    getHospitalsDetails = () => {
        let hospitals = LS_SERVICE.get('slot_hospitals').filter( (hosp) => hosp.doctor_start_consult == true);

        return hospitals.map((data, i) => {
            return (
            <>
            <div className="col-12 my-1" key={i}>
                <div className="custom-control custom-radio custom-control-inline mr-5">
                <input type="radio" id={`hospital${data.id}`} name="hospitals" className="custom-control-input" onClick={() => this.handleHopitalChange(data.id)} autoComplete="off" />
                    <label className="custom-control-label" htmlFor={`hospital${data.id}`}>{data.name}</label>
                </div>                                               
            </div>
            </>
            )
        });
    }

    handleHopitalChange(hospital_id){
        this.setState({ hospital_id : hospital_id,
            
            enableConfirmButton : false});
    }

    render(){
        return(
            <Modal
                title={"Hospitals"}
                visible={this.state.hospitalModal}
                onCancel={() => this.setState({hospitalModal: false, spin_loading : false, })}
                width="500px"
                bodyStyle={{ paddingBottom: "15px" }}
                className="emrfrm"
                footer={
                <div className="col-12 text-center">
                <button type="button" className="btn btn-outline-secondary mr-3"
                    onClick={() => this.setState({hospitalModal: false})}>CANCEL</button>
                <button disabled={this.state.enableConfirmButton} type="button" className="btn btn-primary px-4"
                onClick={() => { 
                this.setState({
                    hospitalModal: false,
                });
                this.props.scheduleImmediateAppointment(this.state.hospital_id, this.state.patient_id, this.state.start_consult_id);
                }}>CONFIRM</button>
                </div>
            }
            >
                <Form layout="vertical">
                    <div class="modal-body emrfrm">
                    <div className="row">
                    {this.getHospitalsDetails()}
                    </div>
                    </div>
                </Form>
            </Modal>
        )
    }
}
export default Hospitalsmodal;