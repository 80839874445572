import React, { Component, createRef } from 'react';
import { Modal, Carousel } from 'antd';
import { LOADER_RED } from '../../utils/constant';
import { FileViewerService } from '../../utils/rxjs-sharing';
import Bowser from "bowser";

class FileViewerComponent extends Component {

    state = {
        files: [
            // {
            //     type: 'pdf',
            //     path: 'https://opd-emr.s3.us-west-2.amazonaws.com/preprod/4/visit-prescription/2022/9/6/UHID-06092022-104922.pdf'
            // },
            // {
            //     type: 'img',
            //     path: 'https://dev.admin.desktop.myhealthcare.co/uploads/hospitals/2022/2/164537127116452714971639740554SakraLogo.png'
            // },
            // {
            //     type: 'pdf',
            //     pdfjs: true,
            //     path: 'https://opd-emr.s3.us-west-2.amazonaws.com/preprod/4/visit-prescription/2022/9/6/UHID-06092022-101116.pdf?v='+Math.random()
            // }
        ],
        modalTitle: 'View Prescription',
        // goToSlide: 0,
        showFileModal: false,
        currentSlide: 1,
        print: false
    }

    constructor(props) {
        super(props);
        this.carouselRef = createRef();
    }

    componentDidMount() {
        this.subscription = FileViewerService.status().subscribe(data => {
            this.setState({ 
                modalTitle: data.modalTitle,
                showFileModal: data.showFileModal,
                files: data.files,
                print: (typeof data.print != 'undefined' && data.print) ? true : false
            });
        });
    }

    componentWillUnmount() {
        this.subscription?.unsubscribe();
    }

    resetData() {
        this.setState({
            modalTitle: '',
            showFileModal: false,
            // goToSlide: 0,
            currentSlide: 1,
            files: [],
            print: false
        });
    }

    goPrevious() {
        this.carouselRef.current.prev();
    }

    goNext() {
        this.carouselRef.current.next();
    }

    onSlideChage(index) {
        this.setState({
            currentSlide: index + 1
        });
    }

    iframeLoaded() {
        const { print } = this.state;
        const pdfiframe = document.getElementById('pdfiframe');
        document.getElementById('iframe_parent').style.height = pdfiframe.height + 'px';
        const browser = Bowser.parse(window.navigator.userAgent).browser.name;
        if (browser == "Safari" && print) {
            setTimeout(() => pdfiframe.contentWindow.print(), 2000);
        }
        if (browser != "Safari" && print) {
            pdfiframe.contentWindow.print();
        }
    }

    render() {
        const { files, modalTitle, showFileModal, currentSlide } = this.state;
        return (
            <Modal
              title={modalTitle}
              visible={showFileModal}
              footer={false}
              width={850}
              onCancel={() => this.resetData()}
              style={{ top: 20 }}
            >
                <div className="row">
                    <div className="col-12 my-3 text-center">
                        {files.length == 1 ? <React.Fragment>
                            {files[0]['type'] == 'pdf' ? <React.Fragment>
                                <div id="iframe_parent" className="iframe_parent">
                                    <div className="iframe_child_0">
                                        {LOADER_RED}
                                    </div>
                                    <div className="iframe_child_1">
                                        {files[0]['pdfjs'] != 'undefined' && files[0]['pdfjs'] ? 
                                            <iframe id="pdfiframe" src={"/pdfjs/web/viewer.html?file=" + files[0]['path'] + "&print=true&version=v" + Math.random()} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700" onLoad={() => this.iframeLoaded()}></iframe>
                                        : 
                                            <iframe id="pdfiframe" src={files[0]['path']} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700" onLoad={() => this.iframeLoaded()}></iframe>}
                                    </div>
                                </div>
                            </React.Fragment> : null}

                            {files[0]['type'] == 'img' ? <img src={files[0]['path']} alt={""} style={{maxWidth: '100%'}}></img> : null}
                        </React.Fragment> : null}
                    
                        {files.length > 1 ? <React.Fragment>
                            <div className="row mb-2">
                                <div className="col-1">
                                    <button disabled={currentSlide == 1} onClick={() => this.goPrevious()} className="btn btn-info">Previous</button>
                                </div>
                                <div className="col-10 pt-2">{currentSlide} / {files.length}</div>
                                <div className="col-1">
                                    <button disabled={currentSlide == files.length} onClick={() => this.goNext()} className="btn btn-info">Next</button>
                                </div>
                            </div>
                            <Carousel arrows dots={false} ref={this.carouselRef} afterChange={(e) => this.onSlideChage(e)}>
                                {files.map((file, index) => <div key={index} className="text-center">
                                    {file.type == 'pdf' ? <iframe src={file.path} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700"></iframe> : null}
                                    {file.type == 'img' ? <img className="fileviewerimg" src={file.path} alt={""} style={{maxWidth: '100%'}}></img> : null}
                                </div>)}
                            </Carousel>
                        </React.Fragment> : null}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default FileViewerComponent;