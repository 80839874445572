import React from 'react';
import { Line, Scatter, Chart } from 'react-chartjs-2';
import { DURATION_TYPE_SINGULAR, LOADER_RED, PDF_CONFIG_FETAL_GRAPH_UPDATE, REFERENCE_TYPE } from '../../utils/constant';
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from "../../utils/localStorage";

class FetalParametersTrendGraph extends React.Component {
  constructor(props) {
    super(props);
    const PDF_PRINT_OBJ = {};
    if (props.chartGraphSetting.length > 0) {
      props.chartGraphSetting.split(',').forEach(ppa => {
        PDF_PRINT_OBJ[ppa] = true
      })
    }
    let max_fetal_param_array = [props.chartData['day'].length, props.chartData['month'].length, props.chartData['week'].length, props.chartData['year'].length];
    let fetal_params_constants = [];
    for(let i = 1; i <= Math.max(...max_fetal_param_array); i++){
      fetal_params_constants.push('fhr_' + i);
    }
    this.state = {
      fetal_params_constants,
      chartData: props.chartData,
      data: props.chartData[DURATION_TYPE_SINGULAR[props.chartsTab]],
      chartsTab: props.chartsTab,
      dataLoaded: props.dataLoaded,
      reference_id: props.reference_id,
      chartDataMapped: {},
      showOnlyTicked: props.showOnlyTicked,
      all_print: PDF_PRINT_OBJ,
      //all_print_checked_status: props.all_print_checked_status
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { allowEdit, isPracticing } = nextProps;
    return ({
      chartData: nextProps.chartData,
      data: nextProps.chartData[DURATION_TYPE_SINGULAR[nextProps.chartsTab]],
      chartsTab: nextProps.chartsTab,
      dataLoaded: nextProps.dataLoaded,
      showOnlyTicked: nextProps.showOnlyTicked,
      allowEdit: allowEdit,
      isPracticing: isPracticing
    })
  }

  async componentDidMount() {
    this.handleDynamicDataCreation();   
  }

  handleDynamicDataCreation = _ => {
    let { data, chartDataMapped } = this.state;
    let one_chart_data_found = false;
    if (data) {
      //one_chart_data_found = true;
      //var fetal_param = 'fhr'; // For now only Fetal Heart Rate
      this.state.fetal_params_constants.map((fetal_param, index) => {
        if(index in data){
          var dataSet = data[index];
        
          var chartData = this.updatedVitalGraphData(dataSet, index);
          if (chartData.data && chartData.data.length > 0) {
            one_chart_data_found = true;
          }
          chartDataMapped[fetal_param] = {
            ['data' + fetal_param]: {
              labels: chartData.label,
              datasets: [
                {
                  label: '',
                  backgroundColor: 'rgb(102 102 102)',
                  borderColor: 'rgb(102 102 102)',
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: chartData.data
                }
              ]
            },
            ['options' + fetal_param]: {
              responsive: true,
              animation: {
                duration: 0
              },
              plugins: {
                legend: {
                  display: false
                },
                datalabels: {
                  font: {
                    size: 0
                  }
                }
              },
              title: {
                display: false,
                text: '',
                fontSize: 15
              },
              elements: {
                line: {
                  tension: 0,
                },
              },
              legend: {
                position: "bottom",
                display: false,
                labels: {
                  fontSize: 11,
                  boxWidth: 6,
                  //fontColor: '#333333',
                  // usePointStyle: true,
                },
              },
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: "Gestational Age (Weeks)",
                    font: {
                      size: 18
                    },
                    padding: { bottom: 0 }
                  },
                  ticks: {
                    font: {
                      size: 11
                    }
                  }
                },
                y: {
                  position: 'left',
                  display: true,
                  suggestedMin: chartData.minY,
                  suggestedMax: chartData.maxY,
                  ticks: {
                    font: {
                      size: 11
                    },
                    stepSize: chartData.stepY,
                  }
                }
              }
            }
          }
        }
      });
    }
    if (this.state.showOnlyTicked != undefined && this.state.showOnlyTicked == true && Object.entries(this.state.all_print).length == 0) {
      one_chart_data_found = false;
    }
    this.setState({
      chartDataMapped,
      oneChartDataFound: one_chart_data_found
    });
  }

  updatedVitalGraphData(dataSet, index) {
    var diffArr = [];
    var totalDiff = 0;
    var finalLabel = this.state.chartData[DURATION_TYPE_SINGULAR[this.state.chartsTab]+'Label'][index];

    dataSet.map((value, i) => {
      if (i < dataSet.length - 1) {
        var diff = parseFloat(dataSet[i + 1]) - parseFloat(value);
        totalDiff += Math.abs(diff);
        diffArr.push(Math.abs(diff));
      }
    });
    diffArr = diffArr.filter(e => e !== 0)
    diffArr = diffArr.sort(function (a, b) { return a - b });
    let minY = 0;
    let maxY = 0;
    let stepY = 1;
    if (dataSet.length > 0 && diffArr.length > 0) {
      let dataArray = [...dataSet];
      let sortedData = dataArray.sort(function (a, b) { return a - b });

      stepY = totalDiff / diffArr.length;
      stepY = Math.round(stepY);
      stepY = stepY > 50 ? 50 : stepY
      minY = parseFloat(sortedData[0]) - stepY;
      maxY = parseFloat(sortedData[sortedData.length - 1]) + stepY;
    }

    minY = minY < 0 ? 0 : minY;
    if (diffArr.length == 0)
      minY = minY == 0 ? parseFloat(dataSet[0]) - stepY : minY;
    maxY = maxY == 0 ? parseFloat(dataSet[dataSet.length - 1]) + stepY : maxY;

    return { label: finalLabel, data: dataSet, minY: minY, maxY: maxY, stepY: stepY };
  }

  handleFetalParamAPI = async (e, fetalparam) => {

    //console.log("fetalparam: ", fetalparam);

    e.preventDefault();
    const { reference_id, all_print, isPracticing, chartDataMapped, fetal_params_constants } = this.state;
    if (!isPracticing) {
      this.props.parentHandleModalPopup(null, 'isPracticingModal');
      return;
    }

    let selected_fetal_params = '';
    let all_print_checked = false;

    const checked = e.target?.checked;
    let dataArr = [];

    if(checked) {
      if(fetalparam === "all_print") {
        for(const val of fetal_params_constants) {
          all_print[val] = true;
        }
      } else {      
        all_print[fetalparam] = true;
      }
    } else {
      if(fetalparam === "all_print") {
        selected_fetal_params = '';
        for(const val of fetal_params_constants) {
          all_print[val] = false;
        }
      } else {       
        all_print[fetalparam] = false;
      }
    }

    if(fetal_params_constants.length === Object.keys(all_print).filter(val => all_print[val] === true).length) {
      all_print_checked = true;
    } else {
      all_print_checked = false;
    }

    dataArr = all_print && Object.keys(all_print).filter((key, index) => all_print[key] ? key : '')

    let selectedfetalparams = dataArr.join(",");

    const PARAMS = {
      reference_type: REFERENCE_TYPE,
      reference_id,
      selected_fetal_params: dataArr.join(",")
    }
    
    const fetalGraphPromise = await Axios.post(PDF_CONFIG_FETAL_GRAPH_UPDATE, qs.stringify(PARAMS));
    if (fetalGraphPromise.data.status) {
      this.props.updateData(selectedfetalparams, all_print_checked);
    }
  }

  render() {
    const { oneChartDataFound } = this.state;
    return (
      <>
        {(this.state.dataLoaded) ? (
          oneChartDataFound ? (
            <div className="row">
              <div className="chartCanvasWrapper w-100">
              {Object.keys(this.state.chartDataMapped).length > 0 ? ( 
                this.state.fetal_params_constants.map((fetal_param, index) => {
                  return !this.state.showOnlyTicked ? 
                    (this.state.chartDataMapped[fetal_param]["data" + fetal_param].datasets[0].data && this.state.chartDataMapped[fetal_param]["data" + fetal_param].datasets[0].data.length > 0) ? 
                      <div className="chartCanvas" key={index}>
                        <h4>FHR (beats/min) - Fetus {index+1}</h4>
                        {!this.state.showOnlyTicked ? 
                          <div className="check_for_print">
                            <div className={(LS_SERVICE.get('user_type') === 2) ? "custom-control custom-checkbox hidden" : "custom-control custom-checkbox"}>
                              <input type="checkbox" disabled={!this.state.allowEdit} onClick={e => this.handleFetalParamAPI(e, fetal_param)} checked={this.state.all_print[fetal_param]}
                                className="custom-control-input pdf_print" name={fetal_param + '_print'} id={fetal_param + '_print'} />
                              <label className="custom-control-label text-basic" htmlFor={fetal_param + '_print'}>&nbsp;</label>
                            </div>
                          </div> 
                        : null}
                          <Line
                            data={this.state.chartDataMapped[fetal_param]['data' + fetal_param]}
                            options={this.state.chartDataMapped[fetal_param]['options' + fetal_param]}
                          />
                      </div> : null
                  : this.state.all_print[fetal_param] // SHOW ONLY TICKED GRAPHS FOR VIEW INFORMATION PAGE 
                  ? 
                    <div className="chartCanvas">
                      <h4>FHR (beats/min) - Fetus {index+1}</h4>
                        <Line
                          data={this.state.chartDataMapped[fetal_param]['data' + fetal_param]}
                          options={this.state.chartDataMapped[fetal_param]['options' + fetal_param]}
                        />
                    </div> 
                  : null
                })
              ) : null}
              </div>
            </div>
          ): <div className = "col d-flex justify-content-center align-self-center mt-2" /* style = {{ paddingTop: '50px' }} */></div>
        ) : this.state.dataLoaded && this.state.data.length === 0 ? (
          <div className="col d-flex justify-content-center align-self-center mt-2">
            No Data
          </div>
        ) : ""}
      </>
    )
  }

}

export default FetalParametersTrendGraph;