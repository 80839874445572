import React from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { MYHC_APPTYPE, REFERENCE_TYPE, WOODLANDS } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$ } from '../../utils/rxjs-sharing';
import SoapFlow from '../../utils/soap';

class SubHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: props.patient,
            soap_subjective: false,
            soap_objective: false,
            soap_assessment: false,
            soap_plan: false
        }
    }

    handleBackClick = e => {
        e.preventDefault();
        this.props.history.goBack();
    }

    componentDidMount() {

        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;
        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }
        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                SoapFlow.hospital(+hospital_id);
                const subjective = SoapFlow.show('subjective');
                const objective = SoapFlow.show('objective');
                const assessment = SoapFlow.show('assessment');
                const plan = SoapFlow.show('plan');
                const vaccination_chart = SoapFlow.showElement('paediatric', 'vaccination_chart');
                this.setState({
                    soap_subjective: subjective,
                    soap_objective: objective,
                    soap_assessment: assessment,
                    soap_plan: plan,
                    soap_vaccination_chart: vaccination_chart
                });
            }
        });
    }

    render() {

        const { patient, soap_subjective, soap_objective, soap_assessment, soap_plan, soap_vaccination_chart } = this.state;
        const { qms_token_id, patient_id, hospital_id } = patient;

        return (
            <div className={(window.location.href.indexOf("/patient/todays-visit/plan/vaccination-record/") > -1) ? "col-8 mb-3" : "col-12 mb-3"}>
                <ul className="nav nav-pills keyfind_nav">
                    {soap_subjective ? <li className="nav-item">
                        <NavLink
                            exact={true} activeClassName='active' to={{
                                pathname: `/patient/todays-visit/subjective/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                                state: { patient }
                            }} className="nav-link">Subjective
                            </NavLink>
                    </li> : null}
                    {soap_objective ? <li className="nav-item">
                        <NavLink
                            exact={true} activeClassName='active' to={{
                                pathname: `/patient/todays-visit/objective/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                                state: { patient }
                            }} 
                            isActive={(match, location) => {
                                if (match) return true;
                                const { pathname } = location;
                                if (pathname.includes('objective')) return true;
                            }}
                            className="nav-link">Objective
                            </NavLink>
                    </li> : null}
                    {soap_assessment ? <li className="nav-item">
                        { }
                        <NavLink
                            exact={true} activeClassName='active' to={{
                                pathname: `${MYHC_APPTYPE === WOODLANDS ?
                                    `/patient/todays-visit/assessment/key-findings/list/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}` :
                                    `/patient/todays-visit/assessment/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`}`,
                                state: { patient }
                            }}
                            isActive={(match, location) => {
                                if (match) return true;
                                const { pathname } = location;
                                if (pathname.includes('assessment')) return true;
                            }}
                            className="nav-link">Assessment
                            </NavLink>
                    </li> : null}
                    {
                      soap_plan && soap_vaccination_chart
                    ? 
                      <li className="nav-item">
                        <NavLink
                            exact={true} activeClassName='active' to={{
                                pathname: `/patient/todays-visit/plan/vaccination-record/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                                state: { patient }
                            }}
                            isActive={(match, location) => {
                                if (match) return true;
                                const { pathname } = location;
                                if (pathname.includes('plan')) return true;
                            }}
                            className="nav-link">Plan
                        </NavLink>
                      </li> 
                    : 
                      <>
                        {
                          soap_plan
                        ? 
                          <li className="nav-item">
                            <NavLink
                                exact={true} activeClassName='active' to={{
                                    pathname: `/patient/todays-visit/plan/prescription/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                                    state: { patient }
                                }}
                                isActive={(match, location) => {
                                    if (match) return true;
                                    const { pathname } = location;
                                    if (pathname.includes('plan')) return true;
                                }}
                                className="nav-link">Plan
                            </NavLink>
                          </li>
                        :
                          null
                        }
                      </>
                    }
                    <li className="nav-item">
                        <NavLink
                            exact={true} activeClassName='active' to={{
                                pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                                state: { patient }
                            }} className="nav-link gohome"><i className="icon-home"></i>
                        </NavLink>
                    </li>
                    <li className="nav-item goback">
                      {
                        window.location.href.indexOf("/patient/todays-visit/plan/vaccination-record/") > -1
                      ? 
                        null
                      : 
                      <a href="#" className="btn btn-outline-secondary px-4 py-1 small text-uppercase" onClick={this.handleBackClick}>
                        BACK
                      </a>
                      }
                    </li>
                </ul>
            </div>
        )
    }
}

export default withRouter(SubHeader);