import React, { Component } from "react";
import { Form, Modal, Input, Radio, Select, Checkbox, DatePicker, notification, Popover, Button } from "antd";
import LMP from "../../components/gynaecology/LMP";
import GPALE from "../../components/gynaecology/GPALE";
import Lactating from "../../components/gynaecology/Lactating";
import EDD from "../../components/gynaecology/EDD";
import MultiplePregnancies from "../../components/gynaecology/multiple-pregnancies";
import LS_SERVICE from "../../utils/localStorage";
import {
  CONCEPTION_OPTIONS, PREGNANCY_OPTIONS, DELIVERY_OPTIONS, OUTCOME_OPTIONS, PLACE_OF_DELIVERY_OPTIONS, OBSTETRIC_HISTORY_STORE, OBSTETRIC_HISTORY_LIST, RISK_FACTORS_LIST, LOADER_RED, LOADER
} from "../../utils/constant";
import Axios from 'axios';
import { speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from '../speech-to-text/speech-recognition';
import { Link } from "react-router-dom";
import { gpaleDataService, lactatingService, fetusService } from '../../utils/rxjs-sharing';
import moment from "moment";

const { Option } = Select;
class ObstetricHx extends Component {
  constructor(props) {
    super(props);
    let riskFactors = {...props.current_pregnancy_data}.riskFactors.map((id, i) => parseInt(id));
    this.state = {
      obsHistoryListStatus: false,
      obsHistoryList: [],
      spin_loading: false,
      no_obs_hx: props.no_obs_history,
      pregnant: {...props.current_pregnancy_data}.pregnant,
      testsDone: {...props.current_pregnancy_data}.testsDone,
      lmpWeeks: {...props.current_pregnancy_data}.lmpWeeks,
      lmpDays: {...props.current_pregnancy_data}.lmpDays,
      usgWeeks: {...props.current_pregnancy_data}.usgWeeks,
      usgDays: {...props.current_pregnancy_data}.usgDays,
      usgDate: {...props.current_pregnancy_data}.usgDate ? moment(new Date({...props.current_pregnancy_data}.usgDate)).format("YYYY-MM-DD") : null,
      lactating: {...props.current_pregnancy_data}.lactating,
      remarks_for_current_pregnancy:{...props.current_pregnancy_data}.remarksForCurrentPregnancy,
      // risk_factors:{...props.current_pregnancy_data}.riskFactors,
      risk_factors: riskFactors,
      risk_factors_remarks:{...props.current_pregnancy_data}.riskFactorsRemarks,
      multiplePregnanciesModal: false,
      edit_pregnancy_id : null,
      pregnancy_year: null,
      mode_of_conception: null,
      pregnancy_type: null,
      delivery_type: null,
      outcome: null,
      place_of_delivery: null,
      birth_weight: null,
      indication_reason: null,
      remarks: null,
      multiplePregnancies: [],
      indication_reason_multiple: null,
      gravidity: {...props.gpale_values}.gravidity,
      parity: {...props.gpale_values}.parity,
      abortion: {...props.gpale_values}.abortion,
      live_birth: {...props.gpale_values}.live_birth,
      ectopic: {...props.gpale_values}.ectopic,
      year_options: [],
      conception_options: CONCEPTION_OPTIONS,
      pregnancy_options: PREGNANCY_OPTIONS,
      delivery_options: DELIVERY_OPTIONS,
      outcome_options: OUTCOME_OPTIONS,
      place_of_delivery_options: PLACE_OF_DELIVERY_OPTIONS,
      show_on_pregnant_yes:true,
      no_of_fetus: {...props.current_pregnancy_data}.no_of_fetus,
      speech: {
        identifier: null,
        state: false
      },
      submitButtonText: 'ADD',
      gpale_values: props.gpale_values,
      gpale_values_initial: props.gpale_values,
      disabled_no_obs_hx: true,
      tabs_available: props.tabs_available
    };
    this.conditionFormRef = React.createRef();
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() { 
    let cyear = new Date().getFullYear();
    let syear = cyear - 60;
    let year_options = [];

    for(let i = cyear; i >= syear; i--){
      year_options.push(i);
    }
    this.setState({
      year_options
    });

    this.obstetricList();

    this.conditionFormRef.current.setFieldsValue({
      no_of_fetus: {...this.props.current_pregnancy_data}.no_of_fetus, 
      lmpWeeks: {...this.props.current_pregnancy_data}.lmpWeeks,
      lmpDays: {...this.props.current_pregnancy_data}.lmpDays,
      usgWeeks: {...this.props.current_pregnancy_data}.usgWeeks,
      usgDays: {...this.props.current_pregnancy_data}.usgDays,
      usgDate: {...this.props.current_pregnancy_data}.usgDate ? moment(new Date({...this.props.current_pregnancy_data}.usgDate)) : null
    });

    this.subscription_fetus = fetusService.status().subscribe(async data => {
      this.setState({ no_of_fetus: data });
      this.conditionFormRef.current.setFieldsValue({
        no_of_fetus: data
      });
    });

    this.subscription = speechTextService.status().subscribe(data => {
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'indication_reason') {
          this.setState({ indication_reason: data.text });
          this.conditionFormRef.current.setFieldsValue({
            indication_reason: data.text
          });
        }
        if (this.state.speech.identifier === 'remarks') {
          this.setState({ remarks: data.text });
          this.conditionFormRef.current.setFieldsValue({
            remarks: data.text
          });
        }
        if (this.state.speech.identifier === 'remarks_for_current_pregnancy') {
          this.setState({ remarks_for_current_pregnancy: data.text });
          this.conditionFormRef.current.setFieldsValue({
            remarks_for_current_pregnancy: data.text
          });
        }
        if (this.state.speech.identifier === 'risk_factors_remarks') {
          this.setState({ risk_factors_remarks: data.text });
          this.conditionFormRef.current.setFieldsValue({
            risk_factors_remarks: data.text
          });
        }
      }
    });
    this.optionRiskFactorsList();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    if (this.subscription_fetus) {
      this.subscription_fetus.unsubscribe();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { patient, allowEdit, isPracticing, current_pregnancy_data /* gpale_values */} = props;
    let edd_date;
    let corrected_edd_date;
    if(state.pregnant == '1' && props.patientDetails && props.patientDetails.lmp_date_non_formatted){
      // if(current_pregnancy_data.eddDate){
      //   edd_date = current_pregnancy_data.eddDate;
      // }else{
        edd_date = moment(new Date({...props.patientDetails}.lmp_date_non_formatted)).add(40, 'weeks').format("YYYY-MM-DD");
      // }

      if(state.inputCorrectedEddDate){
        corrected_edd_date = state.inputCorrectedEddDate;
      }else{
        if(current_pregnancy_data.correctedEddDate){
          corrected_edd_date = current_pregnancy_data.correctedEddDate
        }else{
          corrected_edd_date = null;
        }
      }
    } else {
      edd_date = null;
      corrected_edd_date = null;
    }
  
    return {
      patient: {
        patient_id: patient.patient_id,
        reference_id: patient.reference_id,
        reference_type: patient.reference_type,
        doctor_id: LS_SERVICE.get("staff_id"),
        hospital_id: LS_SERVICE.get("slot_hospital_id")
      },
      patientData: props.patientData,
      patientDetails : props.patientDetails,
      eddDate: edd_date,
      correctedEddDate: corrected_edd_date,
      allowEdit: allowEdit,
      isPracticing: isPracticing
     /*  gpale_values: gpale_values */
    };
  }

  onSelectChangeList = async (value, props, field) => {
    await this.setState({
      [field]: value
    });
    if(field == 'pregnancy_year'){
      this.calculateGPALE();
    }
    if(field == 'outcome'){
      this.calculateGPALE();
    }
    if(field == 'pregnancy_type' && (value == '1' || value == '3')){
      this.calculateGPALE();
      this.setState({
        multiplePregnancies: []
      });
    }
    if(field == 'pregnancy_type' && value == '2'){
      this.setState({
        multiplePregnanciesModal: true
      });
    }
  }

  async handleChange(event, field){
    field ? await this.setState({
      [field] : event.target.value  
      }) : await this.setState({ 
        [event.target.name] : event.target.value 
      });
    if(field == 'gravidity' || field == 'parity' || field == 'abortion' || field == 'live_birth' || field == 'ectopic'){
      /* gpaleDataService.init({
        gravidity: this.state.gravidity,
        parity: this.state.parity,
        abortion: this.state.abortion,
        live_birth: this.state.live_birth,
        ectopic: this.state.ectopic
      }); */
      this.setState({
        gpale_values: {
          gravidity: this.state.gravidity,
          parity: this.state.parity,
          abortion: this.state.abortion,
          live_birth: this.state.live_birth,
          ectopic: this.state.ectopic
        }
      })
    }
    if(field == 'lmpWeeks' || field == 'usgWeeks'){
      if(this.state.obsHistoryList.length == 0){
        this.calculateGPALE();
      } else {
        this.calculateGP();
      }
    }
    /* if(field == 'lmpWeeks' || field == 'lmpDays' || field == 'usgWeeks' || field == 'usgDays'){
      this.props.updatePOG({lmpWeeks: this.state.lmpWeeks, lmpDays: this.state.lmpDays, usgWeeks: this.state.usgWeeks, usgDays: this.state.usgDays});
    } */
  }

  handleRadio = async (e) => {
    const { name, value } = e.target;
    if(value != '1'){
      await this.clearGestationalAgeForm();
    }
    await this.setState({
      [name]: value
    });
    
    if(this.state.obsHistoryList.length == 0){
      this.calculateGPALE();
    } else {
      this.calculateGP();
    }
    if(name === 'pregnant'){
      if(value == 2){
        this.setState({ risk_factors : [] });
        this.conditionFormRef.current.setFieldsValue({ risk_factors_remarks: null });
      }
      if(value == 1){
        await this.conditionFormRef.current.setFieldsValue({remarks_for_current_pregnancy:null});
      }
      await this.setState({'show_on_pregnant_yes' : (value == 1)? true : false})
    }
    /* this.props.updatePOG({lmpWeeks: this.state.lmpWeeks, lmpDays: this.state.lmpDays, usgWeeks: this.state.usgWeeks, usgDays: this.state.usgDays}); */
  }

  clearGestationalAgeForm = async () => {
    await this.setState({
      no_of_fetus: null,
      lmpWeeks: null,
      lmpDays: null,
      usgWeeks: null,
      usgDays: null,
      usgDate: null
    });

    await this.conditionFormRef.current.setFieldsValue({
      no_of_fetus: null,
      lmpWeeks: null,
      lmpDays: null,
      usgWeeks: null,
      usgDays: null,
      usgDate: null
    });
  }

  handleTestDoneChange = async (e, val) => {
    let findex = this.state.testsDone.findIndex(x => x === val);
    if (findex === -1){
      if(val == '1'){
        await this.setState({
          testsDone: ['1']
        });
      } else {
        await this.setState(prevState => {
          const newItems = [...prevState.testsDone, val];
          return {testsDone: newItems}
        });
      }
    } else {
      await this.setState(prevState => ({ 
        testsDone: prevState.testsDone.filter((d, i) => i !== findex)
      }));
    }
  }

  handleDatePickerChange = async(date, dateString, field) => {
    if(date){
      await this.setState({
        [field]: moment(date).format("YYYY-MM-DD")
      });
    } else {
      await this.setState({
        [field]: null
      });
    }
  }

  disabledDate(current) {
    return current && current > moment();
  }

  handleLactatingChange = async (e) => {
    await this.setState({
      lactating: !this.state.lactating
    });
    /* lactatingService.init(
      this.state.lactating
    ); */
  }

  selectRiskFactorsChange = (value) => {
      this.setState({
        risk_factors:value
      });
  }

  handleObstetricHistoryChange = async (e) => {
    await this.setState({
      no_obs_hx: !this.state.no_obs_hx
    });

    if(this.state.no_obs_hx){
      this.clearGestationalAgeForm();
      this.FormClear();
      await this.setState({
        gravidity: null,
        parity: null,
        abortion: null,
        live_birth: null,
        ectopic: null,
        pregnant: null,
        testsDone: [],
        lactating: false,
        remarks_for_current_pregnancy: null,
        risk_factors: [],
        risk_factors_remarks: null,
      });
      await this.conditionFormRef.current.setFieldsValue({
        gravidity: null,
        parity: null,
        abortion: null,
        live_birth: null,
        ectopic: null
      });
  
      /* gpaleDataService.init({
        gravidity: null,
        parity: null,
        abortion: null,
        live_birth: null,
        ectopic: null
      }); */
      this.setState({
        gpale_values: {
          gravidity: null,
          parity: null,
          abortion: null,
          live_birth: null,
          ectopic: null
        }
      })
      /* lactatingService.init(
        false
      ); */
      /* this.props.updatePOG({lmpWeeks: this.state.lmpWeeks, lmpDays: this.state.lmpDays, usgWeeks: this.state.usgWeeks, usgDays: this.state.usgDays}); */
    }
  }

  async handleModalPopup(e, popup, data = null) {
    if (e !== null) e.preventDefault();
    this.setState({
        [popup]: !this.state[popup],
    });
    if(popup == 'multiplePregnanciesModal'){
      if(this.state.multiplePregnancies.length == 0){
        await this.setState({
          multiplePregnancies: [],
          indication_reason_multiple: null,
          pregnancy_type: null
        });
        this.calculateGPALE();
        this.conditionFormRef.current.setFieldsValue({
          pregnancy_type: null
        });
      }
    }
  }

  handleModalSave = async (obj) => {
    if(obj.multiple_pregnancies != undefined){
      await this.setState({
        multiplePregnancies: obj.multiple_pregnancies
      });
      this.calculateGPALE();
    }
    if(obj.indication_reason != undefined){
      this.setState({
        indication_reason_multiple: obj.indication_reason
      });
    }
    this.setState({
      multiplePregnanciesModal: false,
    });
  }

  enableEditPregnancy = async (data) => {
    await this.FormClear();
    await this.calculateGPALE();
    window.scrollTo({
      top: 200,
      behavior: "smooth"
    });
    this.setState({
      edit_pregnancy_id: data.id,
      submitButtonText: 'UPDATE',
      pregnancy_year: data.pregnancy_year,
      mode_of_conception: data.mode_of_conception,
      pregnancy_type: data.pregnancy_type
    });

    await this.conditionFormRef.current.setFieldsValue({
      pregnancy_year: data.pregnancy_year,
      mode_of_conception: data.mode_of_conception,
      pregnancy_type: data.pregnancy_type
    });

    if(data.pregnancy_type == '2'){
      let multiplePregnancies = [];
      data.previous_births.forEach(ele => {
        let obj = {delivery_type: ele.deliveryType, outcome: ele.outcome, birth_weight: ele.birthWeight, place_of_delivery: ele.placeOfDelivery};
        multiplePregnancies.push(obj);
      });
      this.setState({
        multiplePregnancies: multiplePregnancies
      });
      if(data.indication){
        this.setState({
          indication_reason_multiple: data.indication
        })
      } else {
        this.setState({
          indication_reason_multiple: null
        })
      }
    } else {
      this.setState({
        delivery_type: data.previous_births[0].deliveryType,
        outcome: data.previous_births[0].outcome,
        place_of_delivery: data.previous_births[0].placeOfDelivery,
        birth_weight: data.previous_births[0].birthWeight
      });
      await this.conditionFormRef.current.setFieldsValue({
        delivery_type: data.previous_births[0].deliveryType,
        outcome: data.previous_births[0].outcome,
        place_of_delivery: data.previous_births[0].placeOfDelivery,
        birth_weight: data.previous_births[0].birthWeight
      });
      if(data.indication){
        this.setState({
          indication_reason: data.indication
        });
        await this.conditionFormRef.current.setFieldsValue({
          indication_reason: data.indication
        });
      } else {
        this.setState({
          indication_reason: null
        })
        await this.conditionFormRef.current.setFieldsValue({
          indication_reason: null
        });
      }
    }
    

    if(data.remarks){
      await this.setState({
        remarks: data.remarks
      });
      await this.conditionFormRef.current.setFieldsValue({
        remarks: data.remarks
      });
    } else {
      await this.setState({
        remarks: null
      });
      await this.conditionFormRef.current.setFieldsValue({
        remarks: null
      });
    }
  }

  async manualSubmit(type) {
    await this.setState({
      buttonClicked: type
    });
    if(this.conditionFormRef && this.conditionFormRef.current){
      this.conditionFormRef.current.submit();
    }
  }

  handleOnConfirmSubmit = async (values) => {
    const { spin_loading, patient, isPracticing } = this.state;
    // if (this.state.speech.identifier != null) {
    //   this.initSpeech({ identifier: this.state.speech.identifier });
    // }

    const { patient_id, doctor_id, hospital_id, reference_id } = patient;
    if(LS_SERVICE.get('user_type') !== 2){
      if (!isPracticing) {
        this.props.parentHandleModalPopup(null, 'isPracticingModal');
        return;
      }
    }

    let previous_pregnancies = {};
    if(this.state.pregnancy_year != null){
      previous_pregnancies.pregnancy_year = this.state.pregnancy_year;
      previous_pregnancies.mode_of_conception = this.state.mode_of_conception;
      previous_pregnancies.pregnancy_type = this.state.pregnancy_type;
      if(this.state.pregnancy_type != '2'){
        previous_pregnancies.births = [
          {delivery_type: this.state.delivery_type, outcome: this.state.outcome, birth_weight: this.state.birth_weight, place_of_delivery: this.state.place_of_delivery}
        ];
        if(this.state.delivery_type == '2'){
          previous_pregnancies.indication_reason = this.state.indication_reason;
        }
      } else {
        previous_pregnancies.births = this.state.multiplePregnancies;
        if(this.state.indication_reason_multiple != null){
          previous_pregnancies.indication_reason = this.state.indication_reason_multiple;
        }
      }
      previous_pregnancies.remarks = this.state.remarks;
    }

    let gpale = {};
    gpale.gravidity = this.state.gravidity;
    gpale.parity = this.state.parity;
    gpale.abortion = this.state.abortion;
    gpale.live_birth = this.state.live_birth;
    gpale.ectopic = this.state.ectopic

    let current_pregnancy = {pregnant: this.state.pregnant, tests_done: this.state.testsDone, lmp_weeks: this.state.lmpWeeks, lmp_days: this.state.lmpDays, usg_weeks: this.state.usgWeeks, usg_days: this.state.usgDays, usg_done_on: this.state.usgDate, edd_date: this.state.eddDate, corrected_edd_date : this.state.correctedEddDate, lactating: this.state.lactating,remarks_for_current_pregnancy:this.state.remarks_for_current_pregnancy, risk_factors : this.state.risk_factors, risk_factors_remarks : this.state.risk_factors_remarks
    };

    let PARAMS = {
      patient_id: patient_id,
      doctor_id: (LS_SERVICE.get('user_type') === 2) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
      hospital_id: hospital_id,
      reference_id: reference_id,
      reference_type: 0,
      previous_pregnancies: previous_pregnancies,
      gpale: gpale,
      current_pregnancy: current_pregnancy,
      no_obs_hx: this.state.no_obs_hx,
      no_of_fetus: this.state.no_of_fetus
    }

    if(this.state.edit_pregnancy_id){
      PARAMS.id = this.state.edit_pregnancy_id;
    }

    this.setState({
      spin_loading: !spin_loading
    });

    await Axios.post(OBSTETRIC_HISTORY_STORE, PARAMS)
    .then(async success => {
      if (success?.data?.status == true) {
        const { spin_loading } = this.state;
        this.setState({
          spin_loading: !spin_loading
        });
        if(this.state.edit_pregnancy_id){
          await notification.success({
            message: 'Obstetric History Updated',
            placement: 'topRight'
          });
        } else {
          await notification.success({
            message: 'Obstetric History saved',
            placement: 'topRight'
          });
        }
        await this.FormClear();
        this.setState({ obsHistoryListStatus: false });
        this.obstetricList();

        gpaleDataService.init({
          gravidity: this.state.gravidity,
          parity: this.state.parity,
          abortion: this.state.abortion,
          live_birth: this.state.live_birth,
          ectopic: this.state.ectopic
        });
        lactatingService.init(
          this.state.lactating
        );
        fetusService.init(
          this.state.no_of_fetus
        );
        this.props.updatePOGEDD({lmpWeeks: this.state.lmpWeeks, lmpDays: this.state.lmpDays, usgWeeks: this.state.usgWeeks, usgDays: this.state.usgDays, eddDate: this.state.eddDate, correctedEddDate: this.state.correctedEddDate});
        if(this.state.buttonClicked == 'continue'){
          this.handleContinue();
        }
      } else {
        notification.error({
          message: success?.data?.message,
          placement: 'topRight'
        });
      }
    }).catch(err => console.log(err))
    
  }

  handleContinue = () => {
    const {tabs_available} = this.state;
    let next_tab = tabs_available.physical_tab_available ? '4' : null;
    if (next_tab) {
      this.props.handleContinue(next_tab);
    } else {
      const { patient_id, reference_id, reference_type, hospital_id } = this.state.patient;
      const { patientData } = this.state;

      const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
      const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
      let index = pageListArr.indexOf('obstetrics_hx');
      index++;
      if (index < pageListArr.length && !isTodayVisitArr.includes(reference_id.toString())) {
        let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
        soapurl = soapurl.replace("HOSPITALID", hospital_id);
        soapurl = soapurl.replace("REFID", reference_id);
        soapurl = soapurl.replace("REFTYPE", reference_type);
        soapurl = soapurl.replace("PATID", patient_id);
        this.props.history.push({
          pathname: soapurl,
          state: { patientData }
        })
      } else {
        this.props.history.push({
          pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`,
          state: { patientData }
        })
      }
    }
  }

  FormClear = async () => {
    if (this.state.speech.identifier != null) {
      this.initSpeech({ identifier: this.state.speech.identifier });
    }
    await this.setState({
      submitButtonText: 'ADD',
      edit_pregnancy_id: null,
      pregnancy_year: null,
      mode_of_conception: null,
      pregnancy_type: null,
      delivery_type: null,
      outcome: null,
      place_of_delivery: null,
      birth_weight: null,
      indication_reason: null,
      remarks: null,
      multiplePregnancies: [],
      indication_reason_multiple: null,
    });

    await this.conditionFormRef.current.setFieldsValue({
      pregnancy_year: null,
      mode_of_conception: null,
      pregnancy_type: null,
      delivery_type: null,
      outcome: null,
      place_of_delivery: null,
      birth_weight: null,
      indication_reason: null,
      remarks: null,
    });
  }

  handleCancel = async () => {
    await this.FormClear();
    this.calculateGPALE();
  }

  //OBSTETRIC_HISTORY_LIST
  obstetricList = async () => {
    const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;

    const PARAMS = {
      patient_id: patient_id,
      doctor_id: (LS_SERVICE.get('user_type') === 2) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id
    }

    await Axios.post(OBSTETRIC_HISTORY_LIST, PARAMS)
      .then(success => {
        if (success?.data?.status == true) {
          this.setState({ obsHistoryListStatus: true });

          if (success.data.data.length > 0) {
            let obs_history_list = [];
            success.data.data.forEach(ele => {
              let obj = {id: ele.id, pregnancy_year: ele.year, mode_of_conception: ele.mode, mode_text: this.state.conception_options.filter(x => x.val == ele.mode)[0].label, pregnancy_type: ele.pregnancyType, previous_births: ele.previous_births, indication: ele.indication, remarks: ele.remarks};

              if(ele.pregnancyType == '2'){
                obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label + '(' + ele.previous_births.length + ')';
                let prev_del_types = [];
                let prev_outcomes = [];
                ele.previous_births.forEach(el => {
                  if(el.deliveryType){
                    let ob_del = {delivery_type: el.deliveryType, count: 1};
                    let index_del = prev_del_types.findIndex(m => m.delivery_type === el.deliveryType);
                    if(index_del === -1){
                      prev_del_types.push(ob_del)
                    } else {
                      prev_del_types[index_del].count = prev_del_types[index_del].count + 1;
                    }
                  }

                  let ob_outcome = {outcome: el.outcome, count: 1};
                  let index_outcome = prev_outcomes.findIndex(m => m.outcome === el.outcome);
                  if(index_outcome === -1){
                    prev_outcomes.push(ob_outcome)
                  } else {
                    prev_outcomes[index_outcome].count = prev_outcomes[index_outcome].count + 1;
                  }
                });
                let delivery_type_text_arr = [];
                prev_del_types.forEach(e => {
                  delivery_type_text_arr.push(this.state.delivery_options.filter(x => x.val == e.delivery_type)[0].label + '(' + e.count + ')');
                });
                let outcome_text_arr = [];
                prev_outcomes.forEach(e => {
                  outcome_text_arr.push(this.state.outcome_options.filter(x => x.val == e.outcome)[0].label + '(' + e.count + ')');
                });

                obj.delivery_type_text = delivery_type_text_arr.join(',');
                if(obj.delivery_type_text == ''){
                  obj.delivery_type_text = '-';
                }
                obj.outcome_text = outcome_text_arr.join(',');
              } else {
                obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label;
                if(ele.previous_births[0].deliveryType){
                  obj.delivery_type_text = this.state.delivery_options.filter(x => x.val == ele.previous_births[0].deliveryType)[0].label;
                } else {
                  obj.delivery_type_text = '-';
                }
                obj.outcome_text = this.state.outcome_options.filter(x => x.val == ele.previous_births[0].outcome)[0].label;
              }
              obs_history_list.push(obj);
            });
            this.setState({ obsHistoryList: obs_history_list });
          } else {
            let { gpale_values_initial } = this.state;
            if(gpale_values_initial.gravidity === null && gpale_values_initial.parity === null && gpale_values_initial.abortion === null && gpale_values_initial.live_birth === null && gpale_values_initial.ectopic === null){
              this.setState({
                disabled_no_obs_hx: false
              })
            }
          }
        }
      }).catch(err => console.log(err));
  }

  calculateGP = async () => {
    let g = 0, p = 0;

    if(this.state.obsHistoryList.length > 0){
      this.state.obsHistoryList.forEach(ele => {
        if(!this.state.edit_pregnancy_id || (this.state.edit_pregnancy_id && ele.id != this.state.edit_pregnancy_id)){
          g++;p++;
        }
      });
    }

    if(this.state.pregnancy_year){
      g++;p++;
    }

    if(this.state.pregnant == '1'){
      g++;
      if(this.state.lmpWeeks || this.state.usgWeeks){
        if(this.state.lmpWeeks >= 20 || this.state.usgWeeks >= 20){
          p++;
        }
      }
    }

    await this.setState({
      gravidity: g,
      parity: p
    });
    await this.conditionFormRef.current.setFieldsValue({
      gravidity: g,
      parity: p
    });

    /* gpaleDataService.init({
      gravidity: g,
      parity: p,
      abortion: this.state.abortion,
      live_birth: this.state.live_birth,
      ectopic: this.state.ectopic
    }); */
    this.setState({
      gpale_values: {
        gravidity: g,
        parity: p,
        abortion: this.state.abortion,
        live_birth: this.state.live_birth,
        ectopic: this.state.ectopic
      }
    })

  }

  calculateGPALE = async () => {
    let g = 0, p = 0, a = 0, l = 0, e = 0;

    if(this.state.obsHistoryList.length > 0){
      this.state.obsHistoryList.forEach(ele => {
        if(!this.state.edit_pregnancy_id || (this.state.edit_pregnancy_id && ele.id != this.state.edit_pregnancy_id)){
          g++;p++;
          ele.previous_births.forEach(el => {
            if (el.outcome == '5' || el.outcome == '6') {
              a++;
            }
            if (el.outcome == '1' || el.outcome == '2') {
              l++;
            }
          });
          if(ele.pregnancy_type == '3'){
            e++;
          }
        }
      });
    }

    if(this.state.pregnancy_year){
      g++;p++;
      if(this.state.pregnancy_type == '2'){
        this.state.multiplePregnancies.forEach(el => {
          if (el.outcome == '5' || el.outcome == '6') {
            a++;
          }
          if (el.outcome == '1' || el.outcome == '2') {
            l++;
          }
        })
      } else {
        if (this.state.outcome == '5' || this.state.outcome == '6') {
          a++;
        }
        if (this.state.outcome == '1' || this.state.outcome == '2') {
          l++;
        }
        if(this.state.pregnancy_type == '3'){
          e++;
        }
      }
    }

    if(this.state.pregnant == '1'){
      g++;
      if(this.state.lmpWeeks || this.state.usgWeeks){
        if(this.state.lmpWeeks >= 20 || this.state.usgWeeks >= 20){
          p++;
        }
      }
    }

    await this.setState({
      gravidity: g,
      parity: p,
      abortion: a,
      live_birth: l,
      ectopic: e
    });
    await this.conditionFormRef.current.setFieldsValue({
      gravidity: g,
      parity: p,
      abortion: a,
      live_birth: l,
      ectopic: e
    });

    /* gpaleDataService.init({
      gravidity: g,
      parity: p,
      abortion: a,
      live_birth: l,
      ectopic: e
    }); */
    this.setState({
      gpale_values: {
        gravidity: g,
        parity: p,
        abortion: a,
        live_birth: l,
        ectopic: e
      }
    })
  }

  initSpeech = async (info) => {
    let initialText = '';
    if (info.identifier === 'indication_reason') {
      initialText = this.state.indication_reason != undefined ? this.state.indication_reason : '';
    }
    if (info.identifier === 'remarks') {
      initialText = this.state.remarks != undefined ? this.state.remarks : '';
    }
    if (info.identifier === 'remarks_for_current_pregnancy') {
      initialText = this.state.remarks_for_current_pregnancy != undefined ? this.state.remarks_for_current_pregnancy : '';
    }
    if (info.identifier === 'risk_factors_remarks') {
      initialText = this.state.risk_factors_remarks != undefined ? this.state.risk_factors_remarks : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {
        speechService.init(this.state.speech);
      });
    } else {
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);
        setTimeout(() => {
          this.setState({
            speech: {
              identifier: info.identifier,
              state: true,
              initialText: initialText
            }
          }, () => {
            speechService.init(this.state.speech);
          });

        }, 1000);
      });
    }
  }

  optionRiskFactorsList() {
    Axios.get(RISK_FACTORS_LIST).then(async success => {
      if (success?.data?.status == true) {
        this.setState({risk_factors_options : success.data.data});
      }
    }).catch(err => console.log(err));

    
  }

  optionRiskFactorsLoad() {
    if (!this.state.risk_factors_options || this.state.risk_factors_options.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.risk_factors_options.map((obj, i) => {
      return (<Option key={i} value={parseInt(obj.id)} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  render() {
    const textareaSizeDefault = { minRows: 3, maxRows: 6 };
    
    return (
      <div className="row">
        {/* <MyHcSpeechRecognition /> */}
        <div className="col-12">
          <Form
            ref={this.conditionFormRef}
            onFinish={this.handleOnConfirmSubmit}
            layout="vertical" 
            className="emrfrm" 
            style={{fontSize: '16px', color: '#5c5c5c', lineHeight: '1.5'}}>
              
            <div className="row">
              <div className="col-auto">
                <div className="custom-control custom-checkbox pr-3 pt-2">
                  <input type="checkbox" className="custom-control-input" id="noobstetrichistory" name="noobstetrichistory" disabled={(this.state.disabled_no_obs_hx && !this.state.no_obs_hx) || !this.state.allowEdit} checked={this.state.no_obs_hx} onChange={e => this.handleObstetricHistoryChange(e)} />
                  <label className="custom-control-label" htmlFor="noobstetrichistory">No Obstetric History</label>
                </div>
              </div>

              <div className="col-auto">
                <div className="form-group pt-2">
                  <GPALE values={this.state.gpale_values} />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group pt-2 pl-5">
                  {(this.state.patientDetails && this.state.patientDetails.lmp_date) ? 
                    <LMP patient={this.state.patient} patientDetails={this.state.patientDetails} />
                  : null}
                </div>
              </div>
              <EDD eddDate={this.state.eddDate} correctedEddDate={this.state.correctedEddDate} pregnant={this.state.pregnant} allowEdit={this.state.allowEdit} no_obs_hx={this.state.no_obs_hx} type="3" handleDatePickerChange={this.handleDatePickerChange} /> 

              <div className="col" style={{ textAlign: "right", justifyContent: "flex-end", display: "flex", marginTop: "-10px" }}>
                <Lactating lactating={this.state.lactating} />
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-6">
                <span>Pregnant</span>
                <div className="custom-control custom-radio custom-control-inline mr-3 ml-3">
                  <input type="radio" id="yes-obstetric" name="pregnant" className="custom-control-input" value={'1'} disabled={this.state.no_obs_hx || !this.state.allowEdit} checked={this.state.pregnant === '1'} onChange={this.handleRadio} />
                  <label className="custom-control-label pl-2" htmlFor="yes-obstetric">Yes</label>
                </div>

                {this.state.pregnant == '1' ? 
                <div className="col-3 custom-control custom-control-inline mr-1">
                  <div className={this.state.no_of_fetus ? 'antInputItem hasdata' : 'antInputItem'}>
                    <Form.Item name="no_of_fetus" label="No. of Fetus" id="no_of_fetus"
                      onChange={(e) => this.handleChange(e, 'no_of_fetus')}
                      rules={[
                        { pattern: /^[1-9]{1,1}$/, message: 'Not allowed!' },
                      ]}
                    >
                      <Input autoComplete="off" className="form-control" disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.no_of_fetus} />
                    </Form.Item>
                  </div>
                </div>
                :null}
                <div className="custom-control custom-radio custom-control-inline mr-1">
                  <input type="radio" id="no-obstetric" name="pregnant" className="custom-control-input" value={'2'} disabled={this.state.no_obs_hx || !this.state.allowEdit} checked={this.state.pregnant === '2'} onChange={this.handleRadio} />
                  <label className="custom-control-label pl-2" htmlFor="no-obstetric">No</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline mr-3 ml-3">
                  <input type="radio" id="notsure-obstetric" name="pregnant" className="custom-control-input" value={'3'} disabled={this.state.no_obs_hx || !this.state.allowEdit} checked={this.state.pregnant === '3'} onChange={this.handleRadio} />
                  <label className="custom-control-label pl-2" htmlFor="notsure-obstetric">Not Sure</label>
                </div>
              </div>

              
              <div className="col-6">
                <div className="row text-right">
                  <div className="col-3">Test Done</div>
                  <div className="col-2">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="none-obstetric" name="none" disabled={this.state.no_obs_hx || !this.state.allowEdit} checked={this.state.testsDone.includes('1')} onChange={e => this.handleTestDoneChange(e, '1')} />
                      <label className="custom-control-label" htmlFor="none-obstetric">None</label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="upt-obstetric" name="upt" disabled={this.state.testsDone.includes('1') || this.state.no_obs_hx || !this.state.allowEdit} checked={this.state.testsDone.includes('2')} onChange={e => this.handleTestDoneChange(e, '2')} />
                      <label className="custom-control-label" htmlFor="upt-obstetric">UPT</label>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="serum-obstetric" name="serumhcg" disabled={this.state.testsDone.includes('1') || this.state.no_obs_hx || !this.state.allowEdit} checked={this.state.testsDone.includes('3')} onChange={e => this.handleTestDoneChange(e, '3')} />
                      <label className="custom-control-label" htmlFor="serum-obstetric">Serum HCG</label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="usg-obstetric" name="usg" disabled={this.state.testsDone.includes('1') || this.state.no_obs_hx || !this.state.allowEdit} checked={this.state.testsDone.includes('4')} onChange={e => this.handleTestDoneChange(e, '4')} />
                      <label className="custom-control-label" htmlFor="usg-obstetric">USG</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-3 pb-2">
              <div className="col-12">
                <span>Gestational age</span>
              </div>
            </div>

            <div className="row">
              <div className="col-1 pt-2">
                <span>By LMP</span>
              </div>

              <div className="col-1 pl-0">
                <div className="form-group pl-0">
                  <div className={this.state.lmpWeeks != null && this.state.lmpWeeks !== '' ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="lmpWeeks" label="Weeks" id="lmpWeeks"
                      onChange={(e) => this.handleChange(e, 'lmpWeeks')}
                      rules={[
                        { required: this.state.pregnant == '1', message: 'Weeks are required'},
                        { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="form-control" disabled={this.state.pregnant != '1' || this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.lmpWeeks} />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-1 pl-0">
                <div className="form-group">
                  <div className={this.state.lmpDays != null && this.state.lmpDays !== '' ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="lmpDays" label="Days" id="lmpDays"
                      onChange={(e) => this.handleChange(e, 'lmpDays')}
                      rules={[
                        { required: this.state.pregnant == '1', message: 'Days are required' },
                        { pattern: /^(0?)([1-6]{0,1})$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="form-control" disabled={this.state.pregnant != '1' || this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.lmpDays} />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-1 pt-2">
                <span className="float-right">By USG</span>
              </div>

              <div className="col-1">
                <div className="form-group pl-0">
                  <div className={this.state.usgWeeks != null && this.state.usgWeeks !== '' ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="usgWeeks" label="Weeks" id="usgWeeks"
                      onChange={(e) => this.handleChange(e, 'usgWeeks')}
                      rules={[
                        { required: this.state.pregnant == '1' && this.state.usgDate, message: 'Weeks are required' },
                        { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="form-control" disabled={this.state.pregnant != '1' || this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.usgWeeks} />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-1">
                <div className="form-group pl-0">
                  <div className={this.state.usgDays != null && this.state.usgDays !== '' ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="usgDays" label="Days" id="usgDays"
                      onChange={(e) => this.handleChange(e, 'usgDays')}
                      rules={[
                        { required: this.state.pregnant == '1' && this.state.usgDate, message: 'Days are required' },
                        { pattern: /^(0?)([1-6]{0,1})$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="form-control" disabled={this.state.pregnant != '1' || this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.usgDays} />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-3 ">
                <div className={(this.state.usgDate) ? 'antInputItem hasdata' : 'antInputItem hasdata'}>
                  <Form.Item name="usgDate" id="usgDate" label="Done On" 
                    rules={[
                      { required: this.state.pregnant == '1' && ((this.state.usgWeeks != null && this.state.usgWeeks !== '') || (this.state.usgDays != null && this.state.usgDays !== '')), message: 'Done On Date is required' },
                    ]}
                  >
                    <DatePicker
                      format="DD MMM, YYYY"
                      disabledDate={this.disabledDate}
                      disabled={this.state.pregnant != '1' || this.state.no_obs_hx || !this.state.allowEdit}
                      showNow={true}
                      defaultValue={moment()}
                      className="form-control datetimepicker"
                      onChange={(date, dateString) => this.handleDatePickerChange(date, dateString, 'usgDate')}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="col-3">
                <div className="custom-control custom-checkbox pr-4 float-left pt-2">
                  <input type="checkbox" className="custom-control-input" id="lactating" name="lactating" disabled={this.state.no_obs_hx || !this.state.allowEdit} checked={this.state.lactating} onChange={e => this.handleLactatingChange(e)} />
                  <label className="custom-control-label" htmlFor="lactating">Lactating</label>
                </div>
              </div>
            </div>

            {(this.state.show_on_pregnant_yes)?
            <div className="row pt-2 ">
              <div className="col-12">
              <div className={`form-group antInputItem micwrap ${this.state.remarks_for_current_pregnancy ? "hasdata" : ""}`}>
                  <Link 
                    disabled={this.state.no_obs_hx || !this.state.allowEdit}
                    onClick={(this.state.no_obs_hx || !this.state.allowEdit) ? (e) => { 
                      e.preventDefault(); } : (e) => { 
                      e.preventDefault();
                      this.initSpeech({ identifier: "remarks_for_current_pregnancy" });
                    }}
                    className={
                      this.state.speech.identifier === "remarks_for_current_pregnancy" &&
                        this.state.speech.state
                        ? "mic subjective-mic on mute"
                        : "mic subjective-mic"
                    }
                  ></Link>
                  <Form.Item
                    name="remarks_for_current_pregnancy"
                    id="remarks_for_current_pregnancy"
                    label="Remarks For Current Pregnancy"
                    onChange={(e) => this.handleChange(e, "remarks_for_current_pregnancy")}
                  >
                    <Input.TextArea
                      disabled={this.state.no_obs_hx || !this.state.allowEdit}
                      autoSize={{ minRows: 1.3, maxRows: 6 }}
                      defaultValue={this.state.remarks_for_current_pregnancy}
                      showCount
                      maxLength={255}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            :null}

            <div className="row pt-2 ">
              <div className="col-6">
                <div className={`form-label-group input-group ${this.state.risk_factors ? "hasdata" : ""}`}>
                  <Select showSearch id="risk_factors" name="risk_factors" style={{ width: 100 + '%' }}
                    onChange={(e) => this.selectRiskFactorsChange(e)}
                    mode="multiple"
                    disabled={this.state.no_obs_hx || !this.state.allowEdit}
                    value={this.state.risk_factors} 
                    allowClear
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }}
                  >
                    {this.optionRiskFactorsLoad()}
                  </Select>
                  <label htmlFor="schedules">Risk Factors</label>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <div className={`form-group antInputItem micwrap ${this.state.risk_factors_remarks ? "hasdata" : ""}`}>
                    <Link 
                    disabled={this.state.no_obs_hx || !this.state.allowEdit}
                    onClick={(this.state.no_obs_hx || !this.state.allowEdit) ? (e) => { 
                      e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: "risk_factors_remarks" });
                    }}
                    className={
                      this.state.speech.identifier === "risk_factors_remarks" &&
                        this.state.speech.state
                        ? "mic subjective-mic on mute"
                        : "mic subjective-mic"
                    }
                  ></Link>
                    <Form.Item name="risk_factors_remarks" label="Risk Factors Remarks" id="risk_factors_remarks"
                      onChange={(e) => this.handleChange(e, 'risk_factors_remarks')}
                    >
                      <Input className="" disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.risk_factors_remarks} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="row py-2">
              <div className="col-2 pt-2">
                <span>Previous Pregnancies</span>
              </div>

              <div className="col-2">
                <div className={this.state.pregnancy_year ? 'antSelectItem hasdata' :'antSelectItem'}>
                  <Form.Item
                    label="Year"
                    name="pregnancy_year"
                    className="margin-prev"
                  >
                    <Select
                      style={{ width: 100 + '%',marginBottom: "0px !important" }}
                      showSearch
                      name="pregnancy_year"
                      id="pregnancy_year"
                      
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                      }}
                      disabled={
                        this.state.no_obs_hx || !this.state.allowEdit
                      }
                      defaultValue={
                        this.state.pregnancy_year
                      }
                      onChange={(val, props) => this.onSelectChangeList(val, props, 'pregnancy_year')} 
                    >
                      {this.state.year_options !== undefined && this.state.year_options.length > 0 ? this.state.year_options.map((d, i) => (
                        <Option key={i} value={d} label={d}>{d}</Option>
                      )) : null}

                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="col-3">
                <div className={this.state.mode_of_conception ? 'antSelectItem hasdata' : 'antSelectItem'}>
                  <Form.Item
                    label="Mode of Conception"
                    name="mode_of_conception"
                    className="margin-prev"
                    rules={[
                      {
                        required: this.state.pregnancy_year,
                        message: "Select Mode of conception",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 100 + '%' }}
                      showSearch
                      name="mode_of_conception"
                      id="mode_of_conception"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                      }}
                      disabled={
                        this.state.no_obs_hx || !this.state.allowEdit
                      }
                      defaultValue={
                        this.state.mode_of_conception
                      }
                      onChange={(val, props) => this.onSelectChangeList(val, props, 'mode_of_conception')}
                    >
                      {this.state.conception_options !== undefined && this.state.conception_options.length > 0 ? this.state.conception_options.map((d, i) => (
                        <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                      )) : null}

                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="col-2">
                <div className={this.state.pregnancy_type ? 'antSelectItem hasdata' : 'antSelectItem'}>
                  <Form.Item
                    label="Pregnancy Type"
                    name="pregnancy_type"
                    className="margin-prev"
                    rules={[
                      {
                        required: this.state.pregnancy_year,
                        message: "Select Pregnancy Type",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 100 + '%' }}
                      showSearch
                      name="pregnancy_type"
                      id="pregnancy_type"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                      }}
                      disabled={
                        this.state.no_obs_hx || !this.state.allowEdit
                      }
                      defaultValue={
                        this.state.pregnancy_type
                      }
                      onChange={(val, props) => this.onSelectChangeList(val, props, 'pregnancy_type')}
                    >
                      {this.state.pregnancy_options !== undefined && this.state.pregnancy_options.length > 0 ? this.state.pregnancy_options.map((d, i) => (
                        <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                      )) : null}

                    </Select>
                  </Form.Item>
                </div>

                <label class="text-secondary text-underline cursor-pointer" style={{ display: this.state.multiplePregnancies.length ? 'block' : 'none'}}>
                  <button type="button" disabled={!this.state.allowEdit} class="ant-btn nostyle-link text-secondary text-underline float-right pt-1 pr-1" onClick={() => this.setState({ multiplePregnanciesModal: true })}>
                    <span>Multiple({this.state.multiplePregnancies.length})</span>
                  </button>
                </label>
              </div>
              
                
              

              {this.state.pregnancy_type != '2' ? <div className="col-3">
                <div className={this.state.outcome ? 'antSelectItem hasdata' : 'antSelectItem'}>
                  <Form.Item
                    label="Outcome"
                    name="outcome"
                    rules={[
                      {
                        required: this.state.pregnancy_year,
                        message: "Select Outcome",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 100 + '%' }}
                      showSearch
                      name="outcome"
                      id="outcome"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                      }}
                      disabled={
                        this.state.no_obs_hx || !this.state.allowEdit
                      }
                      defaultValue={
                        this.state.outcome
                      }
                      onChange={(val, props) => this.onSelectChangeList(val, props, 'outcome')}
                    >
                      {this.state.outcome_options !== undefined && this.state.outcome_options.length > 0 ? this.state.outcome_options.map((d, i) => (
                        <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                      )) : null}

                    </Select>
                  </Form.Item>
                </div>
              </div> : null}
            </div>

            {this.state.pregnancy_type != '2' ? <div className="row">
            <div className="col-2 ">
                <div className={this.state.delivery_type ? 'antSelectItem hasdata' : 'antSelectItem'}>
                  <Form.Item
                    label="Delivery"
                    name="delivery_type"
                    rules={[
                      {
                        required: this.state.pregnancy_year && this.state.pregnancy_type != 3 && (this.state.outcome == 1 || this.state.outcome == 2),
                        message: "Select Delivery Type",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 100 + '%' }}
                      showSearch
                      name="delivery_type"
                      id="delivery_type"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                      }}
                      disabled={
                        this.state.no_obs_hx || !this.state.allowEdit
                      }
                      defaultValue={
                        this.state.delivery_type
                      }
                      onChange={(val, props) => this.onSelectChangeList(val, props, 'delivery_type')}
                    >
                      {this.state.delivery_options !== undefined && this.state.delivery_options.length > 0 ? this.state.delivery_options.map((d, i) => (
                        <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                      )) : null}

                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="col-3">
                <div className={this.state.place_of_delivery ? 'antSelectItem hasdata' : 'antSelectItem'}>
                  <Form.Item
                    label="Place of delivery"
                    name="place_of_delivery"
                    rules={[
                      {
                        required: this.state.pregnancy_year && this.state.pregnancy_type != 3 && (this.state.outcome == 1 || this.state.outcome == 2),
                        message: "Select Place of Delivery",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 100 + '%' }}
                      showSearch
                      name="place_of_delivery"
                      id="place_of_delivery"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                      }}
                      disabled={
                        this.state.no_obs_hx || !this.state.allowEdit
                      }
                      defaultValue={
                        this.state.place_of_delivery
                      }
                      onChange={(val, props) => this.onSelectChangeList(val, props, 'place_of_delivery')}
                    >
                      {this.state.place_of_delivery_options !== undefined && this.state.place_of_delivery_options.length > 0 ? this.state.place_of_delivery_options.map((d, i) => (
                        <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                      )) : null}

                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group">
                  <div className={this.state.birth_weight ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="birth_weight" label="Birth Weight (kg)" id="birth_weight"
                      onChange={(e) => this.handleChange(e, 'birth_weight')}
                      rules={[
                        {required: this.state.pregnancy_year && this.state.pregnancy_type != 3 && (this.state.outcome == 1 || this.state.outcome == 2), message: 'Please fill Birth Weight.'},
                        { pattern: /^\d{1,2}(\.\d{1,2})?$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="" disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.birth_weight} />
                    </Form.Item>
                  </div>
                </div>
              </div>

              {this.state.delivery_type == '2' ? <div className="col-5">
                <div className={`form-group antInputItem micwrap ${this.state.indication_reason ?  "hasdata" : ""}`}>
                  <Link disabled={this.state.no_obs_hx || !this.state.allowEdit} onClick={(this.state.no_obs_hx || !this.state.allowEdit) ? (e) => { e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: 'indication_reason' }) }} className={(this.state.speech.identifier === 'indication_reason' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'}
                  ></Link>

                  <Form.Item
                    label="Indication"
                    name="indication_reason"
                    rules={[{
                      required: this.state.pregnancy_year,
                      message: "Indication is required"
                    }]}
                    onChange={(e) => this.handleChange(e, 'indication_reason')}
                  >

                    <Input
                      name="indication_reason"
                      value={this.state.indication_reason}
                      defaultValue={this.state.indication_reason}
                      maxLength={60}
                      autoComplete="off"
                      disabled={
                        this.state.no_obs_hx || !this.state.allowEdit
                      }
                    />
                  </Form.Item>
                </div>
              </div> :  null}
            </div> : null}

            <div className="row pt-1 ">
              <div className="col-1">
                <div className="form-group">
                  <div className={this.state.gravidity != null && this.state.gravidity !== '' ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="gravidity" label="Gravidity" id="gravidity"
                      onChange={(e) => this.handleChange(e, 'gravidity')}
                      rules={[
                        { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="" disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.gravidity} />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-1">
                <div className="form-group">
                  <div className={this.state.parity != null && this.state.parity !== '' ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="parity" label="Parity" id="parity"
                      onChange={(e) => this.handleChange(e, 'parity')}
                      rules={[
                        { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="" disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.parity} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="col-1">
                <div className="form-group">
                  <div className={this.state.abortion != null && this.state.abortion !== '' ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="abortion" label="Abortion" id="abortion"
                      onChange={(e) => this.handleChange(e, 'abortion')}
                      rules={[
                        { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="" disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.abortion} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <div className={this.state.live_birth != null && this.state.live_birth !== '' ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="live_birth" label="Live Birth" id="live_birth"
                      onChange={(e) => this.handleChange(e, 'live_birth')}
                      rules={[
                        { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="" disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.live_birth} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="col-1">
                <div className="form-group">
                  <div className={this.state.ectopic != null && this.state.ectopic !== ''  ? 'antInputItem hasdata' : 'antInputItem'} >
                    <Form.Item name="ectopic" label="Ectopic" id="ectopic"
                      onChange={(e) => this.handleChange(e, 'ectopic')}
                      rules={[
                        { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                        /* {max: 8, message: 'Max 8 chars.'} */
                      ]}
                    >
                      <Input className="" disabled={this.state.no_obs_hx || !this.state.allowEdit} defaultValue={this.state.ectopic} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-2 ">
              <div className="col-12">
                <div className={`form-group antInputItem micwrap ${this.state.remarks ? "hasdata" : ""}`}>
                  <Link 
                    disabled={this.state.no_obs_hx || !this.state.allowEdit}
                    onClick={(this.state.no_obs_hx || !this.state.allowEdit) ? (e) => { 
                      e.preventDefault(); } : (e) => { 
                      e.preventDefault();
                      this.initSpeech({ identifier: "remarks" });
                    }}
                    className={
                      this.state.speech.identifier === "remarks" &&
                        this.state.speech.state
                        ? "mic subjective-mic on mute"
                        : "mic subjective-mic"
                    }
                  ></Link>
                  <Form.Item
                    name="remarks"
                    id="remarks"
                    label="Remarks"
                    onChange={(e) => this.handleChange(e, "remarks")}
                  >
                    <Input.TextArea
                      disabled={this.state.no_obs_hx || !this.state.allowEdit}
                      autoSize={textareaSizeDefault}
                      defaultValue={this.state.remarks}
                      showCount
                      maxLength={255}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-12 text-right">
                {/* {this.state.edit_pregnancy_id ?  */}
                  <button disabled={!this.state.allowEdit} type="button" key="back" className="btn btn-outline-secondary text-uppercase px-5 mr-3" onClick={this.handleCancel}>
                    CANCEL
                  </button> {/* :  */}
                {/* null} */}
                <button
                  type="button"
                  className="float-md-right btn btn-outline-primary text-uppercase px-5"
                  disabled={this.state.spin_loading || !this.state.allowEdit} 
                  onClick={(e) => this.manualSubmit('add')}
                >
                  {this.state.submitButtonText}{this.state.spin_loading ? LOADER : ''}
                </button>
              </div>
            </div>
          

            <div className="row mt-3">
              {this.state.obsHistoryListStatus ?
                <div className="col-12">
                  {this.state.obsHistoryList && this.state.obsHistoryList.length > 0 ?
                    <div className="table-responsive">
                      <table className="table table-bordered" id="obstetric-table">
                        <thead>
                          <tr>
                            <th className="text-center" style={{width: "100px"}}>YEAR</th>
                            <th className="text-center" style={{width: "150px"}}>MODE OF CONCEPTION</th>
                            <th className="text-center">PREGNANCY TYPE</th>
                            <th className="text-center">DELIVERY TYPE</th>
                            <th className="text-center">OUTCOME</th>
                            <th className="text-center" style={{width: "250px"}}>REMARKS</th>
                            <th className="text-center">EDIT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.obsHistoryList.map((data, i) => (
                            <tr key={i}>
                              <td className="text-center">{data.pregnancy_year}</td>
                              <td className="text-center">{data.mode_text}</td>
                              <td className="text-center">{data.pregnancy_type_text}</td>
                              <td className="text-center">{data.delivery_type_text}</td>
                              <td className="text-center">{data.outcome_text}</td>
                              <td className="text-center">{data.remarks || '-'}</td>
                              <td className="action text-center">
                                <Button className='btn nostyle-link p-0' disabled={this.state.no_obs_hx ||!this.state.allowEdit} onClick={() => { this.enableEditPregnancy(data); }}>
                                  <i className="icon-edit" />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    : null}
                </div>
                : (
                  <div className="w-100 align-items-center justify-content-center mt-4 d-flex">{LOADER_RED}</div>
                )}
            </div>
            <div className="fixed-cta">
              {(this.props.pathname && this.props.pathname.includes("objective")) ? this.state.allowEdit ? 
                <button
                  type="button"
                  className="btn btn-primary px-4 py-1 nostyle-link"
                  disabled={this.state.spin_loading}
                  onClick={(e) => this.manualSubmit('continue')}
                >
                  CONTINUE{this.state.spin_loading ? LOADER : ''}
                </button> : 
                <button type="button" className="btn btn-primary px-4 py-1 nostyle-link" onClick={this.handleContinue}>CONTINUE</button> : null}
              {(LS_SERVICE.get('user_type') === 2) ?
              <a href="javascript:void(0)" className="btn btn-primary px-4 py-1 nostyle-link" disabled={this.state.spin_loading} onClick={this.handleContinue}>CONTINUE{this.state.spin_loading ? LOADER : ''}</a>
              :null}
            </div>
          </Form>
          {/* Modal For Multiple Pregnancies */}
          <Modal
            title={"Previous Births - Pregnancy Type (Multiple" + (this.state.pregnancy_year ? ", " + this.state.pregnancy_year  : "") + ")"}
            destroyOnClose={true}
            visible={this.state.multiplePregnanciesModal}
            onCancel={(e) =>
              this.handleModalPopup(e, "multiplePregnanciesModal")
            }
            width="1000px"
            bodyStyle={{ paddingBottom: "15px" }}
            className="emrfrm"
            footer={false}
          >
            { this.state.multiplePregnanciesModal ? 
              <MultiplePregnancies 
                pregnancies={this.state.multiplePregnancies} 
                indication_reason={this.state.indication_reason_multiple} 
                handleModalSave={this.handleModalSave} 
              /> 
            : null }
          </Modal>
        </div>
      </div> 
    );
  }
}

export default ObstetricHx;
