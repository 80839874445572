import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
import { Link } from 'react-router-dom';
import Asideleft from '../../components/aside/asideleft';
import {
    ALLERGIES_GETALL,
    FAVORITE_ORDERSET,
    HOSPITALDEFAULTS,
    LOADER_RED,
    MEDICINES_GETALL,
    ORDERSET_SEARCH,
    REFERENCE_TYPE,
    VIEW_ORDERSET,
    ALLERGY_INTENSITY,
    APPLY_ORDERSET,
    API_BASE_URL,
    PRESCRIPTION_DATA,
    ASSESSMENT_API,
    PATIENT_VITALS_ALL,
    LAST_VITAL_ADDED_DATA,
    SOAP_CONFIGURATION_LIST,
    BMI_CALCULATE,
    BSA_CALCULATE,
    PRESCRIPTION_STORE, DELETE_MEDICINE, ISALLOWEDITSOAP, DOSE_RULE,
    TOGGLE_PRACTICE,
    MEDICINES_GETALL_CURRENT_MEDICINES,
    CURRENT_MEDICINES_STOP
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import MedicineForm from '../../components/drugs/medicine-form';
import { Button, notification, Modal } from 'antd';
import { Fragment } from 'react';
import SubHeader from '../../components/subheader/subheader';
import API_SERVICE from '../../utils/apiService';
import { jQueryLabels, FixedCta, AntSelectLabels, onlynumbers } from '../../utils/jQueryLabels';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import MedicineTable from "../../components/drugs/medicine-table";
import axios from "axios";
import { headerService$, practiceService } from '../../utils/rxjs-sharing';
import InstructionTable from "../../components/instructions/instructions-table";
import AssessmentForm from "../../components/assessments/assessment-form";
import SupportingMaterialTable from '../../components/supporting-material/supporting-material-table';
import CurrentMedsPopup from '../../components/drugs/current-meds-popup';
import MedicineAlertPopup from '../copy-to-visit/medicine-alert-popup';

class PlanPrescription extends Component {

    constructor(props) {
        super(props)

        // if (props.location.state === undefined) {
        //     props.history.push("/dashboard");
        //     return;
        // }

        // const { patient } = props.history.location.state;
        this.state = {
            patient: null,
            viewNo: 0,
            allergies: [],
            allergiesID: [],
            drugAllergicData: [],
            drugAllergicDataFinal: [],
            allAllergics: [],
            deleteMedicineIdArray: [],
            userAllergyListDetailsArr: [],
            selectedOrderSetId: '',
            userMedicneList: [],
            templateList: [],
            templateView: [],
            order_set_clicked: null,
            templateDataLoaded: false,
            isModalVisible: false,
            templateId: '',
            listOrderCurrentTab: 'list',
            allergyWarningModal: false,
            medicineWarningModal: false,
            drugData: [],
            drugDataLoaded: false,
            $isTestFavCheckedVal: [],
            polyPharmacyWarningShown: false,
            addedDrugsList: [],
            editData: null,
            editIndex: -1,
            constants: [],
            isPracticing: false,
            isPracticingModal: false,
            allowEdit: true,
            doseRule: 0,
            vitalData: {},
            diagnoseData: [],
            currentMedsLists: [],
            presMedicine: [],
            duplicateMedModel: false,
            ordersetMedList: [],
            showDupMed: []
        }
        this.is_favoriteArr = [];
        this.handleMedicineFlag = 1
        this.medFormRef = React.createRef();
    }

    static getDerivedStateFromProps() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        return true;
    }

    async componentDidMount() {
        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true
            })
        }

        const staff_id = LS_SERVICE.get('staff_id');
        const speciality_id = LS_SERVICE.get('speciality_id');

        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

        let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;

        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient,
            allowEdit
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                this.getUserReqDetails();
                this.handleDrugData();
                this.handleSoapFlow();
                jQueryLabels();
                FixedCta();
                onlynumbers();
                // this.getPatientLastVitalAddedData();
            }
        });

        const assessDataPromise = await Axios.get(ASSESSMENT_API({ reference_type: REFERENCE_TYPE, reference_id: reference_id }))
        if (assessDataPromise.data) {
            let { data } = assessDataPromise.data;
            let assessmentTempArray = [];
            if (data.activeDiagnosisDetails || data.activeDiagnosisDetails.length > 0) {
                data.activeDiagnosisDetails.map((data, i) => {
                    var tempAssessmentObj = {
                        description: data.diagnose_description,
                        diagnosis_id: data.diagnosisId,
                        icd_code: data.icd_code,
                        is_diabetic: data?.is_diabetic || 'N'
                    }
                    assessmentTempArray.push(tempAssessmentObj);
                });
            }
            this.setState({
                diagnoseData: assessmentTempArray,
            })
        }

        const vitalDataPromise = await Axios.get(PATIENT_VITALS_ALL({ patient_id }))
        await this.setState({
            vitalData: vitalDataPromise?.data || {},
        })
        console.log(vitalDataPromise);
        // if (this.props.location.state !== undefined) {
        //     this.setState({
        //         patient: this.props?.location?.state?.patient
        //     }, () => {
        //         this.getUserReqDetails();
        //         this.handleDrugData();
        //     })
        // } else {
        //     this.getPatientData()
        // }
    }

    // getPatientLastVitalAddedData = _ => {

    //     const { match: { params } } = this.props;
    //    // console.log(this.props);return
    //     const { patient_id } = params;
    //     Axios.get(LAST_VITAL_ADDED_DATA({ patient_id }))
    //         .then(success => {
    //             this.setState({
    //                 height:success.data.data.results[0],
    //                 weight:success.data.data.results[1]

    //             }, ()=>{

    //                 if(success.data.data.results[0]!='' && success.data.data.results[1]!=''){
    //                     let bmi = BMI_CALCULATE(success.data.data.results[0], success.data.data.results[1]);
    //                     let bsa = BSA_CALCULATE(success.data.data.results[0], success.data.data.results[1]);
    //                     this.setState({
    //                         bmi:bmi,
    //                         bsa:bsa        
    //                     });
    //                 }

    //             })
    //         })
    // }

    handleSoapFlow = _ => {
        if (!LS_SERVICE.has('goToSoapPatientPageList') || LS_SERVICE.get('goToSoapPatientPageList').length === 0) {
            this.handleSoapInit()
        }
    }

    handleSoapInit = async _ => {

        const { hospital_id } = this.state.patient;

        const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
            hospital_id,
            staff_id: LS_SERVICE.get('staff_id'),
            speciality_id: LS_SERVICE.get('speciality_id') || null
        })

        const pageList = [];
        const newList = [];

        const newSoapList = Object.values(soapList).map((data) => {
            return data.sort((a, b) => a.display_ordering - b.display_ordering)
        });

        Object.values(newSoapList).flat().forEach((en) => {
            if (en.display === 'Y') {
                if (!pageList.includes((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name)) {
                    pageList.push((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name);
                    newList.push({
                        'url': en.alternate_url_format,
                        'parent_module': en.parent_module,
                        'module': (en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name
                    })
                }
            }
        });

        LS_SERVICE.set('goToSoapPatientUrl', newList);
        LS_SERVICE.set('goToSoapPatientPageList', pageList);
    }

    handleDrugData = () => {

        const { patient } = this.state;
        const { patient_id, qms_token_id } = patient;

        this.setState({
            drugDataLoaded: false,
            drugData: []
        })

        const PARAMS = {
            patient_id,
            reference_type: REFERENCE_TYPE,
            reference_id: qms_token_id,
            hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
        }

        Axios.get(PRESCRIPTION_DATA(PARAMS))
            .then(success => {
                let { data } = success.data;
                let addedDrugsList = [];
                let interactionDrugs = [];
                addedDrugsList = data.details.map((v, k) => { return v.drug_id });
                interactionDrugs = data.details.map((v, k) => { return v.drug_name });

                this.setState({
                    drugsData: data.details,
                    addedDrugsList,
                    interactionDrugs,
                    constants: data.constants,
                    drugDataLoaded: true
                })
            })
            .catch(err => console.log(err.response))
    }

    handleDrugDataWithoutLoader = () => {

        const { patient } = this.state;
        const { patient_id, qms_token_id } = patient;

        this.setState({
            drugData: []
        })

        const PARAMS = {
            patient_id,
            reference_type: REFERENCE_TYPE,
            reference_id: qms_token_id,
            hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
        }

        Axios.get(PRESCRIPTION_DATA(PARAMS))
            .then(success => {
                let { data } = success.data;
                this.setState({
                    drugsData: data.details,
                    constants: data.constants,
                })
            })
            .catch(err => console.log(err.response))
    }


    getUserReqDetails = _ => {
        const { patient_id } = this.state.patient;

        const requestAllergiesGetAll = Axios.get(ALLERGIES_GETALL({ patient_id }));
        const requestMedicinesGetAll = Axios.get(MEDICINES_GETALL({ patient_id }));
        Axios.all([requestAllergiesGetAll, requestMedicinesGetAll])
            .then(success => {

                let { allergies, allergiesID } = this.state;
                success[0].data.data.details.allergies.forEach(al => {
                    if (al.allergyType === 'drug') {
                        allergies.push(al);
                        allergiesID.push({
                            intensity: al.intensity,
                            reaction: al.reaction,
                            allergy_code: al.allergy_code
                        });
                    }
                });
                let currentMedsList = success[1].data.data.details.current_medicines;
                var tempArr = [];
                if (currentMedsList) {
                    tempArr = currentMedsList.map(function (v) { return v.drug_id });
                    tempArr = tempArr.filter(function (item, pos) { return tempArr.indexOf(item) == pos; })
                }
                this.setState({
                    allergies,
                    allergiesID,
                    userMedicneList: tempArr
                })
                // , () => {
                //     if (this.state.allergies.length > 0) {
                //         this.getAllergyDetails();
                //     }
                // })
            })
            .catch(err => console.log(err.response))
    }

    // getAllergyDetails = () => {
    //     const { allergies, userAllergyListDetailsArr } = this.state;

    //     // let allergyListData = [];
    //     let genericDrugs = []

    //     allergies.forEach(ual => {
    //         Axios.post(VIEW_ORDERSET, qs.stringify({ orderSetId: ual.allergyId }))
    //             .then(success => {
    //                 success.data.orderSetDrugData.forEach(osd => {
    //                     osd.generic.forEach(gen => {
    //                         genericDrugs.push(gen.generic_id.id)
    //                     })
    //                 })

    //                 this.setState({
    //                     userAllergyListDetailsArr: genericDrugs
    //                 })
    //             })
    //     })
    // }

    templateOnListLoad = () => {
        const PARAMS = {
            searchTerm: '',
            doctor_Id: LS_SERVICE.get('staff_id'),
            list: 'all',
            filter_hospital_id: LS_SERVICE.get("slot_hospital_id"),
            filter_speciality_id: LS_SERVICE.get("speciality_id"),
            hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N'
        }

        Axios.post(ORDERSET_SEARCH, qs.stringify(PARAMS))
            .then(success => {
                success.data.data.orderset.forEach((data, i) => {
                    this.is_favoriteArr.push(data.is_favorite);
                })
                this.setState({
                    templateList: success.data.data.orderset,
                    $isTestFavCheckedVal: this.is_favoriteArr,
                    templateDataLoaded: true
                })
            }).catch(err => console.log(err))
    }

    templateViewDrugs() {
        if (!this.state.templateView.orderSetDrugData || this.state.templateView.orderSetDrugData.length === 0) {
            return (null)
        }
        return (
            <>
                <h4 className="page-title">Medicines</h4>
                <MedicineTable drugsData={this.state.templateView.orderSetDrugData} viewType={'soap-full'}
                    showAction={false} showSelectCheck={false} constants={this.state.templateView.constants} />
                {/* <table id="medicine-lists" className="table table-bordered">
                    <thead>
                        <tr>
                            <th>MEDICINE NAME</th>
                            <th>DOSAGE</th>
                            <th>FREQUENCY</th>
                            <th>ROUTE</th>
                            <th width={120 + 'px'}>NO. OF DAYS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.templateView.orderSetDrugData.map((medicine, index) => {
                            return (
                                <tr key={index}>
                                    <td>{medicine.drug_name}</td>
                                    <td>
                                        {(medicine.dosage !== 'undefined' && medicine.dosage !== null) ?
                                            this.state.templateView.constants.drugDosage.filter(x => x.id === medicine.dosage)[0].description + ' ' + medicine.dosage_form_text
                                            : '-'
                                        }
                                    </td>
                                    <td>
                                        {(medicine.drug_frequency !== 'undefined' && medicine.drug_frequency !== null) ?
                                            this.state.templateView.constants.drugFrequency.filter(x => x.id === medicine.drug_frequency)[0].description
                                            : '-'
                                        }
                                    </td>
                                    <td>
                                        {(medicine.route !== 'undefined' && medicine.route !== null) ?
                                            this.state.templateView.constants.route.filter(x => x.id === medicine.route)[0].description : '-'
                                        }
                                    </td>
                                    <td>{medicine.number_of_days !== null ? medicine.number_of_days : '-'}</td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
             */}
            </>
        )
    }

    templateViewtests() {
        if (!this.state.templateView.orderSetTestData || this.state.templateView.orderSetTestData.length === 0) {
            return (null)
        }
        return (
            <>
                <h4 className="page-title mt-4">Tests</h4>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th width={50 + '%'}>TEST NAME</th>
                            <th>INSTRUCTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.templateView.orderSetTestData.length > 0 ? (
                            this.state.templateView.orderSetTestData.map((test, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{test.test_name}</td>
                                        <td>{test.instructions ? test.instructions : ''}</td>
                                    </tr>
                                )
                            })) : (<tr><td>No Entries</td></tr>)}
                    </tbody>
                </table>
            </>
        )
    }

    templateViewInstruction() {
        if (!this.state.templateView.orderSetInstruction || this.state.templateView.orderSetInstruction.length === 0) {
            return (null)
        }
        return (
            <>
                <h4 className="page-title mt-4">Instructions</h4>
                <div className="row mt-2">
                    <div className="col-10">
                        <ul className="custom-list list-success">
                            <InstructionTable instructionsData={this.state.templateView.orderSetInstruction} viewType={'full'} constants={null} />
                        </ul>
                    </div>
                </div>
                {/*<table className="table table-bordered">
                    <thead><tr><th>INSTRUCTIONS</th></tr></thead>
                    <tbody>
                        {this.state.templateView.length > 0 ? (
                            this.state.templateView.orderSetInstruction.map((val1, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{val1.instruction}</td>
                                    </tr>
                                )
                            })) : (<tr><td>No Entries</td></tr>)}
                    </tbody>
                </table>*/}
            </>
        )
    }

    templateViewMaterial() {
        if (!this.state.templateView.orderSetSupportingMaterial || this.state.templateView.orderSetSupportingMaterial.length === 0) {
            return (null)
        }
        return (
            <>
                <h4 className="page-title mt-4">Supporting Material</h4>
                <div className="row mt-2">
                    <div className="col-10">
                        <ul className="custom-list list-success">
                            <SupportingMaterialTable
                                supportingMaterialData={this.state.templateView.orderSetSupportingMaterial}
                                viewType={'full'} hospitalId={this.state.hospital_id}
                                constants={this.state.constants} />
                        </ul>
                    </div>
                </div>

            </>
        )
    }

    openView(no) {
        if (this.state.viewNo === no) {
            this.setState({ viewNo: 0, templateView: [] });
        }
        else {
            this.setState({ viewNo: no, templateView: [] });
            this.templateOnView(no)
        }
    }

    templateOnView = (templateId) => {
        Axios.post(VIEW_ORDERSET, qs.stringify({ orderSetId: templateId }))
            .then(success => {
                this.setState({ templateView: success.data }, () => { FixedCta() });
            })
            .catch(err => console.log(err))
    }

    templateOnFavourite = (val, index) => {
        if (this.is_favoriteArr[index]) {
            this.is_favoriteArr[index] = 0
        }
        else {
            this.is_favoriteArr[index] = 1
        }

        this.setState({ $isTestFavCheckedVal: this.is_favoriteArr });
        Axios.post(FAVORITE_ORDERSET, qs.stringify({ orderSetId: parseInt(val) }))
            .then(success => {
                this.setState({
                    $isTestFavCheckedVal: this.is_favoriteArr
                });
                notification.success({
                    message: 'Record Updated Successfully',
                    placement: 'topRight'
                });
            })
            .catch(err => {
                console.log(err)
                if (!this.is_favoriteArr[index]) {
                    this.is_favoriteArr[index] = 1
                }
                else {
                    this.is_favoriteArr[index] = 0
                }
                this.setState({
                    $isTestFavCheckedVal: this.is_favoriteArr
                });
            })
    }

    handleListorderTab = (e, tabClicked) => {
        e.preventDefault();

        let { listOrderCurrentTab } = this.state;

        if (tabClicked === listOrderCurrentTab) return;

        listOrderCurrentTab = listOrderCurrentTab === 'list' ? 'order' : 'list';

        this.setState({
            listOrderCurrentTab
        }, () => {
            const { listOrderCurrentTab, templateDataLoaded } = this.state;

            if (listOrderCurrentTab === 'order' && !templateDataLoaded) {
                this.templateOnListLoad()
            }
        })
    }

    drugAllergicMultiple = (drugGeneric) => {

        let { allergiesID, drugAllergicData, deleteMedicineIdArray, allAllergics, drugAllergicDataFinal } = this.state;

        var drugAllergic = false;
        if (drugGeneric && drugGeneric.length > 0) {
            var count = 0;
            for (const j of drugGeneric) {
                for (let allergy of allergiesID) {
                    if (allergy.allergy_code == j.code) {
                        deleteMedicineIdArray.push(count);
                        drugAllergic = true;
                        allAllergics.push(j.drugId)
                        drugAllergicData.push({
                            drugAllergicText: j.generic_name,
                            drugAllergicIntensity: allergy.intensity ? ALLERGY_INTENSITY[parseInt(allergy.intensity)] : ALLERGY_INTENSITY[0]
                        })
                    }
                }
                count++;
            }
            drugAllergicDataFinal = drugAllergicData.filter((set => f => !set.has(f.drugAllergicText) && set.add(f.drugAllergicText))(new Set));
        }
        // Check if Patient has allergy If yes show the allergy Modal popup.
        const { userMedicneList } = this.state;
        if (drugAllergic) {
            this.setState({
                drugAllergicData,
                allAllergics,
                deleteMedicineIdArray,
                drugAllergicDataFinal
            }, () => this.handleModalPopup(null, 'allergyWarningModal'));
        } else if (userMedicneList.length > HOSPITALDEFAULTS.maximum_medicines_allowed && this.handleMedicineFlag) {
            this.handleModalPopup(null, 'medicineWarningModal');
            this.handleMedicineFlag = 0;
        } else {
            this.checkMedicineAlreadyprecribed();
        }

    }

    checkMedicineAlreadyprecribed = async () => {
        const { ordersetMedList } = this.state;
        let orderMeds = ordersetMedList;
        const { patient } = this.state;
        const { patient_id, qms_token_id } = patient;
        await axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id, reference_type: 0, reference_id: qms_token_id })).then(success => {
            let currentMedsList = success.data.data.details.current_medicines;
            //   let constant = success.data.data.constants;
            this.setState({
                currentMedsLists: currentMedsList,
                // constantCurrentMeds: constant
            })
        });
        const { currentMedsLists } = this.state
        let dupMedicine = [];
        currentMedsLists.map((med, i) => {
            orderMeds.map((element) => {
                // var todaysDate=new Date().toISOString().split('T')[0];
                // var createdDate=med.updated_at
                // if(new Date(createdDate)<new Date(todaysDate));//set this condition along with other conditions in below if statement
                if (element.drug_id == med.drug_id && med.prescriptionDrugActive == 'Y')
                    dupMedicine.push(med)
            })
        })
        const ids = dupMedicine.map(o => o.drug_id)
        const filteredMed = dupMedicine.filter(({ drug_id }, index) => !ids.includes(drug_id, index + 1))
        if (dupMedicine.length > 0) {
            this.setState({ presMedicine: dupMedicine, showDupMed: filteredMed })
            this.handleModalPopup(null, 'duplicateMedModel');
        } else {
            this.applyOrderSet();
        }
    }

    handleMedicineListPopup = () => {
        const { userMedicneList } = this.state;
        //   debugger
        if (userMedicneList.length > HOSPITALDEFAULTS.maximum_medicines_allowed && this.handleMedicineFlag) {
            this.handleModalPopup(null, 'medicineWarningModal');
            this.handleMedicineFlag = 0;
        } else {
            this.applyOrderSet();
        }
    }

    applyOrderSet = (orderset_id, removeallergic = '') => {
        const { patient_id, qms_token_id } = this.state.patient;

        orderset_id = orderset_id === undefined ? this.state.selectedOrderSetId : orderset_id;

        Axios.get(APPLY_ORDERSET({ orderset_id, patient_id, reference_type: REFERENCE_TYPE, reference_id: qms_token_id }))
            .then(success => {
                if (success.data.status === "success") {
                    let { listOrderCurrentTab, templateList, order_set_clicked } = this.state;
                    listOrderCurrentTab = listOrderCurrentTab === 'list' ? 'order' : 'list';

                    templateList[order_set_clicked].checked = true;

                    this.setState({
                        templateList,
                        listOrderCurrentTab
                    }, () => this.handleDrugData())
                }
            })
            .catch(error => console.log(error.response))
    }


    getCompleteFromOrderId = async () => {
        const { selectedOrderSetId } = this.state;
        await Axios.post(VIEW_ORDERSET, qs.stringify({ orderSetId: selectedOrderSetId }))
            .then(success => {
                const { data } = success;
                var all_generics = [];
                for (var generic_drug_data of data.orderSetDrugData) {
                    if (generic_drug_data.generic.length > 0) {
                        generic_drug_data.generic[0].generic_id.drugId = generic_drug_data.drug_id
                        all_generics.push(generic_drug_data.generic[0].generic_id)
                    }
                }
                this.setState({ ordersetMedList: data.orderSetDrugData })
                this.drugAllergicMultiple(all_generics);
            })

    }



    handleOrderSetClick = (e, data, checkIndex) => {
        e.preventDefault();
        // debugger;
        //  debugger
        // console.log(e.target.checked)
        /*         if (e.target.checked) { */
        // LS_SERVICE.set('order_set_clicked', checkIndex);

        const { isPracticing } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        this.setState({
            order_set_clicked: checkIndex,
            selectedOrderSetId: data.orderSetId,
        }, this.getCompleteFromOrderId);
        this.handleMedicineFlag = 1;
        /*         } */
    }

    handleEdit = (data, i) => {

        const { isPracticing } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        this.setState({
            editData: null,
            editIndex: -1
        });
        let editData = this.state.drugsData[i];
        editData.previousEndDate = null;
        for (let index = (i - 1); index >= 0; index--) {
            if (this.state.drugsData[index].end_date != null && editData.previousEndDate == null)
                editData.previousEndDate = this.state.drugsData[index].end_date;
        }
        this.setState({
            actionText: "Edit",
            editData: editData,
            editIndex: i
        }, () => {
            const tabId = document.getElementById('create-medicine-details');
            window.scrollTo({ top: (tabId.offsetTop - 10), behavior: 'smooth' });
        })
        AntSelectLabels();
    }

    updateMedicineData = (data, showTaper) => {
        data.selected = true;
        let drugsData = this.state.drugsData;
        let addedDrugsList = [];
        let interactionDrugs = [];
        var index = data.i;
        if (index == -1)
            index = drugsData.length;
        drugsData[index] = data;

        addedDrugsList = drugsData.map((v, k) => { return v.drug_id });
        interactionDrugs = drugsData.map((v, k) => { return v.drug_name });
        const PARAMS = {
            reference_type: REFERENCE_TYPE,
            reference_id: this.state.patient.qms_token_id,
            patient_id: this.state.patient.patient_id,
            medicine_data: [data],

        };
        axios.post(PRESCRIPTION_STORE, qs.stringify(PARAMS))
            .then(success => {
                this.handleDrugDataWithoutLoader();
                this.setState({
                    drugsData, addedDrugsList, interactionDrugs, editData: null, editIndex: -1
                }, () => {
                    if (!showTaper) this.handleModalPopup(null, 'showMedicineModal');;
                })
            })
            .catch(err => console.log(err))

    }

    clearEdit = () => {
        this.setState({
            editData: null,
            editIndex: -1
        })
    }

    polyPharmacyWarningShown = () => {
        this.setState({
            polyPharmacyWarningShown: true,
        })
    }

    handleModalPopup = (e, popupName, data = null, actionType = '', objId = null, patientDocs) => {
        if (e !== null)
            e.preventDefault();
        this.setState({
            [popupName]: !this.state[popupName]
        },
            () => {
                const { viewDocumentModal } = this.state;

                if (viewDocumentModal) {
                    this.carouselRef.current.goTo(patientDocs);
                }

                this.setState({
                    actionText: "Add", editData: null, editIndex: -1
                }, () => {
                    const { showVitalsModal, showAssessmentModal, showMedicineModal, showInstructionModal, showTestModal, deleteConfirmModal, showDrugAllergyModal, showDrugPolyPharmacyModal } = this.state;
                    if (showVitalsModal) {
                        this.setState({ V001: this.state.patientHeight, V002: this.state.patientWeight }, () => { this.handleBmiCalculate(); AntSelectLabels() })
                    }

                    if (showAssessmentModal) {

                        if (actionType === 'editAssessment') {
                            this.setState({
                                actionText: "Edit",
                                editData: this.state.assessmentData[objId],
                                editIndex: objId
                            })
                            AntSelectLabels();
                        }

                    }
                    if (showMedicineModal) {
                        if (actionType === 'editMedicines') {
                            this.setState({ actionText: "Edit" })
                        }
                        if (data !== null) {
                            this.setState({
                                actionText: "Edit",
                                editData: this.state.drugsData[objId],
                                editIndex: objId
                            })
                        }
                    }

                    if (showInstructionModal) {
                        // const { allInstructionsData } = this.state;
                        // this.setState({
                        //   isNewInstruction: false,
                        //   instructionOption: allInstructionsData
                        // });

                    }


                    if (deleteConfirmModal) {
                        // this.setState({ actionType, objId });
                        // For Assessment
                        if (actionType === 'deleteAssessment') {
                            this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete assessment?' });
                        }

                        // For Medicines
                        if (actionType === 'deleteMedicine') {
                            this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete medicine?' });
                        }

                        // For Tests
                        if (actionType === 'deleteTest') {
                            this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete test?' });
                        }

                    }

                    if (showDrugAllergyModal) {
                        this.setState({ allergiesMessage: data.message, deleteObjId: data.type == 'all' ? data.deleteObj : objId })
                    }

                    if (showDrugPolyPharmacyModal) {
                        this.setState({ polyPharmacyMessage: data.message, deleteObjId: data.type == 'all' ? null : objId })
                    }


                })
            })

    }

    /*handleModalPopup = (e, popup, quitReason = '') => {
        if (e !== null)
            e.preventDefault();

        this.setState({
            [popup]: !this.state[popup]
        }, () => {
            const { allergyWarningModal, medicineWarningModal, templateList, order_set_clicked } = this.state;
            if (!allergyWarningModal || !medicineWarningModal && quitReason !== '') {

                templateList[order_set_clicked].checked = false;

                this.setState({
                    templateList
                })
            }
        })
    }*/

    handleOverRule = (e, type) => {
        e.preventDefault();
        //debugger
        if (type === 'allergy') {
            this.handleModalPopup(null, 'allergyWarningModal');
            this.handleMedicineListPopup();
        } else if (type === 'medicine') {
            this.handleModalPopup(null, 'medicineWarningModal');
            //  this.applyOrderSet();
            this.checkMedicineAlreadyprecribed()
        } else if (type === 'medicinePrebAlert') {
            this.stopCurentMeds();
            this.handleModalPopup(null, 'duplicateMedModel');
            this.applyOrderSet();
        }
    }

    stopCurentMeds() {
        const { presMedicine } = this.state;
        let stopDrugIds = presMedicine.map((drugDetailsId) => { return drugDetailsId.prescriptionDrugId })
        let doctor_id = LS_SERVICE.get('staff_id');
        let params = {
            stopDrugIds: stopDrugIds,
        }
        let stopPrescriptionDrugId = null;
        axios.put(CURRENT_MEDICINES_STOP({ stopPrescriptionDrugId, doctor_id }), params).then(res => {
            this.setState({ duplicateMedModel: false })
            notification.success({
                message: 'drug stop succesfully',
                placement: 'topRight'
            });
        }).catch(
            err => {
                notification.error({
                    message: 'failed to stop medicine',
                    placement: 'topRight'
                });
                console.log(err);
            }
        );
    }

    handlePlanContinue = _ => {
        const { patient, isPracticing } = this.state;
        const { patient_id, hospital_id, qms_token_id } = patient;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
        const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
        let index = pageListArr.indexOf('prescribed_medicines');
        index++;
        if (index < pageListArr.length && !isTodayVisitArr.includes(qms_token_id)) {
            let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
            soapurl = soapurl.replace("HOSPITALID", hospital_id);
            soapurl = soapurl.replace("REFID", qms_token_id);
            soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
            soapurl = soapurl.replace("PATID", patient_id);
            this.props.history.push({
                pathname: soapurl,
                state: { patient }
            })
        } else {
            this.props.history.push({
                pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                state: { patient }
            })
            LS_SERVICE.set('blockname', 'plan');
        }
    }

    handleOnItemDelete = (e, actionType, objId) => {
        e.preventDefault();

        var stateVariable = null;
        var stateAddedVariable = null;
        var propKey = null;
        var deleteUrl = null;
        // For Medicine
        if (actionType === 'deleteMedicine') {
            stateVariable = 'drugsData';
            stateAddedVariable = 'addedDrugsList';
            propKey = 'drug_id';
            deleteUrl = DELETE_MEDICINE;
        }

        let dataArray = this.state[stateVariable];
        var deleteObj = dataArray[objId];
        var deleteId = deleteObj.prescription_drug_id;

        this.setState({
            spin_loading: true
        })
        dataArray.splice(objId, 1);
        let addedArray = [];
        let interactionDrugs = [];
        dataArray.map((v, k) => {
            addedArray.push(v[propKey])
            interactionDrugs.push(v.drug_name);
        })

        this.setState({
            deleteConfirmModal: false,
            [stateVariable]: dataArray,
            [stateAddedVariable]: addedArray,
            interactionDrugs,
            editData: null,
            editIndex: -1
        });

        Axios.get(deleteUrl({ id: deleteId }))
            .then(success => {
                this.handleDrugDataWithoutLoader();
                this.setState({
                    spin_loading: false
                })
            })
            .catch(err => console.log(err))
    }

    enablePractice = async e => {
        e.preventDefault();
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
            hospital_id: this.state.hospital_id,
            practice_session: 'start'
        });

        let is_practicing;
        if (practice.data && practice.data.is_practicing === 'Y') {
            is_practicing = true;
            LS_SERVICE.set('practicing', {
                status: true,
                hospital_id: this.state.hospital_id
            });
        } else {
            is_practicing = false;
        }

        this.setState({
            isPracticing: is_practicing,
        }, () => {
            this.handleModalPopup(null, 'isPracticingModal');
        });
    }

    handlePracticeModal = data => {
        this.handleModalPopup(null, data);
    }

    render() {
        const { patient, isPracticing } = this.state;
        // const { qms_token_id, patient_id } = patient

        return (
            <>
                {/* Page sidebar */}
                {(patient != undefined && patient.patient_id != undefined) ? <Asideleft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                {/* Page content */}
                <div className="content-wrapper">
                    <div className="row" id="data-preloader" style={this.state.drugDataLoaded ? { display: 'none' } : null}>
                        <div className="col-12">
                            <div className="text-center">
                                {LOADER_RED}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid" style={!this.state.drugDataLoaded ? { display: 'none' } : null}>
                        <div className="emrfrm row" id="main-page-container">
                            {/* SOAP Bar */}

                            {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                                <SubHeader patient={patient} />
                                : null}

                            <div className="col-12">
                                <div className="row mb-2">
                                    <div className="col-6">
                                        <h1 className="page-title mt-2">Plan - Prescribe Medicines</h1>
                                    </div>{this.state.listOrderCurrentTab === 'list' ?
                                        // <div className="col-6 text-right">
                                        //      <CurrentMedsPopup patient={this.state.patient}/>
                                        // </div>
                                        null
                                        : null}
                                </div>

                                <ul className="nav nav-pills justify-content-center myhealthcare-tabs mb-2">
                                    <li className="nav-item">
                                        <a href="!#" className={`nav-link ${this.state.listOrderCurrentTab === 'list' ? 'active' : ''}`}
                                            onClick={e => this.handleListorderTab(e, 'list')}>List</a>
                                    </li>
                                    <li className="nav-item position-relative">
                                        <a href="!#" className={`nav-link ${this.state.listOrderCurrentTab === 'order' ? 'active' : ''}`}
                                            onClick={e => this.handleListorderTab(e, 'order')}>Order Set</a>
                                        {/*<a href="#" className="copy-btn btn btn-default">
                                                <img src="/images/ic-copypriscription.png" alt="Copy" className="img-fluid" />
                                            </a>*/}
                                    </li>
                                </ul>

                                <div className="row">
                                    <div className="col-12 p-0 tab-content clearfix">
                                        <div className="tab-pane fade show active mb-4 pb-4">
                                            {this.state.listOrderCurrentTab === 'list' ? (
                                                <>
                                                    {patient != undefined && patient.patient_id != undefined && patient.patient_id != null && this.state.drugDataLoaded ?
                                                        /*<MedicineForm medicine_data={this.medicine} isPlanPrescribed={true}
                                                            patient={patient} />*/
                                                        <>
                                                            <MedicineForm
                                                                formType={"soap-full"} actionText={this.state.actionText} updateData={this.updateMedicineData} autofocus={false} editData={this.state.editData}
                                                                vitalData={this.state.vitalData} diagnoseData={this.state.diagnoseData}
                                                                editIndex={this.state.editIndex} addedDrugsList={this.state.addedDrugsList} constants={this.state.constants} patient={this.state.patient} clearEdit={this.clearEdit}
                                                                polyPharmacyWarningShown={this.state.polyPharmacyWarningShown} updatepolyPharmacyWarningShown={this.polyPharmacyWarningShown} interactionDrugs={this.state.interactionDrugs} allowEdit={this.state.allowEdit}
                                                                isPracticing={isPracticing} practiceModal={this.handlePracticeModal}
                                                                ref={this.medFormRef}

                                                            />
                                                            <br />

                                                            <MedicineTable drugsData={this.state.drugsData} viewType={'soap-full'} showAction={!this.state.allowEdit ? false : true} showSelectCheck={false} constants={this.state.constants} handleEdit={this.handleEdit}
                                                                handlePopUp={this.handleModalPopup} isPracticing={isPracticing} practiceModal={this.handlePracticeModal} />
                                                        </>
                                                        : null}

                                                    <div className="fixed-cta">
                                                        <button type="button" className="btn btn-primary px-4 medicine-continue-button"
                                                            onClick={this.handlePlanContinue}>CONTINUE</button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={`w-100 align-items-center justify-content-center mt-4 ${!this.state.templateDataLoaded ? 'd-flex' : 'd-none'}`}>
                                                        {LOADER_RED}
                                                    </div>
                                                    <div className="table-responsive">
                                                        {this.state.templateDataLoaded ? (
                                                            this.state.templateList.length > 0 ? (
                                                                <table className="table table-default">
                                                                    <thead>
                                                                        <tr>
                                                                            {this.state.allowEdit ? <th width={50} /> : null}
                                                                            <th>Order set name</th>
                                                                            <th>Medicines</th>
                                                                            <th>Tests</th>
                                                                            <th width={80}>View</th>
                                                                            <th width={80}>Favourite</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody id="template-list">
                                                                        {this.state.templateList.map((template, i) => {
                                                                            return (
                                                                                <Fragment key={i}>
                                                                                    <tr>
                                                                                        {this.state.allowEdit ?
                                                                                            <td>
                                                                                                <div className="custom-control custom-checkbox orderset-select">
                                                                                                    <input type="radio" className="custom-control-input" name="plan_orderset" id={`orderset_${template.orderSetId}`}
                                                                                                        onClick={e => this.handleOrderSetClick(e, template, i)} />
                                                                                                    <label className="custom-control-label" htmlFor={`orderset_${template.orderSetId}`}></label>
                                                                                                </div>
                                                                                            </td>
                                                                                            : null}
                                                                                        <td>{template.orderSetName}</td>
                                                                                        <td>{template.drug_count} Medicines</td>
                                                                                        <td>{template.test_count} Test</td>
                                                                                        <td style={{ textAlign: 'center' }}>
                                                                                            <Button type="link p-0" className="nostyle-link view_order_set" onClick={() => this.openView(template.orderSetId)}><i className="icon_view"></i></Button>
                                                                                        </td>
                                                                                        <td style={{ textAlign: 'center' }}>
                                                                                            <Button type="link p-0" className="nostyle-link" onClick={(e) => this.templateOnFavourite(template.orderSetId, i)}>
                                                                                                <i className={this.state.$isTestFavCheckedVal[i] === 1 ? 'icon_star favourite' : 'icon_star star'} ></i>
                                                                                            </Button>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className="view_details" style={this.state.viewNo === template.orderSetId ? { display: 'table-row' } : { display: 'none' }}>
                                                                                        <td colSpan="6" className="inner_table">
                                                                                            <div className="text-center">{this.state.templateView.length === 0 ? LOADER_RED : null}</div>
                                                                                            {this.templateViewDrugs()}
                                                                                            {this.templateViewtests()}
                                                                                            {this.templateViewInstruction()}
                                                                                            {this.templateViewMaterial()}
                                                                                        </td>
                                                                                    </tr>
                                                                                </Fragment>
                                                                            )
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            ) : ('No Order Set')) : null}
                                                    </div>

                                                </>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/** || ALLERGY WARNING MODAL */}
                <Modal
                    title=""
                    visible={this.state.allergyWarningModal}
                    onCancel={e => this.handleModalPopup(e, 'allergyWarningModal')}
                    footer={
                        <div className="col-12 text-center py-3">
                            <button type="button" className="btn btn-outline-secondary text-uppercase px-5"
                                onClick={e => this.handleOverRule(e, 'allergy')}>
                                Overrule
                            </button>
                            <button type="button" className="btn btn-primary text-uppercase px-5"
                                onClick={e => this.handleModalPopup(e, 'allergyWarningModal', 'change_drug')}>
                                Change the drug
                            </button>
                        </div>
                    }
                >
                    <div className="row">
                        <div className="col-12 py-3">
                            <p className="pl-5 pr-5 py-2 drug-allergy-message">
                                The patient has:
                                <ul className='custom-list list-success mt-3'>
                                    {this.state.drugAllergicDataFinal.length > 0 ? this.state.drugAllergicDataFinal.map((dadf, idx) => {
                                        return (
                                            <li key={idx}>{dadf.drugAllergicIntensity.name} severity allergy to {dadf.drugAllergicText}</li>
                                        )
                                    }) : null}
                                </ul>
                                Are you sure you want to continue?
                            </p>
                        </div>
                    </div>
                </Modal>


                {/** || MEDICINE WARNING MODAL */}
                <Modal
                    title="Poly Pharmacy Alert"
                    visible={this.state.medicineWarningModal}
                    onCancel={e => this.handleModalPopup(e, 'medicineWarningModal')}
                    footer={false}
                >
                    <div className="row mb-3">
                        <div className="col-12">The patient is already on {this.state.userMedicneList.length} medications. Are you sure you want to prescribe more?</div>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase"
                            onClick={e => this.handleModalPopup(e, 'medicineWarningModal', 'change_medicine')}>
                            No
                        </button>
                        <button type="button" className="btn btn-primary px-5 text-uppercase"
                            onClick={e => this.handleOverRule(e, 'medicine')}>
                            Yes
                        </button>
                    </div>
                    {/* <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyCancel(e)}>No</button>
                        <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyOk(e)}>Yes</button>
                    </div> */}

                </Modal>



                {/* For Modal Delete Confirm  */}
                <Modal
                    title={false}
                    visible={this.state.deleteConfirmModal}
                    onCancel={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}>CANCEL</button>
                        <button type="button" className="btn btn-primary px-5" onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}>DELETE</button>
                    </div>

                </Modal>

                {/* For Modal Allergies Alert  */}
                <Modal
                    title={false}
                    visible={this.state.showDrugAllergyModal}
                    onCancel={(e) => this.handleModalPopup(e, 'showDrugAllergyModal')}
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.allergiesMessage }} />
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleAllergyOverrule(e, this.state.deleteObjId)}>Overrule</button>
                        <button type="button" className="btn btn-primary px-4 text-uppercase" onClick={(e) => this.handleChangeAllergyDrug(e)}>Change the drug</button>
                    </div>
                </Modal>

                {/* For Modal Poly Pharmacy Alert  */}
                <Modal
                    title="Poly Pharmacy Alert"
                    visible={this.state.showDrugPolyPharmacyModal}
                    onCancel={(e) => this.handleModalPopup(e, 'showDrugPolyPharmacyModal')}
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.polyPharmacyMessage }} />
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyPharmacyNo(e)}>No</button>
                        <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyPharmacyYes(e)}>Yes</button>
                    </div>
                </Modal>

                {/** || isPracticing Modal */}
                <Modal
                    title={false}
                    closable={false}
                    visible={this.state.isPracticingModal}
                    footer={false}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                            <h6>Do you want to Start Practice?</h6>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
                        <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
                    </div>
                </Modal>
                {/** || Medicine already precribed Modal */}
                <Modal
                    title="Medicine Prescribed Alert"
                    visible={this.state.duplicateMedModel}
                    onCancel={(e) => this.handleModalPopup(e, 'duplicateMedModel')}
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        {
                            this.state.showDupMed && this.state.showDupMed.length > 0 ?
                                <div className="col-12 text-primary"><span className="font-weight-bold">
                                    {this.state.showDupMed.map((drug, i) => {
                                        if (this.state.showDupMed.length == i + 1) {
                                            return drug.drug_name + " "
                                        } else {
                                            return drug.drug_name + ","
                                        }
                                    }
                                    )}
                                </span> medicines is already prescribed to the patient. Are you sure you want to stop the medication and prescribe again? </div>
                                : null
                        }
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleModalPopup(e, 'duplicateMedModel')}>No</button>
                        <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleOverRule(e, 'medicinePrebAlert')}>YES</button>
                    </div>
                </Modal>I
            </>
        )
    }
}

export default PlanPrescription;