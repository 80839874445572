import React, { Component } from "react";
import { Modal } from "antd";
import { LOADER_RED } from "../../utils/constant";
import { Line } from "react-chartjs-2";

class LineChartModal extends Component {
  render() {
    return (
      <Modal
        title="Vital Graphs"
        visible={this.state.chartModal}
        onCancel={(e) => this.handleModalPopup(e, "chartModal")}
        style={{ top: "40px", height: "296px" }}
        width={600}
        footer={false}
      >
        <div className="row">
          <div className="col-12">
            {this.state.chartDataLoaded ? (
              <div className="chartCanvas">
                <div className="col wrap_chart_filter">
                  <ul className="chart_filter">
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-day ${
                          this.state.chartsTab === "0" ? "active" : ""
                        }`}
                        onClick={(e) => this.handleGraphClickTab(e, "0")}
                      >
                        DAY
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-week ${
                          this.state.chartsTab === "1" ? "active" : ""
                        }`}
                        onClick={(e) => this.handleGraphClickTab(e, "1")}
                      >
                        WEEK
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-month ${
                          this.state.chartsTab === "2" ? "active" : ""
                        }`}
                        onClick={(e) => this.handleGraphClickTab(e, "2")}
                      >
                        MONTH
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-year ${
                          this.state.chartsTab === "3" ? "active" : ""
                        }`}
                        onClick={(e) => this.handleGraphClickTab(e, "3")}
                      >
                        YEAR
                      </button>
                    </li>
                  </ul>
                </div>

                <Line
                  data={this.state.chartData}
                  options={this.state.chartOption}
                />
              </div>
            ) : (
              <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
                {LOADER_RED}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default LineChartModal;
