import * as React from 'react';
import { withRouter } from "react-router-dom";
import FloatingVc from './floating-vc';
import VonageFloatingVc from './vonage-floating-vc';
import { callDriverService } from '../../utils/rxjs-sharing';
import NavHistory from '../../utils/nav-history';

class FloaterWindow extends React.Component {

    state = {
        driver: null
    }
    navigation_urls = []

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setState({
            driver: 'quickblox'
        });

        this.subscription = callDriverService.status().subscribe(result => {
            if (result.driver) {
                this.setState({
                    driver: result.driver
                });
            }
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    // makeNavHistory() {
    //     if (window.location.href !== this.navigation_urls[this.navigation_urls.length - 1]) {
    //         this.navigation_urls[this.navigation_urls.length] = window.location.href;
    //     }
    //     return;
    // }

    render() {
        let pathname = this.props.location.pathname != undefined ? this.props.location.pathname : '';
        NavHistory.save(pathname);
        return(
            <>
                {this.state.driver == 'quickblox' ? (
                    <FloatingVc />
                ) : null}
                {this.state.driver == 'vonage' ? (
                    <VonageFloatingVc />
                ) : null}
            </>
        )
    }

}

export default withRouter(FloaterWindow);
