import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import {MENSTRUAL_HISTORY_GRAPH_PRINT} from "../../utils/constant";
import Axios from 'axios';
import LS_SERVICE from "../../utils/localStorage";
import {
    DURATION_TYPE_SINGULAR,
  } from "../../utils/constant";

class GynaeTrendGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            data: props.chartData[DURATION_TYPE_SINGULAR[props.chartsTab]],
            heading: props.title,
            all_print_checked: false,
            showOnlyTicked: props.showOnlyTicked,
            checkBoxDisable: props.checkBoxDisable,
            checkBoxVisible: props.checkBoxVisible,
            trendMapData: [],
            chartData: props.chartData,
            chartType: props.chartType,
            chartTypes: props.chartTypes,
            chartsTab: props.chartsTab,
            dataLoaded: props.dataLoaded,
            oneChartDataFound: false,
            cycle_length: false,
            duration_of_period: false,
            pain_score: false,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        return {
            isPracticing: nextProps.isPracticing,
            allowEdit: nextProps.allowEdit,
            chartData: nextProps.chartData,
            data: nextProps.chartData[DURATION_TYPE_SINGULAR[nextProps.chartsTab]],
            chartType: nextProps.chartType,
            chartsTab: nextProps.chartsTab,
            dataLoaded: nextProps.dataLoaded,
            showOnlyTicked: nextProps.showOnlyTicked,
            checkBoxDisable: nextProps.checkBoxDisable,
            checkBoxVisible: nextProps.checkBoxVisible,
            single_print: nextProps.single_print,
            patient : nextProps.patient,
        };
    }

    componentDidMount() {
        this.setState(
            async () => {
                await this.createDynamicGraphData();
            }
        );
    }

    createDynamicGraphData() {
        let { data, chartType } = this.state;
        var dataSet = data[chartType];
        var chartData = this.updatedGraphData(dataSet);

        let one_chart_data_found = false;
        if (chartData.data && chartData.data.length > 0) {
            one_chart_data_found = true;
        }

        const datas = {
            labels: chartData.label,
            datasets: [
                {
                    label: "",
                    backgroundColor: "rgb(102 102 102)",
                    borderColor: "rgb(102 102 102)",
                    data: chartData.data,
                    borderWidth: 1,
                    fill: false,
                    spanGaps: true,
                },
            ],
        };

        const options = {
            animation: {
                duration: 0
            },
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    font: {
                    size: 0,
                    },
                },
            },
            title: {
                display: false,
                text: "",
                fontSize: 15,
                position: "left",
            },
            elements: {
                line: {
                    tension: 0,
                },
            },
            legend: {
                position: "bottom",
                display: false,
                labels: {
                  fontSize: 11,
                  boxWidth: 6,
                  //fontColor: '#333333',
                  // usePointStyle: true,
                },
            },
            scales: {
                xAxes: {
                    display: true,
                    title: {
                      display: false,
                      font: {
                        size: 18
                      },
                      padding: { bottom: 0 }
                    },
                    ticks: {
                      font: {
                        size: 11
                      }
                    }
                },
                yAxes: {
                    position: 'left',
                    display: true,
                    suggestedMin: chartData.minY,
                    suggestedMax: chartData.maxY,
                    ticks: {
                      font: {
                        size: 11
                      },
                      stepSize: chartData.stepY,
                    }
                  },
                
            },
        };

        this.setState({ trendMapData: { datas, options } });
        this.setState({ oneChartDataFound: one_chart_data_found });
    }

    updatedGraphData(dataSet) {
        var finalLabel = [];
        var dataLabel = [];
        var rawLabel = [];
        var finalData = [];
        var diffArr = [];
        var totalDiff = 0;
        var count = 0;
        var valueArray = [];
        var labelArray = [];
    
        if (dataSet) {
          valueArray = Object.values(dataSet);
          labelArray = Object.keys(dataSet);
        }
    
        valueArray.map((value, i) => {
          if (value !== "" && value != "NaN") {
            if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == "day") finalLabel.push(moment(labelArray[i], "YYYY-MM-DD HH:mm:ss").format("h:mm A"));
            else finalLabel.push(moment(labelArray[i], "YYYY-MM-DD HH:mm:ss").format("D MMM, YYYY"));
            dataLabel.push(moment(labelArray[i], "YYYY-MM-DD HH:mm:ss").format("D MMM, YYYY | h:mm A"));
            rawLabel.push(labelArray[i]);
            finalData.push(value);
          }
        });
        finalData.map((value, i) => {
          if (i < finalData.length - 1) {
            var diff = parseFloat(finalData[i + 1]) - parseFloat(value);
            totalDiff += Math.abs(diff);
            diffArr.push(Math.abs(diff));
          }
        });
        diffArr = diffArr.filter((e) => e !== 0);
        diffArr = diffArr.sort(function (a, b) {
          return a - b;
        });
        let minY = 0;
        let maxY = 0;
        let stepY = 1;
        if (finalData.length > 0 && diffArr.length > 0) {
          let dataArray = [...finalData];
          let sortedData = dataArray.sort(function (a, b) {
            return a - b;
          });
          // let minCorrection = parseFloat(diffArr[0])>20?20:parseFloat(diffArr[0]);
          // let maxCorrection = parseFloat(diffArr[diffArr.length - 1])>20?20:parseFloat(diffArr[diffArr.length - 1]);
    
          stepY = totalDiff / diffArr.length;
          stepY = Math.round(stepY);
          stepY = stepY > 50 ? 50 : stepY;
          minY = parseFloat(sortedData[0]) - stepY;
          maxY = parseFloat(sortedData[sortedData.length - 1]) + stepY;
        }
        // minY = minY==0?parseFloat(finalData[0])-stepY:minY;
        minY = minY < 0 ? 0 : minY;
        if (diffArr.length == 0) minY = minY == 0 ? parseFloat(finalData[0]) - stepY : minY;
        maxY = maxY == 0 ? parseFloat(finalData[finalData.length - 1]) + stepY : maxY;
    
        return { label: finalLabel, data: finalData, rawLabel: rawLabel, dataLabel: dataLabel, minY: minY, maxY: maxY, stepY: stepY };
    }

    handleGraphPrint = async(e,type="") => {
        var params = {
            patient_id: this.state.patient.patient_id,
            doctor_id: this.state.patient.doctor_id,
            reference_id: this.state.patient.reference_id,
            reference_type:0,
        }

        if(type === "all_print"){
            const checked = e.target?.checked;
            if(checked){
                params.cycle_length = '1';
                params.duration_of_period = '1';
                params.pain_score = '1';
            }else{
                params.cycle_length = '0';
                params.duration_of_period = '0';
                params.pain_score = '0';
            }
        }else{
            e.preventDefault();
            const { isPracticing } = this.state;
            if (!isPracticing) {
                this.props.parentHandleModalPopup(null, 'isPracticingModal');
                return;
            }

            if(document.getElementById("cycle_length") != null){
                params.cycle_length = (document.getElementById("cycle_length").checked)?'1':'0';
            }else if(this.props.menstrualHistoryChecked){
                params.cycle_length = (this.props.menstrualHistoryChecked['checkedMenstrualCycleLength'])?'1':'0';
            }
            if(document.getElementById("duration_of_period") != null){
                params.duration_of_period = (document.getElementById("duration_of_period").checked)?'1':'0';
            }else if(this.props.menstrualHistoryChecked){
                params.duration_of_period = (this.props.menstrualHistoryChecked['checkedMenstrualDurationOfPeriod'])?'1':'0';
            }
            if(document.getElementById("pain_score") != null){
                params.pain_score = (document.getElementById("pain_score").checked)?'1':'0';
            }else if(this.props.menstrualHistoryChecked){
                params.pain_score = (this.props.menstrualHistoryChecked['checkedMenstrualPainScore'])?'1':'0';
            }
        }

        Axios.post(MENSTRUAL_HISTORY_GRAPH_PRINT, params)
        .then(success => {
            this.props.updateData(params);
        }); 
    }

    render() {
        return (
            <>
                {this.state.trendMapData && this.state.oneChartDataFound ? (
                    <>
                    {/* <div className="row" style={{ marginTop: "22px" }}> */}
                        {/* <div className="col-12 pl-2 check-primary">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(e) =>
                                        this.handleGraphPrint(e, "all_print")
                                    }
                                    value={this.state.all_print}
                                    checked={this.state.all_print}
                                    className="custom-control-input"
                                    id="all_print"
                                    name="all_print"
                                    autocomplete="off"
                                />
                            </div>
                        </div> */}
                        <div
                            className={`chartCanvasWrapper w-100 ${
                                (this.state.chartType !== "all" && this.state.chartTypes !== "all")
                                    ? "chartCanvasWrapperSmall"
                                    : "chartCanvasWrapperSmall"
                            }`}
                            style={(this.state.chartType !== "all" && this.state.chartTypes !== "all")? {} : {paddingLeft: "2px",paddingRight: "20px"}}
                        >
                            <div
                                className="chartCanvas"
                                style={{
                                    paddingTop: "20px",
                                    paddingLeft: (this.state.chartType !== "all" && this.state.chartTypes !== "all")? "20px" : "0px",
                                    minWidth: "calc((100%))",
                                    minHeight: "calc((100%))"
                                }}
                            >
                                <div
                                    className="graph__heading check-primary"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        position: "relative",
                                        left: (this.state.chartType !== "all" && this.state.chartTypes !== "all")? "-5px" : '',
                                    }}
                                >
                                    <span
                                        className="heading"
                                        style={{ color: "#000" }}
                                    >
                                        <h4>{this.state.heading}</h4>
                                    </span>
                                    {(this.state.checkBoxVisible === true && LS_SERVICE.get('user_type') !== 2)?
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            onClick={(e) => this.handleGraphPrint(e)}
                                            checked={this.state.single_print}
                                            className="custom-control-input"
                                            id={this.state.chartType}
                                            name={this.state.chartType}
                                            autocomplete="off"
                                            disabled={!this.state.allowEdit}
                                        />
                                        <label
                                            className="custom-control-label text-basic"
                                            htmlFor={this.state.chartType}
                                        ></label>
                                    </div>
                                    :null}
                                </div>

                                <Line
                                    data={this.state.trendMapData.datas}
                                    options={this.state.trendMapData.options}
                                />
                            </div>
                        </div>
                    {/* </div> */}
                    </>
                ) : this.state.trendMapData &&
                  this.state.trendMapData.length === 0 ? (
                    <div className="col d-flex justify-content-center align-self-center mt-2">
                        No Data
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    }
}

export default GynaeTrendGraph;
