import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

class HcLineGraph extends Component {

    constructor() {
        super();
    }

    state = {

    }

    options = {
        responsive: true,
        title: {
            display: false,
            text: 'Height',
            fontSize: 15
        },

        legend: {
            position: "bottom",
            display: false,
            labels: {
                boxWidth: 6,
                fontColor: '#333333',
                usePointStyle: true,
            },
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: '',
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5
                }
            }],
            yAxes: [{
                position: 'left',
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: '',
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 8
                }
            }]
        },
    };

    render() {
        const data = {
            labels: this.props.graphKeys,
            datasets: [
                {
                    label: '',
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000',
                    borderWidth: 1,
                    spanGaps: true,
                    data: this.props.graphValues
                }
            ]
        }

        return (
            <Line data={data} options={this.options} />
        );
    }

}

export default HcLineGraph;