import React, { Component } from "react";
import moment from "moment";
import { Form, DatePicker } from "antd";
class EDD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eddDate: props.eddDate,
      correctedEddDate : props.correctedEddDate,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { eddDate, correctedEddDate, pregnant, no_obs_hx, allowEdit, type } = props;
    return {
      eddDate: eddDate,
      correctedEddDate: correctedEddDate,
      pregnant : pregnant,
      no_obs_hx : no_obs_hx,
      allowEdit : allowEdit,
      type : type
    };
  }

  render() {
    return (
      this.state.eddDate || this.state.correctedEddDate ?
      <>
      {(this.state.type == 3)?
      <>
        <div className="col-2">
          <div className="form-group mt-1 bg-grey py-1 px-3 rounded ">
            <span>EDD:</span>{" "}
            <span className="font-weight-bold">
              {moment(this.state.eddDate).format('DD MMM, YYYY')}
              </span>
          </div>
        </div>
        <div className="col-2 ml-0">
          <div className={(this.state.correctedEddDate) ? 'antInputItem hasdata' : 'antInputItem hasdata'}>
            <Form.Item name="inputCorrectedEddDate" id="inputCorrectedEddDate" label="Corrected EDD" 
            >
              <DatePicker
                format="DD MMM, YYYY"
                inputReadOnly={false}
                disabled={this.state.pregnant != '1' || this.state.no_obs_hx || !this.state.allowEdit}
                showNow={true}
                defaultValue={(this.state.correctedEddDate)? moment(this.state.correctedEddDate) : null}
                className="form-control datetimepicker"
                onChange={(date, dateString) => this.props.handleDatePickerChange(date, dateString, 'inputCorrectedEddDate')}
                allowClear={false}
              />
            </Form.Item>
          </div>
        </div>
      </>
      :
      <div className="form-group mt-1 bg-grey py-1 px-3 rounded ">
        <span>{(this.state.correctedEddDate)? "Corrected EDD:" : "EDD:"}</span>{" "}
        <span className="font-weight-bold">
          {(this.state.correctedEddDate)? moment(this.state.correctedEddDate).format('DD MMM, YYYY') : moment(this.state.eddDate).format('DD MMM, YYYY')}
          </span>
      </div>
      }
      </>
      : null
    );
  }
}

export default EDD;
