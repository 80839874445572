import React from "react";
import Axios from "axios";
import { Collapse, Row, Col } from "antd";
import LS_SERVICE from "../../../utils/localStorage";
// import "./progress.scss";
import AsideLeft from "../../../components/aside/asideleft";
import {
  LOADER_RED,
  HOMECARE_GET_INTERNAL_NOTES,
  HC_GOBACK,
} from "../../../utils/constant";
import moment from "moment";

const { Panel } = Collapse;

class InternalNotes extends React.Component {
  state = {
    dataLoaded: false,
    booking_id: null,
    patient_id: null,
    hospital_id: null,
    internal_notes_doctor: [],
    internal_notes_nurse: [],
  };

  constructor(props) {
    super(props);
  }

  /*Initial Function start*/
  componentDidMount() {
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    this.setState({
      hospital_id: hospital_id,
      patient_id: patient_id,
      booking_id: reference_id,
    }, () => {
      this.getAccordionData();
    });
  }
  /*Initial Function start*/

  getAccordionData() {
    const { booking_id, patient_id } = this.state;

    Axios
      .get(HOMECARE_GET_INTERNAL_NOTES({ booking_id, patient_id }))
      .then((success) => {

        const { internal_notes_doctor, internal_notes_nurse } = success.data;

        this.setState({
          internal_notes_doctor,
          internal_notes_nurse,
          dataLoaded: true
        })
      })
  }

  render() {

    const {
      internal_notes_doctor,
      internal_notes_nurse,
      dataLoaded,
    } = this.state;

    const accordianRow = {

      border: '1px solid #cacaca',
      borderRadius: '5px',
      backgroundColor: '#eff0f0'
    }

    return (
      <>

        <AsideLeft isHomecare={true} />

        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="float-left">
                      <h1 className="page-title mb-0">Internal Notes</h1>
                    </div>
                    <div className="float-right">
                      <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => HC_GOBACK(e, this.props, this.state)}>BACK TO PATIENT</button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="patient-section progress-notes">

                      {dataLoaded && (this.state.internal_notes_doctor.length > 0 || this.state.internal_notes_nurse.length > 0) && (
                        <div className="row">
                          <div
                            className="col-6 pr-0"
                          >
                            <div className="nurse-content" style={{ paddingRight: '1em' }}>
                              <h6 className="mb-4">
                                Internal Notes : Doctor
                                                        </h6>
                              {internal_notes_doctor.length > 0 ? (
                                internal_notes_doctor.map((ind, i) => (
                                  <>
                                    <div
                                      className="text-notes"
                                      key={i}
                                    >
                                      <div className="msg-notes mb-2 p-2" style={accordianRow}>
                                        <Row className="align-items-center">
                                          <Col span={16}>
                                            <span className="text-dark font-weight-bold mb-1">
                                              {ind.created_by}
                                            </span>
                                          </Col>
                                          <Col span={8}>
                                            {ind.created_at !== undefined && (
                                              <p className="text-right mb-0 h6"
                                                style={{ color: '#666' }}>
                                                {moment(ind.created_at).format("D MMM Y | h:mm A")}
                                              </p>
                                            )}
                                          </Col>
                                        </Row>
                                        <div className="row">
                                          <div className="col-12">
                                            <pre className="m-0">{ind.internal_notes}</pre>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))
                              ) : internal_notes_doctor.length === 0 && (
                                <div className="col mb-3 mx-auto card">
                                  <div className="d-flex justify-content-center card-body">
                                    <div className="row">
                                      <div className="col">
                                        <div
                                          className="text-center no-appointement m-0 card-title h5 fontWeightClass textis-primary">
                                          No Doctor Internal notes available!
                                                                            </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-6 pr-0"
                          >
                            <div className="nurse-content" style={{ paddingRight: '1em' }}>
                              <h6 className="mb-4">
                                Internal Notes : Nurse
                                                        </h6>
                              {internal_notes_nurse.length > 0 ? (
                                internal_notes_nurse.map((inn, i) => (
                                  <>
                                    <div
                                      className="text-notes"
                                      key={i}
                                    >
                                      <div className="msg-notes mb-2 p-2" style={accordianRow}>
                                        <Row className="align-items-center">
                                          <Col span={16}>
                                            <span className="text-dark font-weight-bold mb-1">
                                              {inn.created_by}
                                            </span>
                                          </Col>
                                          <Col span={8}>
                                            {inn.created_at !== undefined && (
                                              <p className="text-right mb-0 h6"
                                                style={{ color: '#666' }}>
                                                {moment(inn.created_at).format("D MMM Y | h:mm A")}
                                              </p>
                                            )}
                                          </Col>
                                        </Row>
                                        <div className="row">
                                          <div className="col-12">
                                            <pre className="m-0">{inn.internal_notes}</pre>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))
                              ) : internal_notes_nurse.length === 0 && (
                                <div className="col mb-3 mx-auto card">
                                  <div className="d-flex justify-content-center card-body">
                                    <div className="row">
                                      <div className="col">
                                        <div
                                          className="text-center no-appointement m-0 card-title h5 fontWeightClass textis-primary">
                                          No Nurse Internal notes available!
                                                                            </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {dataLoaded && this.state.internal_notes_doctor.length === 0 && this.state.internal_notes_nurse.length === 0 && (
                        <div className="card bg-light5">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 text-center">No Data available</div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className="col d-flex justify-content-center align-self-center mb-5"
                        style={{
                          visibility: `${!dataLoaded
                            ? "visible"
                            : "hidden"
                            }`,
                        }}
                      >
                        {LOADER_RED}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default InternalNotes;