import React, { Component, Fragment } from 'react';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import {
  Button,
  Select,
  Modal,
  notification
} from 'antd';

import LS_SERVICE from '../../utils/localStorage';
import SupportingMaterialTable from '../supporting-material/supporting-material-table';
import MedicineTable from '../drugs/medicine-table';
import InstructionTable from '../instructions/instructions-table';
import {
  FixedCta
} from '../../utils/jQueryLabels';
import {
  HOSPITALDEFAULTS,
  REFERENCE_TYPE,
  FAVORITE_ORDERSET,
  ORDERSET_SEARCH,
  VIEW_ORDERSET,
  APPLY_ORDERSET,
  LOADER_RED,
  MEDICINES_GETALL_CURRENT_MEDICINES,
  CURRENT_MEDICINES_STOP
} from '../../utils/constant';
import TestTable from '../tests/test-table';


const { Option } = Select;
class OrdersetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateDataLoaded: false,
      viewNo: 0,
      templateList: [],
      templateView: [],
      patient: {},
      selectedOrderSet: [],
      currentMedsLists: [],
      presMedicine: [],
      duplicateMedModel: false,
      ordersetMedList: [],
      currentMedsLists: [],
      activeMed:[],
      selectedInputId: "",
      selectedInputData: {},
      showDupMed: [],
      polyPharmacyModal: false,
      showPolyModel:false,
      orderSetData:{},
      diableConfrimButton:[]
    }
    this.templateOnListLoad()
  }

  async componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    const { patient, selectedOrderSet } = this.props;
    await this.setState({
      patient: patient,
      selectedOrderSet: selectedOrderSet
    })
  }

  templateOnListLoad = async () => {
    const PARAMS = {
      searchTerm: '',
      doctor_Id: LS_SERVICE.get('staff_id'),
      list: 'all',
      filter_hospital_id: LS_SERVICE.get("slot_hospital_id"),
      filter_speciality_id: LS_SERVICE.get("speciality_id"),
      hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N'
    }

    var orderset = await axios.post(ORDERSET_SEARCH, qs.stringify(PARAMS));
    var selectedOrderSet = this.state.selectedOrderSet;
    await orderset.data.data.orderset?.map(function (x) {
      var result = selectedOrderSet.filter(os => os.orderSetId == x.orderSetId);
      if (result.length > 0) { x.is_selected = 'Y' }
      else { x.is_selected = 'N' }
      return x
    })

    await this.setState({
      templateList: orderset.data.data.orderset,
      templateDataLoaded: true
    })
  }

  openView(seq_id) {
    if (this.state.viewNo === seq_id) {
      this.setState({
        viewNo: 0,
        templateView: []
      });
    }
    else {
      this.setState({
        viewNo: seq_id,
        templateView: []
      });
      this.templateOnView(seq_id)
    }
  }

  // Mark a orderset as favourite
  templateOnFavourite(val, index) {
    axios.post(FAVORITE_ORDERSET, qs.stringify({ orderSetId: parseInt(val) }))
      .then((success) => {
        this.templateOnListLoad();
      })
      .catch((err) => {
        console.log('err')
      });
  }

  templateOnView = (templateId) => {
    axios.post(VIEW_ORDERSET, qs.stringify({ orderSetId: templateId }))
      .then(success => {
        this.setState({ templateView: success.data }, () => { FixedCta() });
      })
      .catch(err => console.log(err))
  }

  templateViewDrugs() {
    if (!this.state.templateView.orderSetDrugData || this.state.templateView.orderSetDrugData.length === 0) {
      return (null)
    }
    return (
      <>
        <h4 className="page-title">Medicines</h4>
        <MedicineTable
          drugsData={this.state.templateView.orderSetDrugData}
          viewType={'soap-full'}
          showAction={false}
          showSelectCheck={false}
          constants={this.state.templateView.constants}
        />
      </>
    )
  }

  templateViewtests() {
    if (!this.state.templateView.orderSetTestData || this.state.templateView.orderSetTestData.length === 0) {
      return (null)
    }
    return (
      <>
        <h4 className="page-title mt-4">Tests</h4>
        <TestTable
          testData={this.state.templateView.orderSetTestData}
          viewType={'soap-full'}
          showAction={false}
          showSelectCheck={false}
          constants={this.state.templateView.constants}
        />
      </>
    )
  }

  templateViewInstruction() {
    if (!this.state.templateView.orderSetInstruction || this.state.templateView.orderSetInstruction.length === 0) {
      return (null)
    }
    return (
      <>
        <h4 className="page-title mt-4">Instructions</h4>
        <div className="row mt-2">
          <div className="col-10">
            <ul className="custom-list list-success">
              <InstructionTable
                instructionsData={this.state.templateView.orderSetInstruction}
                viewType={'full'}
                constants={null}
              />
            </ul>
          </div>
        </div>
      </>
    )
  }

  templateViewMaterial() {
    if (!this.state.templateView.orderSetSupportingMaterial || this.state.templateView.orderSetSupportingMaterial.length === 0) {
      return (null)
    }
    return (
      <>
        <h4 className="page-title mt-4">Supporting Material</h4>
        <div className="row mt-2">
          <div className="col-10">
            <ul className="custom-list list-success">
              <SupportingMaterialTable
                supportingMaterialData={this.state.templateView.orderSetSupportingMaterial}
                viewType={'full'}
                hospitalId={this.state.hospital_id}
                constants={this.state.constants}
              />
            </ul>
          </div>
        </div>
      </>
    )
  }

  selectedOrderset = async (e, data, checkIndex) => {
    const { checked } = e.currentTarget;
    this.setState({ selectedInputId: e.target.id })

    //diabled button condition
    if(checked ==true){
      this.state.diableConfrimButton.push('y');
    }
    else{
    this.state.diableConfrimButton.shift();
    }
    data.diableConfrimButton =this.state.diableConfrimButton;
    console.log(this.state.diableConfrimButton);
    if (checked == true) {
      this.checkMedicineAlreadyprecribed(data, checked)
    } else {
      this.props.selectOrderSet(data, checked);
    }
    // axios.get(APPLY_ORDERSET({ 
    //   orderset_id: data.orderSetId, 
    //   patient_id: this.state.patient?.patient_id,
    //   reference_type: REFERENCE_TYPE,
    //   reference_id: this.state.patient?.qmsToken?.qms_token_id
    // }))
  }


  checkMedicineAlreadyprecribed = async (data, checked) => {
    let OrderSetId = data.orderSetId;
    //fetching orderset medicine 
    await axios.post(VIEW_ORDERSET, qs.stringify({ orderSetId: OrderSetId }))
      .then(success => {
        const { data } = success;
        this.setState({ ordersetMedList: data.orderSetDrugData })
      })
      .catch(err => console.log(err))

    const { patient } = this.state;
    const { patient_id, qms_token_id } = patient;

    //fetching current medicine 
    await axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id, reference_type: 0, reference_id: qms_token_id })).then(success => {
      let currentMedsList = success.data.data.details.current_medicines;
      let activeMed=currentMedsList.filter((med)=>{if(med.prescriptionDrugActive == 'Y'){return med.drug_id}})
       activeMed=activeMed.map((med)=>{if(med.prescriptionDrugActive == 'Y'){return med.drug_id}})
      activeMed = activeMed.filter(function (item, pos) { return activeMed.indexOf(item) == pos; })
      this.setState({
        currentMedsLists: currentMedsList,
        activeMed:activeMed,
      })
    });

      //poly pharmacy alert
    if (this.state.activeMed?.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.showPolyModel ) {
      this.showPolyModal()
      this.setState({orderSetData:data})
      return;
    }
    //comparing medicine name 
    const { currentMedsLists, ordersetMedList } = this.state

    let dupMedicine = [];
    currentMedsLists.map((med, i) => {
      ordersetMedList.map((element) => {
        if (element.drug_id == med.drug_id && med.prescriptionDrugActive == 'Y')
          dupMedicine.push(med)
      })
    })
    const ids = dupMedicine.map(o => o.drug_id)
    const filteredMed = dupMedicine.filter(({ drug_id }, index) => !ids.includes(drug_id, index + 1))
    if (dupMedicine.length > 0) {
      this.setState({ presMedicine: dupMedicine, showDupMed: filteredMed, duplicateMedModel: true, selectedInputData: data })
    } else {
      this.props.selectOrderSet(data, checked);
    }
  }


  handlePolyOk=async()=>{
    await this.setState({ polyPharmacyModal: false ,showPolyModel:true });
    this.checkMedicineAlreadyprecribed(this.state.orderSetData,true)
  }

  showPolyModal = () => {
    this.setState({ polyPharmacyModal: true});

  };
  
  stopCurentMeds() {
    const { presMedicine } = this.state;
    let stopDrugIds = presMedicine.map((drugDetailsId) => { return drugDetailsId.prescriptionDrugId })
    let doctor_id = LS_SERVICE.get('staff_id');
    let params = {
      stopDrugIds: stopDrugIds,
    }
    let stopPrescriptionDrugId = null;
    axios.put(CURRENT_MEDICINES_STOP({ stopPrescriptionDrugId, doctor_id }), params).then(res => {
      this.setState({ duplicateMedModel: false })
      notification.success({
        message: 'drug stop succesfully',
        placement: 'topRight'
      });
      this.props.selectOrderSet(this.state.selectedInputData, true);
      document.getElementById(this.state.selectedInputId).checked = true;
    }).catch(
      err => {
        notification.error({
          message: 'failed to stop medicine',
          placement: 'topRight'
        });
        console.log(err);
      }
    );
  }

  handleModalPopup(e) {
    document.getElementById(this.state.selectedInputId).checked = false;
    this.state.diableConfrimButton.shift();
    this.setState({ duplicateMedModel: false, ordersetMedList: [], currentMedsLists: [], selectedInputId: "", selectedInputData: {} })
  }

  handlePolyCancel() {
    document.getElementById(this.state.selectedInputId).checked = false;
    this.state.diableConfrimButton.shift();
    this.setState({
      polyPharmacyModal: false,
    })
  }


  render() {
    return (
      <Fragment>
        <>
          <div className={`w-100 align-items-center justify-content-center mt-4 ${!this.state.templateDataLoaded ? 'd-flex' : 'd-none'}`}>
            {LOADER_RED}
          </div>
          <div className="table-responsive emrfrm">
            {
              this.state.templateDataLoaded
                ?
                (
                  this.state.templateList.length > 0
                    ?
                    (
                      <table className="table table-default">
                        <thead>
                          <tr>
                            <th width={50} />
                            <th>Order set name</th>
                            <th>Medicines</th>
                            <th>Tests</th>
                            <th width={80}>View</th>
                            <th width={80}>Favourite</th>
                          </tr>
                        </thead>
                        <tbody id="template-list">
                          {this.state.templateList.map((template, i) => {
                            return (
                              <Fragment key={i}>
                                <tr>
                                  <td className='text-center'>
                                    <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                      <input type="checkbox" className="custom-control-input" name="fav_check" id={'orderset_' + template.orderSetId} onClick={e => this.selectedOrderset(e, template, i)} />
                                      <label className="custom-control-label" htmlFor={'orderset_' + template.orderSetId}></label>
                                    </div>
                                  </td>
                                  <td>{template.orderSetName}</td>
                                  <td>{template.drug_count} Medicines</td>
                                  <td>{template.test_count} Test</td>
                                  <td style={{ textAlign: 'center' }}>
                                    <Button type="link p-0" className="nostyle-link view_order_set" onClick={() => this.openView(template.orderSetId)}><i className="icon_view"></i></Button>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <Button type="link p-0" className="nostyle-link" onClick={(e) => this.templateOnFavourite(template.orderSetId, i)}>
                                      <i className={template.is_favorite == 1 ? 'icon_star favourite' : 'icon_star'} ></i>
                                    </Button>
                                  </td>
                                </tr>
                                <tr className="view_details" style={this.state.viewNo === template.orderSetId ? { display: 'table-row' } : { display: 'none' }}>
                                  <td colSpan="6" className="inner_table">
                                    <div className="text-center">{this.state.templateView.length === 0 ? LOADER_RED : null}</div>
                                    {this.templateViewDrugs()}
                                    {this.templateViewtests()}
                                    {this.templateViewInstruction()}
                                    {this.templateViewMaterial()}
                                  </td>
                                </tr>
                              </Fragment>
                            )
                          })
                          }
                        </tbody>
                      </table>
                    )
                    :
                    ('No Order Set')
                )
                :
                null
            }
          </div>
          {/* medicine alert popup */}
          <Modal
            title="Medicine Prescribed Alert"
            visible={this.state.duplicateMedModel}
            onCancel={(e) => this.handleModalPopup(e)}
            footer={false}
            closable={false}
          >
            <div className="row mb-3">
              {
                this.state.showDupMed && this.state.showDupMed.length > 0 ?
                  <div className="col-12 text-primary"><span className="font-weight-bold">
                    {this.state.showDupMed.map((drug, i) => {
                      if (this.state.showDupMed.length == i + 1) {
                        return drug.drug_name + "  "
                      } else {
                        return drug.drug_name + "  , "
                      }
                    }
                    )}
                  </span>  medicines is already prescribed to the patient. Are you sure you want to stop the medication and prescribe again? </div>
                  : null
              }

            </div>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleModalPopup(e)}>No</button>
              <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.stopCurentMeds()}>YES</button>
            </div>
          </Modal>
          <Modal
            title="Poly Pharmacy Alert"
            visible={this.state.polyPharmacyModal}
            onCancel={(e) => this.handlePolyCancel()}
            footer={false}
            closable={false}
          >
            <div className="row mb-3">
              <div className="col-12">The patient is already on {this.state.activeMed?.length} medications. Are you sure you want to prescribe more?</div>
            </div>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyCancel(e)}>No</button>
              <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyOk(e)}>Yes</button>
            </div>
          </Modal>
        </>
      </Fragment>
    );
  }
}

export default OrdersetList;
