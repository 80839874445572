import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import $ from 'jquery';
import LS_SERVICE from '../../utils/localStorage';
import Axios from 'axios';
import { LOGOUT, HOSPITALDEFAULTS, TOGGLE_PRACTICE } from '../../utils/constant';
import { headerService$ } from '../../utils/rxjs-sharing';
import { withRouter } from 'react-router-dom';
import API_SERVICE from '../../utils/apiService';
import timer from '../../assets/images/timer.png';
class AppTimeout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idleTime: 0,
            stopTimer: false,
            oldDateObj: new Date(),
            countDownDate: null,
            countDownModal: false,
        }
        this.idleInterval = null;
        this.x = null;
    }

    componentDidMount() {
        LS_SERVICE.set('idleTime', this.state.idleTime);
        this.setState({
            countDownDate: new Date(this.state.oldDateObj.getTime() + 2 * 60000).getTime(),
        })

        //Increment the idle time counter every minute.
        this.idleInterval = setInterval(this.timerIncrement, 60000); // 1 minute is 60000

        //Zero the idle timer on mouse movement.
        let element = document.querySelector('body');

        element.addEventListener('mousemove', this.resetIdleTime, true);
        element.addEventListener('keypress', this.resetIdleTime, true);

    }

    resetIdleTime = _ => {
        this.setState({
            idleTime: 0
        }, () => LS_SERVICE.set('idleTime', this.state.idleTime))
    }

    timerIncrement = () => {
        console.log('Entered Timer Increament');
        this.setState((prevState) => ({
            idleTime: prevState.idleTime + 1,
            oldDateObj: new Date(),
        }), () => {
            LS_SERVICE.set('idleTime', this.state.idleTime);
            this.setState({
                countDownDate: new Date(this.state.oldDateObj.getTime() + 2 * 60000).getTime(),
            }, () => {
                const idleTime = LS_SERVICE.get('idleTime');
                if (idleTime > HOSPITALDEFAULTS.sessionTimeout) {
                    this.handleModalPopup(null, 'countDownModal');
                    this.logoutTimer();
                }
            })
        })
    }

    logoutTimer = () => {
        console.log('enetered Logout timer')
        clearInterval(this.idleInterval);

        // Update the count down every 1 second
        this.x = setInterval(() => {
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = this.state.countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var seconds = distance / 1000;

            this.setState({
                seconds
            })

            // If the count down is finished, write some text
            if (distance <= 0) {
                if (LS_SERVICE.get('token')) {
                    this.setState({
                        countDownModal: false,
                    }, () => {
                        if (document.querySelector('.logout-countdown') !== null) {
                            document.querySelector('.logout-countdown').parentElement.innerHTML = "";
                        }
                    })
                    API_SERVICE.post(TOGGLE_PRACTICE, {
                      practice_session: 'stop'
                    }).then(success1 => {
                      Axios.get(LOGOUT)
                          .then(success => {
                              clearInterval(this.x);
                              LS_SERVICE.clear();
                              $('#root').html(`
                                  <div class="flex-center position-ref full-height">
                                  <div class="content">
                                      <div class="title">
                                      The page has expired due to inactivity.
                                      <br/><br/>
                                      Please refresh and try again.
                                      </div>
                                  </div>
                                  </div>
                              `);
                          })
                      })
                    return false;
                } else {
                    this.setState({
                        countDownModal: false,
                    }, () => {
                        if (document.querySelector('.logout-countdown') !== null) {
                            document.querySelector('.logout-countdown').parentElement.innerHTML = "";
                        }
                        clearInterval(this.x);
                        LS_SERVICE.clear();
                        $('#root').html(`
                                <div class="flex-center position-ref full-height">
                                <div class="content">
                                    <div class="title">
                                    The page has expired due to inactivity.
                                    <br/><br/>
                                    Please refresh and try again.
                                    </div>
                                </div>
                                </div>
                            `);
                    })
                }
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.x);
        clearInterval(this.idleInterval);
    }

    handleModalPopup = (e, popup) => {
        if (e !== null) {
            e.preventDefault();
        }
        this.setState({
            [popup]: !this.state[popup]
        })
    }

    handleLogout = async (e) => {
        e.preventDefault();
        this.handleModalPopup(e, 'countDownModal');
        await API_SERVICE.post(TOGGLE_PRACTICE, {
          practice_session: 'stop'
        });
        Axios.get(LOGOUT)
            .then(success => {
                headerService$.showHeader({ showHeader: 'false' });
                LS_SERVICE.clear();
                this.props.history.push('/');
            })
            .catch(err => console.log(err))
    }

    render() {
        return (
            <>
                {/* SESSION modal */}
                <Modal
                    title=""
                    visible={this.state.countDownModal}
                    onCancel={(e) => this.handleModalPopup(e, 'countDownModal')}
                    wrapClassName="logout-countdown"
                    style={{ top: 40 }}
                    footer={
                        <div className="col-12 text-center">
                            <button type="button" className="btn btn-outline-secondary mr-3" id="logout-session"
                                onClick={this.handleLogout}>LOGOUT</button>
                            <button type="button" className="btn btn-primary" id="stop-logout-timer"
                                onClick={e => {
                                    this.resetIdleTime();
                                    clearInterval(this.x);
                                    this.handleModalPopup(e, 'countDownModal');
                                }}>CONTINUE SESSION</button>
                        </div>
                    }
                >
                    <div className="row">
                        <div className="col-12 text-center">
                            <img className="mb-3" src={timer} alt="" />
                            <div>The system will log you out in</div>
                            <div className="timer"><span className="timerSeconds">{Math.round(this.state.seconds)}</span><small>SECS</small></div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }

}

export default withRouter(AppTimeout);