import React, { Component, Fragment } from 'react';
import { LOADER_RED } from '../../utils/constant';
import { Modal, Button } from 'antd';
import { jQueryLabels, AntSelectLabels, FixedCta } from '../../utils/jQueryLabels';
class SupportingMaterialTable extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            supportingMaterialData: [],
            viewType: 'full', //template, full
            constants: [],
            showPdfModal: false,
            pdfPath: "",
            fileType: ""
        })
    }
    showSuppModal = () => { this.setState({ isSuppModalVisible: true }) };
    handleSuppOk = () => { this.setState({ isSuppModalVisible: false, listingEnabled: true }) };
    handleSuppCancel = () => { this.setState({ isSuppModalVisible: false }) };

    componentDidMount() {
        jQueryLabels();
        FixedCta();
    }
    componentDidUpdate(prevProps, prevState) {

    }
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            supportingMaterialData: nextProps.supportingMaterialData,
            viewType: nextProps.viewType,
            constants: nextProps.constants
        }
    }

    openSupportingFile(e, file_url, file_name) {
        const splitArray = file_name.split('.');
        const extension = splitArray[splitArray.length - 1];
        this.setState({
            pdfPath: file_url,
            fileType: extension,
            showPdfModal: true
        });
    }
    render() {
        return (
            <Fragment>
                <ul className="listed-black round-bullet-black">
                    {  
                        this.state.supportingMaterialData.map((obj, i) => {
                            return (
                                <li>
                                    <a key={i} style={{color: "rgb(102, 153, 204)"}} onClick={e => this.openSupportingFile(e, obj.fileUrl, obj.fileName, i)} className="nostyle-link remove-list remove-instruction text-primary">
                                   <u>{obj.fileName}</u> 
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
                <Modal
                    title="View Supporting Material"
                    visible={this.state.showPdfModal}
                    footer={false}
                    width={850}
                    onCancel={() => { this.setState({ showPdfModal: false, pdfPath: null }) }}
                    style={{ top: 20 }}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                        {this.state.pdfPath != null && this.state.fileType == 'pdf' ? <iframe src={this.state.pdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                                {LOADER_RED}
                            </iframe> : null}
                            {this.state.pdfPath != null && this.state.fileType != 'pdf' ?
                                <img src={this.state.pdfPath} alt={this.state.pdfPath} style={{ width: '100%' }} />
                                : null}
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

export default SupportingMaterialTable;