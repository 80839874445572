import React, { Component } from "react";

class GPALE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props.values
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { values } = props;
    return {
      values: values
    };
  }

  render() {
    let { values } = this.state;
    return (
        (values.gravidity != null && values.gravidity !== '') || (values.parity != null && values.parity !== '') || (values.abortion != null && values.abortion !== '') || (values.live_birth != null && values.live_birth !== '') || (values.ectopic != null && values.ectopic !== '') ? 
          <span className="bg-grey  py-1 px-3 rounded font-weight-bold">
            {(values.gravidity != null && values.gravidity !== '') ? 'G'+values.gravidity+' ' : ''}{(values.parity != null && values.parity !== '') ? 'P'+values.parity+' ' : ''}{(values.abortion != null && values.abortion !== '') ? 'A'+values.abortion+' ' : ''}{(values.live_birth != null && values.live_birth !== '') ? 'L'+values.live_birth+' ' : ''}{(values.ectopic != null && values.ectopic !== '') ? 'E'+values.ectopic+' ' : ''}
          </span>
        : null
    );
  }
}

export default GPALE;
