// import axios from 'axios';
import React, { Component } from 'react';
import { Table } from "antd";
class PrintComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Table
            columns={this.props.columns}
            dataSource={this.props.booking_list}
            pagination={false}
        />
    )
  }
}

export default PrintComponent;