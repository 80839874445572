import Axios from "axios";
import { MEDICINES_GETALL, SEARCH_DRUGS, DRUGS_CONSTANT, DRUG, DRUG_FAVOURITE, CIMS_INFO, CIMS_INTERACTION_EXISTS, CIMS_INTERACTION, REFERENCE_TYPE, PRESCRIPTION_DATA, DELETE_MEDICINE, MYHC_APPTYPE, WOODLANDS, HOSPITALDEFAULTS, LOADER_BLACK, LOADER } from '../utils/constant';
import qs from 'qs';
class MedicineGridHelperClass {

    drugInfo(data){  
        return new Promise(function (resolve, reject) { 
            Axios.post(DRUG, qs.stringify({
            id: data.drug_id
          })).then(success => {
            const response = success.data.data.result;
            resolve(response);
          }).catch(err => console.log(err))      
        });
    }
};
const MedicineGridHelper = new MedicineGridHelperClass();
export default MedicineGridHelper;
