import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import DatePicker from "react-datepicker";
import Axios from 'axios';
import qs from 'qs';
import Moment from 'react-moment';
import moment from 'moment';
import { Modal, notification } from 'antd';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import "react-datepicker/dist/react-datepicker.css";
import { CALENDAR_DOCTOR_LEAVE, FOLLOWUP_UPDATE_BOOKING, LOADER, LOADER_BLACK, LOADER_RED, MONTHS, PATIENT_STATUS, REFERENCE_TYPE, SEARCH_EXISTING } from '../../utils/constant';
import { CALENDAR_DATA, CALENDAR_PATIENT_LIST, CALENDAR_PATIENT_BOOK, CALENDAR_PATIENT_CANCEL, CALENDAR_PATIENT_SLOTS, CALENDAR_SLOTS_BLOCKED, CALENDAR_SLOTS_UNBLOCKED } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
// import calendarIcon from '../../assets/images/ic-calendar-lightblue.png';
import calendarForModal from "../../assets/images/ic-calendar-lightblue.png";
import calendarDatePickerIco from "../../assets/images/ic-calendar.png";
import { jQueryLabels, curentSelectedDate } from '../../utils/jQueryLabels';
import { headerService$ } from '../../utils/rxjs-sharing';

const TAB_STATUS = { 'PATIENT': 1, 'BOOKING': 2, 'LEAVE': 3, 'BLOCKED': 4 };
const TAB_STATUS_NAME = { 1: 'PATIENT', 2: 'BOOKING', 3: 'LEAVE', 4: 'BLOCKED' };

const CancelToken = Axios.CancelToken;
let cancel;

class CalenderComponent extends Component {
  state = {
    startTime: '',
    hospital_id: LS_SERVICE.get('slot_hospitals')[0].id,
    isFirstClick: true,
    calenderEvents: [],
    calDateClicked: null,
    hospital_appointments_data: [],
    hospital_data_loaded: false,
    hospital_data_loaded_once: false,
    hospital_slots_data: [],
    block_slot_button_text: 'Block',
    appointment_page: 1,
    total_pages: 1,
    noMoreData: false,
    isRequestProcessing: false,
    doctor_timings: {
      end_time: '',
      start_time: ''
    },
    filter_count: {
      all: 0,
      arrived: 0,
      completed: 0,
      upcoming: 0,
    },
    activeTab: TAB_STATUS_NAME[1],
    tabStatus: TAB_STATUS.PATIENT,
    bookingModal: false,
    paymentModal: false,
    cancellationModal: false,
    appointmentModal: false,
    existingphone: '',
    first_name: '',
    last_name: '',
    existing_user_array: [],
    existing_dataLoaded: false,
    existing_dataIsProcessing: false,
    existing_user_selected: null,
    existing_slot_clicked: null,
    patient_clicked: null,
    blocked_slot_reason: '',
    blocked_slot_selected: [],
    blocked_slot_selected_index: [],
    startDate: null,
    endDate: null,
    reason_leave: '',
    hospital_slots_counts: '',
    resheduleAppointment: false,
    popupButtonLoading: false,
    hospitalListData: LS_SERVICE.get('slot_hospitals'),
    bookNow: false,
    bookNowTab: false,
    paramsDate: null,
    blockUnblockButtonStatus: false,
    hospitalName: 'Woodlands Multispeciality Hospital Limited'
  }

  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.calendarParamsLoaded = false;
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  componentDidMount() {
    jQueryLabels();

    let date = new Date();
    const { match: { params } } = this.props;

    if (params.hasOwnProperty('hospital_id')) {
      const { hospital_id, patient_id, reference_id } = params;
      const patientUrlData = {
        hospital_id,
        patient_id,
        qms_token_id: reference_id,
      }
      this.setState({
        hospital_id,
        patient_id,
        reference_id,
        patientUrlData
      }, () => {
        if (LS_SERVICE.has('hospitalConfigData')) {
          const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
          let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
          headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
        }
      });
    }

    if (params.hasOwnProperty('date')) {
      date = params.date;
      const patient = LS_SERVICE.get('bookNowPatient');
      const name = patient?.fullname !== undefined ? patient?.fullname : ((patient.first_name !== null ? patient.first_name : '') + ' ' + (patient.last_name !== null ? patient.last_name : ''));
      this.setState({
        startTime: date,
        calDateClicked: date,
        bookNow: true,
        paramsDate: params.date,
        hospital_id: +LS_SERVICE.get('slot_hospital_id'),
        existing_user_selected: { ...patient, name }
      }, () => {
        document.querySelector('.calendarOverlay').classList.remove('d-none');
        this.handleCalenderEvents(date);
        this.handleCalendarTabClick(null, TAB_STATUS.BOOKING);
        this.setState({
          bookNowTab: true
        })
      })
    } else {
      date = date.toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).replace(/\//gm, '-');
      this.handleCalenderEvents(date, true);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.calendarApiTimeer)
  }

  handleCalenderEvents = (date, isClick = false) => {
    const { bookNow, paramsDate } = this.state;

    if (bookNow && !this.calendarParamsLoaded) {
      date = paramsDate;
    }
    const dateMid = date.split('-')[1];
    let currMonNum = dateMid;
    const todayDate = new Date().toLocaleDateString("zh-Hans-CN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//gm, '-');

    Axios.post(CALENDAR_DATA, qs.stringify({ month: date }))
      .then(response => {
        let resData = response.data;
        let data = [];
        let totalSlotsArr = [];
        // let hospitalLists = [];
        const valObj = value => {
          let val = {
            title: '' + value['appointment_count'] + '/' + value['total_slots'],
            start: value['day'],
            className: `${value['day'] === todayDate ? 'emr-today' : ''}`,
            // backgroundColor: `${value['day'] === todayDate ? '#d24350' : ''}`,
            // textColor: `${value['day'] === todayDate ? '#fff' : ''}`,
          }
          data.push(val);
        }
        resData['calendar'].forEach(value => {

          if (date === value['day'] && bookNow) {
            valObj(value);
          } else if ((value['total_slots'] && value['appointment_count']) > 0) {
            valObj(value);
          }

          // if (value['total_slots'] > 0) {
          let va = {
            title: '' + value['appointment_count'] + '/' + value['total_slots'],
            start: value['day'],
            // hospitals: [...value.hospitals]
          }
          totalSlotsArr.push({ date: va.start, slots: va.title });
          // }
        })


        this.calendarApiTimeer = setTimeout(() => {
          if (bookNow && !this.calendarParamsLoaded) {
            let calendarApi = this.calendarRef.current?.getApi();
            if (calendarApi !== undefined && calendarApi !== null) {
              calendarApi.gotoDate(date);
              calendarApi.select(date, [date]);
              this.calendarParamsLoaded = true;
              document.querySelector('.calendarOverlay').classList.add('d-none');
            }
          }
        }, 0)

        this.setState({
          calenderEvents: data,
          slots_array: totalSlotsArr,
          currentMonth: currMonNum,
          // hospitalListData: hospitalLists
        }, () => isClick ? this.handleCalendarPatientClick(date) : null)
      })
  }

  addScrollEvent = () => {
    const elem = document.querySelector(".scroll_loader");
    if (elem !== null)
      elem.addEventListener("scroll", this.handleScroll, true);
  };

  cleanupListener = () => {
    const elem = document.querySelector(".scroll_loader");
    if (elem !== null)
      elem.removeEventListener("scroll", this.handleScroll, true);
  };

  handleScroll = () => {
    const lastLi = document.querySelector(
      ".scroll_loader tr:last-child"
    );
    const scrollWin = document.querySelector(".scroll_loader");
    if (lastLi === null) return;
    if (lastLi.offsetTop + (scrollWin.scrollTop / 2) >= scrollWin.scrollHeight) {
      this.loadMore()
    }
  };


  handleCalendarPatientClick = (date) => {
    this.setState(
      {
        hospital_appointments_data: [],
        hospital_slots_data: [],
        hospital_data_loaded: false,
        appointment_page: 1,
        total_pages: 1,
        startTime: date,
        calDateClicked: date
      }, () => {
        this.hospitalList(date)
      }
    )
  }

  handleSelectAllow = e => {
    let currMonth = LS_SERVICE.get('currentMonth');
    let month = moment(e.start).format('MMM');
    let currMonIndex = MONTHS.indexOf(month);
    currMonIndex = currMonIndex < 10 ? '0' + currMonIndex : currMonIndex;
    if (currMonIndex === currMonth) return true;
    return false;
  }

  handlCalendarSelect = (data) => {
    this.setState({
      startTime: data.startStr,
      calDateClicked: data.startStr
    }, () => this.handleTabFunCall())

    // curentSelectedDate()
  }

  handleHospitalHeaderClick = id => {
    const { hospital_id, bookNow } = this.state;

    if (bookNow) return;

    if (hospital_id === id) {
      this.setState({
        hospital_id: null
      })
      return;
    }

    this.setState({
      hospital_id: id
    }, () => this.handleTabFunCall())
  }

  handleTabFunCall = () => {
    const { activeTab, startTime } = this.state;

    switch (activeTab) {
      case TAB_STATUS_NAME[1]: {
        this.handleCalendarPatientClick(startTime);
        break;
      }
      case TAB_STATUS_NAME[2]: {
        this.handleBookSlotClick();
        break;
      }
      case TAB_STATUS_NAME[4]: {
        this.handleBookSlotClick();
        break;
      }
    }
  }

  loadMore = () => {
    const { noMoreData, isRequestProcessing } = this.state;

    if (noMoreData) return;
    if (isRequestProcessing) return;

    this.setState({
      isRequestProcessing: true,
    });

    this.setState(
      (prevState) => ({
        appointment_page: prevState.appointment_page + 1,
      }),
      () => {
        const date = new Date().toLocaleDateString("zh-Hans-CN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/\//gm, '-');
        this.hospitalList(date)
      }
    );
  };

  hospitalList(date) {

    const { hospital_id, appointment_page } = this.state;

    const PARAMS = {
      date,
      hospital_id,
      booking_status: 0,
      page: appointment_page
    }

    Axios.post(CALENDAR_PATIENT_LIST, qs.stringify(PARAMS), {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    })
      .then(response => {
        const data = response.data;
        const { appointments, pagination, doctor_timings, filter_count } = data;
        const { hospital_appointments_data, slots_array } = this.state;

        const tPages = pagination.total_pages;
        const noMoreData = pagination.links.next === "";
        const currentPage = pagination.current_page;

        let slotcountData = [];

        if (slots_array.length > 0) {
          slotcountData = slots_array.find(e => e.date === date);
        }

        if (data.status) {
          this.setState({
            hospital_appointments_data: +currentPage === 1 ? [...appointments] : [...hospital_appointments_data, ...appointments],
            appointment_page: currentPage,
            total_pages: tPages,
            hospital_data_loaded: true,
            hospital_data_loaded_once: true,
            hospital_slots_counts: slotcountData?.slots || '',
            doctor_timings,
            filter_count,
            noMoreData,
            isRequestProcessing: false,
          }, () => { this.addScrollEvent(); })
          // curentSelectedDate();
        }
      }).catch(err => {
        // if (err?.response?.status === 401 || err?.response?.data?.message.toLowerCase() === "unauthorized") {
        //     this.props.history.push({
        //         pathname: '/dashboard',
        //         state: true
        //     });
        // }
      })
  }

  getCurrentMonth = data => {
    const startMonth = data.start.toLocaleDateString("default", { month: 'short' });
    const endMonth = data.end.toLocaleDateString("default", { month: 'short' })
    let startMonIndex = MONTHS.indexOf(startMonth);
    let endMonIndex = MONTHS.indexOf(endMonth);

    startMonIndex++;
    if (startMonIndex > 12) startMonIndex = 1;
    endMonIndex--;
    if (endMonIndex < 1) endMonIndex = 1;

    if (startMonIndex === endMonIndex) {
      return startMonIndex < 10 ? '0' + startMonIndex : startMonIndex;
    } else {
      startMonIndex--
      return startMonIndex < 10 ? '0' + startMonIndex : startMonIndex;
    }
  }

  handleCalendarTabClick = (e, status) => {
    if (e !== null)
      e.preventDefault();
    const { tabStatus, bookNowTab } = this.state;
    if (tabStatus === status || bookNowTab) return;
    this.setState({
      tabStatus: status,
      activeTab: TAB_STATUS_NAME[status],
      hospital_data_loaded: status !== 3 ? false : true,
      appointment_page: 1,
      hospital_appointments_data: [],
      hospital_slots_data: []
    }, () => {
      this.handleTabFunCall();
    })
  }

  handleBookSlotClick = () => {
    const { calDateClicked } = this.state;
    if (calDateClicked === null) return;
    let date = calDateClicked.split('-').reverse().join('/');
    const doctorId = LS_SERVICE.get('staff_id');

    this.setState({
      hospital_slots_data: [],
      hospital_data_loaded: false
    })

    const { hospital_id } = this.state;

    const PARAMS = {
      doctorId,
      date,
      hospital_id
    }

    Axios.post(CALENDAR_PATIENT_SLOTS, qs.stringify(PARAMS))
      .then(success => {

        let appointmentsData = success.data.time_slots;
        let hospital_slots_data = [];

        if (appointmentsData.all.length > 0) {
          appointmentsData.all.forEach(e => {
            let boockedClass = appointmentsData.is_booked.find(b => b == e.id) !== undefined;
            let blockedClass = appointmentsData.is_blocked.find(b => b == e.id) !== undefined;
            let lapsedClass = false;
            if (!boockedClass) {
              lapsedClass = appointmentsData.is_lapsed.find(l => l == e.id) !== undefined;
            }
            e['status'] = boockedClass ? 'booked' : blockedClass ? 'blocked' : 'available';
            e['slotSelected'] = false;
            e['slotTime'] = e.slot.split(' ');
            if (!lapsedClass) hospital_slots_data.push(e);
          })
        }

        this.setState({
          hospital_slots_data,
          hospital_data_loaded: true
        })
      })
      .catch(err => {
        // if (err?.response?.status === 401 || err?.response?.data?.message.toLowerCase() === "unauthorized") {
        //     this.props.history.push({
        //         pathname: '/dashboard',
        //         state: true
        //     });
        // }
        this.setState({
          hospital_slots_data: [],
          hospital_data_loaded: true
        })
      })
  }

  handleModalPopup = (e, popup) => {
    if (e !== null)
      e.preventDefault();

    this.setState({
      [popup]: !this.state[popup]
    }, () => {
      const { paymentModal } = this.state;

      if (!paymentModal) {
        this.setState({
          existing_user_array: [],
          existingphone: '',
          first_name: '',
          last_name: ''
        })
      }
    })
  }

  handleOnChange = (event) => {
    let { name, value } = event.currentTarget;

    if (name === 'existingphone') {
      value = value.replace(/\D/, "");
    }
    if (name === 'first_name' || name === 'last_name') {
      value = value.replace(/[^0-9.]/g, "");
    }
    this.setState({
      [name]: value
    }, () => {
      const { existingphone, existing_dataLoaded } = this.state;
      if (existingphone.length === 10 && !existing_dataLoaded) {
        this.handleExistingApi();
      } else if (existingphone.length < 10 && existing_dataLoaded) {
        this.setState({
          existing_dataLoaded: false
        })
      }
    })
  }

  handleExistingApi = () => {
    const { existingphone, hospital_id } = this.state;

    this.setState({
      existing_dataIsProcessing: true
    })

    Axios.post(SEARCH_EXISTING, qs.stringify({ mobile_no: existingphone, hospital_id }))
      .then(success => {

        let data = success.data;

        if (data.status) {
          this.setState({
            existing_user_array: [...data.search],
            existing_dataLoaded: true,
            existing_dataIsProcessing: false
          })
        }
      })
      .catch(err => {
        // if (err?.response?.status === 401 || err?.response?.data?.message.toLowerCase() === "unauthorized") {
        //     this.props.history.push({
        //         pathname: '/dashboard',
        //         state: true
        //     });
        // }
      })
  }

  handleExistingUserClick = (e, data) => {
    LS_SERVICE.set('existing_selected', data);
    this.setState({
      existing_user_selected: data
    })
    this.handleModalPopup(e, 'appointmentModal');
    this.handleModalPopup(e, 'paymentModal');
  }

  handleSlotDataClick = (e, data, i) => {
    e.preventDefault();
    const { resheduleAppointment, bookNow } = this.state;
    if (data.status !== 'available') return;
    LS_SERVICE.set('slot_clicked', data);
    LS_SERVICE.set('slot_clicked_index', i);
    this.setState({
      existing_slot_clicked: data
    })
    if (resheduleAppointment || bookNow) {
      this.setState({
        resheduleAppointment: false
      })
      this.handleModalPopup(e, 'appointmentModal');
    } else {
      this.handleModalPopup(e, 'paymentModal');
    }
  }

  handlePatientClick = (e, data) => {
    e.preventDefault();

    let { calDateClicked } = this.state;
    let todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    todayDate = todayDate.getTime();
    calDateClicked = new Date(calDateClicked).getTime();

    if (data.booking_status === "Arrived" || data.booking_status === "Confirmed") {
      if (calDateClicked > todayDate) {
        LS_SERVICE.set('patientClicked', data);
        this.setState({
          patient_clicked: data
        })
        this.handleModalPopup(e, 'bookingModal');
      }
    }
  }

  handleCancelAppointment = (booking_id) => {
    this.setState({
      popupButtonLoading: !this.state.popupButtonLoading
    })
    Axios.post(CALENDAR_PATIENT_CANCEL, qs.stringify({ booking_id }))
      .then(res => {
        this.handleTabFunCall();
        this.handleModalPopup(null, 'bookingModal');
        this.handleModalPopup(null, 'cancellationModal');
        this.setState({
          popupButtonLoading: !this.state.popupButtonLoading
        })
      })
      .catch(err => {
        // if (err?.response?.status === 401 || err?.response?.data?.message.toLowerCase() === "unauthorized") {
        //     this.props.history.push({
        //         pathname: '/dashboard',
        //         state: true
        //     });
        // }
        let response;
        if (err.hasOwnProperty('response')) {
          response = err.response;

          notification.error({
            message: response?.data?.message,
            placement: 'topRight'
          });
        }
      })
  }

  handleRescheduleAppo = () => {
    let { patient_clicked, existing_user_selected } = this.state;

    patient_clicked.patient = { ...patient_clicked.patient, name: patient_clicked.patient.first_name, booking_id: patient_clicked.id };
    existing_user_selected = { ...patient_clicked.patient };

    this.setState({
      resheduleAppointment: true,
      existing_user_selected
    })
    this.handleModalPopup(null, 'bookingModal');
    this.handleCalendarTabClick(null, TAB_STATUS.BOOKING);
  }

  handleUpdateFollowUpBooking = _ => {
    const { existing_user_selected, calDateClicked, existing_slot_clicked, hospital_id, patient_id } = this.state;

    const PARAMS = {
      reference_id: existing_user_selected.qms_token_id,
      reference_type: REFERENCE_TYPE,
      follow_up_date: calDateClicked,
      follow_up_time: existing_slot_clicked.slotTime[1]
    }

    Axios.post(FOLLOWUP_UPDATE_BOOKING, qs.stringify(PARAMS))
      .then(success => {
        this.props.history.push({
          pathname: `/patient/soapss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${existing_user_selected.qms_token_id}`,
          state: { patient: existing_user_selected }
        })
        LS_SERVICE.set('blockname', 'followup');
      })
  }

  handleConfirmAppointment = () => {

    const { existing_slot_clicked, existing_user_selected, bookNow } = this.state;

    this.setState({
      popupButtonLoading: true
    })

    const PARAMS = {
      booking_date: existing_slot_clicked.slotTime[0].split('/').reverse().join('-'),
      hospital_id: LS_SERVICE.get('slot_hospital_id'),
      mobile_number: existing_user_selected.mobile_no,
      patient_first_name: bookNow ? existing_user_selected.patient_first_name : existing_user_selected.first_name,
      patient_last_name: bookNow ? (existing_user_selected.patient_last_name !== "" ? existing_user_selected.patient_last_name : "") : (existing_user_selected.last_name !== "" ? existing_user_selected.last_name : ""),
      patient_name: existing_user_selected?.name || existing_user_selected.first_name,
      timeslot_id: existing_slot_clicked.id,
      isd_code: "91"
    }

    if (existing_user_selected?.id) {
      PARAMS.patient_id = existing_user_selected.id;
    }

    if (existing_user_selected?.booking_id) {
      PARAMS.booking_id = existing_user_selected.booking_id;
    }

    Axios.post(CALENDAR_PATIENT_BOOK, qs.stringify(PARAMS))
      .then(success => {

        let data = success.data;

        if (data.status) {

          if (bookNow) {
            this.handleUpdateFollowUpBooking();
            return;
          }

          let { hospital_slots_data } = this.state;
          const index = LS_SERVICE.get('slot_clicked_index');
          hospital_slots_data[index].status = 'booked';
          this.handleModalPopup(null, 'appointmentModal');
          this.handleCalendarTabClick(null, TAB_STATUS.PATIENT);
          this.setState({
            existing_slot_clicked: null,
            existing_user_selected: null,
            existingphone: '',
            existing_user_array: [],
            existing_dataLoaded: false,
            popupButtonLoading: false
          })

          notification.success({
            message: 'Booking Reference:- ' + data.booking_code,
            placement: 'topRight'
          });
        }
      })
      .catch(err => {
        // if (err?.response?.status === 401 || err?.response?.data?.message.toLowerCase() === "unauthorized") {
        //     this.props.history.push({
        //         pathname: '/dashboard',
        //         state: true
        //     });
        // }
        let response;
        if (err.hasOwnProperty('response')) {
          response = err.response;

          notification.error({
            message: response?.data?.message,
            placement: 'topRight'
          });
        }
      })
  }

  handleBlockSlotClick = (e, data, index) => {
    e.preventDefault();
    const { hospital_slots_data, blocked_slot_selected, blocked_slot_selected_index, block_slot_button_text } = this.state;
    if (data.status === 'available' || data.status === 'booked') {
      if (blocked_slot_selected.length > 0 && block_slot_button_text === 'UNBLOCK') return;

      hospital_slots_data[index].slotSelected = !hospital_slots_data[index].slotSelected;

      if (!hospital_slots_data[index].slotSelected) {
        blocked_slot_selected.splice(blocked_slot_selected.indexOf(hospital_slots_data[index].id), 1);
        blocked_slot_selected_index.splice(blocked_slot_selected_index.indexOf(index), 1);
      }

      this.setState({
        block_slot_button_text: 'BLOCK',
        hospital_slots_data,
        blocked_slot_selected: hospital_slots_data[index].slotSelected ? [...blocked_slot_selected, hospital_slots_data[index].id] : [...blocked_slot_selected],
        blocked_slot_selected_index: hospital_slots_data[index].slotSelected ? [...blocked_slot_selected_index, index] : [...blocked_slot_selected_index]
      });
    } else if (data.status === 'blocked') {
      if (blocked_slot_selected.length > 0 && block_slot_button_text === 'BLOCK') return;
      hospital_slots_data[index].slotSelected = !hospital_slots_data[index].slotSelected;

      if (!hospital_slots_data[index].slotSelected) {
        blocked_slot_selected.splice(blocked_slot_selected.indexOf(hospital_slots_data[index].id), 1);
        blocked_slot_selected_index.splice(blocked_slot_selected_index.indexOf(index), 1);
      }

      this.setState({
        block_slot_button_text: 'UNBLOCK',
        hospital_slots_data,
        blocked_slot_selected: hospital_slots_data[index].slotSelected ? [...blocked_slot_selected, hospital_slots_data[index].id] : [...blocked_slot_selected],
        blocked_slot_selected_index: hospital_slots_data[index].slotSelected ? [...blocked_slot_selected_index, index] : [...blocked_slot_selected_index]
      });
    }
  }

  handleBlockSlotSubmit = e => {
    e.preventDefault();
    this.setState({
      blockUnblockButtonStatus: true
    })

    const { blocked_slot_selected, blocked_slot_reason, block_slot_button_text, calDateClicked } = this.state;
    const date = calDateClicked;

    const PARAMS = {
      data: [{
        date,
        slots: blocked_slot_selected
      }],
      reason: blocked_slot_reason,
    }

    Axios.post(block_slot_button_text === 'BLOCK' ? CALENDAR_SLOTS_BLOCKED : CALENDAR_SLOTS_UNBLOCKED, qs.stringify(PARAMS))
      .then(success => {
        const data = success.data;

        if (data.status) {

          this.handleBookSlotClick();
          let { hospital_slots_data } = this.state;

          notification.success({
            message: data.message,
            placement: 'topRight'
          })


          // blocked_slot_selected_index.forEach(bssi => {
          //     hospital_slots_data[bssi].status = block_slot_button_text === 'BLOCK' ? 'blocked' : 'booked';
          //     hospital_slots_data[bssi].slotSelected = false;
          // });

          // blocked_slot_selected.forEach(bsr => {
          //     debugger
          //     hospital_slots_data = hospital_slots_data.map(hsd => hsd.id === bsr ? hsd.status = 'blocked' : null)
          // });

          // debugger

          this.setState({
            hospital_slots_data,
            blocked_slot_selected: [],
            blocked_slot_selected_index: [],
            blocked_slot_reason: '',
            blockUnblockButtonStatus: false
          })
        }
      })
      .catch(err => {
        // if (err?.response?.status === 401 || err?.response?.data?.message.toLowerCase() === "unauthorized") {
        //     this.props.history.push({
        //         pathname: '/dashboard',
        //         state: true
        //     });
        // }
      })

  }

  handleDateChange = (date, type) => {
    this.setState({
      [type]: date
    }, () => {
      if (type === 'startDate') {
        document.querySelector('#rangeEnd').click();
      }
    })
  }

  handleApplyLeaveSubmit = e => {
    e.preventDefault();

    const { startDate, endDate, reason_leave } = this.state;

    const PARAMS = {
      startDate: new Date(startDate).toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).replace(/\//gm, '-'),
      endDate: new Date(endDate).toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).replace(/\//gm, '-'),
      reason: reason_leave
    }

    Axios.post(CALENDAR_DOCTOR_LEAVE, qs.stringify(PARAMS))
      .then(success => {
        let data = success.data;

        if (data.status) {
          notification.success({
            message: data.message,
            placement: "topRight"
          })

          this.setState({
            startDate: null,
            endDate: null,
            reason_leave: ''
          })
        }
      })
      .catch(err => {
        // if (err?.response?.status === 401 || err?.response?.data?.message.toLowerCase() === "unauthorized") {
        //     this.props.history.push({
        //         pathname: '/dashboard',
        //         state: true
        //     });
        // }
        let error = err.response;
        if (error?.data?.message) {
          notification.error({
            message: error.data.message,
            placement: "topRight"
          })
        }
      })
  }

  handleNewUserContinue = _ => {
    let { existingphone, first_name, last_name, existing_user_selected } = this.state;

    existing_user_selected = {
      first_name,
      last_name,
      name: first_name + ' ' + last_name,
      mobile_no: existingphone
    }

    this.setState({
      existing_user_selected
    }, () => {
      this.handleModalPopup(null, 'appointmentModal');
      this.handleModalPopup(null, 'paymentModal');
    })
  }

  daysCalculations = _ => {
    const { startDate, endDate } = this.state;
    const a = moment(startDate);
    const b = moment(endDate);
    return b.diff(a, 'days') + 1;
  }

  handleCalendarArrowClick = e => {
    e.currentTarget.nextElementSibling.click();
    let calendarApi = this.calendarRef.current.getApi();
    console.log(calendarApi.getDate());
    let date = calendarApi.getDate();
    date = date.toLocaleDateString("zh-Hans-CN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//gm, '-');
    this.handleCalenderEvents(date);
  }

  handleBlockSlotButtonDisable = _ => {
    const { blocked_slot_reason, blocked_slot_selected, blockUnblockButtonStatus } = this.state;
    if (blockUnblockButtonStatus) return true;
    if (blocked_slot_reason.length === 0 || blocked_slot_selected.length === 0) return true;
    return false;
  }

  goBack = (event) => {
    event.preventDefault();
    LS_SERVICE.set('blockname', 'followup');
    this.props.history.goBack();
  }

  render() {

    const { activeTab, hospital_data_loaded, hospitalListData, hospital_slots_counts, calenderEvents, hospital_data_loaded_once,
      hospital_appointments_data, hospital_slots_data, startTime, first_name, last_name, doctor_timings, bookNow,
      existing_dataIsProcessing, existing_dataLoaded, existing_user_array, existingphone, popupButtonLoading,
      existing_user_selected, existing_slot_clicked, patient_clicked, isRequestProcessing, startDate, endDate } = this.state;

    return (
      <>
        <div className="container-fluid">
          <div className="row my-4 px-2">
            <div className="col-md-7">
              <div id="calendar" className="my-calendar">
                <FullCalendar
                  firstDay={1}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  ref={this.calendarRef}
                  customButtons={{
                    leftCustom: {
                      icon: 'left-single-arrow',
                      click: (e) => {
                        this.handleCalendarArrowClick(e);
                      }
                    },
                    rightCustom: {
                      icon: 'right-single-arrow',
                      click: (e) => {
                        this.handleCalendarArrowClick(e);
                      }
                    }
                  }}
                  header={{
                    left: "leftCustom prev",
                    center: "title",
                    right: "rightCustom next"
                  }}
                  // height={'parent'}
                  // events={
                  //     (fetchInfo, successCallback) => !calendarDataLoaded ? this.getCalendarData(fetchInfo, successCallback) : null
                  // }
                  events={calenderEvents}
                  // showNonCurrentDates={false}
                  selectable={true}
                  unselectAuto={false}
                  selectLongPressDelay={50}
                  // selectAllow={this.handleSelectAllow}
                  select={this.handlCalendarSelect}
                />
              </div>
            </div>
            <div className="col-md-5">
              {bookNow ? (
                <div className="backbtn-wrapper text-right mb-3">
                  <button type="button" className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                </div>
              ) : null}
              <ul className="nav nav-pills justify-content-center mycalendar-tabs">
                <li className="nav-item">
                  <a href="!#" className={`nav-link ${activeTab === TAB_STATUS_NAME[1] ? 'active' : ''}`}
                    onClick={(e) => this.handleCalendarTabClick(e, TAB_STATUS.PATIENT)}>Patients</a>
                </li>
                <li className="nav-item">
                  <a href="!#" className={`nav-link ${activeTab === TAB_STATUS_NAME[2] ? 'active' : ''}`}
                    onClick={(e) => this.handleCalendarTabClick(e, TAB_STATUS.BOOKING)}>Book Slots</a>
                </li>
                <li className="nav-item">
                  <a href="!#" className={`nav-link ${activeTab === TAB_STATUS_NAME[3] ? 'active' : ''}`}
                    onClick={(e) => this.handleCalendarTabClick(e, TAB_STATUS.LEAVE)}>Mark Leave</a>
                </li>
                <li className="nav-item">
                  <a href="!#" className={`nav-link ${activeTab === TAB_STATUS_NAME[4] ? 'active' : ''}`}
                    onClick={(e) => this.handleCalendarTabClick(e, TAB_STATUS.BLOCKED)}>Block Slots</a>
                </li>
              </ul>

              <div className="tab-content mt-4">
                <div className="tab-pane fade active show" role="tabpanel" id="hospitalAccordionList">
                  <div className="accordion my-calendar-accordion" id="accordionHospital">
                    {hospitalListData.map((hl, i) => {
                      return (
                        <div className="card rounded-0" style={{ overflow: 'visible' }} key={i}>
                          <div className="card-header" onClick={_ => this.handleHospitalHeaderClick(hl.id)}>
                            <h6 className={`panel-collapse ${this.state.hospital_id === hl.id ? 'collapsed' : null}`}>
                              {hl.name}
                              <span className="text-primary d-block small hospital-timings">
                                {hospital_data_loaded_once ? (
                                  doctor_timings.start_time !== null && doctor_timings.end_time !== null ? (
                                    <>
                                      <Moment parse="HH:mm A" date={doctor_timings.start_time} format="h:mm A" />
                                      {" "}to{" "}
                                      <Moment parse="HH:mm A" date={doctor_timings.end_time} format="h:mm A" />{" "}

                                                                            (<span className="curr_date">
                                        <Moment parse="YYYY-MM-DD" date={startTime} format="D, MMM YYYY" />
                                      </span>)
                                                                        </>
                                  ) : 'No Slots') : null}
                              </span>
                              <strong>
                                {hospital_data_loaded ? hospital_slots_counts : null}
                              </strong>
                            </h6>
                          </div>
                          <div className={`emrfrm collapse ${this.state.hospital_id === hl.id ? 'show' : null}`}>
                            {activeTab === TAB_STATUS_NAME[1] ? (
                              <div className="card-body table-responsive scroll_loader">
                                <div>
                                  <table className="table">
                                    <tbody>
                                      {hospital_data_loaded ? (
                                        hospital_appointments_data.length > 0 ? (
                                          hospital_appointments_data?.map((e, i) => {
                                            const { first_name, last_name, uhid } = e.patient;
                                            return (
                                              <tr className={`dummy ${e.booking_status === "Confirmed" || e.booking_status === "Arrived" ? "cursor-pointer" : ""}`} key={i}
                                                onClick={(el) => this.handlePatientClick(el, e)}>
                                                <td>
                                                  <h6 className="mb-0">{first_name + ' ' + (last_name !== null ? last_name : '')}</h6>
                                                  {uhid !== null ? (
                                                    <span className="small text-muted d-block">UHID : {uhid}</span>
                                                  ) : null}
                                                </td>
                                                <td className="text-center">
                                                  <Moment parse="HH:mm A" date={e.booking_time} format="h:mm A" />
                                                </td>
                                                <td className="text-right" width="200">
                                                  <span className={PATIENT_STATUS[e.booking_status]}>{e.booking_status}</span>
                                                </td>
                                              </tr>
                                            )
                                          })) : (
                                          <tr>
                                            <td>No Appointments</td>
                                          </tr>
                                        )
                                      ) : (null)}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : (null)}

                            {activeTab === TAB_STATUS_NAME[2] ? (
                              <>
                                <ul className="slots_color">
                                  <li>
                                    <i className="available"></i> Available
                                                            </li>
                                  <li>
                                    <i className="booked"></i> Booked
                                                            </li>
                                  <li>
                                    <i className="blocked"></i> Blocked
                                                            </li>
                                </ul>
                                <ul className="slots">
                                  {hospital_data_loaded ? (
                                    hospital_slots_data.length > 0 ?
                                      (hospital_slots_data.map((e, i) => (
                                        <li className="list-inline-item" key={i}>
                                          <a className={`btn btn-outline slot ${e.status}`} href="!#" onClick={(el) => this.handleSlotDataClick(el, e, i)}>
                                            <Moment parse="HH:mm A" date={e.slotTime[1] + ' ' + e.slotTime[2]} format="h:mm A" />
                                          </a>
                                        </li>
                                      )
                                      )) : (<li className="ml-2">NO SLOTS AVAILABLE</li>)
                                  ) : (null)}
                                </ul>
                              </>
                            ) : (null)}

                            {activeTab === TAB_STATUS_NAME[3] ? (
                              <form className="row mt-4" onSubmit={this.handleApplyLeaveSubmit}>
                                <div className="col-12 col-md-6">
                                  <div className={`form-group react_datepicker ${startDate !== null ? "hasdata" : ""}`}>
                                    {/* <div className="form-label-group input-group"> */}
                                    {/* <input type="text" id="start-date" className="form-control datepicker start-date" autoComplete="off" /> */}
                                    <DatePicker
                                      selected={this.state.startDate}
                                      onChange={date => this.handleDateChange(date, 'startDate')}
                                      selectsStart
                                      minDate={new Date()}
                                      dateFormat="dd/MM/yyyy"
                                      startDate={this.state.startDate}
                                      endDate={this.state.endDate}
                                      tabIndex={1}
                                    />
                                    <span className="react_datepicker_icon">
                                      <img src={calendarDatePickerIco} alt="Calendar" className="icon" />
                                    </span>
                                    <label className="required" htmlFor="start-date">Start Date<span>*</span></label>
                                    {/* </div> */}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className={`form-group react_datepicker ${endDate !== null ? "hasdata" : ""}`}>
                                    {/* <input type="text" id="end-date" className="form-control datepicker end-date" placeholder="End Date" autoComplete="off" /> */}
                                    <DatePicker
                                      selected={this.state.endDate}
                                      onChange={date => this.handleDateChange(date, 'endDate')}
                                      selectsEnd
                                      minDate={this.state.startDate}
                                      dateFormat="dd/MM/yyyy"
                                      startDate={this.state.startDate}
                                      endDate={this.state.endDate}
                                      tabIndex={2}
                                      id="rangeEnd"
                                    />
                                    <span className="react_datepicker_icon">
                                      <img src={calendarDatePickerIco} alt="Calendar" className="icon" />
                                    </span>
                                    <label className="required" htmlFor="end-date">End Date<span>*</span></label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12">
                                  <div className="form-group">
                                    <input type="text" id="leave-reason" value={this.state.reason_leave} onChange={this.handleOnChange}
                                      name="reason_leave" className="form-control" placeholder="Reason" autoComplete="off" />
                                    <label className="required" htmlFor="leave-reason">Reason<span>*</span></label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className={`form-group ${startDate && endDate !== null ? "hasdata" : ""}`}>
                                    <span className="form-control">
                                      {/* <input type="text" id="no-of-days" className="form-control" placeholder="No of Days" disabled autoComplete="off" /> */}
                                      {(this.state.startDate && this.state.endDate) !== null ? (this.daysCalculations()) : ''}
                                      {/* <span> No of Days</span> */}
                                    </span>
                                    <label className="required" for="no-of-days">No of Days</label>

                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="float-right form-group">
                                    <button type="submit" className="btn btn-primary text-uppercase" disabled={this.state.reason_leave.length === 0 || this.state.startDate === null || this.state.endDate === null}>
                                      Confirm
                                                                </button>
                                  </div>
                                </div>
                              </form>
                            ) : (null)}

                            {activeTab === TAB_STATUS_NAME[4] ? (
                              <>
                                <ul className="slots_color">
                                  <li>
                                    <i className="available"></i> Available
                                                                    </li>
                                  <li>
                                    <i className="booked"></i> Booked
                                                                    </li>
                                  <li>
                                    <i className="blocked"></i> Blocked
                                                                    </li>
                                </ul>
                                <ul className="slots">
                                  {hospital_data_loaded ? (
                                    hospital_slots_data.length > 0 ? (
                                      hospital_slots_data.map((e, i) => (
                                        <li className="list-inline-item" key={i}>
                                          <a className={`btn btn-outline slot cursor-pointer ${e.status} ${e.slotSelected ? 'slot-select' : ''} `}
                                            href="!#" onClick={(el) => this.handleBlockSlotClick(el, e, i)}>
                                            <Moment parse="HH:mm A" date={e.slotTime[1] + ' ' + e.slotTime[2]} format="h:mm A" />
                                          </a>
                                        </li>
                                      )
                                      )) : (<li className="ml-2">NO SLOTS AVAILABLE</li>)
                                  ) : (null)}
                                </ul>
                                {hospital_data_loaded ? (
                                  hospital_slots_data.length > 0 ?
                                    <form className="row emrfrm mt-2" onSubmit={this.handleBlockSlotSubmit}>
                                      <div className="col-12 col-md-6">
                                        <div className="form-group">
                                          <input type="text" value={this.state.blocked_slot_reason} onChange={this.handleOnChange}
                                            name="blocked_slot_reason" className="form-control" placeholder="Reason" />
                                          <label className="required" htmlFor="block-reason">Reason<span>*</span></label>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="float-right form-group">
                                          <button type="button" className="btn btn-primary text-uppercase"
                                            disabled={this.handleBlockSlotButtonDisable()}
                                            type="submit">
                                            {this.state.block_slot_button_text}
                                          </button>
                                        </div>
                                      </div>
                                    </form> : (null)
                                ) : (null)}
                              </>
                            ) : (null)}

                            <div className={`w-100 align-items-center justify-content-center mt-4
                                                        ${!hospital_data_loaded || isRequestProcessing ? 'd-flex' : 'd-none'}
                                                    `}>
                              {LOADER_RED}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* || Booking Confirmation MODAL */}
        <Modal
          title="Booking Confirmation"
          visible={this.state.bookingModal}
          onCancel={(e) => this.handleModalPopup(e, 'bookingModal')}
          style={{ top: 40 }}
          footer={false}
        >
          <figure>
            <div className="text-center">
              <span className="bg-lightblue">
                <img src={calendarForModal} width="100" alt="Calendar" className="img-fluid" />
              </span>
            </div>
            <figcaption className="text-center">
              <div className="text-muted" id="confirmation_txt">
                <p>Booking is confirmed.</p>
                {patient_clicked !== null ? (
                  <>
                    <p className="text-capitalize">For <span className="h5 text-success">{patient_clicked.patient.first_name + ' ' + (patient_clicked.patient.last_name !== null ? patient_clicked.patient.last_name : '')}</span></p>
                    <div className="my-3">
                      <p>Booking Reference:- <span className="h6 text-success">{patient_clicked.booking_code}</span></p>
                      <h6>at <Moment parse="HH:mm:ss" date={patient_clicked.booking_time} format="h:mm A" />
                        {" "}-{" "}
                        <Moment parse="YYYY-MM-DD" date={patient_clicked.booking_date} format="dddd, D MMM, YYYY" /></h6>
                      <h5>{this.state.hospitalName}</h5>
                    </div>
                    {patient_clicked.booking_status === "Confirmed" ? (
                      <div className="row">
                        <div className="col-12 text-center">
                          <button className="btn btn-outline-secondary cancel-appointment" disabled={popupButtonLoading}
                            onClick={() => this.handleCancelAppointment(patient_clicked.id)}>
                            Cancel Appointment{popupButtonLoading ? LOADER_BLACK : null}
                          </button>
                        </div>
                        <div className="col-12 text-center">
                          <button className="btn btn-primary reschedule-appointment" onClick={this.handleRescheduleAppo}>Reschedule Appointment</button>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </figcaption>
          </figure>
        </Modal>


        {/* Booking cancellation modal */}
        <Modal
          title="Booking Cancellation"
          visible={this.state.cancellationModal}
          onCancel={(e) => this.handleModalPopup(e, 'cancellationModal')}
          style={{ top: 40 }}
          footer={false}
        >
          <figure>
            <div className="text-center">
              <span className="bg-lightblue">
                <img src={calendarForModal} width="100" alt="Calendar" className="img-fluid" />
              </span>
            </div>
            <figcaption className="text-center">
              <div className="text-muted" id="confirmation_txt">
                <p>Booking Cancellation.</p>
                {patient_clicked !== null ? (
                  <>
                    <p>Hi, <span className="h5 text-success">{LS_SERVICE.get('staff_name')}</span></p>
                    <p>You have just cancelled an appointment </p>
                    <p className="text-capitalize">For <span className="h5 text-success">{patient_clicked.patient.first_name + ' ' + (patient_clicked.patient.last_name !== null ? patient_clicked.patient.last_name : '')}</span></p>
                    <h6>at <Moment parse="HH:mm:ss" date={patient_clicked.booking_time} format="h:mm A" />
                      {" "}-{" "}
                      <Moment parse="YYYY-MM-DD" date={patient_clicked.booking_date} format="dddd, D MMM, YYYY" /></h6>
                    <h5>{this.state.hospitalName}</h5>
                  </>
                ) : null}

              </div>
            </figcaption>
          </figure>
        </Modal>

        {/* || ADD APPOINTMENT MODAL */}
        <Modal
          title="Add Appointment"
          visible={this.state.appointmentModal}
          onCancel={(e) => this.handleModalPopup(e, 'appointmentModal')}
          style={{ top: 40 }}
          footer={false}
        >
          <figure>
            <div className="text-center">
              <span className="bg-lightblue">
                <img src={calendarForModal} width={100} alt="Calendar" className="img-fluid" />
              </span>
            </div>
            <figcaption className="text-center">
              <div className="text-muted" id="pre_confirm_txt">
                <h5 className="text-capitalize">Hi {LS_SERVICE.get('staff_name').split(' ')[0]}</h5>
                <p>You have just booked an appointment for</p>
                <div className="my-3">
                  {existing_user_selected !== null ? (
                    <h5 className="text-capitalize text-success">{existing_user_selected.name}</h5>
                  ) : null}
                  {existing_slot_clicked !== null ? (
                    <p>at <Moment parse="HH:mm A" date={existing_slot_clicked.slotTime[1] + ' ' + existing_slot_clicked.slotTime[2]} format="h:mm A" />
                      {" "}-{" "}
                      <Moment parse="DD/MM/YYYY" date={existing_slot_clicked.slotTime[0]} format="dddd, D MMM, YYYY" /></p>
                  ) : null}
                  <h5>{this.state.hospitalName}</h5>
                </div>
              </div>
            </figcaption>
            <div className="text-center">
              <button className="btn btn-primary px-5 small" disabled={popupButtonLoading}
                onClick={this.handleConfirmAppointment}>
                CONFIRM{popupButtonLoading ? LOADER : null}
              </button>
            </div>
          </figure>

        </Modal>

        {/* || Booking Confirmation MODAL */}
        <Modal
          title="Patient Details"
          visible={this.state.paymentModal}
          onCancel={(e) => this.handleModalPopup(e, 'paymentModal')}
          style={{ top: 40 }}
          width={600}
          footer={false}
        >
          <form className="select-patient-form radio-tabs emrfrm" autoComplete="off">
            <div className="collapse show" id="existing-user">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <input type="text" className="form-control mobilenumbers" value={existingphone} onChange={this.handleOnChange}
                      name="existingphone" maxLength={10} placeholder="Phone" autoComplete="off" />
                    <label htmlFor="existingphone">Phone<span className="text-danger">*</span></label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <input type="text" className="form-control" value={first_name} onChange={this.handleOnChange}
                      name="first_name" placeholder="First Name" maxLength={50} autoComplete="off" />
                    <label htmlFor="first_name">First Name<span className="text-danger">*</span></label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <input type="text" className="form-control" value={last_name} onChange={this.handleOnChange}
                      name="last_name" placeholder="Last Name" maxLength={50} autoComplete="off" />
                    <label htmlFor="last_name">Last Name<span className="text-danger">*</span></label>
                  </div>
                </div>
                <hr className="my-2" />
                <br />
                <div className="col-md-12">
                  <div className="existing_patients">
                    {existing_dataLoaded && existing_user_array.length > 0 ? (
                      <>
                        <h6>Registered Patients</h6>
                        <ul>
                          {existing_user_array.map((e, i) => (
                            <li className="custom-checkbox" key={i} onClick={(el) => this.handleExistingUserClick(el, e)}>
                              <input type="radio" name="existing_patients" defaultValue={e.id} />
                              <label className="custom-control-label" htmlFor="patient_52384">{e.name}
                                {" "}
                                {e.uhid !== null ? (
                                  <span>UHID :{" "}{e.uhid}</span>
                                ) : null}</label>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (null)}

                    <div className={`w-100 align-items-center justify-content-center mt-4
                                                ${existing_dataIsProcessing ? 'd-flex' : 'd-none'}
                                            `}>
                      {LOADER_RED}
                    </div>
                  </div>
                </div>
                {existing_dataLoaded && existing_user_array.length === 0 && (existingphone && first_name && last_name).length > 0 ? (
                  <div className="new-user-booking col-12">
                    <div className="text-center">
                      <a href="#" className="btn btn-outline-secondary px-5 small mr-3"
                        onClick={e => this.handleModalPopup(e, 'paymentModal')}>CANCEL</a>
                      <a href="#" className="btn btn-primary px-5 small"
                        onClick={this.handleNewUserContinue}>CONTINUE</a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </form>

        </Modal>

        <div className="calendarOverlay text-center d-none">
          {LOADER_RED}
        </div>
        {/* ${calendarDataLoading ? '' : 'd-none'} */}
      </>
    )
  }
}

export default CalenderComponent;