import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Asideleft from '../../components/aside/asideleft'
//import Subheader from '../../components/subheader/subheader'


export default class InternalNotesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }
    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }
    render() {
        return (
            <>
                <Asideleft />
                <div className="content-wrapper">
                    <div className="container-fluid emrfrm ">

                        <div className="row">
                            {/* <Subheader/> */}
                            <div className="col-12 mb-3">
                                <div class="float-left">
                                    <h1 class="page-title">Internal Notes</h1>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group micwrap normal">
                                    <Link
                                        className='mic subjective-mic '>
                                    </Link>
                                    <div className='d-flex'>
                                        <label htmlFor={"ss"} className="control-label">Notes </label>
                                    </div>
                                    <textarea className="form-control subjective-field" id={"ss"}
                                        rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-8'>
                                <div className="custom-control custom-checkbox custom-control-inline mr-0 ml-0">
                                    <input type="checkbox" className="custom-control-input" name="not_print" id="not_print"
                                    />
                                    <label className="custom-control-label" htmlFor="not_print">Not to be Printed
                                    </label>
                                </div>
                            </div>
                            <div className='col-4 text-right'>
                                <button class="btn btn-primary text-uppercase px-4">SAVE</button>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className='col-12 mb-3'>
                                <div className="card bg-grey">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <span className='font-weight-bold text-dark'>Dr Sarfaraz Test Doctor,&nbsp;</span><span>Cardiology</span>
                                            </div>
                                            <div className='col-4 text-right'>
                                                <h6>10 Mar 2022 | 10:20 AM</h6>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <span>Patient has fever since morning.Patient has headache</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className="card bg-grey">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <span className='font-weight-bold text-dark'>Dr Sarfaraz Test Doctor,&nbsp;</span><span>Cardiology</span>
                                            </div>
                                            <div className='col-4 text-right'>
                                                <h6>10 Mar 2022 | 10:20 AM</h6>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <span>Patient has fever since morning.Patient has headache</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className="card bg-grey">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <span className='font-weight-bold text-dark'>Dr Sarfaraz Test Doctor,&nbsp;</span><span>Cardiology</span>
                                            </div>
                                            <div className='col-4 text-right'>
                                                <h6>10 Mar 2022 | 10:20 AM</h6>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <span>Patient has fever since morning.Patient has headache</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </>

        )
    }
}
