import React, { Component } from 'react';
import AsideLeft from '../../components/aside/asideleft';
import copyImg from '../../assets/images/copy.png';
import searchIcon from '../../assets/images/search-ic.png';
import {
  TEST_RESULTS,
  LOADER_RED
} from '../../utils/constant';
import API_SERVICE from '../../utils/apiService';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import { jQueryLabels } from '../../utils/jQueryLabels';

class PastVisit extends Component {

  state = {
    currentTab: 'radiology_pathology',
    currentPage: 1,
    allResults: [],
    Laboratory: [],
    radiology: [],
    loading: false,
    dateFormat: 'DD/MM/YYYY',
    filterByValue: '3 Months',
    patientId: null,
    search_start_date: null,
    search_end_date: null,
    keyword: '',
    moment_start_date: null,
    moment_end_date: null,
    abnormal: false,
    preset_ranges: {
      'Last 24 Hours': [moment().subtract(24, 'hours'), moment()],
      '1 Week': [moment().subtract(1, 'weeks'), moment()],
      '2 Weeks': [moment().subtract(2, 'weeks'), moment()],
      '3 Weeks': [moment().subtract(3, 'weeks'), moment()],
      '1 Month': [moment().subtract(1, 'month'), moment()],
      '2 Months': [moment().subtract(2, 'month'), moment()],
      '3 Months': [moment().subtract(3, 'month'), moment()],
      '1 Year': [moment().subtract(1, 'year'), moment()]
    },
    dateFilter: '3 Months',
    requestSent: false
  }

  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }

  componentDidMount() {
    jQueryLabels();
    this.dateFilter = this.dateFilter.bind(this);
    this.navigateToPage = this.navigateToPage.bind(this);
    this.setInitialSearch();
  }

  setInitialSearch() {
    const start = moment().subtract(3, 'months').toObject();
    const end = moment().toObject();
    this.setState({
      currentTab: 'radiology_pathology',
      search_start_date: new Date(start.years, start.months, start.date),
      search_end_date: new Date(end.years, end.months, end.date),
      moment_start_date: moment().subtract(3, 'months').format(this.state.dateFormat),
      moment_end_date: moment().format(this.state.dateFormat),
      keyword: '',
      abnormal: false,
      filterByValue: '3 Months'
    }, () => {
      this.searchResults();
    });
  }

  dateFilter(start, end, label) {
    const crlabel = start.format('LL') + ' - ' + end.format('LL');
    this.setState({
      filterByValue: (label === 'Custom Range') ? crlabel : label,
      search_start_date: new Date(start.years, start.months, start.date),
      search_end_date: new Date(end.years, end.months, end.date),
      abnormal: this.state.abnormal,
      keyword: this.state.keyword,
      moment_start_date: start.format(this.state.dateFormat),
      moment_end_date: end.format(this.state.dateFormat)
    }, () => {
      this.searchResults();
    });
  }

  async searchResults() {
    const arrRoute = this.props.location.pathname.split('/');
    const pid = arrRoute[arrRoute.length - 1];
    this.setState({
      patientId: pid,
      loading: true,
      allResults: [],
      Laboratory: [],
      radiology: [],
      pagination: null,
      requestSent: false
    });
    const start = this.state.moment_start_date;
    const end = this.state.moment_end_date;

    const results = await API_SERVICE.post(TEST_RESULTS, {
      search: this.state.keyword,
      patientId: pid,
      startdate: start,
      enddate: end,
      is_abnormal: this.state.abnormal ? 1 : 0,
      type: this.state.currentTab,
      page: this.state.currentPage,
      json_view: "true"
    });

    if (results.hasOwnProperty("test_results") === true) {
        this.setState({
          loading: false,
          requestSent: true,
          allResults: results.test_results.appointments,
          pagination: results.test_results.pagination
        });
    }
  }

  tabChanged(tab) {
    this.setState({
      currentTab: tab,
      currentPage: 1,
    }, () => this.searchResults())
  }

  navigateToPage(page) {
    this.setState({
      currentPage: page
    }, () => this.searchResults())
  }

  listing() {
    return this.state.allResults.length > 0 ?
      this.state.allResults.map((appointment, index) => (
        <div className="card bg-light mb-3" key={index}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-5">{appointment.test_name}</div>
              <div className="col-md-3">Order Date: {appointment.appointment_booking_date}</div>
              <div className="col-md-2 text-right">
                {appointment.is_abnormal === "Yes" ? <><i className="icon-severity-medium2"></i> Abnormal</> : null}
              </div>
              <div className="col-md-2 text-right">
                {appointment.test_status === "Result Awaited" ? <div style={{ color: "red" }}>{appointment.test_status}</div> :
                  appointment.test_type === "radiology" ? <Link to={`/patient/view-radiology-result/${this.state.patientId}/${appointment.test_booking_code}`} className="text-success">View Result</Link> :
                    <Link to={`/patient/view-test-result/${this.state.patientId}/${appointment.test_booking_code}`} className="text-success">View Result</Link>}
              </div>
            </div>
          </div>
        </div>
      )) : null;
  }

  render() {
    return (
      <>

        {this.state.patientId != null ? <AsideLeft patient_id={this.state.patientId} /> : null}

        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
              </div>
              <div className="col-8">
                <ul className="nav nav-pills justify-content-center myhealthcare-tabs auto-width test-results-tabs" role="tablist">
                  <li className="nav-item">
                    <a onClick={() => this.tabChanged('radiology_pathology')} href="#all-tab"
                      className={"nav-link " + (this.state.currentTab === 'radiology_pathology' ? 'active' : '')}
                      data-toggle="pill" role="tab" aria-controls="all-tab" aria-selected="true">All</a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => this.tabChanged('pathology')} href="#pathology-tab"
                      className={"nav-link " + (this.state.currentTab === 'pathology' ? 'active' : '')}
                      data-toggle="pill" role="tab" aria-controls="pathology-tab" aria-selected="false">Laboratory</a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => this.tabChanged('radiology')} href="#radiology-tab"
                      className={"nav-link " + (this.state.currentTab === 'radiology' ? 'active' : '')}
                      data-toggle="pill" role="tab" aria-controls="radiology-tab" aria-selected="false">Radiology &amp; others</a>
                  </li>
                </ul>
              </div>
              <div className="col">
                <div className="float-right">
                <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <form onSubmit={(e) => { e.preventDefault(); this.searchResults(); }} className="emrfrm" id="reportSearchForm" autoComplete="off">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="form-label-group input-group">
                              <input onChange={(e) => this.setState({ keyword: e.target.value })} value={this.state.keyword} type="text" id="search" name="search" className="form-control" placeholder="Search by Reports" autoComplete="off" />
                              <span onClick={() => this.searchResults()} id="reportSpanSubmit" className="input-group-addon bg-primary text-white" style={{ cursor: "pointer", borderRadius: " 0 4px 4px 0", maxWidth: "116px", padding: "5px 15px 0px 10px", fontSize: "1.125rem", color: "#fff" }}><img className="mr-2" src={searchIcon} alt="" />Search</span>
                              <label htmlFor="search">Search by Reports</label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">

                            {this.state.search_start_date != null ? <DateRangePicker
                              onCallback={this.dateFilter}
                              initialSettings={{
                                startDate: this.state.search_start_date,
                                endDate: this.state.search_end_date,
                                ranges: this.state.preset_ranges
                              }}
                            >
                              <button type="button" className="text-left form-control">{this.state.filterByValue}</button>
                            </DateRangePicker> : null}
                            <label className="control-label" htmlFor="search">Filter by duration</label>
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group">
                            <div className="custom-control custom-checkbox custom-control-inline mt-2">
                              <input onChange={(e) => { this.setState({ abnormal: e.target.checked }, () => { this.searchResults() }); }} type="checkbox" name="is_abnormal" className="custom-control-input" value="1" id="abnormal_filter" autoComplete="off" checked={this.state.abnormal} />
                              <label className="custom-control-label" htmlFor="abnormal_filter">Abnormal parameters</label>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div onClick={(e) => { e.preventDefault(); this.setInitialSearch(); }} className="btn btn-primary reset cursor-pointer">RESET</div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>


                <div className="tab-content">
                  <div className="tab-pane show active">
                  {this.state.loading ? 
                        this.state.allResults.length ===0 ? 
                                    <div className="card bg-light mb-3">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-12">Test results not available for selected duration!</div>
                                      </div>
                                    </div>
                                  </div>
                                : 
                                <div className="col-lg-12 mb-5 text-center">{LOADER_RED}</div>
                        
                          : this.listing()}



                    {this.state.requestSent && this.state.allResults.length == 0 ?
                      <div className="card bg-light mb-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">Test results not available for selected duration!</div>
                          </div>
                        </div>
                      </div> : null}

                  </div>
                </div>

                {this.state.pagination != null && this.state.pagination.total > 0 ? <Pagination defaultCurrent={this.state.currentPage} total={this.state.pagination.total} onChange={this.navigateToPage} /> : null}

                <br />

                {/* <div className="tab-content">
                  <div className="tab-pane show active" id="all-tab" role="tabpanel">
                    <div className="card bg-light mb-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-5">Hemoglobin A1c Test (HbA1c)</div>
                          <div className="col-md-3">Order Date: 3 Dec 2020</div>
                          <div className="col-md-2 text-right">

                          </div>
                          <div className="col-md-2 text-right">

                            <div style={{ color: "red" }}>Result Awaited</div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="tab-pane" id="pathology-tab" role="tabpanel">
                    <div className="card bg-light mb-3">
                      <div className="card-body">
                        <div className="row"><div className="col-md-5">Hemoglobin A1c Test (HbA1c)</div><div className="col-md-3">Order Date: 3 Dec 2020</div><div className="col-md-2 text-right"></div><div className="col-md-2 text-right"><div style={{ color: "red" }}>Result Awaited</div></div></div>
                      </div>
                    </div>

                  </div>
                  <div className="tab-pane" id="radiology-tab" role="tabpanel">
                    <div className="card bg-light mb-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">Radiology & Others Test results not available for selected duration!</div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="tab-pane" id="others-tab" role="tabpanel">
                  </div>
                </div> */}

                <div id="pagination-content">
                  <nav aria-label="Page navigation example" id="paginationSet" className="">
                  </nav>
                  <nav aria-label="Page navigation example" id="paginationSetPathology" className="d-none">
                  </nav>
                  <nav aria-label="Page navigation example" id="paginationSetRadiology" className="d-none">
                  </nav>
                  <nav aria-label="Page navigation example" id="paginationSetOther" className="d-none">
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* 
  <div className="modal" id="start-practice-alert-menu" tabindex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 my-3 text-center">
                      <h6>Do you want to Start Practice?</h6>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <a href="#" className="btn btn-outline-secondary px-4" id="doctorStartPracticeAlertConfirmNo" data-dismiss="modal">No</a>
                    <div className="btn btn-primary px-4" id="doctorStartPracticeAlertConfirmYes" data-dismiss="modal">
                      Yes
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

<div className="modal" id="referalConfirm" tabindex="-1" role="dialog">
  <div className="modal-dialog modal-md" role="document">
    <div className="modal-content" style="width: 530px;">
      <div className="modal-header">
        <h4 className="modal-title">Referral Alert</h4>
      </div>
      <div id="note"></div>
      <div className="modal-footer justify-content-center">
        <div className="btn btn-primary px-4" id="referalConfirmOK" data-dismiss="modal">
          OK
        </div>
      </div>
    </div>
  </div>
</div> */}

      </>
    )
  }
}

export default PastVisit;