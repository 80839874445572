import React, { Component } from "react";

class PageOpenListener extends Component {
  componentDidMount() {
    // Broadcast that you're opening a page.
    localStorage.setItem("openpages", Date.now());
    this.onLocalStorageEvent = (e) => {
      if (e.key === "openpages") {
        // Listen if anybody else is opening the same page!
        localStorage.setItem("page_available", Date.now());
      }
      if (e.key === "page_available") {
        // alert("Application is already running in another Tab.");
        window.open('/duptab', '_self').close();
        window.close();
      }
    };
    // Add the event listener
    window.addEventListener("storage", this.onLocalStorageEvent, false);
  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    window.removeEventListener("storage", this.onLocalStorageEvent, false);
  }

  render() {
    return null; // This component doesn't render anything
  }
}

export default PageOpenListener;
