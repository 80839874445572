import React, { Component } from "react";
import lactatingImage from "../../assets/images/mother-with-baby.svg";

class Lactating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lactating: props.lactating
    }
  }
  static getDerivedStateFromProps(props, state) {
    const { lactating } = props;
    return {
      lactating: lactating
    };
  }

  render() {
    return (
      this.state.lactating ? <img style={{ width: "16px" }} src={lactatingImage} alt="lactating" className="icon"/> : null
    )
  }
}

export default Lactating;