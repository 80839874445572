import $ from 'jquery';

export const jQueryLabels = thisElem => {
    $(document).on('focusin', '.form-control', function () {
        var getEle = $(this).prop('tagName');
        if (getEle === 'INPUT' || getEle === 'TEXTAREA') {
            var gatPlaceholder = $(this).attr('placeholder');
            if ((typeof gatPlaceholder !== 'undefined') && gatPlaceholder !== '') {
                $(this).parent().addClass("hasdata");
            }

            $(this).on('focusout', function () {
                if ($(this).val() === '') {
                    $(this).parent().removeClass("hasdata");
                }
            })
        }
        if (getEle === 'SELECT') {
            $(this).on('change', function () {
                var Slabel = $(this).find('option:selected').attr('label');
                if (typeof Slabel === 'undefined') {
                    $(this).parent().addClass("hasdata");
                } else {
                    $(this).parent().removeClass("hasdata");
                }
            });
        }
    })

    // $(document).on('focusin', '.ant-input:not(.multi)', function () {
    $(document).on('focusin', '.ant-input', function () {
        var getEle = $(this).prop('tagName');
        if (getEle === 'INPUT' || getEle === 'TEXTAREA') {
            $(this).parents('.antInputItem').addClass("hasdata");
            $(this).on('focusout', function () {
                if ($(this).val() === '') {
                    $(this).parents('.antInputItem').removeClass("hasdata");
                }
            })
        }

    })


    $(document).on('focusin', '.ant-picker', function () {
        var getEle = $(this).find('input');
        if (getEle.val() !== '') {
            $(this).parents('.antInputItem').addClass("hasdata");
        }

    })



    $(document).on('focusin', '.ant-select', function () {
        $(this).parent().addClass("hasdata");
        $(this).parents('.antSelectItem').addClass("hasdata");

        $(this).on('focusout', function () {
            if (($(this).hasClass('ant-select-open')) || ($(this).parents(".antSelectItem").next().hasClass("ant-form-item-has-success"))) {
                $(this).parent().removeClass("hasdata");
                $(this).parents('.antSelectItem').removeClass("hasdata");
            }

            if ($(this).find(".ant-select-selector").text() !== '') {
                $(this).parent().addClass("hasdata");
                $(this).parents('.antSelectItem').addClass("hasdata");
            }
        })
    })


    $(document).on('keyup', 'input[type=text].ant-input, input[type=text].form-control, textarea.form-control, .ant-select-selection-search-input', function () {
        if (!$(this).hasClass('no-case-action')) {
            $(this).val($(this).val().charAt(0).toUpperCase() + $(this).val().slice(1));
        }
    });



    $(document).on('focusin', '.emrfrm .multi-input-col input', function () {
        var This = $(this);
        This.parents('.multi-input-col').find('input').each(function () {
            if ($(this).val() == '') {
                This.parents('.multi-input-col').addClass("hasdata");
            }
        });
        This.on('focusout', function () {
            This.parents('.multi-input-col').find('input').each(function () {
                if (($(this).val() == '') && ($(this).siblings().val() == '')) {
                    This.parents('.multi-input-col').removeClass("hasdata");
                }
            });
        })
    })

    $('.emrfrm .multi-input-col input').each(function () {
        if ($(this).val() != '') {
            $(this).parents('.multi-input-col').addClass("hasdata");
        }
    });

}

export const AntSelectLabels = () => {
    setTimeout(function () {
        $('.ant-select').each(function () {
            let This = $(this);
            if ((This.find('.ant-select-selection-item').attr('title') === '')) {
                This.parent().removeClass("hasdata");
                $(this).parents('.antSelectItem').removeClass("hasdata");
            } else {
                This.parent().addClass("hasdata");
                $(this).parents('.antSelectItem').addClass("hasdata");
            }
        });


        $('.form-control, .ant-input:not(.multi)').each(function () {
            var getEle = $(this).prop('tagName');
            if (getEle === 'INPUT' || getEle === 'TEXTAREA') {
                if ($(this).val() !== '') {
                    $(this).parent().addClass("hasdata");
                    $(this).parents('.antInputItem').addClass("hasdata");
                }
            }
        })

        $('.ant-picker').each(function () {
            var getEle = $(this).find('input');
            if (getEle.val() !== '') {
                $(this).parents('.antInputItem').addClass("hasdata");
            }
        })


    }, 80);

    
    
}

export const AntClearLabels = () => {
    setTimeout(function () {
        $('.ant-select').each(function () {
            let This = $(this);
            if ((This.find('.ant-select-selection-item').attr('title') === '')) {
                This.parent().removeClass("hasdata");
                $(this).parents('.antSelectItem').removeClass("hasdata");
            } else {
                This.parent().addClass("hasdata");
                $(this).parents('.antSelectItem').addClass("hasdata");
            }
        });


        $('.form-control, .ant-input:not(.multi)').each(function () {
            var getEle = $(this).prop('tagName');
            if (getEle === 'INPUT' || getEle === 'TEXTAREA') {
                if ($(this).val() !== '') {
                    $(this).parent().addClass("hasdata");
                    $(this).parents('.antInputItem').addClass("hasdata");
                }else{
                    $(this).parent().removeClass("hasdata");
                    $(this).parents('.antInputItem').removeClass("hasdata"); 
                }
            }
        })

        $('.ant-picker').each(function () {
            var getEle = $(this).find('input');
            if (getEle.val() !== '') {
                $(this).parents('.antInputItem').addClass("hasdata");
            }else{
                $(this).parents('.antInputItem').removeClass("hasdata");
            }
        })

    }, 80);

    
}
export const AntDisabledLabels = () => {
    setTimeout(function () {
        $('.ant-select, .ant-input').each(function () {
            if($(this).hasClass('ant-select-disabled') === true){
                $(this).parents(".antSelectItem").addClass("field_disabled")
            }else{
                $(this).parents(".antSelectItem").removeClass("field_disabled")
            }
            if($(this).hasClass('ant-input-disabled') === true){
                $(this).parents(".antInputItem").addClass("field_disabled")
            }else{
                $(this).parents(".antInputItem").removeClass("field_disabled")
            }
            
        })
    }, 100);
}

export const onlynumbers = () => {
    $(document).on('keypress', '.onlynumbers', function (e) {
        if (e.which !== 32 && e.which !== 0 && (e.which < 48 || e.which > 57) && e.which !== 8) {
            return false;
        }
    });
}

export const FixedCta = () => {
    setTimeout(function () {
        if ($(document).find(".fixed-cta").length > 0) {
            $("#main > .content-wrapper").css('padding-bottom', '80px');
        } else {
            $("#main > .content-wrapper").css('padding-bottom', '');
        }
    }, 500);
}
export const ClearAntList = () => {
        $('.rc-virtual-list-holder-inner').html('');
}
export const ClearSelectSelectionItem = () => {
    $('.ant-select-focused .ant-select-selection-item').html('');
}

export const curentSelectedDate = () => {
    $('.fc-highlight-skeleton tr td').each(function () {
        let parentRow = $(this).parents('.fc-row')
        let index = $('td.fc-highlight').index();
        parentRow.find(".fc-content-skeleton thead tr td").eq(index).addClass('is-active')
        parentRow.find(".fc-content-skeleton tbody tr td").eq(index).addClass('is-active')
        // console.log($('td.fc-highlight').index());

    })
}