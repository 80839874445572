import React, { Component } from "react";
import {
    MENSTRUAL_HISTORY_GRAPH, LOADER_RED
} from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import Axios from 'axios';
import moment from 'moment';
class MenstrualHx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartGraphSettings: "",
            chartDataLoaded: false,
            chartType: "all",
            chartModal: false,
            heading: "",
            chartHeading: "Trend Graph",
            chartData: [],
        };
    }

    componentDidMount() { 
        this.menstrualGraph();
    }

    chartStateUpdate = (data) => {
        this.setState({
            chartGraphSettings: data,
        });
    };

    static getDerivedStateFromProps(props, state) {
        const { patient } = props;
        return {
            patient: {
                patient_id: patient.patient_id,
                reference_id: patient.reference_id,
                reference_type: patient.reference_type,
                doctor_id:LS_SERVICE.get("staff_id"),
                hospital_id:LS_SERVICE.get("slot_hospital_id")
            },
        };
    }

    //MENSTRUAL_HISTORY_GRAPH
    menstrualGraph = async () => {
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;
        
        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id: hospital_id,
            reference_id:reference_id,
            reference_type:0,
        }

        const result = await Axios.get(MENSTRUAL_HISTORY_GRAPH(PARAMS));
        if (result?.data?.data && result?.data?.data.chartdata) {
            this.setState({
                chartData: result?.data?.data.chartdata,
            });
        }
    }

    render() {
        return (
            <>
                {this.props.menstrualHistoryListStatus ?
                    this.props.menstrualHistoryList && this.props.menstrualHistoryList.length > 0 ?  
                    <div className="col-12 mb-4">
                        <div className="card rounded-0 cardbg">
                            <div className="card-body vitals">
                                <div className="row">
                                <div className="col-12 table-responsive">
                                    
                                    <table className="table">
                                        <tbody>

                                            <tr className="">
                                                <th style={{width: "230px",}}>Parameters</th>
                                                { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                    this.props.menstrualHistoryList.map((m, i) => (
                                                        <td key={i} className="" style={{ width: "250px" }}>
                                                            <div className="row">
                                                                <div className="col-12 text-center">
                                                                {m.created_at}
                                                                <br />
                                                                {m.created_at_time}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    ))
                                                : null  }
                                                <th className="text-center" style={{width: "130px",}}>Trend Graph</th>
                                            </tr>
                                            
                                            {/* <tr className="">
                                              <td style={{width: "200px",}}>Mensuration Start Date</td>
                                              { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                  this.props.menstrualHistoryList.map((m, i) => (
                                                      <td key={i} className="" style={{ maxWidth: "200px" }}>
                                                      <div className="row">
                                                      <div className="col-12 text-center">
                                                      {m.lmp && m.lmp != null ? moment(m.lmp).format("DD MMM, YYYY") : '-'}
                                                      </div>
                                                      </div>
                                                      </td>
                                                  ))
                                              : null  }
                                              <td></td>
                                            </tr> */}

                                            <tr className="">
                                                <td style={{width: "230px",}}>Cycle Length (Days)</td>
                                                { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                    this.props.menstrualHistoryList.map((m, i) => (
                                                        <td key={i} className="" style={{ width: "250px" }}>
                                                        <div className="row">
                                                        <div className="col-12 text-center">
                                                        {(m.cycle_length?m.cycle_length:'-')}
                                                        </div>
                                                        </div>
                                                        </td>
                                                    ))
                                                : null  }
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn chart-btn"
                                                        onClick={(e) =>
                                                            this.props.handleModalPopup(e, 'chartModal', { type: 'cycle_length', name: 'Cycle Length (Days)', checkedVal : 'checkedMenstrualCycleLength' })
                                                        }
                                                    >
                                                        <i className="icon-chart"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",}}>Duration of Period
                                                    (Days) </td>
                                                { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                    this.props.menstrualHistoryList.map((m, i) => (
                                                        <td key={i} className="" style={{ width: "250px" }}>
                                                        <div className="row">
                                                        <div className="col-12 text-center">
                                                        {(m.duration_of_period)?m.duration_of_period:'-'}
                                                        </div>
                                                        </div>
                                                        </td>
                                                    ))
                                                : null  }
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn chart-btn"
                                                        onClick={(e) =>
                                                            this.props.handleModalPopup(e, 'chartModal', { type: 'duration_of_period', name: 'Duration of Period (Days)', checkedVal : 'checkedMenstrualDurationOfPeriod' })
                                                        }
                                                    >
                                                        <i className="icon-chart"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",}}>Flow</td>
                                                { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                    this.props.menstrualHistoryList.map((m, i) => (
                                                        <td key={i} className="" style={{ width: "250px" }}>
                                                        <div className="row">
                                                        <div className="col-12 text-center">
                                                        {(m.flow)?m.flow:'-'}
                                                        </div>
                                                        </div>
                                                        </td>
                                                    ))
                                                : null  }
                                                <td></td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",}}>Regularity</td>
                                                { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                    this.props.menstrualHistoryList.map((m, i) => (
                                                        <td key={i} className="" style={{ width: "250px" }}>
                                                        <div className="row">
                                                        <div className="col-12 text-center">
                                                        {(m.regularity)? m.regularity : '-'}
                                                        </div>
                                                        </div>
                                                        </td>
                                                    ))
                                                : null  }
                                                <td></td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",}}>Pain Score</td>
                                                { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                    this.props.menstrualHistoryList.map((m, i) => (
                                                        <td key={i} className="" style={{ width: "250px" }}>
                                                        <div className="row">
                                                        <div className="col-12 text-center">
                                                        {(m.pain_score)? m.pain_score : '-'}
                                                        </div>
                                                        </div>
                                                        </td>
                                                    ))
                                                : null  }
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn chart-btn"
                                                        onClick={(e) =>
                                                            this.props.handleModalPopup(e, 'chartModal', { type: 'pain_score', name: 'Pain Score', checkedVal : 'checkedMenstrualPainScore' })
                                                        }
                                                    >
                                                        <i className="icon-chart"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",}}>Change in Cycle</td>
                                                { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                    this.props.menstrualHistoryList.map((m, i) => (
                                                        <td key={i} className="" style={{ width: "250px" }}>
                                                        <div className="row">
                                                        <div className="col-12 text-center">
                                                        {(m.changes_in_cycle) ? m.changes_in_cycle : '-'}
                                                        </div>
                                                        </div>
                                                        </td>
                                                    ))
                                                : null  }
                                                <td></td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",}}>Remarks</td>
                                                { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                    this.props.menstrualHistoryList.map((m, i) => (
                                                        <td key={i} className="" style={{ width: "250px" }}>
                                                        <div className="row">
                                                        <div className="col-12 text-center" title={m.remarks}>
                                                        {(m.remarks) ? `${m.remarks.substring(0, 40)}${(m.remarks.length>40?'...':'') }` : '-'}
                                                        </div>
                                                        </div>
                                                        </td>
                                                    ))
                                                : null  }
                                                <td></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                :
                <div className="col-12 mb-4">
                        <div className="card rounded-0 cardbg">
                            <div className="card-body vitals">
                                <div className="row">
                                <div className="col-12 table-responsive">
                                    
                                    <table className="table">
                                        <tbody>

                                            <tr className="">
                                                <th style={{width: "230px",}}>Parameters</th>
                                            </tr>
                                            
                                            {/* <tr className="">
                                              <td style={{width: "200px",}}>Mensuration Start Date</td>
                                              { (this.props.menstrualHistoryList !== undefined && this.props.menstrualHistoryList.length > 0) ? 
                                                  this.props.menstrualHistoryList.map((m, i) => (
                                                      <td key={i} className="" style={{ maxWidth: "200px" }}>
                                                      <div className="row">
                                                      <div className="col-12 text-center">
                                                      {m.lmp && m.lmp != null ? moment(m.lmp).format("DD MMM, YYYY") : '-'}
                                                      </div>
                                                      </div>
                                                      </td>
                                                  ))
                                              : null  }
                                              <td></td>
                                            </tr> */}

                                            <tr className="">
                                                <td style={{width: "230px",textAlign: "left",paddingLeft: "10px"}}>Cycle Length (Days)</td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",textAlign: "left",paddingLeft: "10px"}}>Duration of Period (Days) </td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",textAlign: "left",paddingLeft: "10px"}}>Flow</td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",textAlign: "left",paddingLeft: "10px"}}>Regularity</td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",textAlign: "left",paddingLeft: "10px"}}>Pain Score</td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",textAlign: "left",paddingLeft: "10px"}}>Change in Cycle</td>
                                            </tr>

                                            <tr className="">
                                                <td style={{width: "230px",textAlign: "left",paddingLeft: "10px"}}>Remarks</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :(
                    <div className="w-100 align-items-center justify-content-center mt-4 d-flex">{LOADER_RED}</div>
                )}            
            </>
        );
    }
}

export default MenstrualHx;
