import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import Axios from "axios";
import {
    STORE_PHYSICAL_EXAMINATION,
    LIST_PHYSICAL_EXAMINATION,
    DELETE_PHYSICAL_EXAMINATION,
    STORE_PHYSICAL_EXAMINATION_FETAL_PARAMS,
    LIST_PHYSICAL_EXAMINATION_FETAL_PARAMS,
    DELETE_PHYSICAL_EXAMINATION_FP,
    PHYSICAL_EXAM_GRAPH,
    PDF_CONFIG_VITALS_GRAPH,
    FETAL_MOVEMENT,
    PRESENTATION,
    RELATIONSHIP_TO_PP_BRIM, 
    LOADER_RED, 
    LOADER
} from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import { speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import FetalParametersTrendGraph from "../../components/chart/fp-trend-graph";
import LMP from "../../components/gynaecology/LMP";
import GPALE from "../../components/gynaecology/GPALE";
import EDD from "../../components/gynaecology/EDD";
import { Form, Button, Input, Select, Spin, notification, Modal } from "antd";
import { fetusService } from '../../utils/rxjs-sharing';

const { Option } = Select;
class PhysicalExam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitButtonTextMother: 'ADD',
            submitButtonTextFetus: 'ADD', 
            edit_mother_param_id : null, 
            deleteModalTitle: '',
            isDeleteAlertVisible: false,
            spin_loading: false,
            phyExamListStatus: false,
            spin_loading_fetus: false,
            phyExamFPListStatus: false,
            patient: null,
            edema: "",
            pallor: "",
            fundalHeight: "",
            motherRemarks: "",
            no_of_fetus: {...props.current_pregnancy_data}.no_of_fetus,
            multipleFetalParams: [],
            remarks: "",
            edit_fetal_param_id: null,
            physicalExaminationData: [],
            physicalExaminationFPData: [],
            physicalSaveBtn: true,
            chartDataLoaded: false,
            chartType: "all",
            chartsTab: "2",
            chartModal: false,
            chartData: {},
            chartGraphSettings: "",
            gpale_values: props.gpale_values,
            current_pregnancy_data: props.current_pregnancy_data,
            speech: {
                identifier: null,
                state: false
            },
            all_print_checked_status:false,
            update_all_print_checked: false,
            continueModalForNurse: false,
            toddaychartData : true
        };
        this.editIndexListMotherParam = null;
        this.editIndexListFetalParam = null;
        this.no_of_fetus_before_edit = null;
        this.deleteIndex = null;
        this.deleteInnerIndex = null;
        this.deleteType = null;
        this.savePhysicalHandler = this.savePhysicalHandler.bind(this);
        this.FormRef = React.createRef();
        this.TrendGraphRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const { patient, gpale_values, current_pregnancy_data, allowEdit, isPracticing } = props;
        return {
            patient: {
                patient_id: patient.patient_id,
                reference_id: patient.reference_id,
                reference_type: patient.reference_type,
                doctor_id: LS_SERVICE.get("staff_id"),
                hospital_id: LS_SERVICE.get("slot_hospital_id")
            },
            patientData: props.patientData,
            patientDetails : props.patientDetails,
            gpale_values: gpale_values,
            current_pregnancy_data: current_pregnancy_data,
            allowEdit: allowEdit,
            isPracticing: isPracticing
        };
    }

    async componentDidMount() {
        this.fetchPhysicalExaminationData();
        this.fetchPhysicalExaminationFPData();
        this.physicalExamGraph();

        this.FormRef.current.setFieldsValue({
            no_of_fetus: {...this.props.current_pregnancy_data}.no_of_fetus
        });

        if({...this.props.current_pregnancy_data}.no_of_fetus){
            await this.setState(
                {
                    edit_fetal_param_id: null,
                    submitButtonTextFetus: 'ADD',
                    remarks: ''
                }
            );
            this.addFetalParams({...this.props.current_pregnancy_data}.no_of_fetus);
        } else {
            await this.setState({multipleFetalParams: []})
        }

        this.subscription_fetus = fetusService.status().subscribe(async data => {
            this.FormRef.current.resetFields();
            this.setState({ no_of_fetus: data });
            this.FormRef.current.setFieldsValue({
                no_of_fetus: data
            });
            if(data){
                await this.setState(
                    {
                        edit_fetal_param_id: null,
                        submitButtonTextFetus: 'ADD',
                        remarks: ''
                    }
                );
                this.addFetalParams(data);
            } else {
                await this.setState({multipleFetalParams: []})
            }
        });

        this.subscription = speechTextService.status().subscribe((data) => {
            if (this.state.speech.state) {
                if (this.state.speech.identifier === "mother_remarks") {
                    this.setState({ motherRemarks: data.text });
                }
                if (this.state.speech.identifier === "remarks") {
                    this.setState({ remarks: data.text });
                }
                document.querySelector(
                    "#" + this.state.speech.identifier
                ).style.height =
                    document.querySelector("#" + this.state.speech.identifier)
                        .scrollHeight + "px";
            }
          });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscription_fetus) {
            this.subscription_fetus.unsubscribe();
        }
    }

    initSpeech(info) {
        let initialText = '';
        if (info.identifier === 'mother_remarks') {
            initialText = this.state.motherRemarks != undefined ? this.state.motherRemarks : '';
        }
        if (info.identifier === 'remarks') {
            initialText = this.state.remarks != undefined ? this.state.remarks : '';
        }
        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        } else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                setTimeout(() => {
                    this.setState({
                        speech: {
                            identifier: info.identifier,
                            state: true,
                            initialText: initialText
                        }
                    }, () => {
                        speechService.init(this.state.speech);
                    });

                }, 1000);
            });
        }
    }

    showPhysicalExamData() {
        const __physicalExamData = this.state.physicalExaminationData;
        if (__physicalExamData && __physicalExamData.length > 0) {
            return __physicalExamData.map((data,i) => (
                <tr key={data.id}>
                    <td style={(i + 1) == __physicalExamData.length ? {paddingBottom: '20px'} : {}}>
                        {moment(data.createdAt).format("D MMM, YYYY")}
                        {/* <Moment
                            parse="YYYY-MM-DD"
                            date={data.createdAt}
                            format="D MMM, YYYY"
                        /> */}
                    </td>
                    <td className="text-center">{data.edema || '-'}</td>
                    <td className="text-center">{data.pallor || '-'}</td>
                    <td className="text-center">{data.fundalHeight || '-'}</td>
                    <td className="text-center">{data.remarks || '-'}</td>
                    <td className="text-center">
                        <Button className='btn nostyle-link p-0' disabled={!this.state.allowEdit} onClick={() => { this.enableEditMotherParam(data, i); }}>
                            <i className="icon-edit" />
                        </Button>
                        <Button className='btn nostyle-link p-0' disabled={!this.state.allowEdit || data.disable_cancel} onClick={ () => { this.callDelete(i, 'mother_param')} }>
                            <i className="icon_delete" />
                        </Button>
                    </td>
                    {/* <td>
                        {this.getListValue(data.fetalMovement, FETAL_MOVEMENT)}
                    </td>
                    <td>{data.fetalHeartRate || '-'}</td>
                    <td>
                        {this.getListValue(data.presentation, PRESENTATION)}
                    </td>
                    <td>
                        {this.getListValue(
                            data.relPPtoBrim,
                            RELATIONSHIP_TO_PP_BRIM
                        )}
                    </td> */}
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan="6">No data available</td>
                </tr>
            );
        }
    }

    async savePhysicalHandler() {
        const { spin_loading, isPracticing } = this.state;
        if(LS_SERVICE.get('user_type') !== 2){
            if (!isPracticing) {
                this.props.parentHandleModalPopup(null, 'isPracticingModal');
                return;
            }
        }

        if (this.state.speech.identifier != null) {
            this.initSpeech({ identifier: null });
        }

        const edema = this.state.edema;
        const pallor = this.state.pallor;
        const fundalHeight = this.state.fundalHeight;
        const motherRemarks = this.state.motherRemarks ? this.state.motherRemarks.trim() : null;
        /* const fetalMovement = this.state.fetalMovement;
        const fetalHeartRate = this.state.fetalHeartRate;
        const presentation = this.state.presentation;
        const relPPtoBrim = this.state.relPPtoBrim; */

        const { patient_id, doctor_id, hospital_id, reference_id, reference_type } = this.state.patient;

        let param = {
            patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id,
            reference_id,
            reference_type,
            edema,
            pallor,
            fundalHeight,
            motherRemarks
            /* fetalMovement,
            fetalHeartRate,
            presentation,
            relPPtoBrim */
        };

        if (this.state.edit_mother_param_id) {
            param.id = this.state.edit_mother_param_id;
        }

        this.setState({
            spin_loading: !spin_loading
        });

        //Api call
        const result = await Axios.post(STORE_PHYSICAL_EXAMINATION, param);

        if (result?.data?.status) {
            const { spin_loading } = this.state;
            this.setState({
                spin_loading: !spin_loading
            });
            if (this.state.edit_mother_param_id) {
                await notification.success({
                    message: 'Mother Parameters Updated',
                    placement: 'topRight'
                });
            } else {
                await notification.success({
                    message: 'Mother Parameters Saved',
                    placement: "topRight",
                });
            }
            if (this.state.speech.identifier != null) {
                this.initSpeech({ identifier: this.state.speech.identifier });
            }
            await this.clearMotherParamForm();
            this.validatePhysicalExamForm();
            this.setState({ phyExamListStatus: false });
            this.fetchPhysicalExaminationData();
            //this.physicalExamGraph();
        } else {
            notification.error({
                message: result?.data?.message,
                placement: "topRight",
            });
        }
    }

    callDelete = (index, type, innerindex = null) => {
        this.deleteIndex = index;
        this.deleteType = type;
        if(innerindex !== null){
            this.deleteInnerIndex = innerindex;
        }

        let title_1;
        if (type == 'mother_param') {
            title_1 = 'Mother\'s Parameter'
        } else if(type == 'fetal_param') {
            title_1 = 'Fetal\'s Parameter'
        }
        let title = 'Are you sure you want to delete ' + title_1 + '?'

        this.setState({
            deleteModalTitle: title,
            isDeleteAlertVisible: true
        });
    }

    handleDeleteModalOk = async () => {
        //await this.setState({ isDeleteAlertVisible: false });
        if (this.deleteType == 'mother_param') {
            this.deleteMotherParam(this.deleteIndex);
        }
        if (this.deleteType == 'fetal_param') {
            this.deleteFetalParam(this.deleteIndex, this.deleteInnerIndex);
        }
    }

    deleteMotherParam = async (index) => {
        const { spin_loading, isPracticing } = this.state;
        if (!isPracticing) {
            this.props.parentHandleModalPopup(null, 'isPracticingModal');
            return;
        }
        const { patient_id } = this.state.patient;
        let param = {
            patient_id,
            id: this.state.physicalExaminationData[index].id
        }
        this.setState({
            spin_loading: !spin_loading
        });
        //Api call
        const result = await Axios.post(DELETE_PHYSICAL_EXAMINATION, param);

        if (result?.data?.status) {
            const { spin_loading } = this.state;
            this.setState({
                spin_loading: !spin_loading
            });
            await notification.success({
                message: 'Mother Parameters Deleted',
                placement: 'topRight'
            });
            await this.clearMotherParamForm();
            await this.setState({ isDeleteAlertVisible: false });
            this.validatePhysicalExamForm();
            this.setState({ phyExamListStatus: false });
            this.fetchPhysicalExaminationData();
        } else {
            notification.error({
                message: result?.data?.message,
                placement: "topRight",
            });
        }
    };

    enableEditMotherParam = async (data, index) => {
        let physicalExaminationDataArr = this.state.physicalExaminationData;
        physicalExaminationDataArr.forEach((v, i) => {
            v.disable_cancel = false;
        });

        physicalExaminationDataArr[index].disable_cancel = true;
        this.setState({ physicalExaminationData: physicalExaminationDataArr });
        this.editIndexListMotherParam = index;

       // await this.clearMotherParamForm();
        await this.setState({
            edit_mother_param_id: data.id,
            submitButtonTextMother: 'UPDATE',
            edema: data.edema || "",
            pallor: data.pallor || "",
            fundalHeight: data.fundalHeight || "",
            motherRemarks: data.remarks || ""
        });
        this.validatePhysicalExamForm();
    }

    clearMotherParamForm = async () => {
        if(this.state.edit_mother_param_id){
            await this.setState(prevState => {
                const prevItems = [...prevState.physicalExaminationData];
                prevItems[this.editIndexListMotherParam].disable_cancel = false;
                return {physicalExaminationData: prevItems}
            });
        }
        await this.setState(
            {
                edit_mother_param_id: null,
                submitButtonTextMother: 'ADD',
                edema: "",
                pallor: "",
                fundalHeight: "",
                motherRemarks: ''
                /* fetalMovement: "0",
                fetalHeartRate: "",
                presentation: "0",
                relPPtoBrim: "0", */
            }
        );
    }

    //Fetch from api
    async fetchPhysicalExaminationData() {
        const { patient_id, doctor_id } = this.state.patient;
        const param = {
            patient_id, 
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
        };

        const result = await Axios.get(LIST_PHYSICAL_EXAMINATION(param));

        if (result?.data?.status == true) {
            this.setState({ phyExamListStatus: true });

            if (result?.data?.data && result?.data?.data.length > 0) {
                this.setState({ physicalExaminationData: result?.data?.data });              
            }
        }
    }

    numberValidation(value) {
        const reg = /^[0-9]+$/g;
        if (reg.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    alphaNumericValidation(value) {
        const reg = /^[a-zA-Z0-9\s]+$/g;
        if (reg.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    validatePhysicalExamForm() {
        if (
            this.state.edema.trim() != '' ||
            this.state.pallor.trim() != '' ||
            this.state.fundalHeight.trim() != '' 
            /* (this.state.motherRemarks && this.state.motherRemarks.trim() != '') */
            /* this.state.fetalMovement != '0' ||
            this.state.fetalHeartRate.trim() != '' ||
            this.state.presentation != '0' ||
            this.state.relPPtoBrim != '0' */
        ) {
            this.setState({ physicalSaveBtn: false });
        } else {
            this.setState({ physicalSaveBtn: true });
        }
    }

    numberValidation(value) {
        const isInteger = /^[0-9]+$/g;
        if (value === '' || isInteger.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    async physicalExamHandler(e, type = "", index = null) {

        const value = e.target.value;

        if (type === "edema") {
            if (value === "" || this.alphaNumericValidation(value)) {
                this.setState(
                    {
                        [type]: e.target.value,
                    },
                    () => {
                        this.validatePhysicalExamForm();
                    }
                );
            }
        } else if (type === "pallor") {
            if (value === "" || this.alphaNumericValidation(value)) {
                this.setState(
                    {
                        [type]: e.target.value,
                    },
                    () => {
                        this.validatePhysicalExamForm();
                    }
                );
            }
        } else if (type === "fetalHeartRate" || type === "headCC" || type === "abdominalCC" || type === "femurLength") {

            if (value === "" || this.numberValidation(value)) {
                await this.setState(prevState => {
                    const prevItems = [...prevState.multipleFetalParams];
                    prevItems[index][type] = e.target.value;
                    return { multipleFetalParams: prevItems };
                });
            }
        } else {
            this.setState(
                {
                    [type]: e.target.value,
                },
                () => {
                    this.validatePhysicalExamForm();
                }
            );
        }
    }

    async handleModalPopup(e, popup, data = null) {
        if (!popup) return;
        await this.setState({
            [popup]: !this.state[popup],
        });
        let { chartModal } = this.state;

        if (chartModal) {
            this.setState({
                chartDataLoaded: true,
                chartType: (data == null)?this.state.chartType:data.type,
            });
        }
    }

    getDropDownOptions(OptionObj, selectedValue) {
        return Object.keys(OptionObj).map((key, index) => (
            <option
                key={index}
                value={key}
                selected={index === selectedValue ? "selected" : ""}
            >
                {OptionObj[key]}
            </option>
        ));
    }

    getListValue(selectedValue, OptionObj = null) {
        if (selectedValue == 0 || selectedValue === null) {
            return "-";
        } else if (OptionObj) {
            return OptionObj[selectedValue];
        } else {
            return selectedValue;
        }
    }

    async handleChange(event, field) {
        field ? this.setState({
            [field]: event.target.value
        }) : this.setState({
            [event.target.name]: event.target.value
        });
        if (field == 'no_of_fetus' && event.target.value >= 1 && event.target.value <= 9) {
            let new_length = event.target.value;
            let prev_length = this.state.multipleFetalParams.length;
            if (new_length > prev_length) {
                let adds = new_length - prev_length;
                for (let i = 0; i < adds; i++) {
                    const obj = { fetalMovement: null, fetalHeartRate: '', presentation: null, relPPtoBrim: null, headCC: '', abdominalCC: '', femurLength: '' }
                    await this.setState(prevState => {
                        const newItems = [...prevState.multipleFetalParams, obj];
                        return { multipleFetalParams: newItems };
                    });
                }
            } else if (prev_length > new_length) {
                let removes = prev_length - new_length;
                let index = prev_length - 1;
                for (let i = 0; i < removes; i++) {
                    //let prevValues = await this.FormRef.current.getFieldsValue();
                    await this.setState(prevState => ({
                        multipleFetalParams: prevState.multipleFetalParams.filter((data, j) => j !== index)                        
                    }));
                    
                    /* await prevValues['multiple_pregnancies'].splice(index, 1);
                    await this.FormRef.current.setFieldsValue({
                        ...prevValues
                    }) */
                    index--;
                }
                
            }
        }
    }

    async addFetalParams(count) {
        let fetalParamArray = [];
        for (let i = 0; i < count; i++) {
            const obj = { fetalMovement: null, fetalHeartRate: '', presentation: null, relPPtoBrim: null, headCC: '', abdominalCC: '', femurLength: '' }
            fetalParamArray.push(obj);
        }
        await this.setState({
            multipleFetalParams: fetalParamArray
        });
    }

    onSelectChangeListMultiple = async (value, props, field, index) => {
        await this.setState(prevState => {
            const prevItems = [...prevState.multipleFetalParams];
            prevItems[index][field] = value;
            return { multipleFetalParams: prevItems };
        });
    }

    enableEditFetalParam = async (data, index) => {
        if(this.no_of_fetus_before_edit === null){
            this.no_of_fetus_before_edit = this.state.multipleFetalParams.length;
        }
        let physicalExaminationFPDataArr = this.state.physicalExaminationFPData;
        physicalExaminationFPDataArr.forEach((v, i) => {
            v.disable_cancel = false;
        });

        physicalExaminationFPDataArr[index].disable_cancel = true;
        this.setState({ physicalExaminationFPData: physicalExaminationFPDataArr });

        this.editIndexListFetalParam = index;

        this.FormRef.current.setFieldsValue({
            no_of_fetus: data.fetal_params.length
        });
        await this.setState({
            edit_fetal_param_id: data.id,
            submitButtonTextFetus: 'UPDATE',
            no_of_fetus: data.fetal_params.length,
            remarks: data.remarks || ""
        });
  
        let fetalParamArray = [];
        data.fetal_params.forEach((val, i) => {
            if(i === 0){ this.FormRef.current.setFieldsValue({ fetalMovement0: val.fetalMovement, fetalHeartRate0: val.fetalHeartRate }); }
            if(i === 1){ this.FormRef.current.setFieldsValue({ fetalMovement1: val.fetalMovement, fetalHeartRate1: val.fetalHeartRate }); }
            if(i === 2){ this.FormRef.current.setFieldsValue({ fetalMovement2: val.fetalMovement, fetalHeartRate2: val.fetalHeartRate }); }
            if(i === 3){ this.FormRef.current.setFieldsValue({ fetalMovement3: val.fetalMovement, fetalHeartRate3: val.fetalHeartRate }); }
            if(i === 4){ this.FormRef.current.setFieldsValue({ fetalMovement4: val.fetalMovement, fetalHeartRate4: val.fetalHeartRate }); }
            if(i === 5){ this.FormRef.current.setFieldsValue({ fetalMovement5: val.fetalMovement, fetalHeartRate5: val.fetalHeartRate }); }
            if(i === 6){ this.FormRef.current.setFieldsValue({ fetalMovement6: val.fetalMovement, fetalHeartRate6: val.fetalHeartRate }); }
            if(i === 7){ this.FormRef.current.setFieldsValue({ fetalMovement7: val.fetalMovement, fetalHeartRate7: val.fetalHeartRate }); }
            if(i === 8){ this.FormRef.current.setFieldsValue({ fetalMovement8: val.fetalMovement, fetalHeartRate8: val.fetalHeartRate }); }
            const obj = { fetalMovement: val.fetalMovement, fetalHeartRate: val.fetalHeartRate || '', presentation: val.presentation, relPPtoBrim: val.relPPtoBrim, headCC: val.headCc || '', abdominalCC: val.abdominalCc || '', femurLength: val.femurLength || '' }
            fetalParamArray.push(obj);
        });
        await this.setState({multipleFetalParams: fetalParamArray});
    }

    handleFetalParamFormSubmit = async (values) => {
        const { spin_loading_fetus, isPracticing, multipleFetalParams } = this.state;
        if(LS_SERVICE.get('user_type') !== 2){
            if (!isPracticing) {
                this.props.parentHandleModalPopup(null, 'isPracticingModal');
                return;
            }
        }

        const no_of_fetus = this.state.no_of_fetus;
        const remarks = this.state.remarks ? this.state.remarks.trim() : null;

        const { patient_id, doctor_id, hospital_id, reference_id, reference_type } = this.state.patient;

        let param = {
            patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id,
            reference_id,
            reference_type,
            no_of_fetus,
            remarks,
            fetal_params: multipleFetalParams
        };

        if (this.state.edit_fetal_param_id) {
            param.id = this.state.edit_fetal_param_id;
        }

        this.setState({
            spin_loading_fetus: !spin_loading_fetus
        });
        //Api call
        const result = await Axios.post(STORE_PHYSICAL_EXAMINATION_FETAL_PARAMS, param);

        if (result?.data?.status) {
            const { spin_loading_fetus } = this.state;
            this.setState({
                spin_loading_fetus: !spin_loading_fetus
            });
            if (this.state.edit_fetal_param_id) {
                await notification.success({
                    message: 'Fetal Parameters Updated',
                    placement: 'topRight'
                });
            } else {
                await notification.success({
                    message: 'Fetal Parameters Saved',
                    placement: "topRight",
                });
            }
            if (this.state.speech.identifier != null) {
                this.initSpeech({ identifier: this.state.speech.identifier });
            }
            if (this.state.edit_fetal_param_id == null || (this.state.edit_fetal_param_id && this.editIndexListFetalParam == 0)) {
                fetusService.init(
                    this.state.no_of_fetus
                );
            }
            await this.clearFetalParamForm(true);
            this.setState({ phyExamListFPStatus: false });
            this.fetchPhysicalExaminationFPData();
            this.physicalExamGraph();
        } else {
            notification.error({
                message: result?.data?.message,
                placement: "topRight",
            });
        }
    }

    clearFetalParamForm = async (change_latest_fetus = false) => {       
        this.FormRef.current.setFieldsValue({ fetalMovement0: null, fetalHeartRate0: null});
        if(this.state.edit_fetal_param_id){
            await this.setState(prevState => {
                const prevItems = [...prevState.physicalExaminationFPData];
                prevItems[this.editIndexListFetalParam].disable_cancel = false;
                return {physicalExaminationFPData: prevItems}
            });
        }
        if(this.state.edit_fetal_param_id){
            if(this.editIndexListFetalParam == 0 && change_latest_fetus){
                await this.state.multipleFetalParams.forEach(async (v, i) => {
                    await this.setState(prevState => {
                        const prevItems = [...prevState.multipleFetalParams];
                        prevItems[i] = { fetalMovement: null, fetalHeartRate: null, presentation: null, relPPtoBrim: null, headCC: '', abdominalCC: '', femurLength: '' };
                        return { multipleFetalParams: prevItems };
                    });
                });
                this.FormRef.current.resetFields();
            } else {
                this.setState({ no_of_fetus: this.no_of_fetus_before_edit ? this.no_of_fetus_before_edit: null });
                this.FormRef.current.setFieldsValue({
                    no_of_fetus: this.no_of_fetus_before_edit ? this.no_of_fetus_before_edit: null
                });
                if (this.no_of_fetus_before_edit) {
                    await this.addFetalParams(this.no_of_fetus_before_edit);
                } else {
                    await this.setState({ multipleFetalParams: [] })
                }
            }
        } else {
            await this.state.multipleFetalParams.forEach(async (v, i) => {
                await this.setState(prevState => {
                    const prevItems = [...prevState.multipleFetalParams];
                    prevItems[i] = { fetalMovement: null, fetalHeartRate: '', presentation: null, relPPtoBrim: null, headCC: '', abdominalCC: '', femurLength: '' };
                    return { multipleFetalParams: prevItems };
                });
            });
             this.FormRef.current.resetFields();
        }
        this.no_of_fetus_before_edit = null;
        await this.setState(
            {
                edit_fetal_param_id: null,
                submitButtonTextFetus: 'ADD',
                remarks: ''
            }
        );
    }

    deleteFetalParam = async (index, innerindex) => {
        const { spin_loading_fetus, isPracticing } = this.state;
        if (!isPracticing) {
            this.props.parentHandleModalPopup(null, 'isPracticingModal');
            return;
        }
        const { patient_id } = this.state.patient;
        let param = {
            patient_id,
            id: this.state.physicalExaminationFPData[index].id,
            inner_id: this.state.physicalExaminationFPData[index].fetal_params[innerindex].id
        }
        this.setState({
            spin_loading_fetus: !spin_loading_fetus
        });
        //Api call
        const result = await Axios.post(DELETE_PHYSICAL_EXAMINATION_FP, param);

        if (result?.data?.status) {
            const { spin_loading_fetus } = this.state;
            this.setState({
                spin_loading_fetus: !spin_loading_fetus
            });
            await notification.success({
                message: 'Fetal Parameters Deleted',
                placement: 'topRight'
            });
            await this.clearFetalParamForm();
            if(this.deleteIndex == 0){
                let change_fetus = (this.state.no_of_fetus - 1) !== 0 ? (this.state.no_of_fetus - 1) : null;
                fetusService.init(
                    change_fetus
                );
                
                this.FormRef.current.setFieldsValue({
                    no_of_fetus: change_fetus
                });
                if (change_fetus) {
                    await this.addFetalParams(change_fetus);
                } else {
                    await this.setState({ multipleFetalParams: [] })
                }
                await this.setState({ no_of_fetus: change_fetus });
            }
            await this.setState({ isDeleteAlertVisible: false });
            this.setState({ phyExamListFPStatus: false });
            this.fetchPhysicalExaminationFPData();
            this.physicalExamGraph();
        } else {
            notification.error({
                message: result?.data?.message,
                placement: "topRight",
            });
        }
    };

    //Fetch from api
    async fetchPhysicalExaminationFPData() {
        const { patient_id, doctor_id } = this.state.patient;
        const param = {
            patient_id, 
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
        };

        const result = await Axios.get(LIST_PHYSICAL_EXAMINATION_FETAL_PARAMS(param));

        if (result?.data?.status == true) {
            this.setState({ phyExamFPListStatus: true });

            if (result?.data?.data && result?.data?.data.length > 0) {
                this.setState({ physicalExaminationFPData: result?.data?.data });              
            }
        }
    }

    showPhysicalExamFPData() {
        const __physicalExamFPData = this.state.physicalExaminationFPData;
        if (__physicalExamFPData && __physicalExamFPData.length > 0) {
            return __physicalExamFPData.map((data,i) => (
                data.fetal_params.map((d, j) => (
                    <tr key={d.id} className={j != 0 ? 'highlight_bg' : ''}>
                        {j==0 ? 
                            <td rowSpan={data.fetal_params.length}> {moment(data.createdAt).format("D MMM, YYYY")}
                            </td> 
                        : null}
                        <td className="text-center" style={(i + 1) == __physicalExamFPData.length && (j + 1) == data.fetal_params.length ? {paddingBottom: '20px'} : {}}>{j+1}</td>
                        <td className="text-center">{this.getListValue(d.fetalMovement, FETAL_MOVEMENT)}</td>
                        <td className="text-center">{d.fetalHeartRate || '-'}</td>
                        <td className="text-center">{this.getListValue(d.presentation, PRESENTATION)}</td>
                        <td className="text-center">{this.getListValue(d.relPPtoBrim, RELATIONSHIP_TO_PP_BRIM)}</td>
                        <td className="text-center">{d.headCc || '-'}</td>
                        <td className="text-center">{d.abdominalCc || '-'}</td>
                        <td className="text-center">{d.femurLength || '-'}</td>
                        <td style={{wordBreak: "break-all"}} className="text-center">{data.remarks || '-'}</td>
                        <td className="text-center">
                            <Button className='btn nostyle-link p-0' disabled={!this.state.allowEdit} onClick={() => { this.enableEditFetalParam(data, i); }}>
                                <i className="icon-edit" />
                            </Button>
                            <Button className='btn nostyle-link p-0' disabled={!this.state.allowEdit || data.disable_cancel} onClick={() => { this.callDelete(i, 'fetal_param', j) }}>
                                <i className="icon_delete" />
                            </Button>
                        </td>
                    </tr>
                ))
            ));
        } else {
            return (
                <tr>
                    <td colSpan="11">No data available</td>
                </tr>
            );
        }
    }

    physicalExamGraph = async () => {
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;
        
        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            /* hospital_id: hospital_id,
            reference_id:reference_id,
            reference_type:0, */
        }
        
        const result = await Axios.get(PHYSICAL_EXAM_GRAPH(PARAMS));
        if (result?.data?.data && result?.data?.data.chartdata) {
            this.setState({
                chartData: result?.data?.data.chartdata,
            });
        }

        const vitalPDFVitalGraphPromise = await Axios.get(
            PDF_CONFIG_VITALS_GRAPH({
                reference_type: 0,
                reference_id: reference_id,
            })
        );

        if (vitalPDFVitalGraphPromise.data.status) {
            const { data } = vitalPDFVitalGraphPromise;
      
            this.setState({
                chartGraphSettings: data.data.details !== null ? (data.data.details.fetal_params_graph_settings !== null ? data.data.details.fetal_params_graph_settings : "") : ""
            },  () => {
                this.checkedChart(result?.data?.data.chartdata);
            });
          }
    }

    handleGraphClick = (e, type = "2") => {
        var grphStatus = true;
        if(type == 0 && this.state.chartData.day.length === 0){
            grphStatus = false;
        }else if(type == 1 && this.state.chartData.week.length === 0){
            grphStatus = false;
        }else if(type == 2 && this.state.chartData.month.length === 0){
            grphStatus = false;
        }else if(type == 3 && this.state.chartData.year.length === 0){
            grphStatus = false;
        }

        this.setState(
            {
                chartDataLoaded: false,
                chartsTab: type,
                toddaychartData: grphStatus
            },
            () => this.setState({ chartDataLoaded: true })
        );
    };

    chartStateUpdate = (data, all_print_checked = false) => {
        this.setState({
            chartGraphSettings: data,
            all_print_checked_status : all_print_checked, 
        });
    }

    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }

    handleOnFocus = (e) => {
        var identifierValue =
            this.state.speech.identifier === e.target.id
                ? e.target.id
                : this.state.speech.identifier;
        this.setState(
            (prevState) => ({
                speech: {
                    ...prevState.speech,
                    identifier: identifierValue,
                    state: false,
                    initialText:
                        identifierValue === "mother_remarks"
                            ? this.state.motherRemarks
                            : this.state.remarks,
                }
            }),
            () => {
                speechService.init(this.state.speech);
            }
        )
    }

    handleContinue = () => {

        const { patient_id, reference_id, reference_type, hospital_id } = this.state.patient;
        const { patientData } = this.state;

        const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
        const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
        let index = pageListArr.indexOf('physical_examination');
        index++;
        if (index < pageListArr.length && !isTodayVisitArr.includes(reference_id.toString())) {
            let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
            soapurl = soapurl.replace("HOSPITALID", hospital_id);
            soapurl = soapurl.replace("REFID", reference_id);
            soapurl = soapurl.replace("REFTYPE", reference_type);
            soapurl = soapurl.replace("PATID", patient_id);
            this.props.history.push({
                pathname: soapurl,
                state: { patientData }
            })
        } else {
            this.props.history.push({
                pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`,
                state: { patientData }
            })
        }
    }

    checkedChart(constantData) {
        let max_fetal_param_array = [constantData['day'].length, constantData['month'].length, constantData['week'].length, constantData['year'].length];
        
        let fetal_params_constants = [];
        for(let i = 1; i <= Math.max(...max_fetal_param_array); i++){
          fetal_params_constants.push('fhr_' + i);
        }

        let graphLength = 0;
        if(this.state.chartGraphSettings && this.state.chartGraphSettings.trim() !== '') {
            graphLength = this.state.chartGraphSettings.trim().split(",").length;
        }
        
        if(graphLength === fetal_params_constants.length && graphLength !== 0) {
            this.setState({all_print_checked_status: true})
        }
    }

    handleObjectiveContinueForNurse = () => {
        this.setState({ continueModalForNurse: !this.state.continueModalForNurse });
    }

    continueActionForNurse = (action = "") => {
        const { patient } = this.state;
        const { patient_id, reference_type, hospital_id, reference_id } = this.state.patient;
        
    
        this.setState({ continueModalForNurse: false }, () => {
          if (action === "yes") {
            this.props.history.push({
              pathname: `/patient/history/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`,
              state: { patient },
            });
            return;
          }
    
          if (action === "no") {
            this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
            return;
          }
        });
    
      }
      
      showFetalInfo() {
        let show = false;
        const {physicalExaminationFPData } = this.state;
        if (physicalExaminationFPData.length > 0) {
          loop1:
          for (let element of physicalExaminationFPData) {
            if (element.fetal_params.length > 0) {
              loop2:
              for (let fetal_param of element.fetal_params) {
                show = true;
                break loop1;
              }
            }
          }
        }
        
        return show;
      }

    render() {
        return (
            <>
                <div className="row">
                    {/* <MyHcSpeechRecognition /> */}
                    <div className="col-12">
                        
                            <div className="row mt-2">
                                {this.state.current_pregnancy_data.lmpWeeks || this.state.current_pregnancy_data.lmpDays ? 
                                <div className="col">
                                    <div className="form-group pt-1">
                                        <span>POG(D)</span>
                                        <span>:&nbsp;</span>
                                        <span className="font-weight-bold">
                                        {this.state.current_pregnancy_data.lmpWeeks ? <>{this.state.current_pregnancy_data.lmpWeeks} Weeks</> : null}&nbsp;
                                        {this.state.current_pregnancy_data.lmpDays ? <>{this.state.current_pregnancy_data.lmpDays} Days</> : null}
                                        </span>
                                    </div>
                                </div> : null }
                                {this.state.current_pregnancy_data.usgWeeks || this.state.current_pregnancy_data.usgDays ?
                                <div className="col">
                                    <div className="form-group pt-1">
                                        <span>POG(USG)</span>
                                        <span>:&nbsp;</span>
                                        <span className="font-weight-bold">
                                        {this.state.current_pregnancy_data.usgWeeks ? <>{this.state.current_pregnancy_data.usgWeeks} Weeks</> : null}&nbsp;
                                        {this.state.current_pregnancy_data.usgDays ? <>{this.state.current_pregnancy_data.usgDays} Days</> : null}
                                        </span>
                                    </div> 
                                </div> : null }
                                <div className="col">
                                    <div className="form-group pt-1">
                                        <GPALE values={this.state.gpale_values} />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-group pt-1 pl-5">
                                    {(this.state.patientDetails && this.state.patientDetails.lmp_date) ? 
                                        <LMP patient={this.state.patient} patientDetails={this.state.patientDetails} />
                                    : null}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <EDD eddDate={this.state.current_pregnancy_data.eddDate} correctedEddDate={this.state.current_pregnancy_data.correctedEddDate} />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4">
                                    <span>Mother's Parameters</span>
                                </div>
                            </div>
                            <form className="emrfrm">
                                <div className="row pt-2">
                                    <div className="col-3">
                                        <div className={this.state.edema != null && this.state.edema !== '' ? 'form-group hasdata' : 'form-group'}>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                id="edema-physical"
                                                className="form-control"
                                                placeholder="Edema"
                                                maxLength="40"
                                                value={this.state.edema}
                                                disabled={!this.state.allowEdit}
                                                onChange={(e) =>
                                                    this.physicalExamHandler(
                                                        e,
                                                        "edema"
                                                    )
                                                }
                                            />
                                            <label htmlFor="edema-physical">
                                                Edema
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className={this.state.pallor != null && this.state.pallor !== '' ? 'form-group hasdata' : 'form-group'}>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                id="pallor"
                                                className="form-control"
                                                placeholder="Pallor"
                                                maxLength="40"
                                                value={this.state.pallor}
                                                disabled={!this.state.allowEdit}
                                                onChange={(e) =>
                                                    this.physicalExamHandler(
                                                        e,
                                                        "pallor"
                                                    )
                                                }
                                            />
                                            <label htmlFor="pallor">Pallor</label>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className={this.state.fundalHeight != null && this.state.fundalHeight !== '' ? 'form-group hasdata' : 'form-group'}>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                id="fundal-height"
                                                className="form-control"
                                                placeholder="Fundal Height"
                                                maxLength="40"
                                                value={this.state.fundalHeight}
                                                disabled={!this.state.allowEdit}
                                                onChange={(e) =>
                                                    this.physicalExamHandler(
                                                        e,
                                                        "fundalHeight"
                                                    )
                                                }
                                            />
                                            <label htmlFor="fundal-health">
                                                Fundal Height
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-12">
                                        <div className={this.state.motherRemarks ? 'form-group micwrap ant-mic-wrap hasdata' : 'form-group micwrap ant-mic-wrap'}>
                                            <a
                                                disabled={!this.state.allowEdit}
                                                onClick={(!this.state.allowEdit) ? (e) => { e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: 'mother_remarks' }) }}
                                                className={(this.state.speech.identifier === 'mother_remarks' && this.state.speech.state) ? 'mic r-0 on mute' : 'mic r-0'}
                                            ></a>

                                            <textarea
                                                className="form-control speech-field"
                                                rows="1"
                                                placeholder="Remarks"
                                                id="mother_remarks"
                                                // maxlegth="255"
                                                name="mother_remarks"
                                                value={this.state.motherRemarks} 
                                                onChange={(e) =>
                                                    this.physicalExamHandler(
                                                        e,
                                                        "motherRemarks"
                                                    )
                                                }
                                                disabled={!this.state.allowEdit}
                                                onInput={(e) => this.autoHeight(e)}
                                                onFocus={this.handleOnFocus}
                                            ></textarea>
                                            <label htmlFor="mother_remarks">
                                                Remarks
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 py-2 text-right">
                                        <button disabled={!this.state.allowEdit} type="button" key="back" className="btn btn-outline-secondary text-uppercase px-5 mr-3" onClick={async (e) => {await this.clearMotherParamForm(); this.validatePhysicalExamForm();}}>
                                            CANCEL
                                        </button>
                                        <button
                                            type="button"
                                            disabled={this.state.spin_loading || this.state.physicalSaveBtn || !this.state.allowEdit}
                                            className="float-md-right btn btn-outline-primary text-uppercase px-5"
                                            onClick={this.savePhysicalHandler}
                                        >
                                            {this.state.submitButtonTextMother}{this.state.spin_loading ? LOADER : ''}
                                        </button>
                                    </div>
                                </div>
                                <div className="row py-2">
                                {this.state.phyExamListStatus ? 
                                this.state.physicalExaminationData.length ? 
                                <div className="col-12 mb-4">
                                    <div className="card rounded-0 cardbg">
                                        <div className="card-body vitals">
                                            <div className="row">
                                                <div className="col-12 table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr className="text-default">
                                                                <td>
                                                                    Parameters
                                                                </td>
                                                                <td className="text-center">Edema</td>
                                                                <td className="text-center">Pallor</td>
                                                                <td className="text-center">
                                                                    Fundal 
                                                                    Height
                                                                </td>
                                                                <td className="text-center">
                                                                    Remarks
                                                                </td>
                                                                <td className="text-center">
                                                                    Action
                                                                </td>
                                                            </tr>
                                                            {this.showPhysicalExamData()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null 
                                : <div className="w-100 align-items-center justify-content-center mt-4 d-flex">{LOADER_RED}</div> }
                            </div>
                            </form>

                        <Form
                            ref={this.FormRef}
                            onFinish={this.handleFetalParamFormSubmit}
                            layout="vertical"
                            className="emrfrm"
                            style={{fontSize: '16px', color: '#5c5c5c', lineHeight: '1.5'}}>
                            
                            <div className="row">
                                <div className="col-2">
                                    <div className="mt-2 pt-1">Fetal Parameters</div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <div className={this.state.no_of_fetus ? 'antInputItem hasdata' : 'antInputItem'} >
                                            <Form.Item name="no_of_fetus" label="No. Of Fetus" id="no_of_fetus" 
                                                onChange={(e) => this.handleChange(e, 'no_of_fetus')}
                                                initialValue={this.state.no_of_fetus}
                                                rules={[
                                                    { required: true, message: 'Please fill No. Of Fetus.' },
                                                    { pattern: /^[1-9]{1,1}$/, message: 'Not allowed!' },
                                                    /* {max: 8, message: 'Max 8 chars.'} */
                                                ]}
                                            >
                                                <Input autoComplete="off" className="form-control" disabled={!this.state.allowEdit} defaultValue={this.state.no_of_fetus} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { (!this.state.multipleFetalParams || this.state.multipleFetalParams.length === 0) ? null : 
                                this.state.multipleFetalParams.map((data, i) => (
                                    <React.Fragment key={i}>
                                        <div className="row">
                                            <div className="col-auto mt-2 pt-1">
                                                F{i+1}
                                            </div>
                                            <div className="col">
                                                <div className={data.fetalMovement ? 'antSelectItem hasdata' :'antSelectItem'}>

                                                    <Form.Item
                                                        label="Fetal Movement"
                                                        name={`fetalMovement${i}`} 
                                                        id={`fetalMovement${i}`}
                                                        preserve={false}
                                                        rules={[
                                                            { required: true, message: 'Field required.' },
                                                            /* {max: 8, message: 'Max 8 chars.'} */
                                                        ]}
                                                    >

                                                        <Select showSearch style={{ width: 100 + '%' }}                                                        
                                                        // name={`fetalMovement${i}`} 
                                                            optionFilterProp="children"
                                                            onChange={(val, props) => this.onSelectChangeListMultiple(val, props, 'fetalMovement', i)}
                                                            value={data.fetalMovement}
                                                            defaultValue={data.fetalMovement}
                                                            disabled={!this.state.allowEdit}
                                                            filterOption={(input, option) => {
                                                                return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                            }}
                                                            
                                                        >
                                                            {/* <Option value="" label=""></Option> */}
                                                            {FETAL_MOVEMENT !== undefined ? Object.keys(FETAL_MOVEMENT).map((d, i) => (
                                                                <Option key={i} value={d} label={FETAL_MOVEMENT[d]}>{FETAL_MOVEMENT[d]}</Option>
                                                            )) : null}
                                                        </Select>
                                                    
                                                    </Form.Item>
                                                  
                                                </div>
                                            </div>
                                            <div className="col">
                                            <div className={data.fetalHeartRate ? 'antInputItem hasdata' :'antInputItem'}>
                                                <Form.Item
                                                        label="Fetal Heart Rate"
                                                        id={`fetalHeartRate${i}`}
                                                        name={`fetalHeartRate${i}`}
                                                        preserve={false}
                                                        rules={[
                                                                { required: true, message: 'Field required.' },
                                                                {                                                          
                                                                type: 'number',
                                                                max:999,
                                                                message:`Field required.`,
                                                                pattern: /^[0-9]+$/, message: 'Not allowed!',
                                                                transform: function (value) {
                                                                return value === "" || value === undefined ? value : +value
                                                            }
                                                        }]}
                                                    >
                                                    <Input
                                                        autoComplete="off"
                                                        className="form-control"
                                                        type="text"
                                                        maxLength="3"
                                                        value={data.fetalHeartRate}
                                                        defaultValue={data.fetalHeartRate}
                                                        disabled={!this.state.allowEdit}
                                                        onChange={(e) =>
                                                            this.physicalExamHandler(
                                                                e,
                                                                "fetalHeartRate", 
                                                                i
                                                            )
                                                        }
                                                    />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className={data.presentation ? 'form-group hasdata' : 'form-group'}>
                                                    <Select showSearch style={{ width: 100 + '%' }} 
                                                        id={`presentation${i}`} 
                                                        name={`presentation${i}`} 
                                                        optionFilterProp="children"
                                                        onChange={(val, props) => this.onSelectChangeListMultiple(val, props, 'presentation', i)}
                                                        value={data.presentation}
                                                        disabled={!this.state.allowEdit}
                                                        filterOption={(input, option) => {
                                                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                        }}
                                                    >
                                                        {PRESENTATION !== undefined ? Object.keys(PRESENTATION).map((d, i) => (
                                                            <Option key={i} value={d} label={PRESENTATION[d]}>{PRESENTATION[d]}</Option>
                                                        )) : null}
                                                    </Select>
                                                    <label htmlFor={`presentation${i}`}>Presentation</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className={data.relPPtoBrim ? 'form-group hasdata' : 'form-group'}>
                                                    <Select showSearch style={{ width: 100 + '%' }} 
                                                        id={`relPPtoBrim${i}`} 
                                                        name={`relPPtoBrim${i}`} 
                                                        optionFilterProp="children"
                                                        onChange={(val, props) => this.onSelectChangeListMultiple(val, props, 'relPPtoBrim', i)}
                                                        value={data.relPPtoBrim}
                                                        disabled={!this.state.allowEdit}
                                                        filterOption={(input, option) => {
                                                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                        }}
                                                    >
                                                        {RELATIONSHIP_TO_PP_BRIM !== undefined ? Object.keys(RELATIONSHIP_TO_PP_BRIM).map((d, i) => (
                                                            <Option key={i} value={d} label={RELATIONSHIP_TO_PP_BRIM[d]}>{RELATIONSHIP_TO_PP_BRIM[d]}</Option>
                                                        )) : null}
                                                    </Select>
                                                    <label htmlFor={`relPPtoBrim${i}`}>Relation of PP to Brim</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto mt-2 pt-1">&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            <div className="col">
                                                <div className={data.headCC ? 'form-group hasdata' : 'form-group'}>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        id={`headCC${i}`}
                                                        name={`headCC${i}`}
                                                        className="form-control"
                                                        placeholder="Head CC (cm)"
                                                        maxLength="3"
                                                        value={data.headCC}
                                                        disabled={!this.state.allowEdit}
                                                        onChange={(e) =>
                                                            this.physicalExamHandler(
                                                                e,
                                                                "headCC",
                                                                i
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor={`headCC${i}`}>Head CC (cm)</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className={data.abdominalCC ? 'form-group hasdata' : 'form-group'}>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        id={`abdominalCC${i}`}
                                                        name={`abdominalCC${i}`}
                                                        className="form-control"
                                                        placeholder="Abdominal CC (cm)"
                                                        maxLength="3"
                                                        value={data.abdominalCC}
                                                        disabled={!this.state.allowEdit}
                                                        onChange={(e) =>
                                                            this.physicalExamHandler(
                                                                e,
                                                                "abdominalCC",
                                                                i
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor={`abdominalCC${i}`}>Abdominal CC (cm)</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className={data.femurLength ? 'form-group hasdata' : 'form-group'}>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        id={`femurLength${i}`}
                                                        name={`femurLength${i}`}
                                                        className="form-control"
                                                        placeholder="Femur Length (cm)"
                                                        maxLength="3"
                                                        value={data.femurLength}
                                                        disabled={!this.state.allowEdit}
                                                        onChange={(e) =>
                                                            this.physicalExamHandler(
                                                                e,
                                                                "femurLength",
                                                                i
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor={`femurLength${i}`}>Femur Length (cm)</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                            <div className="row">
                                <div className="col-12">
                                    <div className={this.state.remarks ? 'form-group micwrap ant-mic-wrap hasdata' : 'form-group micwrap ant-mic-wrap'}>
                                        <a
                                            disabled={!this.state.allowEdit}
                                            onClick={(!this.state.allowEdit) ? (e) => { e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: 'remarks' }) }}
                                            className={(this.state.speech.identifier === 'remarks' && this.state.speech.state) ? 'mic r-0 on mute' : 'mic r-0'}
                                        ></a>

                                        <textarea
                                            className="form-control speech-field"
                                            rows="1"
                                            placeholder="Remarks"
                                            id="remarks"
                                            // maxlegth="255"
                                            name="remarks"
                                            value={this.state.remarks}
                                            onChange={(e) =>
                                                this.physicalExamHandler(
                                                    e,
                                                    "remarks"
                                                )
                                            }
                                            disabled={!this.state.allowEdit}
                                            onInput={(e) => this.autoHeight(e)}
                                            onFocus={this.handleOnFocus}
                                        ></textarea>
                                        <label htmlFor="remarks">
                                            Remarks
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 py-2 text-right">
                                    <button disabled={!this.state.allowEdit} type="button" key="back" className="btn btn-outline-secondary text-uppercase px-5 mr-3" 
                                    onClick={async (e) => { await this.clearFetalParamForm();}}
                                   // onClick={this.FormRef.current.resetFields()}
                                    >
                                        CANCEL
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={this.state.spin_loading_fetus || !this.state.allowEdit}
                                        className="float-md-right btn btn-outline-primary text-uppercase px-5"
                                    >
                                        {this.state.submitButtonTextFetus}{this.state.spin_loading_fetus ? LOADER : ''}
                                    </button>
                                </div>
                            </div>

                            {this.state.phyExamFPListStatus ? 
                            this.state.physicalExaminationFPData.length && this.showFetalInfo() ? 
                            <div className="row" >
                                <div className="col-12">
                                    <button 
                                        style={{paddingRight: '0'}}
                                        type="button"
                                        className="btn chart-btn float float-md-right"
                                        onClick={(e) =>
                                            this.handleModalPopup(
                                                e,
                                                "chartModal", {
                                                    type: "all"
                                                }
                                            )
                                        }
                                    >
                                        <i className="icon-chart"></i>
                                    </button>
                                </div>
                            </div> : null
                            : null
                            }
                            
                            <div className="row py-2">
                                {this.state.phyExamFPListStatus ? 
                                this.state.physicalExaminationFPData.length && this.showFetalInfo() ? 
                                <div className="col-12 mb-4">
                                    <div className="card rounded-0 cardbg">
                                        <div className="card-body vitals">
                                            <div className="row">
                                                <div className="col-12 table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr className="text-default">
                                                                <td style={{width: "120px"}}>
                                                                    Parameters
                                                                </td>
                                                                <td className="text-center" style={{width: "100px"}}>Fetus No.</td>
                                                                <td className="text-center">Fetal Movement</td>
                                                                <td className="text-center" style={{width: "115px"}}>Fetal Heart Rate</td>
                                                                <td className="text-center">
                                                                    Presentation
                                                                </td>
                                                                <td className="text-center">
                                                                    Relation Of
                                                                    PP To Brim
                                                                </td>
                                                                <td className="text-center">
                                                                    Head CC
                                                                </td>
                                                                <td className="text-center">
                                                                    Abdominal CC
                                                                </td>
                                                                <td className="text-center">
                                                                    Femur Length
                                                                </td>
                                                                <td className="text-center">
                                                                    Remarks
                                                                </td>
                                                                <td className="text-center">
                                                                    Action
                                                                </td>
                                                            </tr>
                                                            {this.showPhysicalExamFPData()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null 
                                : <div className="w-100 align-items-center justify-content-center mt-4 d-flex">{LOADER_RED}</div> }
                            </div>
                        </Form>
                    </div>
                </div>
                {/* || FETAL PARAMETERS CHART MODAL */}
                {this.state.chartModal ? ( 
                    <Modal
                        destroyOnClose={true} 
                        title="Fatal Parameters Trend Graph"
                        visible={this.state.chartModal}
                        onCancel={(e) => this.handleModalPopup(e, "chartModal")}
                        style={{
                            top: "40px",
                            maxWidth: "90%",
                        }}
                        width="100%"
                        className="all_vital"
                        bodyStyle={{ maxHeight: "700px", overflowY: "auto" }}
                        footer={
                            <div className="text-center justify-content-center">
                            <button
                                type="submit"
                                className="btn btn-primary text-uppercase ml-2"
                                onClick={(e) => this.handleModalPopup(e, "chartModal")}
                            >
                                Confirm
                            </button>
                            </div>
                          }
                    >
                        <div className="col d-flex justify-content-around align-items-center" /* className="col d-flex justify-content-around align-items-center wrap_chart_filter_small" */>
                            <ul className="chart_filter">
                                <li>
                                    <button
                                        type="button"
                                        className={`btn btn-default chart-day ${this.state.chartsTab === "0" ? "active" : ""
                                            }`}
                                        onClick={(e) => this.handleGraphClick(e, "0")}
                                    >
                                        DAY
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn btn-default chart-week ${this.state.chartsTab === "1" ? "active" : ""
                                            }`}
                                        onClick={(e) => this.handleGraphClick(e, "1")}
                                    >
                                        WEEK
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn btn-default chart-month ${this.state.chartsTab === "2" ? "active" : ""
                                            }`}
                                        onClick={(e) => this.handleGraphClick(e, "2")}
                                    >
                                        MONTH
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`btn btn-default chart-year ${this.state.chartsTab === "3" ? "active" : ""
                                            }`}
                                        onClick={(e) => this.handleGraphClick(e, "3")}
                                    >
                                        YEAR
                                    </button>
                                </li>
                            </ul>
                        </div>

                        {(this.state.toddaychartData && this.state.chartType === 'all' && this.state.chartDataLoaded && LS_SERVICE.get('user_type') !== 2)?
                        <div className="col-8 pl-2 check-primary">
                            <div className="custom-control custom-checkbox">
                                <input 
                                type="checkbox"
                                checked={this.state.all_print_checked_status}
                                onChange={(e) => this.TrendGraphRef.current.handleFetalParamAPI(e, 'all_print')}
                                className="custom-control-input"
                                id="all_print"
                                name="all_print" autocomplete="off"
                                />
                                <label className="custom-control-label text-basic" htmlFor="all_print">Select All to Print with Prescription</label>
                            </div>
                        </div>
                        :null}
                        {this.state.chartDataLoaded ?
                            <div className="" style={{ marginTop: "22px" }}>
                                <FetalParametersTrendGraph 
                                    allowEdit={this.state.allowEdit} 
                                    isPracticing={this.state.isPracticing} 
                                    parentHandleModalPopup={this.props.parentHandleModalPopup} 
                                    chartData={this.state.chartData} 
                                    dataLoaded={this.state.chartDataLoaded} 
                                    reference_id={this.state.patient.reference_id} 
                                    chartGraphSetting={this.state.chartGraphSettings} 
                                    chartsTab={this.state.chartsTab} 
                                    updateData={this.chartStateUpdate}  
                                    chartTypes={'all'}
                                    ref={this.TrendGraphRef}
                                />
                            </div>
                         : null}
                    </Modal>
                ) : null}
                {/* Common Modal for deleting */}
                <Modal id="alert_confirm_message"
                    className="alert_confirm_message"
                    title={this.state.deleteModalTitle}
                    visible={this.state.isDeleteAlertVisible}
                    onOk={() => this.handleDeleteModalOk()}
                    bodyStyle={{ padding: 0 + 'px' }}
                    onCancel={() => this.setState({ isDeleteAlertVisible: false })}
                    closable={false}
                    footer={[
                        <Button disabled={this.state.spin_loading || this.state.spin_loading_fetus} key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.setState({ isDeleteAlertVisible: false })}>
                            CANCEL
                        </Button>,
                        <Button disabled={this.state.spin_loading || this.state.spin_loading_fetus} key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.handleDeleteModalOk()}>
                            DELETE{(this.state.spin_loading || this.state.spin_loading_fetus) ? LOADER : ''}
                        </Button>,
                    ]}
                >
                </Modal>
                {/** || Continue Modal for nurse */}
                <Modal title={false} closable={false} visible={this.state.continueModalForNurse} footer={false}>
                <div className="row">
                    <div className="col-12 my-3 text-center">
                    <h6>Do you want to add history for this patient?</h6>
                    </div>
                </div>
                <div className="modal-footer justify-content-center">
                    <div className="btn btn-outline-secondary px-5" onClick={(e) => this.continueActionForNurse("no")}>
                    No
                    </div>
                    <div className="btn btn-primary px-5" onClick={(e) => this.continueActionForNurse("yes")}>
                    Yes
                    </div>
                </div>
                </Modal>
                
                <div className="fixed-cta">
                    {(this.props.pathname && (this.props.pathname.includes("objective") || LS_SERVICE.get('user_type') === 2)) ? 
                        <a href="javascript:void(0)" className="btn btn-primary px-4 py-1 nostyle-link" onClick={(LS_SERVICE.get('user_type') === 2) ? this.handleObjectiveContinueForNurse : this.handleContinue}>CONTINUE</a> : null}
                </div>
            </>
        );
    }
}

export default PhysicalExam;
