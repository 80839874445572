import React, { Component, Fragment } from "react";
import { Button, Modal, Form, Input, notification } from "antd";
import axios from "axios";
import { GET_ALL_VARIENT_WORD, SAVE_VARIENT_WORD, DELETE_VARIENT_WORD, LOADER_RED } from "../../utils/constant";
import qs from "qs";
import LS_SERVICE from "../../utils/localStorage";


class IntuitiveWordVarients extends Component {

  constructor() {
    super();
    this.state = {
      editWord: '',
      varientList: [],
      wordModal: false,
      edit_id: null,
      delete_id: false,
      deleteConfirmModal: false,
      listingLoader: true,
    };
    this.wordFormRef = React.createRef();
  }

  async componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      LS_SERVICE.get("token");
    this.varientWordData();
  }

  async varientWordData() {
    let params = {
    };
    await axios.get(GET_ALL_VARIENT_WORD, qs.stringify(params)
    ).then((success) => {
      this.setState({
        varientList: success.data.data,
        listingLoader: false,
      })
    }).catch((err) => {
      console.log(err);
      this.setState({
        listingLoader: false,
      });
    });
  }

  addWords() {
    this.setState({
      wordModal: true,
    })
  }

  async saveIntuitiveVarient() {
    var params = {};
    this.setState({
      saveModalButton: false
    })
    const { words_name, varient_name } = this.wordFormRef.current.getFieldValue();
    if (words_name != undefined && varient_name != undefined) {
      if (this.state.edit_id != null) {
        params = {
          id: this.state.edit_id,
          name: words_name,
          varient: varient_name
        };
      } else {
        params = {
          name: words_name,
          varient: varient_name
        };
      }
      await axios.post(SAVE_VARIENT_WORD, params
      ).then((success) => {
        notification.success({
          message: this.state.edit_id ? 'word and type updated succesfully' : 'word and type save succesfully',
          placement: 'topRight'
        });
        this.onCancelWordModel();
        this.varientWordData();
      }).catch((err) => {
        notification.error({
          message: 'word and type not save',
          placement: 'topRight'
        });
        console.log(err)
      });
    } else {
      notification.error({
        message: 'word and varient can not be empty',
        placement: 'topRight',
      })
    }
  }

  async editView(id, word, type) {
    await this.setState({
      wordModal: true,
      editWord: word,
      edit_id: id,
    })
    this.wordFormRef.current.setFieldsValue({
      words_name: word,
      varient_name: type,
    });
  }

  async deleteWord(id) {
    await this.setState({
      deleteConfirmModal: true,
      delete_id: id,
    })
  }
  async deleteWordVarient() {
    let params = {
      id: this.state.delete_id
    }
    await axios.post(DELETE_VARIENT_WORD, qs.stringify(params)
    ).then((success) => {
      this.setState({
        deleteConfirmModal: false,
        delete_id: '',
      })
      this.varientWordData();
      notification.success({
        message: 'word deleted succesfully',
        placement: 'topRight'
      });
    }).catch((err) => {
      notification.error({
        message: 'word not deleted ',
        placement: 'topRight'
      });
      console.log(err)
    });
  }

  handleModalPopup() {
    this.setState({
      deleteConfirmModal: false,
      delete_id: '',
    })
  }

  canceldeletePopup() {
    this.setState({
      deleteConfirmModal: false,
      delete_id: '',
    })
  }

  onCancelWordModel() {
    this.wordFormRef.current.setFieldsValue({
      words_name: '',
      varient_name: ''
    });
    this.setState({
      wordModal: false,
      editWord: '',
      edit_id: '',
    })
  }

  templaterowload() {

    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{
              textAlign:
                "center",
            }}>ID</th>
            <th style={{
              textAlign:
                "center",
            }}>VARIENT WORD</th>
            <th style={{
              textAlign:
                "center",
            }}>TYPE</th>
            <th style={{
              textAlign:
                "center",
            }}>ACTION</th>
          </tr>
        </thead>
        <tbody id="template-list"></tbody>
        <>
          {
            this.state.varientList.length == 0 ?
              (<><td></td><td className="text-center">No Records Found.</td><td></td><td></td></>)
              :
              this.state.varientList.map((template, i) => {
                return (
                  <tr>
                    <td style={{
                      textAlign:
                        "center",
                    }}>{template.id}</td>
                    <td style={{
                      textAlign:
                        "center",
                    }}>{template.word}</td>
                    <td style={{
                      textAlign:
                        "center",
                    }}>{template.varient}</td>
                    <td style={{
                      textAlign:
                        "center",
                    }}><Button
                      type="link p-0"
                      className="nostyle-link mr-2"
                      onClick={() =>
                        this.editView(
                          template.id, template.word, template.varient
                        )
                      }
                    >
                        <i className="icon-edit"></i>
                      </Button>
                      &nbsp;
                      <Button
                        type="link p-0"
                        className="nostyle-link data-delete"
                        onClick={() =>
                          this.deleteWord(
                            template.id
                          )
                        }
                      >
                        <i className="icon_delete"></i>
                      </Button></td>
                  </tr>
                )
              })
          }
        </>
      </table >
    );
  }
  render() {
    return (
      <Fragment>
        {
          this.state.listingLoader == true ? (
            <div className="col-12 text-center">{LOADER_RED}</div>
          )
            :
            <div className="container-fluid">
              <div className="row">
              </div>
              <div className="row">
                <>
                  <div className="col-md-3">
                    <h1 className="page-title mb-4">
                      Intutive Varient Word
                    </h1>
                  </div>
                  <div className="col-md-6">
                  </div>
                  <div className="col-md-3">
                    <Button
                      style={{ float: "right" }}
                      type="primary" className="px-4"
                      onClick={e => this.addWords()
                      }
                    >
                      Add
                    </Button>
                  </div>
                </>
              </div>
              <div classname="row"></div>
              <div classname="row"
                className=" table-responsive"
                id="order-set-tab">

                {this.templaterowload()}
              </div>

              <>
                <Modal
                  title={this.state.edit_id ? "Update Intutive Varient Word" : "Add Intutive Varient Word"}
                  centered
                  className="emrfrm"
                  visible={this.state.wordModal}
                  // onOk={() => setModal2Visible(false)}
                  onCancel={() => this.onCancelWordModel()}
                  footer={[
                    <div className="text-center">
                      <Button type="primary" className="px-4" htmlType="submit"
                        onClick={() => this.saveIntuitiveVarient()}>
                        {this.state.edit_id ? 'UPDATE' : 'SAVE'}
                      </Button>
                    </div>,
                  ]}

                >
                  <Form
                    ref={this.wordFormRef}
                    className='emrfrm'
                    layout="vertical"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="antInputItem hasdata">
                          <Form.Item
                            name="words_name"
                            // id="word_name"
                            label="Intutive Varient Word"
                            rules={[
                              { required: true, message: "Please enter words" },
                              {
                                pattern: /^[A-Za-z01-9]+$/,
                                message: " special charcter or spaces not allowed ",
                              },
                            ]}
                          >
                            <Input autoFocus={true}
                              name="words_name"
                            />
                          </Form.Item>
                          <Form.Item
                            name="varient_name"
                            label="Intuitive Varient Type"
                            rules={[
                              { required: true, message: "Please enter words" },
                              {
                                pattern: /^[A-Za-z01-9]+$/,
                                message: " special charcter or spaces not allowed ",
                              },
                            ]}
                          >
                            <Input autoFocus={true}
                              name="varient_name"
                            />
                          </Form.Item>
                      </div>
                    </div>
                  </div>
                  </Form>
                </Modal>
                <Modal
                  title={false}
                  visible={this.state.deleteConfirmModal}
                  onCancel={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}
                  footer={false}
                  closable={false}
                >
                  <div className="row mb-3">
                    <h6 className="col-12 text-center">Are you sure to delete?</h6>
                  </div>
                  <div className="modal-footer justify-content-center emrfrm pb-0">
                    <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.canceldeletePopup(e, 'deleteConfirmModal')}>CANCEL</button>
                    <button type="button" className="btn btn-primary px-5" onClick={(e) => this.deleteWordVarient(e)}>DELETE</button>
                  </div>
                </Modal>
              </>

            </div>
        }
      </Fragment >
    );
  }
}
export default IntuitiveWordVarients;
